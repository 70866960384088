import React, { useEffect, useState } from 'react'
import send_comment_icon from 'common/assets/svg/send_comment_icon.svg'
import styles from './AIComment.module.scss'
import { message } from 'antd'
import {
  useLazyGenerateCommentQuery,
  useLazyGetSeedsQuery,
  useLazyImproveCommentQuery,
  useLazySendCommentQuery,
} from 'common/APIs/HeatboxApi'
import TextArea from 'antd/es/input/TextArea'
import AICommentImprove from 'common/components/Heatbox/Actions/AICommentImprove'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_AI_COMMENT, SET_CHECK_COMMENT } from 'common/components/Heatbox/Utils/HeatboxSlice'
import SuggestionsList from 'common/components/Heatbox/UI/SuggestionsList'
import { useUser } from 'Utils/UserContext'
import { useParams } from 'react-router-dom'
import { TrackHeatboxActionData } from '../Heatbox'
import { CUE_CONSTANTS } from '../Utils/CardUtils'

interface AICommentProps {
  open: boolean
  onClose: () => void
  setComment: (comment: string) => void
  card: any
  trackings: {
    trackHeatboxAction: (data: TrackHeatboxActionData, additional?: any) => void
    trackCuesAction: (data: TrackHeatboxActionData, additional?: any) => void
  }
}

const suggestions = [
  {
    text: `Make a simple but thoughtful comment to engage`,
    numberOfBoldWords: 3,
  },
  {
    text: `Subtly reference your solution without being overly promotional`,
    numberOfBoldWords: 2,
  },
  {
    text: `Just add value to the readers of the post`,
    numberOfBoldWords: 3,
  },
  // {
  //   text: `Promote my product by 5% and 95% give value`,
  //   numberOfBoldWords: 1,
  // },
  // {
  //   text: `Only give value to other people reading to prepare for outreach`,
  //   numberOfBoldWords: 3,
  // },
  // {
  //   text: `Cheer up to build a relationship with the prospect`,
  //   numberOfBoldWords: 2,
  // },
]

// A helper function to animate text letter-by-letter.
const animateTyping = (text: string, setter: (value: string) => void, speed: number = 50): Promise<void> => {
  if (!text) return Promise.resolve()
  return new Promise((resolve) => {
    let index = 0
    setter('') // Clear the field before starting
    const interval = setInterval(() => {
      setter(text.slice(0, index + 1))
      index++
      if (index === text.length) {
        clearInterval(interval)
        resolve()
      }
    }, speed)
  })
}

const AIComment: React.FC<AICommentProps> = ({ open, onClose, setComment, card, trackings }) => {
  const dispatch = useAppDispatch()
  const [generateComment] = useLazyGenerateCommentQuery()
  const [improveComment] = useLazyImproveCommentQuery()
  const [sendComment] = useLazySendCommentQuery()
  const [getSeeds] = useLazyGetSeedsQuery()
  const [inputValue, setInputValue] = useState('')
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [auroraActive, setAuroraActive] = useState(false)
  const { AIComment } = useAppSelector((state) => state.heatbox)
  const view = useParams<any>()
  const subUrl = view?.['*']
  const isCues = subUrl === 'cues'
  const { user } = useUser()
  const isReddit = card?.channel === 'reddit'
  const HEATBOX_USERID = user?.id
  const handleSelectSuggestion = async (suggestion: { text: string }) => {
    setShowSuggestions(false)
    setDisableButton(true)
    // Activate the aurora effect and set the input to "Thinking..."
    setAuroraActive(true)
    setInputValue('Thinking...')

    // Fire the API call while the aurora animation is active.
    const { data } = await generateComment({
      user_id: HEATBOX_USERID,
      post_content: card?.content?.text || card?.content?.content?.text || card?.content?.title,
      tone: 'natural',
      comment_type: suggestion.text,
    })

    // Introduce a minimum delay to ensure the animation lasts at least 1.5 seconds.
    const minDelay = 1250 // 1.25 seconds
    const timeToWait = Math.max(minDelay, 500) // Ensure the delay is at least 0.5 seconds if the API call is very fast

    // Wait for the minimum delay
    await new Promise((resolve) => setTimeout(resolve, timeToWait))

    // Turn off the aurora effect before animating the final text.
    setAuroraActive(false)
    if (data) {
      await animateTyping(data, setInputValue, 20)
      if (isCues) {
        trackings.trackCuesAction({
          action_type: 'generate_comment_with_ai',
          card: card,
          input: {
            selection_of_comment: suggestion.text,
          },
        })
      } else {
        trackings.trackHeatboxAction({
          action_type: 'generate_comment_with_ai',
          card: card,
          input: {
            selection_of_comment: suggestion.text,
          },
        })
      }
    } else {
      setInputValue('')
      message.error('Something went wrong generating your message. Please try again.')
    }
    setDisableButton(false)
  }

  const handleInputChange = (e: any) => {
    const value = e.target.value
    setInputValue(value)
    if (value?.trim() === '') {
      setShowSuggestions(true)
    } else {
      setShowSuggestions(false)
    }
  }

  const handleDiscard = () => {
    setShowSuggestions(false)
    setInputValue('')
    setTimeout(() => {
      onClose()
    }, 200)
  }

  const handleImprove = async (type: string) => {
    if (!inputValue) {
      message.error('No comment to improve')
      return
    }
    try {
      const tempInputValue = inputValue
      setDisableButton(true)
      setAuroraActive(true)
      setInputValue('Thinking...')

      const body = {
        original_comment: inputValue,
        improvement_instruction: type,
      }

      const res = await improveComment(body)

      const { data: result } = res
      if (subUrl === 'heatbox') {
        trackings.trackHeatboxAction({
          action_type: 'improve_comment_with_ai',
          card: card,
          command: type,
          input: {
            original_comment: inputValue,
            improved_comment: result,
          },
        })
      } else {
        trackings.trackCuesAction({
          action_type: 'improve_comment_with_ai',
          card: card,
          command: type,
          input: {
            original_comment: inputValue,
            improved_comment: result,
          },
        })
      }
      if (!result || res.isError) {
        message.error('Error in improving AI Comment')
        setInputValue(tempInputValue)
        setDisableButton(false)
        setAuroraActive(false)
        return
      }

      setAuroraActive(false)
      await animateTyping(result, setInputValue, 20)
      setDisableButton(false)
      message.success('AI Comment improved successfully!')
    } catch (e) {
      message.error('Error in improving AI Comment')
      console.error(e)
    }
  }
  const handleSendComment = async () => {
    if (disableButton) return
    setShowSuggestions(false)
    setInputValue('')
    onClose()

    const result = await sendComment({
      user_id: HEATBOX_USERID,
      content_id: card?.content_id,
      comment_content: inputValue,
      content: isReddit ? card?.content?.content?.text : card?.content?.text,
      lead_id: isReddit ? card?.lead?.id : card?.lead?.username || card?.lead_id,
      type: card?.content_type,
      post_url: isReddit
        ? card?.url
        : isCues
        ? `https://www.linkedin.com/feed/update/urn:li:activity:${card?.content?.urn}/`
        : card?.content_type === 'comment'
        ? card?.content?.comment_url
        : card?.content?.post_url,
      is_cue: isCues,
      is_reddit: isReddit,
    })
    if (result?.isError || result?.data?.status === 'error') {
      if (result?.error?.status === 470) {
        message.error(CUE_CONSTANTS.SEEDS_LIMIT_MESSAGE)
        return
      }
      if (result?.data?.message === 'User not found') {
        message.error('No Linkedin account found. Please connect your Linkedin account to proceed.')
        return
      }
      message.error('Error in sending comment')
      return
    }
    setComment(inputValue)
    await getSeeds({ user_id: HEATBOX_USERID })
    if (isCues) {
      trackings.trackCuesAction({
        action_type: 'comment',
        card: card,
        input: {
          comment_text: inputValue,
        },
      })
    } else {
      trackings.trackHeatboxAction({
        action_type: 'comment',
        card: card,
        input: {
          comment_text: inputValue,
        },
      })
    }
    dispatch(SET_CHECK_COMMENT(true))
  }

  useEffect(() => {
    if (AIComment) {
      setInputValue(AIComment)
      dispatch(SET_AI_COMMENT(''))
    }
  }, [open])

  if (!open) return null

  return (
    <>
      <div className={styles.aiComment}>
        <div className={styles.inputSection}>
          <TextArea
            autoSize={{ minRows: 1, maxRows: 2 }}
            // Conditionally apply the aurora class when active.
            className={auroraActive ? styles.auroraText : ''}
            value={inputValue}
            onChange={handleInputChange}
            onFocus={() => {
              if (inputValue?.trim() === '') {
                setShowSuggestions(true)
              }
            }}
            onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
          />
          <div className={styles.actions}>
            <span className={styles.discard} onClick={handleDiscard}>
              Discard
            </span>
            <AICommentImprove onImprove={handleImprove} disabled={inputValue?.trim() === ''} />
          </div>
        </div>
        <div className={styles.sendCommentSection}>
          <img src={send_comment_icon} alt='Send comment icon' onClick={handleSendComment} />
        </div>
      </div>

      <SuggestionsList
        options={suggestions}
        visible={showSuggestions}
        onSelectSuggestion={(option) => {
          handleSelectSuggestion(option)
        }}
        color='black'
        boldColor='#7043ff'
      />
    </>
  )
}

export default AIComment
