import styles from './CustomCard.module.scss'
import heatbox_contact_stamp from 'common/assets/svg/heatbox_contact_stamp.svg'
import linkedin_icon from 'common/assets/svg/linkedin_icon.svg'
import reddit_icon from 'common/assets/svg/reddit_icon.svg'
import { RightOutlined } from '@ant-design/icons'
import { motion } from 'framer-motion'
import { useUser } from 'Utils/UserContext'
import { Card } from '../CardStack'
import React, { useState } from 'react'
import ContentSection from 'common/components/Heatbox/CustomCard/ContentSection'
import RightPanelHeatbox from 'common/components/Heatbox/CustomCard/RightPanelHeatbox'
import RightPanelCues from 'common/components/Heatbox/CustomCard/RightPanelCues'
import { extractSubreddit } from 'common/components/Heatbox/Utils/CardUtils'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'

interface CustomCardProps {
  comment?: any
  card?: Card
  loading?: boolean
  screenType?: string
  commentStatus?: string
  isEmpty?: boolean
}

const CustomCard: React.FC<CustomCardProps> = ({ comment, card, loading, screenType, commentStatus, isEmpty }) => {
  const navigate = useNavigate()
  const { user } = useUser()
  const [useHeatboxPanel, setUseHeatboxPanel] = useState(false)
  const isPost = card?.content_type === 'post'
  const isRepost = card?.content?.is_repost === true
  const type = isRepost ? 'repost' : isPost ? 'post' : 'comment'
  const name = user?.name
  const list_name = card?.list_name || 'your list of prospects'

  const toggleHeatboxPanel = () => {
    setUseHeatboxPanel(!useHeatboxPanel)
  }

  // const content = typeof card?.content === 'string' ? JSON.parse(card?.content) : card?.content
  let content: any = {}
  try {
    content = JSON.parse(card?.content)
  } catch (error) {
    content = card?.content
  }
  // const content = card?.content
  const source = content?.post_url?.includes('https://www.linkedin.com') ? 'linkedin' : 'reddit' // to replace with source when available
  const subReddit = source === 'reddit' && extractSubreddit(content?.url)

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.1,
      },
    },
  }

  const itemVariants = {
    hidden: {
      opacity: 0,
      y: -5,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 24,
      },
    },
  }

  const aboveCommentVariants = {
    initial: { y: 0 },
    animate: {
      y: comment ? -5 : 0,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
      },
    },
  }

  const belowCommentVariants = {
    initial: { y: 0 },
    animate: {
      y: comment ? 5 : 0,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
      },
    },
  }

  if (loading) {
    return (
      <div className={styles.card}>
        <div className={styles.left}>
          {/* <Skeleton active paragraph={{ rows: 3 }} />
          <Skeleton.Button active style={{ width: '100%', height: '150px' }} /> */}
        </div>

        <img src={heatbox_contact_stamp} alt='' className={styles.stamp} />

        <div className={styles.right}>
          {/* <Skeleton.Avatar active size='large' /> */}
          {/* <Skeleton active paragraph={{ rows: 4 }} /> */}
        </div>
      </div>
    )
  }

  if (isEmpty) {
    return (
      <div
        className={styles.emptyCard}
        style={{ backgroundColor: screenType === 'cues' ? 'rgba(112, 67, 255, 0.8)' : 'rgba(255, 222, 170, 0.8)' }}>
        <div className={styles.emptyCardText}>
          <p>That's it!</p>
          <span>You completed all {screenType === 'cues' ? 'cues' : 'heatboxes'} for today.</span>
          <Button
            style={{ borderColor: screenType === 'cues' ? 'rgba(112, 67, 255, 0.8)' : 'rgba(255, 222, 170, 0.8)' }}
            onClick={() => navigate('/cues/seeds')}>
            Go to Seeds
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.card} style={screenType === 'cues' ? { gap: '0px' } : {}}>
      <motion.div className={styles.left} initial='hidden' animate='visible' variants={containerVariants}>
        <div className={styles.header}>
          <p className={styles.source}>
            <img src={source === 'linkedin' ? linkedin_icon : reddit_icon} alt='' className={styles.platformIcon} />{' '}
            {subReddit ? (
              <b>{subReddit}</b>
            ) : (
              <>
                source <RightOutlined style={{ fontSize: '10px' }} />{' '}
                <b>{screenType === 'cues' ? 'CuesAI' : list_name}</b>
              </>
            )}
          </p>
        </div>
        <ContentSection
          type={type}
          content={content}
          card={card}
          comment={comment}
          commentStatus={commentStatus}
          name={name}
          aboveCommentVariants={aboveCommentVariants}
          belowCommentVariants={belowCommentVariants}
          itemVariants={itemVariants}
          toggleHeatboxPanel={toggleHeatboxPanel}
        />
      </motion.div>
      {screenType === 'heatbox' && <img src={heatbox_contact_stamp} alt='' className={styles.stamp} />}
      {screenType === 'heatbox' ? (
        <RightPanelHeatbox card={card} loading={loading} />
      ) : screenType === 'cues' ? (
        useHeatboxPanel ? (
          <RightPanelHeatbox
            card={card}
            loading={loading}
            useHeatboxPanel={useHeatboxPanel}
            toggleHeatboxPanel={toggleHeatboxPanel}
          />
        ) : (
          <RightPanelCues card={card} loading={loading} />
        )
      ) : (
        <></>
      )}
    </div>
  )
}

export default CustomCard
