import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { motion, useAnimationControls, AnimatePresence } from 'framer-motion'
import styles from './CardStack.module.scss'
import VerticalDots from 'common/components/Heatbox/VerticalDots'
import CardStackActions from 'common/components/Heatbox/CardStackActions'
import CustomCard from 'common/components/Heatbox/CustomCard/CustomCard'
import AIComment from 'common/components/Heatbox/Actions/AIComment'
import { useHotkeys } from 'react-hotkeys-hook'
import KeyboardShortcut from 'common/components/Heatbox/UI/KeyboardShortcut'
import { useAppDispatch, useAppSelector } from 'state'
import {
  SET_CHECK_COMMENT,
  SET_CHECK_LIKE,
  SET_LIKED,
  SET_MENU_OPEN,
  SET_OPEN_AI_COMMENT,
} from 'common/components/Heatbox/Utils/HeatboxSlice'
import { useLazyGetCommentQuery, useLazyGetLikeQuery } from 'common/APIs/HeatboxApi'
import { TrackHeatboxActionData } from './Heatbox'

export interface Card {
  content_id: any
  author_id?: any
  content?: any
  lead?: any
  content_score?: any
  content_type?: any
  updated_time?: any
  created_time?: any
  heatbox_score?: any
  lead_score?: any
  marks?: any
  topic?: any
  analysis?: any
  channel?: any
  list_name?: any
  last_engagement?: any
  url?: any
}

const cardVariants = {
  placeholder: ({ index, screenType }: { index: number; screenType: any }) => ({
    backgroundColor:
      screenType === 'heatbox'
        ? '#FF7171'
        : screenType === 'cues'
        ? '#7043ff'
        : screenType === 'seeds'
        ? '#20BA6D'
        : '#7043ff',
    scale: 1 - index * 0.04,
    opacity: index === 0 || index === 1 ? 1 : 1 - index * 0.25,
    y: index * -20,
    transition: { duration: 0.3, ease: 'easeInOut' },
  }),
  top: {
    backgroundColor: '#fff',
    scale: 1,
    opacity: 1,
    y: 0,
    transition: { duration: 0.01, ease: 'easeInOut' },
  },
}

interface CardStackProps {
  cards: Card[]
  setCards: Dispatch<SetStateAction<Card[]>>
  isLoading: boolean
  onSkip: (mark: 'skip' | 'bad' | 'stop_heatbox' | 'already_seen' | 'not_relevant') => void
  screenType: 'heatbox' | 'cues' | 'seeds' | any
  functions: {
    handleAddToSeeds: () => Promise<boolean>
    handleStepBack: () => void
    trackHeatboxAction: (data: TrackHeatboxActionData, additional?: any) => void
    trackCuesAction: (data: TrackHeatboxActionData, additional?: any) => void
  }
}

const CardStack: React.FC<CardStackProps> = ({ cards, setCards, isLoading, onSkip, screenType, functions }) => {
  const dispatch = useAppDispatch()
  const [getComment] = useLazyGetCommentQuery()
  const [getLike] = useLazyGetLikeQuery()
  // A flag so that we can disable swipe buttons while animating
  const [isSwiping, setIsSwiping] = useState(false)
  const controls = useAnimationControls()
  const [isAICommentVisible, setIsAICommentVisible] = useState(false)
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false)
  const [comment, setComment] = useState('')
  const [commentStatus, setCommentStatus] = useState('')
  const { openAIComment, menuOpen, checkComment, killedCard, checkLike } = useAppSelector((state) => state.heatbox)
  const [showKeyboardShortcut, setShowKeyboardShortcut] = useState(false)
  const isCues = screenType === 'cues'
  const totalCount = 30
  const topCardRef = useRef(cards?.[0])
  useHotkeys('mod+k', () => setIsAICommentVisible(!isAICommentVisible))
  useHotkeys('mod+right', () => handleSkip('skip'))
  useHotkeys('mod+down', () => dispatch(SET_MENU_OPEN(!menuOpen)))

  const swipe = async (direction: 'left' | 'right') => {
    if (cards?.length === 0) return

    setIsSwiping(true)
    await controls.start({
      x: direction === 'left' ? -window.innerWidth : window.innerWidth,
      opacity: 0.5,
      transition: { duration: 1 },
    })

    setComment('')
    setCards((prev) => prev.slice(1))
    controls.set({ x: 0, opacity: 1 })
    setIsSwiping(false)
  }

  const handleSkip = (mark: 'skip' | 'bad' | 'stop_heatbox' | 'already_seen' | 'not_relevant') => {
    swipe('right')
    onSkip(mark)
  }

  const getCommentHandler = async () => {
    const card = topCardRef.current
    if (card) {
      const isReddit = card?.channel === 'reddit'
      const { data } = await getComment({
        content_id: isReddit
          ? card?.url
          : isCues
          ? `https://www.linkedin.com/feed/update/urn:li:activity:${card?.content?.urn}/`
          : card?.content_type === 'comment'
          ? card?.content?.comment_url
          : card?.content?.post_url,
        is_reddit: isReddit,
      })
      if (data) {
        return { empty: data?.empty, content: data?.data?.content, status: data?.data?.status }
      } else {
        return { empty: false, content: '', status: '' }
      }
    } else {
      return { empty: false, content: '', status: '' }
    }
  }
  const getLikeHandler = async () => {
    const card = topCardRef.current
    if (card) {
      const isReddit = card?.channel === 'reddit'
      const { data } = await getLike({
        content_id: isReddit
          ? card?.url
          : isCues
          ? `https://www.linkedin.com/feed/update/urn:li:activity:${card?.content?.urn}/`
          : card?.content_type === 'comment'
          ? card?.content?.comment_url
          : card?.content?.post_url,
        is_reddit: isReddit,
      })
      if (data) {
        return { empty: data?.empty, status: data?.data?.status }
      } else {
        return { empty: false, status: '' }
      }
    } else {
      return { empty: false, status: '' }
    }
  }
  useEffect(() => {
    if (openAIComment) {
      setIsAICommentVisible(true)
      dispatch(SET_OPEN_AI_COMMENT(false))
    }
  }, [openAIComment])

  useEffect(() => {
    let pollingTimeout: ReturnType<typeof setTimeout> | null = null
    topCardRef.current = cards?.[0]

    const pollComment = async (card: any, noKill = false) => {
      // Get the top card
      if (!card) return
      if (!checkComment && card.content_id === killedCard && !noKill) return
      dispatch(SET_CHECK_COMMENT(false))

      // Fetch comment data
      console.log('Getting comment handler for card:', card.content_id)
      const { empty, content, status } = await getCommentHandler()

      // If the comment is empty, update state and stop polling.
      if (empty) {
        setComment('')
        setCommentStatus('')
        return
      }

      // Update comment content and status
      setComment(content)
      setCommentStatus(status)

      // If the status is pending, schedule the next poll in 3 seconds.
      if (status === 'pending') {
        pollingTimeout = setTimeout(() => {
          pollComment(card, true)
        }, 3000)
      }
    }

    // Start polling for the top card
    pollComment(topCardRef?.current)

    // Cleanup the timeout when the card changes or on unmount.
    return () => {
      if (pollingTimeout) clearTimeout(pollingTimeout)
    }
  }, [cards, checkComment])
  useEffect(() => {
    let pollingTimeout: ReturnType<typeof setTimeout> | null = null
    topCardRef.current = cards?.[0]

    const pollLike = async (card: any, noKill = false) => {
      // Get the top card
      if (!card) return
      if (!checkLike && card.content_id === killedCard && !noKill) return
      dispatch(SET_CHECK_LIKE(false))

      console.log('Getting like handler for card:', card.content_id)
      const { empty, status } = await getLikeHandler()
      console.log('Like handler result:', empty, status)
      if (empty) {
        dispatch(SET_LIKED(''))
        return
      }

      dispatch(SET_LIKED(status))

      // If the status is pending, schedule the next poll in 3 seconds.
      if (status === 'pending') {
        pollingTimeout = setTimeout(() => {
          pollLike(card, true)
        }, 3000)
      }
    }

    // Start polling for the top card
    pollLike(topCardRef?.current)

    // Cleanup the timeout when the card changes or on unmount.
    return () => {
      if (pollingTimeout) clearTimeout(pollingTimeout)
    }
  }, [cards, checkLike])

  useEffect(() => {
    if (isAICommentVisible || isSuggestionsVisible) {
      setTimeout(() => {
        setShowKeyboardShortcut(true)
      }, 800)
    } else {
      setShowKeyboardShortcut(false)
    }
  }, [isAICommentVisible, isSuggestionsVisible])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.cardWrapper}>
          <div className={styles.ellipticalShadow}></div>
          <VerticalDots currentIndex={cards?.length - 1} totalCount={totalCount} screenType={screenType} />
          {cards?.slice(0, 5).map((card, index) => {
            const isTopCard = index === 0
            const zIndex = 10 - index

            return (
              <motion.div
                key={card.content_id}
                //   className={styles.card}
                className={`${styles.card} ${!isTopCard ? styles.placeholder : ''}`}
                // Pass the index to the variant functions using the `custom` prop.
                custom={{ index, screenType }}
                variants={cardVariants}
                // The top card’s initial state is “top” while the others start as placeholders.
                initial={isTopCard ? 'top' : 'placeholder'}
                // For the top card, if we are swiping, animate with controls;
                // otherwise, it should be at its “top” style.
                // All other cards remain in the “placeholder” state.
                animate={isTopCard ? (isSwiping ? controls : 'top') : 'placeholder'}
                style={{ zIndex, position: 'absolute' }}>
                {isTopCard && (
                  <CustomCard
                    card={card}
                    loading={isLoading}
                    comment={comment}
                    commentStatus={commentStatus}
                    screenType={screenType}
                  />
                )}
              </motion.div>
            )
          })}
          {cards?.length === 0 && <CustomCard isEmpty screenType={screenType} />}
        </div>

        {/* Animate CardStackActions based on AIComment visibility */}
        <motion.div
          className={styles.buttons}
          animate={{ y: isAICommentVisible ? 50 : 0 }}
          transition={{ duration: 0.3 }}>
          {!isAICommentVisible && (
            <CardStackActions
              onCommentWithAI={() => setIsAICommentVisible(true)}
              onSkip={(mark) => handleSkip(mark)}
              onOutreach={() => console.log('Outreach')}
              onMessage={() => console.log('Message')}
              comment={comment}
              onShowSuggestions={() => setIsSuggestionsVisible(!isSuggestionsVisible)}
              onStepBack={functions.handleStepBack}
              onAddToSeeds={functions.handleAddToSeeds}
              screenType={screenType}
              card={cards?.[0]}
              trackings={{
                trackHeatboxAction: functions.trackHeatboxAction,
                trackCuesAction: functions.trackCuesAction,
              }}
            />
          )}
        </motion.div>

        {/* Animate AIComment appearance/disappearance */}
        <AnimatePresence>
          {isAICommentVisible && (
            <motion.div
              style={{ zIndex: 10001 }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.3 }}>
              <AIComment
                open={isAICommentVisible}
                onClose={() => setIsAICommentVisible(false)}
                setComment={setComment}
                card={cards?.[0]}
                trackings={{
                  trackHeatboxAction: functions.trackHeatboxAction,
                  trackCuesAction: functions.trackCuesAction,
                }}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {!showKeyboardShortcut && (
        <KeyboardShortcut open={!isAICommentVisible && !isSuggestionsVisible} screenType={screenType} />
      )}
    </>
  )
}

export default CardStack
