import React, { useState, useRef, useEffect } from 'react'
import { motion } from 'framer-motion'
import styles from './CardStackActions.module.scss'
import heatbox_comment_icon from 'common/assets/svg/heatbox_comment_icon.svg'
import skip_icon from 'common/assets/svg/skip_icon.svg'
import red_x_icon from 'common/assets/svg/red_x_icon.svg'
import like_icon from 'common/assets/svg/like_outcome.svg'
import track_in_seeds_icon from 'common/assets/svg/track_in_seeds_icon.svg'
import arrow_turn_left from 'common/assets/svg/arrow_turn_left.svg'
import SuggestionsList from 'common/components/Heatbox/UI/SuggestionsList'
import { TrackHeatboxActionData } from './Heatbox'
import { message } from 'antd'
import { useLazyGetSeedsQuery, useLazySendLikeQuery } from 'common/APIs/HeatboxApi'
import { useUser } from 'Utils/UserContext'
import { useParams } from 'react-router-dom'
import { CUE_CONSTANTS } from './Utils/CardUtils'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_CHECK_LIKE, SET_LIKED } from './Utils/HeatboxSlice'
import { ArrowUpOutlined, LoadingOutlined } from '@ant-design/icons'

interface SuggestionOption {
  text: string
  numberOfBoldWords: number
  action: 'bad' | 'skip' | 'stop_heatbox' | 'already_seen' | 'not_relevant'
}

const options: SuggestionOption[] = [
  {
    text: `Stop showing this person`,
    numberOfBoldWords: 1,
    action: 'stop_heatbox',
  },
  {
    text: `I've seen this content already`,
    numberOfBoldWords: 2,
    action: 'already_seen',
  },
  {
    text: `Not relevant content for me`,
    numberOfBoldWords: 2,
    action: 'not_relevant',
  },
]
const tickVariants = {
  hidden: {
    opacity: 0,
    scale: 0.5,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    pathLength: 1,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
}

const circleVariants = {
  hidden: {
    opacity: 0,
    scale: 0.5,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.4,
      ease: 'easeInOut',
    },
  },
}
interface CardStackActionsProps {
  comment: string
  onCommentWithAI: () => void
  onSkip: (mark: 'skip' | 'bad' | 'stop_heatbox' | 'already_seen' | 'not_relevant') => void
  onOutreach: () => void
  onMessage: () => void
  onShowSuggestions: () => void
  onAddToSeeds: () => Promise<boolean>
  screenType: string
  onStepBack: () => void
  trackings: {
    trackHeatboxAction: (data: TrackHeatboxActionData, additional?: any) => void
    trackCuesAction: (data: TrackHeatboxActionData, additional?: any) => void
  }
  card: any
}

const CardStackActions = ({
  comment,
  onCommentWithAI,
  onSkip,
  onOutreach,
  onMessage,
  onShowSuggestions,
  onAddToSeeds,
  screenType,
  onStepBack,
  trackings,
  card,
}: CardStackActionsProps) => {
  const [showOptions, setShowOptions] = useState(false)
  const suggestionsRef = useRef<HTMLDivElement>(null)
  const redXRef = useRef<HTMLButtonElement>(null)
  const [addedToSeeds, setAddedToSeeds] = useState(false)
  const [likedAlready, setLikedAlready] = useState(false)
  const [stepBackDisabled, setStepBackDisabled] = useState(false)
  const [sendLike] = useLazySendLikeQuery()
  const { user } = useUser()
  const HEATBOX_USERID = user?.id
  const view = useParams<any>()
  const subUrl = view?.['*']
  const isCues = subUrl === 'cues'
  const isReddit = card?.channel === 'reddit'
  const [getSeeds] = useLazyGetSeedsQuery()
  const dispatch = useAppDispatch()
  const { linkedinConnected, liked } = useAppSelector((state) => state.heatbox)
  const demo_organic = user?.user_type === 'demo_organic'

  const handleComment = () => {
    if (!linkedinConnected && !isReddit && !demo_organic) {
      message.error('Please connect your LinkedIn account to comment')
      return
    }
    setAddedToSeeds(true)
    onCommentWithAI()
  }
  const handleLike = async () => {
    if (likedAlready) return
    if (!linkedinConnected && !isReddit && !demo_organic) {
      message.error('Please connect your LinkedIn account to like')
      return
    }
    dispatch(SET_LIKED('pending'))
    setLikedAlready(true)
    const result = await sendLike({
      user_id: HEATBOX_USERID,
      content_id: card?.content_id,
      content: isReddit ? card?.content?.content?.text : card?.content?.text,
      lead_id: isReddit ? card?.lead?.id : card?.lead?.username || card?.lead_id,
      type: card?.content_type,
      post_url: isReddit
        ? card?.url
        : isCues
        ? `https://www.linkedin.com/feed/update/urn:li:activity:${card?.content?.urn}/`
        : card?.content_type === 'comment'
        ? card?.content?.comment_url
        : card?.content?.post_url,
      is_cue: isCues,
      is_reddit: isReddit,
    })
    if (result?.isError) {
      if (result?.error?.status === 470) {
        message.error(CUE_CONSTANTS.SEEDS_LIMIT_MESSAGE)
        return
      }
      message.error('Error in sending like')
      return
    }
    dispatch(SET_CHECK_LIKE(true))
    await getSeeds({ user_id: HEATBOX_USERID })
    if (isCues) {
      trackings.trackCuesAction({
        action_type: 'like',
        card: card,
      })
    } else {
      trackings.trackHeatboxAction({
        action_type: 'like',
        card: card,
      })
    }
  }
  const handleAddToSeeds = async () => {
    try {
      const res = await onAddToSeeds()
      if (res === false) {
        return
      }
      if (screenType === 'heatbox') {
        trackings.trackHeatboxAction({ action_type: 'track_in_seeds', card: card })
      }
      setAddedToSeeds(true)
    } catch (e) {
      console.error(e)
      message.error('Failed to add to seeds')
    }
  }

  // Wrap onSkip so that when a new card is revealed, we re-enable the step back button.
  const handleSkip = (action: 'skip' | 'bad' | 'stop_heatbox' | 'already_seen' | 'not_relevant') => {
    onSkip(action)
    setStepBackDisabled(false)
    setLikedAlready(false)
    if (action === 'bad' || action === 'stop_heatbox' || action === 'not_relevant') {
      if (screenType === 'heatbox') {
        trackings.trackHeatboxAction({ action_type: 'mark_as_bad', card: card })
      } else {
        trackings.trackCuesAction({ action_type: 'mark_as_bad', card: card })
      }
    }
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target as Node) &&
        redXRef.current &&
        !redXRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false)
        onShowSuggestions()
      }
    }

    if (showOptions) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showOptions, onShowSuggestions])

  // Reset addedToSeeds when onSkip fires.
  useEffect(() => {
    setAddedToSeeds(false)
  }, [onSkip])

  // Reset the step back button when screenType changes.
  useEffect(() => {
    setStepBackDisabled(false)
  }, [screenType])

  useEffect(() => {
    if (liked === 'completed' || liked === 'error' || liked === 'pending') {
      setLikedAlready(true)
    } else {
      setLikedAlready(false)
    }
  }, [screenType, liked])
  return (
    <div className={styles.container}>
      <div className={styles.firstRow}>
        <motion.button
          onClick={handleComment}
          className={styles.actionButton}
          disabled={!!comment}
          transition={{ duration: 0.3 }}
          whileHover={{ scale: 1.06 }}>
          <img src={heatbox_comment_icon} alt='' />
          AI Comment
        </motion.button>

        <motion.button
          disabled={addedToSeeds || !!comment}
          onClick={handleAddToSeeds}
          className={styles.actionButton}
          transition={{ duration: 0.3 }}
          whileHover={{ scale: 1.06 }}>
          <img src={track_in_seeds_icon} alt='' />
          Track in Seeds
        </motion.button>
        <motion.button
          disabled={likedAlready}
          onClick={handleLike}
          className={styles.actionButtonLike}
          transition={{ duration: 0.3 }}
          whileHover={{ scale: 1.06 }}>
          {liked === 'pending' ? (
            <LoadingOutlined style={{ fontSize: 24, color: '#7043ff' }} spin />
          ) : liked === 'completed' ? (
            <motion.div initial='hidden' animate={'visible'} className={styles.verificationIcon}>
              <motion.div variants={circleVariants} className={styles.circle}>
                <motion.svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
                  <motion.path
                    d='M6 12L10 16L18 8'
                    stroke='white'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    variants={tickVariants}
                  />
                </motion.svg>
              </motion.div>
            </motion.div>
          ) : (
            <>
              {isReddit ? (
                <>
                  <ArrowUpOutlined />
                </>
              ) : (
                <>
                  <img src={like_icon} alt='' />
                </>
              )}
            </>
          )}
          {isReddit
            ? liked === 'pending'
              ? 'Upvoting...'
              : liked === 'completed'
              ? 'Upvoted'
              : 'Upvote'
            : liked === 'pending'
            ? 'Liking...'
            : liked === 'completed'
            ? 'Liked'
            : 'Like'}
        </motion.button>
      </div>

      <div className={styles.secondRow}>
        <motion.button
          ref={redXRef}
          onClick={() => {
            onShowSuggestions()
            setShowOptions(!showOptions)
          }}
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
          className={styles.roundBigActionButton}>
          <img src={red_x_icon} alt='' />
        </motion.button>

        <motion.button
          onClick={() => handleSkip('skip')}
          className={styles.roundActionButton}
          animate={{ opacity: showOptions ? 0 : 1, scale: showOptions ? 0.2 : 1 }}
          transition={{ duration: 0.3 }}
          whileHover={{ scale: 1.1 }}>
          <img src={skip_icon} alt='' />
        </motion.button>

        <motion.button
          onClick={() => {
            setStepBackDisabled(true)
            onStepBack()
          }}
          disabled={stepBackDisabled}
          className={styles.roundSmallActionButton}
          animate={{ opacity: showOptions ? 0 : 1, scale: showOptions ? 0.2 : 1 }}
          transition={{ duration: 0.3 }}
          whileHover={{ scale: 1.1 }}>
          <img src={arrow_turn_left} alt='' />
        </motion.button>
      </div>

      <div ref={suggestionsRef}>
        <SuggestionsList
          options={options}
          visible={showOptions}
          onSelectSuggestion={(option, index) => {
            setShowOptions(false)
            onShowSuggestions()
            handleSkip(option.action as 'skip' | 'bad' | 'stop_heatbox' | 'already_seen' | 'not_relevant')
          }}
          bottom={52}
          left={'50%'}
          boldColor='#E85656'
          color='#777777'
        />
      </div>
    </div>
  )
}

export default CardStackActions
