import React, { useState } from 'react'
import { Modal, Input, Button, Typography, message, Select, Checkbox } from 'antd'
import styles from './WebhookModal.module.scss'

const { Text } = Typography
const { Option } = Select

interface WebhookModalProps {
  isVisible: boolean
  onCancel: () => void
  onSubscribe: (url: string, events: string[]) => Promise<void>
}

// Example event types; replace with actual events as needed
const availableEvents = ['sent_message', 'replied_message']

const WebhookModal: React.FC<WebhookModalProps> = ({ isVisible, onCancel, onSubscribe }) => {
  const [webhookUrl, setWebhookUrl] = useState<string>('')
  const [selectedEvents, setSelectedEvents] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [isSubscribing, setIsSubscribing] = useState<boolean>(false)
  const [isUrlValid, setIsUrlValid] = useState<boolean>(true)

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setWebhookUrl(value)
    setIsUrlValid(value.trim().length > 0)
  }

  const handleSelectAllChange = (e: any) => {
    const checked = e.target.checked
    setSelectAll(checked)
    if (checked) {
      setSelectedEvents(availableEvents)
    } else {
      setSelectedEvents([])
    }
  }

  const handleSubscribe = async () => {
    if (!webhookUrl.trim()) {
      setIsUrlValid(false)
      return
    }
    if (selectedEvents.length === 0) {
      message.error('Please select at least one event')
      return
    }

    setIsSubscribing(true)
    try {
      await onSubscribe(webhookUrl, selectedEvents)
      handleClose()
    } catch (error) {
      console.error('Failed to subscribe to webhook')
    } finally {
      setIsSubscribing(false)
    }
  }

  const handleClose = () => {
    setWebhookUrl('')
    setSelectedEvents([])
    setSelectAll(false)
    setIsUrlValid(true)
    onCancel()
  }

  return (
    <Modal
      centered
      open={isVisible}
      footer={null}
      onCancel={handleClose}
      width={550}
      className={styles.modal}
      maskClosable={false}>
      <div className={styles.content}>
        <div className={styles.title}>
          <p>Subscribe to Webhook</p>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.inputGroup}>
            <Text strong className={styles.label}>
              Webhook URL
            </Text>
            <Input
              placeholder='Enter webhook URL'
              value={webhookUrl}
              onChange={handleUrlChange}
              className={!isUrlValid ? styles.inputError : ''}
            />
            {!isUrlValid && (
              <Text type='danger' className={styles.errorText}>
                Please enter a webhook URL
              </Text>
            )}
          </div>
          <div className={styles.inputGroup}>
            <Text strong className={styles.label}>
              Events
            </Text>
            <Checkbox checked={selectAll} onChange={handleSelectAllChange}>
              Select All Events
            </Checkbox>
            <Select
              mode='multiple'
              placeholder='Select events'
              value={selectedEvents}
              onChange={setSelectedEvents}
              style={{ width: '100%', marginTop: '8px' }}>
              {availableEvents.map((event) => (
                <Option key={event} value={event}>
                  {event}
                </Option>
              ))}
            </Select>
          </div>
          <div className={styles.actionButtons}>
            <Button onClick={handleClose} className={styles.cancelButton}>
              Cancel
            </Button>
            <Button
              type='primary'
              onClick={handleSubscribe}
              loading={isSubscribing}
              disabled={!webhookUrl.trim() || selectedEvents.length === 0}
              className={styles.generateButton}>
              Subscribe
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default WebhookModal
