import React, { useMemo } from 'react'
import { Skeleton } from 'antd'
import ImageWithFallback from 'LEGACY/General/ImageWithFallback'
import fallbackIcon1 from 'common/assets/svg/FallbackIcon1.png'
import fallbackIcon2 from 'common/assets/svg/FallbackIcon2.png'
import fallbackIcon3 from 'common/assets/svg/FallbackIcon3.png'
import fallbackIcon4 from 'common/assets/svg/FallbackIcon4.png'
import fallbackIcon5 from 'common/assets/svg/FallbackIcon5.png'
import fallbackIcon6 from 'common/assets/svg/FallbackIcon6.png'
import fallbackIcon7 from 'common/assets/svg/FallbackIcon7.png'
import fallbackIcon8 from 'common/assets/svg/FallbackIcon8.png'
import fallbackIcon9 from 'common/assets/svg/FallbackIcon9.png'
import styles from '../CustomCard/CustomCard.module.scss'
import { Card } from '../CardStack'

interface ProfilePictureProps {
  loading?: boolean
  card?: Card
}

const hashString = (str: string): number => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0
  }
  return Math.abs(hash)
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({ loading, card }) => {
  const fallbackIcons = [
    fallbackIcon1,
    fallbackIcon2,
    fallbackIcon3,
    fallbackIcon4,
    fallbackIcon5,
    fallbackIcon6,
    fallbackIcon7,
    fallbackIcon8,
    fallbackIcon9,
  ]

  const fallbackIcon = useMemo(() => {
    if (card?.lead?.name) {
      const hash = hashString(card.lead.name)
      const index = hash % fallbackIcons.length
      return fallbackIcons[index]
    }

    return fallbackIcons[0]
  }, [card?.lead?.name])

  return (
    <ImageWithFallback
      fallbackStrOrElement={loading ? <Skeleton.Image /> : fallbackIcon}
      src={card?.lead?.profile_picture}
      alt={card?.lead?.name}
      className={styles.profilePic}
    />
  )
}

export default ProfilePicture
