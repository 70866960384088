import React, { useEffect, useState } from 'react'
import styles from '../CustomMenu.module.scss'
import { Button, DatePicker, Select, theme } from 'antd'
import arrowDown from 'common/assets/svg/arrow_section.svg'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_FILTER_OBJECT, SET_HAS_FILTERS } from '../Utils/HeatboxSlice'
const { useToken } = theme
const mapToValueLabel = (data: any) => {
  return {
    label: data,
    value: data,
  }
}
const isIsNot = [
  {
    label: 'Is',
    value: 'is',
  },
  {
    label: 'Is not',
    value: 'is_not',
  },
]
const within = [
  {
    label: 'Within',
    value: 'within',
  },
  {
    label: 'Not within',
    value: 'not_within',
  },
]
const above = [
  {
    label: 'More Than',
    value: 'above',
  },
]
const below = [
  {
    label: 'Less Than',
    value: 'below',
  },
]
const typeToIs: any = {
  channel: isIsNot,
  date: within,
  content_score: above,
  heat_level: [...isIsNot, ...above, ...below],
  lead_score: above,
  reactions: [...above, ...below],
}
const typeToValue: any = {
  channel: [
    {
      label: 'Linkedin',
      value: 'linkedin',
    },
    {
      label: 'Reddit',
      value: 'reddit',
    },
  ],
  content_score: ['50%', '70%', '90%'].map(mapToValueLabel),
  heat_level: [0, 1, 2, 3, 4].map(mapToValueLabel),
  lead_score: ['50%', '70%', '90%'].map(mapToValueLabel),
  reactions: ['5', '10', '50', '100', '200'].map(mapToValueLabel),
}
interface IMenuFiltersProps {
  menuContainerRef: React.RefObject<HTMLDivElement>
  currentScreen: string
  getHeatbox: (data: any) => void
  getCues: (data: any) => void
  user: any
}
export default function MenuFiltersOne({
  menuContainerRef,
  currentScreen,
  getCues,
  getHeatbox,
  user,
}: IMenuFiltersProps) {
  const { token } = useToken()
  const [applyLoading, setApplyLoading] = useState<boolean>(false)
  const { filterObject, sort, asc } = useAppSelector((state) => state.heatbox)
  const dispatch = useAppDispatch()
  // const setSort = (value: string) => {
  //   dispatch(SET_SORT(value))
  // }
  // const setAsc = (value: boolean) => {
  //   dispatch(SET_ASC(value))
  // }
  const setFilterObject = (value: any) => {
    console.log('set filter object:', value)
    dispatch(SET_FILTER_OBJECT(value))
  }
  const setHasFilters = (value: boolean) => {
    dispatch(SET_HAS_FILTERS(value))
  }

  // const sortOptions = [
  //   {
  //     label: 'Relevance',
  //     value: 'relevance',
  //   },
  //   {
  //     label: 'Date',
  //     value: 'date',
  //   },
  //   {
  //     label: 'Content Score',
  //     value: 'score',
  //   },
  //   {
  //     label: 'Lead Score',
  //     value: 'lead_score',
  //   },
  // ]
  const types =
    currentScreen === 'heatbox'
      ? [
          {
            label: 'Channel',
            value: 'channel',
          },
          {
            label: 'Date',
            value: 'date',
          },
          {
            label: 'Content Score',
            value: 'content_score',
          },
          {
            label: 'Heat Level',
            value: 'heat_level',
          },
          {
            label: 'Lead Score',
            value: 'lead_score',
          },
          {
            label: 'Reactions',
            value: 'reactions',
          },
        ]
      : [
          {
            label: 'Channel',
            value: 'channel',
          },

          {
            label: 'Date',
            value: 'date',
          },
          {
            label: 'Content Score',
            value: 'content_score',
          },
          {
            label: 'Reactions',
            value: 'reactions',
          },
        ]
  const disableFutureDates = (current: dayjs.Dayjs) => {
    return current && current.isAfter(dayjs().endOf('day'))
  }
  const contentStyleFilter: React.CSSProperties = {
    backgroundColor: 'white',
    borderRadius: '3px',
    boxShadow: token.boxShadow,
    width: '160%',
    marginTop: 10,
    height: 'fit-content',
    marginLeft: '-35%',
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
  const handleApplyFilters = async () => {
    try {
      setApplyLoading(true)
      if (currentScreen === 'heatbox') {
        await getHeatbox({
          user_id: user?.id,
          filter: JSON.stringify(filterObject),
          sort: `${sort}_${asc ? 'asc' : 'desc'}`,
        })
      } else {
        await getCues({
          user_id: user?.id,
          filter: JSON.stringify(filterObject),
          sort: `${sort}_${asc ? 'asc' : 'desc'}`,
        })
      }
      setHasFilters(true)
    } catch (e) {
      console.log(e)
    } finally {
      setApplyLoading(false)
    }
  }
  const handleResetFilters = async () => {
    try {
      setApplyLoading(true)
      if (currentScreen === 'heatbox') {
        await getHeatbox({
          user_id: user?.id,
        })
      } else {
        await getCues({
          user_id: user?.id,
        })
      }

      setFilterObject([
        {
          type: 'channel',
          is: 'is',
          value: 'linkedin',
        },
      ])
      handleChangeType(0)
      setHasFilters(false)
    } catch (e) {
      console.log(e)
    } finally {
      setApplyLoading(false)
    }
  }
  const handleChangeType = (index: number) => {
    let thisFilter = filterObject[index]
    console.log('handle change type. this filter:', thisFilter)

    const thisIs = typeToIs[thisFilter?.type]
    const thisValue = typeToValue[thisFilter?.type]
    if (!thisIs.find((i: any) => i.value === thisFilter?.is)) {
      const copy = [...filterObject]
      const deep_copy = JSON.parse(JSON.stringify(copy))
      deep_copy[index].is = thisIs[0].value
      setFilterObject(deep_copy)
    }
    if (thisFilter?.type === 'date') {
      if (thisValue?.length !== 2 && thisValue) {
        const copy = [...filterObject]
        const deep_copy = JSON.parse(JSON.stringify(copy))
        deep_copy[index].value = [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
        setFilterObject(deep_copy)
      }
    }
    if (!thisValue) return
    if (!thisValue.find((i: any) => i.value === thisFilter?.value)) {
      const copy = [...filterObject]
      const deep_copy = JSON.parse(JSON.stringify(copy))
      deep_copy[index].value = thisValue[0].value
      setFilterObject(deep_copy)
    }
  }
  useEffect(() => {
    console.log('did', filterObject)
    console.log('filter object length is:', filterObject?.length)
    if (filterObject?.length > 0) {
      filterObject?.forEach((_: any, index: any) => {
        console.log('filterObject at index:', filterObject[index])
        handleChangeType(index)
      })
    }
  }, [JSON.stringify(filterObject)])
  return (
    <>
      <div
        style={contentStyleFilter}
        ref={menuContainerRef}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        onMouseDown={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}>
        <>
          {/* <div
            style={{
              width: '2%',
              height: '65%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <span className={`${styles.plusHeatbox}`}>+</span>
          </div>
          <div
            style={{
              width: '18%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: '10px',
              gap: '60px',
            }}>
            <Select
              defaultValue={'all'}
              className='selectFiltersHeatboxSmall'
              options={[
                {
                  label: 'All',
                  value: 'all',
                },
                {
                  label: 'At least',
                  value: 'at_least',
                },
              ]}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
              suffixIcon={<img src={arrowDown} alt='arrow_down' />}
              onSelect={(v, o) => {}}
              dropdownMatchSelectWidth
              dropdownStyle={{
                borderRadius: '5px',
                border: 'none',
                padding: '0px',
                margin: '0px',
              }}
              dropdownRender={(menu) => <div className='selectFiltersHeatboxDropdownSmall'>{menu}</div>}
            />
            <Select
              defaultValue={'and'}
              className='selectFiltersHeatboxSmall'
              options={[
                {
                  label: 'And',
                  value: 'and',
                },
                {
                  label: 'Or',
                  value: 'or',
                },
              ]}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
              suffixIcon={<img src={arrowDown} alt='arrow_down' />}
              onSelect={(v, o) => {}}
              dropdownMatchSelectWidth
              dropdownStyle={{
                borderRadius: '5px',
                border: 'none',
                padding: '0px',
                margin: '0px',
              }}
              dropdownRender={(menu) => <div className='selectFiltersHeatboxDropdownSmall'>{menu}</div>}
            />
          </div> */}
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '10px',
            }}>
            <div
              style={{
                width: '100%',
                height: '80%',
                display: 'flex',
                flexDirection: 'column',
              }}>
              {filterObject.map((_: any, index: number) => {
                const thisFilter = filterObject[index]
                const thisIs = typeToIs[thisFilter?.type]
                const thisType = thisFilter?.type
                const thisValue = typeToValue[thisType]
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'stretch',
                    }}>
                    <div style={{ width: '100%', paddingBlock: '15px' }} className={styles.filterBox}>
                      <div className={styles.filterBoxInside}>
                        <Select
                          onSelect={(value) => {
                            const copy = [...filterObject]
                            const deep_copy = JSON.parse(JSON.stringify(copy))
                            deep_copy[index].type = value
                            setFilterObject(deep_copy)
                          }}
                          value={thisFilter?.type}
                          defaultValue={'channel'}
                          className={`heatboxFilterRect`}
                          options={types}
                          suffixIcon={<img src={arrowDown} alt='arrow_down' />}
                        />
                        <Select
                          onSelect={(value) => {
                            const copy = [...filterObject]
                            const deep_copy = JSON.parse(JSON.stringify(copy))
                            deep_copy[index].is = value
                            setFilterObject(deep_copy)
                          }}
                          defaultValue={thisIs[0].value}
                          value={thisFilter?.is}
                          options={thisIs}
                          className={`heatboxFilterCirc`}
                          suffixIcon={<img src={arrowDown} alt='arrow_down' />}
                        />
                        {thisType === 'date' ? (
                          <>
                            <DatePicker.RangePicker
                              className='custom-range-picker'
                              placeholder={['Start', 'End']}
                              style={{
                                width: '35%',
                                height: '100%',
                              }}
                              value={
                                thisFilter?.value?.length > 1
                                  ? [
                                      dayjs(thisFilter?.value[0]).isValid() ? dayjs(thisFilter?.value[0]) : null,
                                      dayjs(thisFilter?.value[1]).isValid() ? dayjs(thisFilter?.value[1]) : null,
                                    ]
                                  : undefined
                              }
                              disabledDate={disableFutureDates}
                              onChange={(dates, dateStrings) => {
                                const copy = [...filterObject]
                                const deep_copy = JSON.parse(JSON.stringify(copy))
                                deep_copy[index].value = dateStrings
                                setFilterObject(deep_copy)
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Select
                              onSelect={(v) => {
                                const copy = [...filterObject]
                                const deep_copy = JSON.parse(JSON.stringify(copy))
                                deep_copy[index].value = v
                                setFilterObject(deep_copy)
                              }}
                              defaultValue={thisValue[0]}
                              options={thisValue}
                              value={thisFilter?.value}
                              className={`heatboxFilterRect`}
                              suffixIcon={<img src={arrowDown} alt='arrow_down' />}></Select>
                          </>
                        )}
                      </div>
                    </div>
                    {/* <span className={`${styles.plusHeatbox}`}>+</span> */}
                  </div>
                )
              })}
            </div>
            {/* <div
              style={{
                width: '100%',
                height: '80%',
                display: 'flex',
                flexDirection: 'column',
              }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'stretch',
                }}>
                <div style={{ width: '100%', paddingBlock: '15px' }} className={styles.filterBox}>
                  <div className={styles.filterBoxInside}>
                    <span
                      style={{
                        width: '31%',
                        fontSize: '16px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}>
                      Sort by
                    </span>
                    <Select
                      onSelect={(value) => {
                        setSort(value)
                      }}
                      value={sort}
                      defaultValue={'relevance'}
                      className={`heatboxFilterRect`}
                      options={sortOptions}
                      suffixIcon={<img src={arrowDown} alt='arrow_down' />}
                    />
                    <Select
                      onSelect={(value) => {
                        setAsc(value === 'asc')
                      }}
                      defaultValue={'desc'}
                      value={asc ? 'asc' : 'desc'}
                      options={[
                        {
                          label: 'Ascending',
                          value: 'asc',
                        },
                        {
                          label: 'Descending',
                          value: 'desc',
                        },
                      ]}
                      className={`heatboxFilterRect`}
                      suffixIcon={<img src={arrowDown} alt='arrow_down' />}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: 10 }}>
              <div style={{ justifySelf: 'flex-start' }}>
                <Button onClick={handleResetFilters} type='text' className={styles.applyFilters} loading={applyLoading}>
                  Reset Filters
                </Button>
              </div>
              <div style={{ justifySelf: 'flex-end' }}>
                <Button onClick={handleApplyFilters} type='text' className={styles.applyFilters} loading={applyLoading}>
                  Apply Filters
                </Button>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  )
}
