// React and React-related imports
import { useEffect, useState } from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'

// Third-party libraries
import cx from 'classnames'
import { ConfigProvider, Layout } from 'antd'
import { ErrorBoundary } from 'react-error-boundary'
import { useIntercom } from 'react-use-intercom'
import { useLocalStorage } from 'usehooks-ts'

// Redux hooks and state
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useAppSelector, useAppDispatch } from 'state'
import { SET_EMAIL_TOKEN } from 'GeneralSlice'
import {
  SET_ARTICLE_OPEN,
  SET_FLOAT_VISIBLE,
  SET_GETTING_STARTED_OPEN,
  SET_INTERCOM_OPEN,
  SET_LEARNING_OPEN,
} from 'common/components/GeneralComponents/LearningCenter/state/slice/learningSlice'

// API hooks
import {
  //  useLazyGetCampaignHeaderQuery,
  useLazyGetUserDetailsQuery,
} from 'common/APIs/OutreachApi'
import { useLazyFillSuggestionsQuery } from 'common/APIs/OutreachAI-API'

// Project-specific components
import SmallLogout from 'common/components/GeneralComponents/UI/SmallLogout/SmallLogout'
import { Header } from 'common/components/GeneralComponents/Header/Header'
import Sidebar from 'common/components/Sidebar/Sidebar'
import {
  useRedirects,
  useIntercomSetup,
  useRouteChecks,
  useSafariCheck,
  useScreenSizeCheck,
  useSubscriptionStatus,
  useHandleLocalUser,
  useReloadOnTokenChange,
} from 'Utils/useApp'
import {
  getRequireReloginAccounts,
  renderCustomNotifications,
  renderLearningCenter,
  shouldDisplayPayWall,
} from 'Utils/AppUtils'

// Layouts
import MobileProvider from 'common/layouts/MobileLayout'

// Routes
import { getAvailableRoutes } from './routes'

// Styles
import './App.css'
import './antd-overwrites.scss'
import '/node_modules/primeflex/primeflex.css'
import 'swiper/css'
import 'swiper/css/navigation'
import { SideFilter } from 'LEGACY/Research/Filters/SideFilter/SideFilter'
import { TopBar } from 'LEGACY/Research/Filters/TopBar/TopBar'
import ConvrtTabs from 'LEGACY/Research/Filters/TopBar/ConvrtTabs'
import { useScreenSize } from 'common/hooks/useScreenSize'
import { useLazyGetContactsByCompanyIdQuery } from 'LEGACY/API/ContactsApi'
import { useLazyGetListsFastQuery, useLazyGetListsQuery } from 'LEGACY/API/CompaniesApi'
import { SET_SELECTED_CHAT_RECIPIENT } from 'common/components/Chat/state/chatSlice'

const { Content } = Layout

const App = () => {
  // Core hooks
  const dispatch = useAppDispatch()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token')
  const { boot, shutdown, isOpen: isIntercomOpen, update: intercomUpdate } = useIntercom()

  // Local storage
  const [localUser, setLocalUser] = useLocalStorage<any>('user', null)
  const [, setStep] = useLocalStorage<any>('step', 0)
  const [, setLocalUserMetadata] = useLocalStorage<any>('user_metadata', null)
  const [refetchLists] = useLazyGetListsQuery()
  // Redux state selectors
  const {
    floatVisible,
    learningOpen,
    articleOpen,
    gettingStartedOpen,
    intercomOpen,
    articleSeeMoreOpen,
    videoSeeMoreOpen,
  } = useAppSelector((state) => state.learning)
  const { isMobile } = useAppSelector((state) => state.General)

  // Lazy queries
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [fillSuggestions] = useLazyFillSuggestionsQuery()
  const [getContacts] = useLazyGetContactsByCompanyIdQuery()
  const [getFast] = useLazyGetListsFastQuery()
  // const [getCampaignStats] = useLazyGetCampaignHeaderQuery()
  // User and auth state
  const { user, logout, userDetails } = useAuth()
  const [userData, setUserData] = useState(userDetails)
  const [userAddons, setUserAddons] = useState(localUser?.addons || {})
  const { requires_onboarding, onboarding_status, pricing } = userAddons

  // Custom hooks
  const isSafari = useSafariCheck()
  const { navToCheckout } = useSubscriptionStatus(userDetails)
  const { bootIntercom, handleError } = useIntercomSetup(userDetails, boot, shutdown, logout)
  const { showSidebar, showTopBar, notForLearning, showWeb3TopBar } = useRouteChecks()
  const isResolutionBad = useScreenSizeCheck()
  const screenSize = useScreenSize()

  // Pricing and outreach state
  const [forcePricing, setForcePricing] = useState(false)
  const [facebookUsersWithMissingPin, setFacebookUsersWithMissingPin] = useState<any[]>([])
  const [requireReloginAccounts, setRequireReloginAccounts] = useState<any>({})
  const [outOfOutreach, setOutOfOutreach] = useState(false)

  // UI state
  const [shouldHideCheckout, setShouldHideCheckout] = useState(userDetails?.flag)
  const [calledSmartlook, setCalledSmartlook] = useState(false)
  const [calledIntercom, setCalledIntercom] = useState(false)
  const [didFillSuggestions, setDidFillSuggestions] = useState(false)
  const [didGetContactsInitial, setDidGetContactsInitial] = useState(false)
  // Path-related data
  const locationPathName = location.pathname.split('/')[1]

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////// LEGACY //////////////////////////////////////
  const { isSidebarOpen: isSideFilterOpen } = useAppSelector((state) => state.filters) /////////////////
  ////////////////////////////////////// LEGACY //////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  useReloadOnTokenChange()

  useRedirects({
    user,
    token: token || '',
    setStep,
    localUser,
    pricing,
    requires_onboarding,
    onboarding_status,
    navToCheckout,
    userDetails,
    setLocalUserMetadata,
    bootIntercom,
  })

  useEffect(() => {
    async function fill() {
      await fillSuggestions()
    }

    if (user) {
      if (!didFillSuggestions) {
        fill()
        setDidFillSuggestions(true)
      }
    }
  }, [user])

  useEffect(() => {
    async function getContactsData() {
      await Promise.all([
        getContacts({ current: 1, pageSize: 10 }),
        refetchLists(),
        getFast(),
        // getCampaignStats()
      ])
    }

    const fetchCampaigns = async () => {
      // await getCampaignStats()
    }

    if (userDetails && userDetails?.user_type === 'web3') {
      if (!didGetContactsInitial) {
        getContactsData()
        setDidGetContactsInitial(true)
      }
    } else if (userDetails) {
      if (!didGetContactsInitial) {
        fetchCampaigns()
        setDidGetContactsInitial(true)
      }
    }
  }, [userDetails?.user_type])

  useHandleLocalUser({
    localUser,
    userData,
    navToCheckout,
    setStep,
    setForcePricing,
    setFacebookUsersWithMissingPin,
    setOutOfOutreach,
  })

  useEffect(() => {
    const reloginAccounts = getRequireReloginAccounts(localUser)
    setRequireReloginAccounts(reloginAccounts)
  }, [localUser])

  useEffect(() => {
    const getUserDetails = async () => {
      const { data } = await refetchGetUserDetails()
      if (data) {
        setLocalUser(data)
        setShouldHideCheckout(data.flag)
        setUserData(data)
      }
    }

    if (user) {
      getUserDetails()

      const intervalId = setInterval(() => {
        getUserDetails()
      }, 30000)

      return () => clearInterval(intervalId)
    }
  }, [user])

  useEffect(() => {
    if (user && user.addons) {
      setUserAddons(user.addons)
    }
  }, [user])

  useEffect(() => {
    if (!isIntercomOpen) {
      dispatch(SET_INTERCOM_OPEN(false))
      intercomUpdate({ hideDefaultLauncher: true })
    }
  }, [isIntercomOpen])

  useEffect(() => {
    if (
      !learningOpen &&
      articleOpen === 'NONE' &&
      !gettingStartedOpen &&
      !intercomOpen &&
      !articleSeeMoreOpen &&
      !videoSeeMoreOpen
    ) {
      dispatch(SET_FLOAT_VISIBLE(true))
    } else {
      dispatch(SET_FLOAT_VISIBLE(false))
    }
  }, [learningOpen, articleOpen, gettingStartedOpen, intercomOpen, articleSeeMoreOpen, videoSeeMoreOpen])

  useEffect(() => {
    dispatch(SET_FLOAT_VISIBLE(true))
    dispatch(SET_LEARNING_OPEN(false))
    dispatch(SET_ARTICLE_OPEN('NONE'))
    dispatch(SET_GETTING_STARTED_OPEN(false))
  }, [])

  useEffect(() => {
    if ((window as any).smartlook && localUser && user && !calledSmartlook) {
      ;(window as any).smartlook('identify', localUser.email, {
        email: localUser.email,
      })
      setCalledSmartlook(true)
    }
  }, [localUser, user, calledSmartlook])

  useEffect(() => {
    if ((window as any).Intercom && localUser && localUser?.user_type === 'web3' && user && !calledIntercom) {
      ;(window as any).Intercom('update', {
        lead_category: 'Web3',
        user_id: localUser?.id,
      })
      setCalledIntercom(true)
    }
  }, [localUser, user, calledIntercom])

  useEffect(() => {
    if (token) {
      dispatch(SET_EMAIL_TOKEN(token))
      const currentUrl = window.location.href
      const url = new URL(currentUrl)
      url.searchParams.delete('token')
      window.history.replaceState({}, '', url.pathname + url.search)
    }
  }, [location.search])

  useEffect(() => {
    if (!window.location.pathname.startsWith('/unifiedInbox')) {
      dispatch(SET_SELECTED_CHAT_RECIPIENT({}))
    }
  }, [window?.location?.pathname])

  const isHFull =
    window.location.pathname.startsWith('/upgrade') || (window.location.pathname.startsWith('/register') && isMobile)
      ? ''
      : 'h-full'

  const payWall = shouldDisplayPayWall(userData, window.location.pathname)

  const notifications = renderCustomNotifications({
    showSidebar,
    isResolutionBad,
    isSafari,
    isMobile,
    facebookUsersWithMissingPin,
    requireReloginAccounts,
    user,
    outOfOutreach,
  })

  const LearningCenter = renderLearningCenter({
    userData,
    notForLearning,
    locationPathName,
    forcePricing,
    floatVisible,
    dispatch,
  })

  const routes = getAvailableRoutes(shouldHideCheckout, localUser?.user_type, userAddons)

  return (
    <ErrorBoundary fallback={<>{'Something went wrong'}</>} onError={handleError}>
      <MobileProvider>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#7043FF',
            },
          }}>
          <Layout className='sm:flex min-h-screen layout'>
            {showSidebar && !forcePricing && <Sidebar />}
            {showSidebar && forcePricing && !isMobile && <SmallLogout />}
            <Layout className='h-screen' style={{ marginLeft: !showSidebar || forcePricing ? 0 : '64px' }}>
              {notifications}
              {showTopBar && <Header />}
              {/* ------------------------------------- */}
              {/* ---------------- LEGACY ------------- */}
              {showWeb3TopBar && <TopBar />}
              {/* ---------------- LEGACY ------------- */}
              {/* ------------------------------------- */}

              <Content className='overflow-auto flex max-h-full'>
                {/* ------------------------------------- */}
                {/* ---------------- LEGACY ------------- */}
                {isSideFilterOpen && <SideFilter />}
                {/* ---------------- LEGACY ------------- */}
                {/* ------------------------------------- */}
                {payWall}
                <Layout
                  style={{
                    backgroundColor: '#F9FAFB',
                  }}
                  className={cx('flex flex-column relative overflow-auto max-h-full')}>
                  {/* ------------------------------------- */}
                  {/* ---------------- LEGACY ------------- */}
                  {(locationPathName === 'contacts' || locationPathName === 'companies') && (
                    <ConvrtTabs activeTab={locationPathName} />
                  )}
                  {/* ---------------- LEGACY ------------- */}
                  {/* ------------------------------------- */}
                  <div className={`flex flex-column ${isHFull}`}>
                    {LearningCenter}
                    <Routes>
                      {routes.map((route, index) => {
                        // Inject screenSize prop only for components that need it
                        const routeProps = route.needsScreenSize ? { ...route.props, screenSize } : route.props

                        return <Route key={index} path={route.path} element={<route.element {...routeProps} />} />
                      })}
                      <Route path='*' element={<Navigate to='/outreach' />} />
                    </Routes>
                  </div>
                </Layout>
              </Content>
            </Layout>
          </Layout>
        </ConfigProvider>
      </MobileProvider>
    </ErrorBoundary>
  )
}

export default App
