import React, { useEffect, useState } from 'react'
import { Button, message, Tooltip } from 'antd'
import styles from './CustomCollapseFooter.module.scss'
import { useAppSelector, useAppDispatch } from 'state'
import CampaignSuccessModal from './CampaignSuccessModal'
import {
  useLazyAddCampaignAILeadsQuery,
  useLazyAddLeadsFromGroupsQuery,
  useLazyAddLeadsFromListsQuery,
  useLazyCreateCampaignActionsQuery,
  useLazyCreateCampaignQuery,
  // useLazyCreateCampaignRecipientsQuery,
  // useLazyCreateCampaignRecipientsUseRemainingPointsQuery,
} from '../../../../APIs/OutreachAI-API'
import { useNavigate } from 'react-router-dom'
import { transformToNewStructure } from '../transformStepsUtil'
import {
  SET_ARE_MESSAGES_MISSING,
  SET_IS_REVIEW_MODE,
  SET_LOADING_GENERATE_CAMPAIGN,
} from '../../state/outreachAICampaignSlice'
import OutOfPointsUseRemaining from 'common/components/GeneralComponents/Modals/OutOfPoints/OutOfPointsUseRemaining'
import { getSenderByPlatform } from 'common/utils/userHelpers'
import { trackEvent } from 'eventConfig'

interface CustomCollapseFooterProps {
  id?: any
  onBack?: () => void
  onNext?: () => void
  onSkip?: () => void
  onReset?: () => void
  onGenerate?: boolean
  leftButton?: any
}

const CustomCollapseFooter: React.FC<CustomCollapseFooterProps> = ({
  id,
  onBack,
  onNext,
  onGenerate,
  onSkip,
  onReset,
  leftButton,
}) => {
  const [createCampaignActions] = useLazyCreateCampaignActionsQuery()
  const [createCampaign] = useLazyCreateCampaignQuery()
  const [addLeadsFromGroups] = useLazyAddLeadsFromGroupsQuery()
  const [addLeadsFromLists] = useLazyAddLeadsFromListsQuery()
  const [isMagic, setIsMagic] = useState(false)
  const [addCampaignAILeadsQuery] = useLazyAddCampaignAILeadsQuery() // Only For Companies AI for now.

  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const { custom_flow_id, flag_campaign_actions } = useAppSelector((state) => state.CampaignBuilder)
  const {
    prompt,
    saved_prompt_params,
    selected_leads,
    selected_platforms_accounts,
    campaign_id,
    campaign_settings_ai,
    nodes_data,
    messages_types,
    active_platforms,
    ai_campaign_name,
    are_messages_missing,
    skip_prompt,
    select_custom_template,
    are_leads_from_groups,
    stored_leads_from_db,
    ai_leads_type,
    setup_method,
    user_updated_sequence,
    default_columns,
  } = useAppSelector((state) => state.outreachAICampaign)
  const [openModal, setOpenModal] = useState(false)
  const truePlatforms = Object.keys(active_platforms).filter((platform) => active_platforms[platform])
  const [showMessage, setShowMessage] = useState(false)
  const [insertedLeads, setInsertedLeads] = useState(0)
  const [totalLeadsCount, setTotalLeadsCount] = useState(0)

  const validator = () => {
    switch (id) {
      case '1':
        const atLeastOneNonEmpty = Object.values(selected_platforms_accounts).some(
          (value) => Array.isArray(value) && value.length > 0,
        )
        return atLeastOneNonEmpty
      case '2':
        if (prompt.id) {
          return true
        }

        return false
      case '3':
        if (setup_method === 'manually') {
          return true
        } else if (setup_method === 'ai') {
          const allNonEmpty = Object.entries(saved_prompt_params).every(([key, value]) => {
            // if (key === 'goal' || key === 'expertise') return true
            if (key === 'campaign_type') return true
            return typeof value === 'string' && value.trim() !== ''
          })

          return allNonEmpty
        }

        return false

      case '4':
        return selected_leads?.length > 0
      case '5':
        return true
      default:
        return false
    }
  }

  // const validatorSkipButton = () => {
  //   switch (id) {
  //     case '1':
  //       return prompt.id ? true : false
  //     default:
  //       return false
  //   }
  // }

  const generateCampaignValidator = () => {
    const allNonEmpty = Object.entries(saved_prompt_params).every(([key, value]) => {
      // if (key === 'goal') return true
      return typeof value === 'string' && value.trim() !== ''
    })
    const atLeastOneNonEmpty = Object.values(selected_platforms_accounts).some(
      (value) => Array.isArray(value) && value.length > 0,
    )

    const allMessageIdsHaveData = nodes_data.every((node: any) => {
      if (messages_types.includes(node.type) && node?.data?.messages) {
        for (const message of node?.data?.messages) {
          if (message?.trim() === '') {
            return false
          }
        }
        if (node?.data?.messages?.length < 1) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    })

    if (
      selected_leads?.length > 0 &&
      (allNonEmpty || skip_prompt || setup_method === 'manually') &&
      atLeastOneNonEmpty &&
      !allMessageIdsHaveData
    ) {
      dispatch(SET_ARE_MESSAGES_MISSING(true))
    } else {
      dispatch(SET_ARE_MESSAGES_MISSING(false))
    }

    return (
      selected_leads?.length > 0 &&
      nodes_data?.length > 0 &&
      (allNonEmpty || skip_prompt || setup_method === 'manually') &&
      atLeastOneNonEmpty &&
      allMessageIdsHaveData
    )
  }

  const [outOfPoints, setOutOfPoints] = useState(false)
  const [costPoints, setCostPoints] = useState(0)

  // const handleGenerateCampaign = async (isRetryWithRemainingPoints: any) => {
  //   console.log('handle generate campaign')
  //   try {
  //     dispatch(SET_IS_REVIEW_MODE(true))
  //     dispatch(SET_LOADING_GENERATE_CAMPAIGN(true))
  //     // Update latest changes in campaigns table
  //     const newSteps = transformToNewStructure(nodes_data, truePlatforms[0])
  //     const platforms = Object.entries(selected_platforms_accounts).reduce((acc: any, [key, value]: any) => {
  //       if (value.length > 0) {
  //         acc[key] = value
  //       }
  //       return acc
  //     }, {})

  //     // Filter out leads that are already stored in the database
  //     const filteredLeads = selected_leads.filter((lead: any) => {
  //       if (!lead.source || lead.mode !== 'groups') {
  //         return !stored_leads_from_db.includes(lead.index)
  //       }
  //       const combinedIndex = `${lead.source}_${lead.index}`
  //       return !stored_leads_from_db.includes(lead.index) && !stored_leads_from_db.includes(combinedIndex)
  //     })
  //     const indexValues = filteredLeads
  //       .filter((item: any) => item.type !== 'company' && item?.source !== 'CSV')
  //       .map((item: any) => item.index)
  //       .filter((val: any) => val !== null && val?.trim() !== '')

  //     // index values are the AI Leads indexes, companyIndexes are the company indexes

  //     const companyIndexes = filteredLeads
  //       .filter((item: any) => item.type === 'company')
  //       .map((item: any) => item.index)
  //       .filter((val: any) => val !== null)
  //     const recipientsData = filteredLeads.filter((item: any) => item.source === 'CSV')
  //     const filtered_active_platforms = Object.entries(active_platforms)
  //       .filter(([platform, isActive]) => isActive === true)
  //       .map(([platform]) => platform)
  //     // Create campign recipient

  //     const allSenders = getSenderByPlatform(selected_platforms_accounts)

  //     let campaignRecipientsData
  //     if (are_leads_from_groups) {
  //       console.log('inserting groups')
  //       campaignRecipientsData = await addLeadsFromGroups({
  //         campaign_id,
  //         campaign_name: ai_campaign_name || 'New Campaign',
  //         leads: filteredLeads.map((item: any) => ({
  //           username: item.username,
  //           platform: item.platform,
  //           source:
  //             item.platform === 'linkedin'
  //               ? item.search_id
  //               : item?.followed_username || item?.source || item.group_name,
  //           ...(item?.type && { type: item?.type }),
  //         })), // Each item contains platform, in case we need in the future
  //         ignoreLeadsFromOtherCampaigns: campaign_settings_ai?.isAvoidMessagesInOtherCampaignsActive || false,
  //         ignoreOutreachedLeads: campaign_settings_ai?.isDoNotSendMessagesActive || false,
  //         sent_from: selected_platforms_accounts,
  //         useRemainingPoints: isRetryWithRemainingPoints,
  //       })
  //       console.log('campaignRecipientsData', campaignRecipientsData)
  //     }
  //     if (indexValues.length > 0 || recipientsData.length > 0 || companyIndexes.length > 0) {
  //       // return dispatch(SET_LOADING_GENERATE_CAMPAIGN(false)) // delete later
  //       console.log('inserting other')
  //       campaignRecipientsData = await addCampaignAILeadsQuery({
  //         campaign_id,
  //         campaign_name: ai_campaign_name || 'New Campaign',
  //         companyLeads: companyIndexes,
  //         AILeads: indexValues,
  //         CSVLeads: recipientsData,
  //         ignoreLeadsFromOtherCampaigns: campaign_settings_ai?.isAvoidMessagesInOtherCampaignsActive || false,
  //         ignoreOutreachedLeads: campaign_settings_ai?.isDoNotSendMessagesActive || false,
  //         sent_from: selected_platforms_accounts,
  //         useRemainingPoints: isRetryWithRemainingPoints,
  //       })
  //       console.log('campaignRecipientsData', campaignRecipientsData)
  //     }
  //     if (campaignRecipientsData?.error?.status === 470) {
  //       console.log('status is 470')
  //       dispatch(SET_LOADING_GENERATE_CAMPAIGN(false))
  //       if (campaignRecipientsData?.error?.data?.type === 'magic_points') {
  //         setIsMagic(true)
  //       } else {
  //         setIsMagic(false)
  //       }
  //       setCostPoints(campaignRecipientsData?.error?.data?.cost_point)
  //       setOutOfPoints(true)
  //     } else {
  //       await createCampaign({
  //         campaign_id: campaign_id,
  //         campaign_name: ai_campaign_name || 'New Campaign',
  //         prompt_params: JSON.stringify(saved_prompt_params),
  //         platforms: platforms,
  //         ...(newSteps !== undefined && newSteps.length > 0 && { messages_steps: JSON.stringify(newSteps) }),
  //         steps: JSON.stringify(nodes_data),
  //         summarytSettings: {
  //           selected_platforms_accounts,
  //           prompt,
  //           saved_prompt_params,
  //           active_platforms,
  //           campaign_settings_ai,
  //           skip_prompt,
  //           are_leads_from_groups,
  //           select_custom_template,
  //           ai_leads_type,
  //           setup_method,
  //           allSenders,
  //           user_updated_sequence,
  //           default_columns,
  //         },
  //         custom_flow_id: custom_flow_id,
  //       })
  //       // Create Campaign Actions
  //       await createCampaignActions({
  //         campaign_id: campaign_id,
  //         campaign_settings: campaign_settings_ai,
  //         platforms: filtered_active_platforms,
  //       })
  //       dispatch(SET_LOADING_GENERATE_CAMPAIGN(false))
  //       // navigate(`/outreach/create-new-campaign/summary/${campaign_id}`)

  //       trackEvent('CAMPAIGN_REVIEW_LAUNCH')

  //       navigate(`/outreach/create-new-campaign/summary?campaign_id=${campaign_id}`)
  //     }
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }

  const handleGenerateCampaign = async (isRetryWithRemainingPoints: any) => {
    try {
      dispatch(SET_IS_REVIEW_MODE(true))
      dispatch(SET_LOADING_GENERATE_CAMPAIGN(true))
      // Update latest changes in campaigns table
      const newSteps = transformToNewStructure(nodes_data, truePlatforms[0])
      const platforms = Object.entries(selected_platforms_accounts).reduce((acc: any, [key, value]: any) => {
        if (value.length > 0) {
          acc[key] = value
        }
        return acc
      }, {})

      // Filter out leads that are already stored in the database
      const filteredLeads = selected_leads.filter((lead: any) => {
        if (!lead.source || lead.mode !== 'groups') {
          return !stored_leads_from_db.includes(lead.index)
        }
        const combinedIndex = `${lead.source}_${lead.index}`
        return !stored_leads_from_db.includes(lead.index) && !stored_leads_from_db.includes(combinedIndex)
      })

      // Ai Leads
      const indexValues = filteredLeads
        .filter((item: any) => item.convrt_source === 'ai_leads')
        .map((item: any) => item.index)
        .filter((val: any) => val !== null && val?.trim() !== '')

      // Company Leads
      const companyIndexes = filteredLeads
        .filter((item: any) => item.convrt_source === 'ai_leads_companies')
        .map((item: any) => item.index)
        .filter((val: any) => val !== null)
      // CSV Leads
      const recipientsData = filteredLeads.filter((item: any) => item.convrt_source === 'CSV')
      const filtered_active_platforms = Object.entries(active_platforms)
        .filter(([platform, isActive]) => isActive === true)
        .map(([platform]) => platform)

      // Create campign recipient

      const allSenders = getSenderByPlatform(selected_platforms_accounts)
      setTotalLeadsCount((prev) => prev + groupLeads.length + listsLeads.length + recipientsData.length)

      let campaignRecipientsData: any = null
      const groupLeads = filteredLeads
        ?.filter((lead: any) => {
          return ['social_hub', 'web3Event', 'web3Group'].includes(lead?.convrt_source)
        })
        .map((item: any) => ({
          username: item.username,
          platform: item.platform,
          source:
            item.platform === 'linkedin' ? item.search_id : item?.followed_username || item?.source || item.group_name,
          ...(item?.type && { type: item?.type }),
        }))
      const listsLeads = filteredLeads
        ?.filter((lead: any) => {
          return lead?.convrt_source === 'lists'
        })
        .map((item: any) => ({
          index: item.index,
        }))
      if (listsLeads.length > 0) {
        campaignRecipientsData = await addLeadsFromLists({
          campaign_id,
          campaign_name: ai_campaign_name || 'New Campaign',
          leads: listsLeads,
          ignoreLeadsFromOtherCampaigns: campaign_settings_ai?.isAvoidMessagesInOtherCampaignsActive || false,
          ignoreOutreachedLeads: campaign_settings_ai?.isDoNotSendMessagesActive || false,
          ignoreOutreachedLeadsFromTeam: campaign_settings_ai?.ignoreOutreachedLeadsFromTeam || false,
          ignoreLeadsFromOtherTeamMembers: campaign_settings_ai?.ignoreLeadsFromOtherTeamMembers || false,
          sent_from: selected_platforms_accounts,
          useRemainingPoints: isRetryWithRemainingPoints,
          platform: active_platforms['linkedin'] ? 'linkedin' : 'telegram',
        })

        setInsertedLeads((prev) => prev + (campaignRecipientsData?.data?.rows?.length || 0))
      }

      if (groupLeads.length > 0) {
        campaignRecipientsData = await addLeadsFromGroups({
          campaign_id,
          campaign_name: ai_campaign_name || 'New Campaign',
          leads: groupLeads,
          ignoreLeadsFromOtherCampaigns: campaign_settings_ai?.isAvoidMessagesInOtherCampaignsActive || false,
          ignoreOutreachedLeads: campaign_settings_ai?.isDoNotSendMessagesActive || false,
          ignoreOutreachedLeadsFromTeam: campaign_settings_ai?.ignoreOutreachedLeadsFromTeam || false,
          ignoreLeadsFromOtherTeamMembers: campaign_settings_ai?.ignoreLeadsFromOtherTeamMembers || false,
          sent_from: selected_platforms_accounts,
          useRemainingPoints: isRetryWithRemainingPoints,
        })
        setInsertedLeads((prev) => prev + (campaignRecipientsData?.data?.length || 0))
        // return dispatch(SET_LOADING_GENERATE_CAMPAIGN(false)) // delete later
      }

      if (indexValues.length > 0 || recipientsData.length > 0 || companyIndexes.length > 0) {
        campaignRecipientsData = await addCampaignAILeadsQuery({
          campaign_id,
          campaign_name: ai_campaign_name || 'New Campaign',
          companyLeads: companyIndexes,
          AILeads: indexValues,
          CSVLeads: recipientsData,
          ignoreLeadsFromOtherCampaigns: campaign_settings_ai?.isAvoidMessagesInOtherCampaignsActive || false,
          ignoreOutreachedLeads: campaign_settings_ai?.isDoNotSendMessagesActive || false,
          ignoreOutreachedLeadsFromTeam: campaign_settings_ai?.ignoreOutreachedLeadsFromTeam || false,
          ignoreLeadsFromOtherTeamMembers: campaign_settings_ai?.ignoreLeadsFromOtherTeamMembers || false,
          sent_from: selected_platforms_accounts,
          useRemainingPoints: isRetryWithRemainingPoints,
        })
        setInsertedLeads((prev) => prev + (campaignRecipientsData?.data?.length || 0))
      }

      if (campaignRecipientsData?.error?.status === 470) {
        dispatch(SET_LOADING_GENERATE_CAMPAIGN(false))
        if (campaignRecipientsData?.error?.data?.type === 'magic_points') {
          setIsMagic(true)
        } else {
          setIsMagic(false)
        }
        setCostPoints(campaignRecipientsData?.error?.data?.cost_point)
        setOutOfPoints(true)
      } else {
        setShowMessage(true)

        await createCampaign({
          campaign_id: campaign_id,
          campaign_name: ai_campaign_name || 'New Campaign',
          prompt_params: JSON.stringify(saved_prompt_params),
          platforms: platforms,
          ...(newSteps !== undefined && newSteps.length > 0 && { messages_steps: JSON.stringify(newSteps) }),
          steps: JSON.stringify(nodes_data),
          summarytSettings: {
            selected_platforms_accounts,
            prompt,
            saved_prompt_params,
            active_platforms,
            campaign_settings_ai,
            skip_prompt,
            are_leads_from_groups,
            select_custom_template,
            ai_leads_type,
            setup_method,
            allSenders,
            user_updated_sequence,
            default_columns,
          },
          custom_flow_id: custom_flow_id,
        })
        // Create Campaign Actions
        await createCampaignActions({
          campaign_id: campaign_id,
          campaign_settings: campaign_settings_ai,
          platforms: filtered_active_platforms,
          stepsToCheck: flag_campaign_actions,
        })
        dispatch(SET_LOADING_GENERATE_CAMPAIGN(false))
        // navigate(`/outreach/create-new-campaign/summary/${campaign_id}`)

        trackEvent('CAMPAIGN_REVIEW_LAUNCH')

        navigate(`/outreach/create-new-campaign/summary?campaign_id=${campaign_id}`)
      }
    } catch (e) {
      console.error(e)
    }
  }
  const retryWithRemainingPoints = () => {
    handleGenerateCampaign(true)
    setOutOfPoints(false)
  }

  useEffect(() => {
    if (showMessage && totalLeadsCount > 0 && insertedLeads !== totalLeadsCount) {
      message.success(`${insertedLeads}/${totalLeadsCount} leads added successfully!`)
      setShowMessage(false)
    }
  }, [showMessage])

  return (
    <div className={styles.footer}>
      <div className={styles.leftSide}>
        {leftButton ||
          (id === '3' && (
            <span
              className={styles.reset}
              onClick={onReset}
              style={{ color: '#7043ff', fontWeight: 600, cursor: 'pointer' }}>
              Reset
            </span>
          ))}
      </div>
      <div className={styles.rightSide}>
        {/* {onSkip && (
          <Button style={{ marginRight: '10px' }} onClick={onSkip} disabled={!validatorSkipButton()}>
            Skip
          </Button>
        )} */}
        {onBack && (
          <Button onClick={onBack} className={styles.white}>
            Back
          </Button>
        )}
        {onNext && (
          <Button onClick={onNext} disabled={!validator()}>
            Next
          </Button>
        )}
        {onGenerate && (
          <Tooltip title={are_messages_missing ? 'Please fill all messages' : null}>
            <Button
              disabled={!generateCampaignValidator()}
              onClick={() => {
                handleGenerateCampaign(false)
              }}>
              Review & Launch
            </Button>
          </Tooltip>
        )}
        {openModal && (
          <CampaignSuccessModal
            open={openModal}
            onClose={() => {
              setOpenModal(false)
            }}
          />
        )}
      </div>
      {outOfPoints && (
        <OutOfPointsUseRemaining
          open={outOfPoints}
          onClose={() => {
            setOutOfPoints(false)
          }}
          onUseRemainingPoints={retryWithRemainingPoints}
          cost_points={costPoints}
          isMagic={isMagic}
        />
      )}
    </div>
  )
}

export default CustomCollapseFooter
