import React, { useEffect, useRef, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Divider, Dropdown, Input, Button, MenuProps, theme, Popover } from 'antd'
import styles from './CustomMenu.module.scss'
import heatbox_icon_bw from 'common/assets/svg/heatbox_icon_bw.svg'
import heatbox_icon from 'common/assets/svg/heatbox_icon.svg'
// import menu_linkedin_icon_bw from 'common/assets/svg/menu_linkedin_icon_bw.svg'
// import menu_linkedin_icon from 'common/assets/svg/menu_linkedin_icon.svg'
// import contact_icon_bw from 'common/assets/svg/contact_icon_bw.svg'
// import contact_icon from 'common/assets/svg/contact_icon.svg'
import cues_icon_bw from 'common/assets/svg/cues_icon_bw.svg'
import cues_icon from 'common/assets/svg/cues_icon.svg'
import seeds_icon_bw from 'common/assets/svg/seeds_icon_bw.svg'
import seeds_icon from 'common/assets/svg/seeds_icon.svg'
import ai_agents_icon_bw from 'common/assets/svg/ai_agents_icon_bw.svg'
import ai_agents_icon from 'common/assets/svg/ai_agents_icon.svg'
import menu_close_icon from 'common/assets/svg/menu_close_icon.svg'
import lists_icon from 'common/assets/svg/lists_icon.svg'
import lists_icon_bw from 'common/assets/svg/lists_icon_bw.svg'
import search_right from 'common/assets/svg/search_right.svg'
import right_gray_arrow from 'common/assets/svg/right_gray_arrow.svg'
import menu_filter_icon from 'common/assets/svg/menu_filter_icon.svg'
import menu_filter_icon_bw from 'common/assets/svg/menu_filter_icon_bw.svg'
import crown from 'common/assets/svg/premium_crown.svg'
import { useAppDispatch, useAppSelector } from 'state'
import {
  SET_CURRENT_SCREEN,
  SET_FILTER_OBJECT,
  SET_HAS_FILTERS,
  SET_MENU_OPEN,
  SET_SOURCE_LIST,
} from 'common/components/Heatbox/Utils/HeatboxSlice'
import { useNavigate } from 'react-router-dom'
import { useUser } from 'Utils/UserContext'
import { TrackHeatboxActionData } from './Heatbox'
import MenuFiltersOne from './UI/MenuFiltersOne'
import CreateImportModal from 'common/components/Heatbox/Modals/CreateImportListModal'
import ImportListFromCSV from 'common/components/Heatbox/Modals/ImportListFromCSV'
import three_dots_purple from 'common/assets/svg/three_dots_purple.svg'

const { useToken } = theme

const menuVariants = {
  open: { opacity: 1, y: 0, transition: { duration: 0.3, ease: 'easeOut' } },
  closed: { opacity: 0, y: -20, transition: { duration: 0.2, ease: 'easeIn' } },
}

type MenuItem = {
  id: string
  label: string
  bw: string
  color: string
  dropdownItems?: MenuItem[]
  premium?: boolean
}

interface CustomMenuProps {
  lists: any
  getHeatbox: any
  trackings: {
    trackHeatboxAction: (data: TrackHeatboxActionData, additional?: any) => void
    trackCuesAction: (data: TrackHeatboxActionData, additional?: any) => void
  }
  card: any
  getCues: any
}

const CustomMenu: React.FC<CustomMenuProps> = ({ lists, getHeatbox, trackings, card, getCues }: CustomMenuProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [hovered, setHovered] = useState<string | null>(null)
  const { currentScreen, menuOpen, hasFilters } = useAppSelector((state) => state.heatbox)
  const [inputValue, setInputValue] = useState('')
  const [openCreateImportListModalOpen, setOpenCreateImportListModalOpen] = useState(false)
  const [openCSVModal, setOpenCSVModal] = useState(false)
  const [openPopoverId, setOpenPopoverId] = useState<string | null>(null)

  // For toggling between "Create and Import List" text and the input+button
  const [showCreateInput, setShowCreateInput] = useState(false)
  const [listName, setListName] = useState('')

  const { token } = useToken()
  const { user } = useUser()
  const cuesAddon = user?.addons?.cues
  const heatboxAddon = user?.addons?.heatbox
  const setHasFilters = (a: any) => {
    dispatch(SET_HAS_FILTERS(a))
  }
  const setFilterObject = (a: any) => {
    dispatch(SET_FILTER_OBJECT(a))
  }
  const contentStyle: React.CSSProperties = {
    backgroundColor: 'white',
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    width: '90%',
    marginLeft: '5%',
    marginTop: 10,
  }

  // Dropdown state (active extra item and whether the dropdown is open)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [activeExtraItem, setActiveExtraItem] = useState<string | null>(null)
  const menuContainerRef = useRef<HTMLDivElement>(null)

  const handleClick = (id: string) => {
    if (!menuOpen) return
    if (id === 'cues' && !cuesAddon) return
    if (id === 'heatbox' && !heatboxAddon) return
    if (id === 'filter' && currentScreen === 'seeds') return
    dispatch(SET_CURRENT_SCREEN(id))
    navigate(`/cues/${id}`)
  }

  const handleExtraClick = (item: MenuItem) => {
    if (item.id === 'lists') {
      // if (!item.dropdownItems || item.dropdownItems.length === 0) return
      if (activeExtraItem === item.id) {
        setDropdownOpen(false)
        setActiveExtraItem(null)
      } else {
        setDropdownOpen(true)
        setActiveExtraItem(item.id)
      }
    }
    if (item.id === 'filter') {
      if (currentScreen === 'seeds') return
      if (activeExtraItem === item.id) {
        setDropdownOpen(false)
        setActiveExtraItem(null)
      } else {
        setDropdownOpen(true)
        setActiveExtraItem(item.id)
      }
    }
  }

  const handleOpenCreateImportModal = (listName: string) => {
    setListName(listName)
    setOpenCreateImportListModalOpen(true)
    setDropdownOpen(false)
    setActiveExtraItem(null)
  }

  const handlePopoverVisibilityChange = (visible: boolean, popoverId: string) => {
    if (visible) {
      // Close any other open popover
      setOpenPopoverId(popoverId)
    } else if (openPopoverId === popoverId) {
      // Only close if this is the currently open popover
      setOpenPopoverId(null)
    }
  }

  const PopoverContent = ({ listName }: { listName: string }) => {
    const [isHovered, setIsHovered] = useState(false)

    return (
      <div className={styles.popoverContent}>
        <span
          onClick={() => handleOpenCreateImportModal(listName)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            cursor: 'pointer',
            padding: '8px 12px',
            display: 'block',
            backgroundColor: isHovered ? '#f0f0f0' : 'transparent',
            borderRadius: '4px',
            transition: 'background-color 0.2s ease',
          }}>
          Edit List
        </span>
      </div>
    )
  }

  const items: MenuProps['items'] = lists?.map((list: any) => ({
    key: list.id,
    name: list.list_name,
    label: (
      <div className={styles.listItem}>
        <span>{list.list_name}</span>
        <div className={styles.listItemActions}>
          <span>{list.total_success}</span>
          <Popover
            content={<PopoverContent listName={list.list_name} />}
            placement='right'
            trigger='click'
            arrow
            zIndex={1050}
            open={openPopoverId === list.id.toString()}
            onOpenChange={(visible) => handlePopoverVisibilityChange(visible, list.id.toString())}>
            <motion.div
              whileHover={{ scale: 1.05, opacity: 0.8 }}
              whileTap={{ scale: 0.9 }}
              onClick={(e) => {
                e.stopPropagation()
                // handleOpenCreateImportModal(list.list_name)
              }}
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              {/* <PlusOutlined style={{ fontSize: '14px', color: '#7043ff' }} /> */}
              <img src={three_dots_purple} alt='' />
            </motion.div>
          </Popover>
        </div>
      </div>
    ),
    onClick: async () => {
      if (openPopoverId) return

      setDropdownOpen(false)
      setActiveExtraItem(null)
      setShowCreateInput(false)
      setOpenPopoverId(null)

      await getHeatbox({
        user_id: user?.id,
        source_list: list.list_name,
      })

      dispatch(SET_SOURCE_LIST(list.list_name))
      if (currentScreen === 'heatbox') {
        trackings.trackHeatboxAction({
          action_type: 'filter_by_list',
          card: card,
          input: {
            list_name: list.list_name,
          },
        })
      } else {
        trackings.trackCuesAction({
          action_type: 'filter_by_list',
          card: card,
          input: {
            list_name: list.list_name,
          },
        })
      }
    },
  })) || [
    {
      key: '1',
      label: 'Item 1',
    },
    {
      key: '2',
      label: 'Item 2',
    },
    {
      key: '3',
      label: 'Item 3',
    },
  ]

  const filteredItems: any = items?.filter((item: any) => item?.name?.toLowerCase().includes(inputValue.toLowerCase()))

  const menuItems: MenuItem[] = [
    { id: 'cues', label: 'Cues', bw: cues_icon_bw, color: cues_icon, premium: !cuesAddon },
    { id: 'heatbox', label: 'Heatbox', bw: heatbox_icon_bw, color: heatbox_icon, premium: !heatboxAddon },
    { id: 'seeds', label: 'Seeds', bw: seeds_icon_bw, color: seeds_icon },
  ]

  const extraItems: MenuItem[] = [
    { id: 'ai_agents', label: 'AI Agents', bw: ai_agents_icon_bw, color: ai_agents_icon, premium: true },
    // { id: 'linkedin', label: 'LinkedIn', bw: menu_linkedin_icon_bw, color: menu_linkedin_icon },
    ...(currentScreen === 'heatbox'
      ? [
          {
            id: 'lists',
            label: 'Lists',
            bw: lists_icon_bw,
            color: lists_icon,
            dropdownItems: filteredItems,
          },
        ]
      : []),
    // { id: 'contact', label: 'Contact', bw: contact_icon_bw, color: contact_icon },
    {
      id: 'filter',
      label: 'Filter',
      bw: menu_filter_icon_bw,
      color: menu_filter_icon,
      premium: currentScreen === 'seeds',
    },
  ]

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Get all popover elements
      const popoverElements = document.querySelectorAll('.ant-popover')
      let clickedInsidePopover = false

      // Check if click was inside any popover
      popoverElements.forEach((element) => {
        if (element.contains(event.target as Node)) {
          clickedInsidePopover = true
        }
      })

      if (
        menuContainerRef.current &&
        !menuContainerRef.current.contains(event.target as Node) &&
        !extraItems.some((item) => (event.target as HTMLElement).closest(`[data-id="${item.id}"]`)) &&
        !clickedInsidePopover // Add this condition to prevent closing when clicking inside popovers
      ) {
        setDropdownOpen(false)
        setActiveExtraItem(null)
        setShowCreateInput(false)
      }

      // Close popover when clicking outside of it
      if (!clickedInsidePopover && !(event.target as HTMLElement).closest('.three-dots-button')) {
        setOpenPopoverId(null)
      }
    }

    if (dropdownOpen || openPopoverId !== null) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownOpen, extraItems, openPopoverId])

  useEffect(() => {
    setFilterObject([
      {
        type: 'channel',
        is: 'is',
        value: 'linkedin',
      },
    ])
    setHasFilters(false)
  }, [currentScreen])

  // Handle the user clicking the "Create" button after entering the new list name
  const handleCreateList = () => {
    if (!listName.trim()) return
    // Here you can do anything with listName if you want to store it, etc.

    // Reset states
    setShowCreateInput(false)

    // Close the dropdown
    setDropdownOpen(false)
    setActiveExtraItem(null)

    // Finally, open the modal
    setOpenCreateImportListModalOpen(true)
    // setListName('')
  }

  return (
    <>
      <Dropdown
        menu={{ items: filteredItems }}
        open={dropdownOpen}
        trigger={['click']}
        dropdownRender={(menu) => (
          <>
            {activeExtraItem === 'lists' && (
              <div style={contentStyle} ref={menuContainerRef}>
                <div style={{ padding: '10px' }}>
                  <Input
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    prefix={<img src={search_right} alt='ai_wand' />}
                    suffix={
                      <img
                        className={styles.right_arrow}
                        style={{ cursor: 'pointer' }}
                        src={right_gray_arrow}
                        alt='right_arrow'
                      />
                    }
                    placeholder='Search for lists'
                    style={{ backgroundColor: '#f5f5f5', border: 'none', padding: '8px' }}
                    styles={{
                      input: {
                        backgroundColor: '#f5f5f5',
                        fontSize: 12,
                      },
                    }}
                  />
                </div>

                <Divider style={{ margin: '0' }} />

                {React.cloneElement(menu as React.ReactElement<{ style: React.CSSProperties }>, {
                  style: {
                    borderRadius: 0,
                    // boxShadow: '0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05)',
                    boxShadow: 'none',
                  },
                })}

                <Divider style={{ margin: '0' }} />

                {/* AnimatePresence for the toggling between text and input */}
                <div className={styles.createImportList} style={{ padding: '10px', textAlign: 'center' }}>
                  <AnimatePresence mode='wait'>
                    {!showCreateInput ? (
                      // Step 1: The default text
                      <motion.div
                        key='defaultText'
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        transition={{ duration: 0.1 }}
                        whileHover={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
                        style={{ cursor: 'pointer', color: token.colorPrimary, padding: '5px 0px' }}
                        onClick={() => setShowCreateInput(true)}>
                        Create and Import List
                      </motion.div>
                    ) : (
                      // Step 2: The input + button
                      <motion.div
                        key='createInput'
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        transition={{ duration: 0.1 }}
                        style={{ display: 'flex', gap: '8px', justifyContent: 'space-between' }}>
                        <Input
                          autoFocus
                          placeholder='Insert List Name'
                          value={listName}
                          onChange={(e) => setListName(e.target.value)}
                          style={{ width: '70%' }}
                        />
                        <Button
                          type='primary'
                          onClick={handleCreateList}
                          style={{ borderRadius: '6px' }}
                          disabled={
                            listName.trim() === '' ||
                            filteredItems?.map((item: any) => item.name?.toLowerCase()).includes(listName.toLowerCase())
                          }>
                          Create
                        </Button>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            )}
            {activeExtraItem === 'filter' && (
              <MenuFiltersOne
                menuContainerRef={menuContainerRef}
                currentScreen={currentScreen}
                getCues={getCues}
                getHeatbox={getHeatbox}
                user={user}
              />
            )}
          </>
        )}>
        <motion.div
          // ref={menuContainerRef}
          className={styles.menuContainer}
          initial='closed'
          animate={menuOpen ? 'open' : 'closed'}
          exit='closed'
          variants={menuVariants}>
          {menuItems.map((item) => (
            <motion.div
              key={item.id}
              className={styles.menuItem}
              style={!menuOpen ? { cursor: 'default' } : { cursor: 'pointer' }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0 }}
              onMouseEnter={() => setHovered(item.id)}
              onMouseLeave={() => setHovered(null)}
              onClick={() => handleClick(item.id)}>
              <div className={currentScreen === item.id ? styles.iconContainer : styles.iconContainerGray}>
                <img
                  src={currentScreen === item.id || hovered === item.id ? item.color : item.bw}
                  alt={item.label}
                  className={styles.icon}
                />
              </div>
              {item?.premium && (
                <img src={crown} alt='premium' style={{ position: 'absolute', right: '-7px', top: '-7px' }} />
              )}
              <span className={styles.label}>{item.label}</span>
            </motion.div>
          ))}

          <Divider style={{ margin: 0, height: '1.5em' }} type='vertical' />

          {extraItems.map((item) => (
            <motion.div
              key={item.id}
              className={styles.menuItem}
              style={!menuOpen ? { cursor: 'default' } : { cursor: 'pointer' }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0 }}
              onMouseEnter={() => setHovered(item.id)}
              onMouseLeave={() => setHovered(null)}
              onClick={() => handleExtraClick(item)}
              data-id={item.id} // for the outside click detection
            >
              <img src={hovered === item.id ? item.color : item.bw} alt={item.label} className={styles.icon} />
              {item?.premium && (
                <img src={crown} alt='premium' style={{ position: 'absolute', right: '-7px', top: '-7px' }} />
              )}
              {item.id === 'filter' && hasFilters && <div className={styles.filterDot} />}
              <span className={styles.label}>{item.label}</span>
            </motion.div>
          ))}

          {currentScreen !== 'seeds' && (
            <div className={styles.closeButton}>
              <img
                style={!menuOpen ? { cursor: 'default' } : { cursor: 'pointer' }}
                src={menu_close_icon}
                alt=''
                onClick={() => {
                  dispatch(SET_MENU_OPEN(false))
                  setDropdownOpen(false)
                }}
              />
            </div>
          )}
        </motion.div>
      </Dropdown>

      {/* The modal(s) */}
      <CreateImportModal
        open={openCreateImportListModalOpen}
        onCancel={() => {
          setOpenCreateImportListModalOpen(false)
          setListName('')
        }}
        onClose={() => {
          setOpenCreateImportListModalOpen(false)
        }}
        onCSV={() => setOpenCSVModal(true)}
      />
      {openCSVModal && (
        <ImportListFromCSV
          open={openCSVModal}
          onClose={() => {
            setOpenCSVModal(false)
            setListName('')
          }}
          listName={listName}
        />
      )}
    </>
  )
}

export default CustomMenu
