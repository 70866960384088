import React, { useEffect, useState } from 'react'
import styles from './Heatbox.module.scss'
import { message, notification } from 'antd'
import CardStack from 'common/components/Heatbox/CardStack'
import AISummary from 'common/components/Heatbox/AISummary'
import {
  useLazyAddToSeedsQuery,
  // useLazyExplainCommentRelevanceQuery,
  // useLazyExplainPostRelevanceQuery,
  useLazyGetCuesQuery,
  useLazyGetHeatboxListsQuery,
  useLazyGetHeatboxQuery,
  useLazyGetLatestMarkedCueQuery,
  useLazyGetLatestMarkedHeatboxQuery,
  useLazyGetLinkedinAccountStatusQuery,
  useLazyGetSeedsQuery,
  // useLazyMarkCommentQuery,
  useLazyMarkContentQuery,
  useLazyMarkCueQuery,
  useLazyRemoveFromCuesQuery,
  useLazyRemoveFromHeatboxQuery,
  // useLazyMarkPostQuery,
  useLazySummarizePostQuery,
} from 'common/APIs/HeatboxApi'
import CustomMenu from 'common/components/Heatbox/CustomMenu'
import { useNavigate, useParams } from 'react-router-dom'
import Seeds from 'common/components/Heatbox/Seeds'
import {
  SET_CURRENT_SCREEN,
  SET_KILLED_CARD,
  SET_LINKEDIN_CONNECTED,
  SET_MENU_OPEN,
} from 'common/components/Heatbox/Utils/HeatboxSlice'
import { useAppDispatch, useAppSelector } from 'state'
import toggle_menu_icon from 'common/assets/svg/toggle_menu_icon.svg'
import { motion } from 'framer-motion'

import { useUser } from 'Utils/UserContext'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { trackEvent } from 'eventConfig'
import { formatNumber } from '../Campaigns/Helpers/GroupHelpers'
import { CUE_CONSTANTS, removeEmojis } from './Utils/CardUtils'
export interface Card {
  content_id: any
  cue_id?: any
  author_id?: any
  content?: any
  lead?: any
  content_score?: any
  content_type?: any
  updated_time?: any
  created_time?: any
  heatbox_score?: any
  lead_score?: any
  marks?: any
  topic?: any
  channel?: any
  lead_id?: any
  posted_at?: any
  url?: any
  analysis?: any
}
const dummyCards: Card[] = Array.from({ length: 5 }, (_, i) => ({
  content_id: i + 1,
  content: `text ${i + 1}`,
}))
export interface TrackHeatboxActionData {
  action_type: string // action_type is required
  card: Card
  input?: any // allows any other properties
  command?: string
}
interface HeatboxProps {}

const Heatbox: React.FC<HeatboxProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const view = useParams<any>()
  const { menuOpen, sourceList, fireLevel, filterObject, sort, hasFilters, asc } = useAppSelector(
    (state) => state.heatbox,
  )
  const subUrl = view?.['*']
  const [getHeatbox, { data: heatboxData, isFetching: isHeatboxLoading }] = useLazyGetHeatboxQuery()
  const [getCues, { data: cuesData, isFetching: isCuesLoading }] = useLazyGetCuesQuery()
  const [getSeeds, { data: seedsData, isLoading: isSeedsLoading }] = useLazyGetSeedsQuery()
  const [getLatestMarkedCue] = useLazyGetLatestMarkedCueQuery()
  const [getLatestMarkedHeatbox] = useLazyGetLatestMarkedHeatboxQuery()
  const [getHeatboxLists, { data: heatboxLists }] = useLazyGetHeatboxListsQuery()
  // const [explainPostRelevance] = useLazyExplainPostRelevanceQuery()
  const [getAccountStatus, { data: linkedinAccountStatus, error: accountStatusError, status: accountStatusStatus }] =
    useLazyGetLinkedinAccountStatusQuery()
  // const [explainCommentRelevance] = useLazyExplainCommentRelevanceQuery()
  const [summarizePost] = useLazySummarizePostQuery()
  const [markCue] = useLazyMarkCueQuery()
  // const [markPost] = useLazyMarkPostQuery()
  // const [markComment] = useLazyMarkCommentQuery()
  const [addToSeeds] = useLazyAddToSeedsQuery()
  const [removeFromHeatbox] = useLazyRemoveFromHeatboxQuery()
  const [removeFromCues] = useLazyRemoveFromCuesQuery()
  const [markContent] = useLazyMarkContentQuery()
  const [isSummaryOpen, setIsSummaryOpen] = useState(false)
  const [isAiSummaryLoading, setIsAiSummaryLoading] = useState(false)
  const [showContent, setShowContent] = useState(false)
  const [summaryForCardId, setSummaryForCardId] = useState<string | null>(null)
  const [cards, setCards] = useState<Card[]>(dummyCards)
  const [aiSummaryData, setAiSummaryData] = useState<any>(null)
  // const [counter, setCounter] = useState(0)
  // Replace the single counter with two separate counters:
  const [heatboxCounter, setHeatboxCounter] = useState(0)
  const [cuesCounter, setCuesCounter] = useState(0)
  const [messageApi, contextHolder] = message.useMessage()

  const handleCounters = (sign: '+' | '-') => {
    if (subUrl === 'heatbox') setHeatboxCounter((prev) => (sign === '+' ? prev + 1 : prev - 1))
    if (subUrl === 'cues') setCuesCounter((prev) => (sign === '+' ? prev + 1 : prev - 1))
  }

  const customMessage = (content: string, type: 'success' | 'error' | 'warning' | 'info') => {
    messageApi.open({
      type: type,
      content: content,
      className: 'custom-class',
      style: {
        marginLeft: '60px',
        marginTop: menuOpen ? '85px' : '0px',
      },
    })
  }
  const [notify, contextHolderNotification] = notification.useNotification()
  const firstCard = cards?.[0]
  const { user } = useUser()
  const cuesAddon = user?.addons?.cues
  const heatboxAddon = user?.addons?.heatbox
  const HEATBOX_USERID = user?.id
  const totalCount = 30

  const openLinkedinNotification = () => {
    dispatch(SET_LINKEDIN_CONNECTED(false))
    notify.error({
      message: 'LinkedIn account not connected',
      description: 'Please connect your LinkedIn account in the Convrt extension to comment and interact.',
      icon: <ExclamationCircleOutlined />,
      duration: null,
    })
  }

  const onAiSummaryClose = () => {
    setIsSummaryOpen(false)
  }

  const getHeatboxData = async () => {
    await Promise.all([
      getHeatbox({
        user_id: HEATBOX_USERID,
        source_list: sourceList || null,
        filter: filterObject && hasFilters ? JSON.stringify(filterObject) : null,
        sort: sort ? `${sort}_${asc ? 'asc' : 'desc'}` : null,
      }),
      getHeatboxLists({ user_id: HEATBOX_USERID }),
      getCues({
        user_id: HEATBOX_USERID,
        filter: filterObject && hasFilters ? JSON.stringify(filterObject) : null,
        sort: sort ? `${sort}_${asc ? 'asc' : 'desc'}` : null,
      }),
      getSeeds({ user_id: HEATBOX_USERID }),
      getAccountStatus(),
    ])
  }

  // const handleRelevance = async () => {
  //   const isReddit = firstCard?.channel === 'reddit'
  //   const content = firstCard?.content
  //   if (firstCard.content_type === 'post') {
  //     let post = `
  //       content: ${isReddit ? content?.content?.text || content?.title : content?.text},
  //       author_name: ${content?.author?.firstName} ${content?.author?.lastName},
  //       author_headline: ${isReddit ? '' : content?.author?.headline},
  //       is_repost: ${isReddit ? false : content?.is_repost},
  //       reactions: ${content?.reactions},
  //       reposts: ${isReddit ? null : content?.reposts},
  //     `
  //     let leadScore = firstCard?.lead_score
  //     let contentScore = firstCard?.content_score
  //     const result = await explainPostRelevance({
  //       post,
  //       lead_score: leadScore,
  //       content_score: contentScore,
  //       user_id: HEATBOX_USERID,
  //     })

  //     return result
  //   } else {
  //     let post = `
  //     content: ${isReddit ? content?.content?.text || content?.title : content?.text},
  //     author_name: ${content?.author?.firstName} ${content?.author?.lastName},
  //     author_headline: ${isReddit ? '' : content?.author?.headline},
  //     is_repost: ${isReddit ? false : content?.is_repost},
  //     reactions: ${content?.reactions},
  //     reposts: ${isReddit ? null : content?.reposts},
  //   `
  //     let comment = `
  //       content: ${content?.highlights?.[0]}
  //     `
  //     let leadScore = firstCard?.lead_score
  //     let contentScore = firstCard?.content_score
  //     const result = await explainCommentRelevance({
  //       comment_content: comment,
  //       post_content: post,
  //       lead_score: leadScore,
  //       content_score: contentScore,
  //       user_id: HEATBOX_USERID,
  //     })
  //     return result
  //   }
  // }

  const handleSummary = async () => {
    const isReddit = firstCard?.channel === 'reddit'

    const content = firstCard?.content
    const user_id = HEATBOX_USERID
    const channel = firstCard?.channel || 'linkedin'
    const post_content = isReddit ? content?.content?.text || content?.title : content?.text
    const author_name = content?.author?.firstName + (content?.author?.lastName ? ' ' + content?.author?.lastName : '')
    const post_engagements = String(content?.reactions)
    const d = firstCard?.posted_at || firstCard?.content?.posted_date || firstCard?.content?.commented_date
    const post_time_in_days = d
      ? String(Math.floor((new Date().getTime() - new Date(d)?.getTime()) / (1000 * 60 * 60 * 24)) || '')
      : ''
    const author_job_title = isReddit ? null : content?.author?.headline
    const subreddit_name = content?.subreddit?.name || null
    //   let post = `
    //   content: ${isReddit ? content?.content?.text || content?.title : content?.text},
    //   author_name: ${content?.author?.firstName} ${content?.author?.lastName},
    //   author_headline: ${isReddit ? '' : content?.author?.headline},
    //   is_repost: ${isReddit ? false : content?.is_repost},
    //   reactions: ${content?.reactions},
    //   reposts: ${isReddit ? null : content?.reposts},
    // `

    const result = await summarizePost({
      user_id,
      channel,
      post_content,
      author_name,
      post_engagements,
      post_time_in_days,
      author_job_title,
      subreddit_name,
    })

    return result
  }

  const onAiSummary = async () => {
    if (!firstCard || isSummaryOpen) {
      return
    }
    if (subUrl === 'heatbox') {
      trackHeatboxAction({
        card: firstCard,
        action_type: 'ai_summary_click',
      })
    } else {
      trackCuesAction({
        card: firstCard,
        action_type: 'ai_summary_click',
      })
    }
    if (summaryForCardId && summaryForCardId === firstCard?.content_id) {
      setShowContent(false)
      setTimeout(() => {
        setIsAiSummaryLoading(true)
      }, 200)
      setTimeout(() => {
        setIsAiSummaryLoading(false)
        setShowContent(true)
      }, 1000)
      setIsSummaryOpen(true)
      return
    }
    setShowContent(false)
    try {
      setIsSummaryOpen(true)
      setIsAiSummaryLoading(true)
      // const [postRelevance, postSummary] = await Promise.all([handleRelevance(), handleSummary()])
      const { data } = await handleSummary()
      // if (!postRelevance || !postSummary || postRelevance?.error?.status || postSummary?.error?.status) {
      //   throw new Error('No data found')
      // }
      if (!data) {
        throw new Error('No data found')
      }
      if (Object.keys(data).length === 0) {
        throw new Error('No data found')
      }
      setAiSummaryData(data)
      setSummaryForCardId(firstCard?.content_id)
      setShowContent(true)
      setIsSummaryOpen(true)
    } catch (error) {
      setIsSummaryOpen(false)
      message.error('Failed to fetch data, please try again.')
      console.log('error', error)
    } finally {
      setIsAiSummaryLoading(false)
    }
  }

  const onSkip = async (mark: 'skip' | 'bad' | 'stop_heatbox' | 'already_seen' | 'not_relevant' = 'skip') => {
    if (!firstCard) return
    dispatch(SET_KILLED_CARD(firstCard.content_id))
    setShowContent(false)
    // setCounter((prev) => prev + 1)
    handleCounters('+')
    setIsSummaryOpen(false)

    if (mark === 'stop_heatbox') {
      if (subUrl === 'cues') {
        await removeFromCues({ lead_id: firstCard.lead_id, user_id: HEATBOX_USERID })
      } else {
        await removeFromHeatbox({ lead_id: firstCard.lead.username, user_id: HEATBOX_USERID })
      }
      await getHeatboxData()
    } else {
      if (subUrl === 'cues') {
        await markCue({ cue_id: firstCard.cue_id, user_id: HEATBOX_USERID, mark: mark })
      } else {
        await markContent({ content_id: firstCard.content_id, user_id: HEATBOX_USERID, mark: mark })
      }
    }
  }

  const handleAddToSeeds = async () => {
    try {
      const result = await addToSeeds({
        lead_id: firstCard.lead.username || firstCard.lead_id,
        user_id: HEATBOX_USERID,
        content_id: firstCard.content_id,
        content: firstCard.content.text || firstCard.content?.content?.text,
        platform: firstCard?.channel || 'linkedin',
      })
      if (result?.error?.status === 470) {
        customMessage(CUE_CONSTANTS.SEEDS_LIMIT_MESSAGE, 'error')
        return false
      }
      if (result?.isError) {
        throw new Error('Failed to add to seeds')
      }
      customMessage('Successfully added to seeds!', 'success')
      await getSeeds({ user_id: HEATBOX_USERID })
      return true
    } catch (error) {
      customMessage('Failed to add to seeds', 'error')
      return false
    }
  }

  const onToggleMenu = () => {
    dispatch(SET_MENU_OPEN(!menuOpen))
  }

  const refreshSeedsData = async () => {
    await getSeeds({ user_id: HEATBOX_USERID })
  }

  const handleStepBack = async () => {
    const oldCard = [...cards][0]
    const { data } =
      subUrl === 'cues'
        ? await getLatestMarkedCue({ user_id: HEATBOX_USERID })
        : await getLatestMarkedHeatbox({ user_id: HEATBOX_USERID })

    if (!data || data.length === 0) return
    const newCard = data[0]

    if (subUrl === 'heatbox') {
      trackHeatboxAction({
        action_type: 'return_back',
        card: newCard,
      })
    } else {
      trackCuesAction({
        action_type: 'return_back',
        card: newCard,
      })
    }

    if (
      cards.length > 0 &&
      ((subUrl === 'cues' && cards[0].cue_id === newCard.cue_id) ||
        (subUrl === 'heatbox' && cards[0].content_id === newCard.content_id))
    ) {
      return // Do not update if the cue_id/content_id is the same
    }

    handleCounters('-')
    setIsSummaryOpen(false)
    setShowContent(false)
    dispatch(SET_KILLED_CARD(oldCard.content_id))
    setCards([newCard, ...cards]) // Add the new card at the beginning
  }

  const trackHeatboxAction = (data: TrackHeatboxActionData, additional?: any) => {
    const isCues = subUrl === 'cues'
    const card = data.card
    const action_type = data.action_type
    const isReddit = card?.channel === 'reddit'
    const lead_id = isReddit ? card?.lead?.id : card?.lead?.username || card?.lead_id
    const topic = card?.topic
    const lead_name =
      card?.lead?.name || card?.content?.author?.name
        ? card?.content?.author?.name
        : card?.content?.author?.firstName && card?.content?.author?.firstName !== 'undefined'
        ? `${card?.content?.author?.firstName} ${card?.content?.author?.lastName}`
        : card?.content?.company?.name || 'Unknown Name'
    const platform = isReddit ? 'reddit' : 'linkedin'
    const lead_heat_score = fireLevel
    const content_id = card?.content_id
    const content_date =
      card?.content?.posted_date ||
      card?.content.posted_at ||
      card?.posted_at ||
      card?.content?.commented_at ||
      card?.content?.commented_date
    const contact_score = card?.lead_score
    const content_score = card?.content_score
    const content_type = card?.content_type
    const input = data?.input || {}
    const post_link = isReddit
      ? card?.url
      : isCues
      ? `https://www.linkedin.com/feed/update/urn:li:activity:${card?.content?.urn}/`
      : card?.content_type === 'comment'
      ? card?.content?.comment_url
      : card?.content?.post_url
    const command = data?.command || ''
    trackEvent('HEATBOX_ACTION', {
      action_type,
      lead_id,
      topic,
      lead_name,
      platform,
      lead_heat_score,
      content_id,
      content_date,
      contact_score,
      content_score,
      input,
      command,
      post_link,
      content_type,
      ...(additional || {}),
    })
  }

  const trackCuesAction = (data: TrackHeatboxActionData, additional?: any) => {
    function round50(x: number) {
      return Math.ceil(x / 50) * 50
    }

    const isCues = subUrl === 'cues'
    const card = data.card
    const action_type = data.action_type
    const isReddit = card?.channel === 'reddit'
    const lead_id = isReddit ? card?.lead?.id : card?.lead?.username || card?.lead_id
    const topic = card?.topic
    const lead_name =
      card?.lead?.name || card?.content?.author?.name
        ? card?.content?.author?.name
        : card?.content?.author?.firstName && card?.content?.author?.firstName !== 'undefined'
        ? `${card?.content?.author?.firstName} ${card?.content?.author?.lastName}`
        : card?.content?.company?.name || 'Unknown Name'
    const platform = isReddit ? 'reddit' : 'linkedin'
    const content_id = card?.content_id
    const content_date =
      card?.content?.posted_date ||
      card?.content.posted_at ||
      card?.posted_at ||
      card?.content?.commented_at ||
      card?.content?.commented_date
    const contact_score = card?.lead_score
    const content_score = card?.content_score
    const content_type = card?.content_type
    const input = data?.input || {}
    const post_link = isReddit
      ? card?.url
      : isCues
      ? `https://www.linkedin.com/feed/update/urn:li:activity:${card?.content?.urn}/`
      : card?.content_type === 'comment'
      ? card?.content?.comment_url
      : card?.content?.post_url
    const command = data?.command || ''
    const analysis = typeof card?.analysis === 'string' ? JSON.parse(card?.analysis || '{}') : card?.analysis
    const top_persona = analysis?.top_personas || []
    const icp_fit_percentage = analysis?.icp_fit_percentage || 0
    const competitors_mentioned = analysis?.competitors_mentioned || []
    const reach_total = analysis?.reach_total || 1000
    const discussion_tone =
      typeof analysis?.discussion_tone === 'string' ? [analysis?.discussion_tone] : analysis?.discussion_tone || []
    const total_range = reach_total
      ? `${formatNumber(round50(Math.floor(reach_total * 0.5)))} - ${formatNumber(
          round50(Math.floor(reach_total * 1.5)),
        )}`
      : 'N/A'
    const icp_range = reach_total
      ? `${formatNumber(round50(Math.floor(reach_total * (icp_fit_percentage / 100) * 0.5)))} - ${formatNumber(
          round50(Math.floor(reach_total * (icp_fit_percentage / 100) * 1.5)),
        )}`
      : 'N/A'
    const top_personas = top_persona.slice(0, 3)
    const heat_score = removeEmojis(analysis?.heat_score || 'Warming Up')
    const problem_fit = analysis?.problem_fit_score?.problem_fit || {}
    const solution_fit = analysis?.problem_fit_score?.solution_fit || {}
    const market_fit = analysis?.problem_fit_score?.market_fit || {}

    trackEvent('CUES_ACTION', {
      action_type,
      lead_id,
      lead_name,
      platform,
      discussion_tone,
      topic,
      total_range,
      icp_range,
      top_personas,
      warm_level: heat_score,
      problem_fit,
      solution_fit,
      market_fit,
      competitors_mentioned,
      content_id,
      content_date,
      contact_score,
      content_score,
      input,
      command,
      post_link,
      content_type,
      ...(additional || {}),
    })
  }

  useEffect(() => {
    getHeatboxData()
  }, [HEATBOX_USERID])

  useEffect(() => {
    if (linkedinAccountStatus) {
      if (linkedinAccountStatus?.status === 'error') {
        openLinkedinNotification()
        return
      }
    }
    if (accountStatusError) {
      openLinkedinNotification()
      return
    }
    if (accountStatusStatus === '404') {
      openLinkedinNotification()
      return
    }
  }, [linkedinAccountStatus, accountStatusError, accountStatusStatus])

  useEffect(() => {
    if (subUrl === 'heatbox' && heatboxData) {
      const newCards = heatboxData?.slice(heatboxCounter)
      setCards(newCards)
    } else if (subUrl === 'cues' && cuesData) {
      const newCards = cuesData?.slice(cuesCounter)
      setCards(newCards)
    }
  }, [subUrl, heatboxData, cuesData])

  useEffect(() => {
    setIsSummaryOpen(false)

    if (!subUrl || (subUrl === 'cues' && !cuesAddon) || (subUrl === 'heatbox' && !heatboxAddon)) {
      navigate(cuesAddon ? `/cues/cues` : heatboxAddon ? `/cues/heatbox` : `/cues/seeds`)
      return
    }

    if (subUrl === 'seeds') {
      dispatch(SET_MENU_OPEN(true))
      dispatch(SET_CURRENT_SCREEN('seeds'))
    } else {
      dispatch(SET_CURRENT_SCREEN(subUrl))

      if (subUrl !== 'seeds') {
        refreshSeedsData()
      }
    }
  }, [subUrl])

  useEffect(() => {
    if (heatboxCounter === totalCount - 1) {
      setHeatboxCounter(0)
      getHeatboxData()
    }
  }, [heatboxCounter])

  useEffect(() => {
    if (cuesCounter === totalCount - 1) {
      setCuesCounter(0)
      getHeatboxData()
    }
  }, [cuesCounter])

  useEffect(() => {
    if (subUrl) {
      // TODO BEFORE UPLOAD
      // trackEvent('ENTER_CUES_SCREEN', {
      //   view: subUrl,
      // })
    }
  }, [subUrl])

  return (
    <>
      {contextHolder}
      {contextHolderNotification}
      <div className={styles.container}>
        <CustomMenu
          getCues={getCues}
          lists={heatboxLists}
          getHeatbox={getHeatbox}
          trackings={{
            trackHeatboxAction,
            trackCuesAction,
          }}
          card={firstCard}
        />
        {subUrl === 'seeds' ? (
          <Seeds data={seedsData} isLoading={isSeedsLoading} refreshSeedsData={refreshSeedsData} />
        ) : (
          <>
            {/* Left Section - AI Summary */}
            <div className={`${styles.leftSider} ${isSummaryOpen ? styles.expanded : ''}`}>
              <AISummary
                open={isSummaryOpen}
                onClose={onAiSummaryClose}
                onClick={onAiSummary}
                isLoading={isAiSummaryLoading}
                showContent={showContent}
                aiSummaryData={aiSummaryData}
                card={firstCard}
              />
            </div>

            {/* Center Section - Stackable Cards */}
            <div className={`${styles.content} ${isSummaryOpen ? styles.shifted : ''}`}>
              <div
                className={`${styles.ellipseBlur} ${
                  subUrl === 'seeds' ? styles.seeds : subUrl === 'cues' ? styles.cues : styles.heatbox
                }`}
              />
              <CardStack
                cards={cards}
                setCards={setCards}
                isLoading={subUrl === 'cues' ? isCuesLoading : isHeatboxLoading}
                onSkip={onSkip}
                screenType={subUrl}
                functions={{
                  handleAddToSeeds,
                  handleStepBack,
                  trackHeatboxAction,
                  trackCuesAction,
                }}
              />
            </div>

            {/* Right Section - Shrinks when AI Summary is open */}
            <div className={`${styles.rightSider} ${isSummaryOpen ? styles.shrunk : ''}`}>
              <motion.div style={{ marginBottom: 80 }} whileHover={{ scale: 1.1 }} transition={{ duration: 0.3 }}>
                <img src={toggle_menu_icon} alt='' onClick={onToggleMenu} />
              </motion.div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Heatbox
