import React, { useEffect, useState } from 'react'
import { Select, Divider, DatePicker, Space, Button } from 'antd'
import styles from './CampaignFilters.module.scss'
import {
  SET_CAMPAIGN_DATES_FILTERS,
  SET_CAMPAIGN_FILTERS,
  SET_LOADING_CAMPAIGNS,
  SET_SHOW_PERCENTAGE,
} from '../../state/outreachCampaignSlice'
import { useAppDispatch, useAppSelector } from 'state'
import { useLazyGetSenderQuery } from 'common/APIs/OutreachApi'
import { useAuth } from 'common/hooks/useAuth.hooks'
import type { TimeRangePickerProps } from 'antd'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import CustomToggleSwitch from '../UI/CustomToggleSwitch'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { trackEvent } from 'eventConfig'
import { useUser } from 'Utils/UserContext'

const { Option } = Select
const { RangePicker } = DatePicker

interface CampaignFiltersProps {
  selectedTab: any // 1 === campaigns | 2 === recipients | 3 === messages
}

const CampaignFilters: React.FC<CampaignFiltersProps> = ({ selectedTab }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { userDetails } = useAuth()
  const { all_campaigns_data, campaign_filters, campaign_date_filters } = useAppSelector(
    (state) => state.outreachCampaign,
  )
  const [filterType, setFilterType] = useState<string>(campaign_filters?.type)
  const [filterValue, setFilterValue] = useState<any>(campaign_filters?.value)
  const [refetcGetSenderList, { data }] = useLazyGetSenderQuery()
  const [campaignNames, setCampaignNames] = useState([])
  const [dateType, setDateType] = useState<any>('created_at')
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null] | null>(null)
  const { user } = useUser()
  const isWeb3User = user?.user_type === 'web3'

  const stepsItems = [
    // Telegram actions
    ...(isWeb3User
      ? [
          {
            label: 'Telegram',
            children: [
              { label: 'Outreach', value: 'telegram_send_message_outreach' },
              { label: 'Followup', value: 'telegram_send_message_followup' },
              { label: 'Responder', value: 'telegram_auto_reply' },
            ],
          },
        ]
      : []),
    // Instagram actions
    {
      label: 'Instagram',
      children: [
        { label: 'IG DM', value: 'instagram_send_message', step_id: '1' },
        { label: 'IG Follow', value: 'instagram_follow' },
        { label: 'IG Unfollow', value: 'instagram_unfollow' },
        { label: 'Comment', value: 'instagram_send_comment' },
        { label: 'Like', value: 'instagram_like_post' },
        { label: 'Responder', value: 'instagram_auto_reply' },
      ],
    },
    // Facebook actions
    {
      label: 'Facebook',
      children: [
        { label: 'Outreach', value: 'facebook_send_message' },
        { label: 'FB Follow', value: 'facebook_follow' },
        { label: 'FB Add Friend', value: 'facebook_addfriend' },
        { label: 'FB Unfollow', value: 'facebook_unfollow' },
        { label: 'FB Unfriend', value: 'facebook_unfriend' },
        { label: 'Comment', value: 'facebook_send_comment' },
        { label: 'Like', value: 'facebook_like_post' },
        { label: 'Responder', value: 'facebook_auto_reply' },
      ],
    },
    // LinkedIn actions
    {
      label: 'LinkedIn',
      children: [
        { label: 'Outreach', value: 'linkedin_send_message' },
        { label: 'Follow', value: 'linkedin_follow' },
        { label: 'Unfollow', value: 'linkedin_unfollow' },
        { label: 'Like', value: 'linkedin_like_post' },
        { label: 'Connect', value: 'linkedin_connect' },
        { label: 'Connect With Note', value: 'connect_lead_with_note' },
        { label: 'Disconnect', value: 'linkedin_disconnect' },
        { label: 'Comment', value: 'linkedin_send_comment' },
        { label: 'Responder', value: 'linkedin_auto_reply' },
      ],
    },
  ]

  const renderStepOptions = () => {
    const options: React.ReactNode[] = []

    stepsItems.forEach((item, index) => {
      // Add group label as disabled option
      options.push(
        <Option key={`group-${index}`} disabled value={`group-${index}`}>
          <b> {item.label}</b>
        </Option>,
      )

      // Add children options
      item.children.forEach((child, childIndex) => {
        options.push(
          <Option
            key={`child-${index}-${childIndex}`}
            value={child.value} // Join multiple values
            label={child.label}>
            &nbsp;{child.label}
          </Option>,
        )
      })
    })

    return options
  }

  const handleSetDateType = (value: any) => {
    setDateType(value)
    dispatch(SET_CAMPAIGN_DATES_FILTERS({ ...campaign_date_filters, dateType: value }))
    dispatch(SET_LOADING_CAMPAIGNS(true))
  }

  const getFilterItems = (selectedTab: any) => {
    switch (selectedTab) {
      case '1':
        return ['Campaign Name', 'Senders', 'Show Archive']
      case '2':
        return ['Campaign Name', 'Senders', 'Last Step', 'Replied']
      case '3':
        return ['Campaign Name', 'Senders', 'Last Step']
      default:
        return []
    }
  }
  const filterItems = getFilterItems(selectedTab)

  const handleFilterTypeChange = (value: string) => {
    setFilterType(value)
    if (value === 'Show Archive') {
      setFilterValue('archive')
      dispatch(SET_CAMPAIGN_FILTERS({ type: 'Show Archive', value: 'archive' }))
      dispatch(SET_LOADING_CAMPAIGNS(true))
    } else {
      setFilterValue(undefined)
    }
  }

  const handleSelectChange = (value: any) => {
    setFilterValue(value)
    dispatch(SET_CAMPAIGN_FILTERS({ type: filterType, value: value }))
    dispatch(SET_LOADING_CAMPAIGNS(true))
  }

  const handleClearFilters = async () => {
    dispatch(SET_CAMPAIGN_FILTERS({}))
    dispatch(SET_LOADING_CAMPAIGNS(true))
    onRangeChange(null, [])
    setFilterValue(undefined)
    setFilterType(filterItems[0])
    dispatch(SET_LOADING_CAMPAIGNS(true))
  }

  const handleNewCampaignClick = () => {
    navigate('/outreach/create-new-campaign')
    dispatch(SET_LOADING_CAMPAIGNS(true))
    trackEvent('ADD_NEW_CAMPAIGN_BUTTON')
  }

  const renderOption = (item: any) => {
    const fullNumber = item.area_code + item.number
    const username = item.username

    if (fullNumber) {
      const user: any = userDetails?.outreach?.find((u: any) => fullNumber?.includes(u.number))
      if (user?.userName?.length > 0) {
        return (
          <span>
            {user?.userName} ({fullNumber})
          </span>
        )
      } else {
        return <span key={fullNumber}>{fullNumber}</span>
      }
    } else if (username) {
      return <span key={username}>{username}</span>
    }

    return null
  }

  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
  ]

  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    setDateRange(dates as [Dayjs | null, Dayjs | null] | null)
    if (dates) {
      dispatch(SET_CAMPAIGN_DATES_FILTERS({ from: dates[0], to: dates[1], dateType: dateType }))
    } else {
      dispatch(SET_CAMPAIGN_DATES_FILTERS({}))
    }
    dispatch(SET_LOADING_CAMPAIGNS(true))
  }

  useEffect(() => {
    setFilterType(filterItems[0])
    setFilterValue(undefined)
    dispatch(SET_CAMPAIGN_FILTERS({}))
  }, [selectedTab])

  useEffect(() => {
    const getRefetcGetSenderList = async () => {
      await refetcGetSenderList()
    }
    getRefetcGetSenderList()
  }, [])

  useEffect(() => {
    const names = all_campaigns_data?.map((c: any) => ({ campaign_name: c.campaign_name, campaign_id: c.campaign_id }))
    const uniqueArray: any = Array.from(new Set(names))
    setCampaignNames(uniqueArray)
  }, [all_campaigns_data])

  const renderSendersOptions = (data: any) => {
    const options = []

    // instagram
    if (data?.instagram) {
      options.push(
        ...data.instagram.map((item: any, index: number) => (
          <Option key={index + data.response.length} value={item.id} disabled={item.cooldown}>
            {renderOption(item)}
          </Option>
        )),
      )
    }

    // telegram
    // if (data?.response) {
    //   options.push(
    //     ...data.response.map((item: any, index: number) => (
    //       <Option key={index} value={item.id} disabled={item.cooldown}>
    //         {renderOption(item)}
    //       </Option>
    //     )),
    //   )
    // }
    return options
  }

  const renderFilterOptions = () => {
    switch (filterType) {
      case 'Senders':
        return renderSendersOptions(data)
      case 'Campaign Name':
        return campaignNames?.map((item: any, index: any) => {
          return (
            <Option key={index} value={item.campaign_id} label={item.campaign_name}>
              {item.campaign_name}
            </Option>
          )
        })
      case 'Last Step':
        return renderStepOptions()
      case 'Replied':
        return ['Replied', 'Did not Reply']?.map((item: any, index: any) => (
          <Option key={index} value={item}>
            {item}
          </Option>
        ))
      default:
        return null
    }
  }

  return (
    <div className={styles.campaignFilters}>
      <div style={{ display: 'flex', minWidth: 0, alignItems: 'center' }}>
        <span style={{ marginRight: '10px', fontWeight: 600, minWidth: '81px' }}>Apply Filters:</span>
        <Select
          style={{ minWidth: '150px' }}
          placeholder='Select Filter Type'
          value={filterType}
          onChange={handleFilterTypeChange}>
          {filterItems.map((item, index) => (
            <Option key={index} value={item}>
              {item}
            </Option>
          ))}
        </Select>
        {filterType && (
          <>
            <Divider type='vertical' style={{ height: '24px', margin: '0 8px' }} />
            {/* {filterType !== 'Show Archive' && ( */}
            <Select
              mode='multiple'
              style={{ width: '250px' }}
              placeholder={filterType === 'Show Archive' ? 'Archive' : `Select ${filterType}`}
              value={filterType === 'Show Archive' ? undefined : filterValue}
              maxTagCount={1}
              disabled={filterType === 'Show Archive'}
              onChange={(value) => handleSelectChange(value)}
              filterOption={(input, option: any) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {renderFilterOptions()}
            </Select>
            {/* )} */}
            <Divider type='vertical' style={{ height: '24px', margin: '0 8px' }} />
          </>
        )}

        <div className={styles.rightSide}>
          {' '}
          <Space>
            <Select style={{ minWidth: '80px' }} value={dateType} onChange={handleSetDateType}>
              <Option value='created_at'>Created</Option>
              <Option value='sent_at'>Sent</Option>
            </Select>
            <RangePicker presets={rangePresets} onChange={onRangeChange} value={dateRange} />
            <Divider type='vertical' style={{ margin: 0, height: '24px' }} />
            <CustomToggleSwitch onToggle={(checked) => dispatch(SET_SHOW_PERCENTAGE(checked))} />
            <Divider type='vertical' />
          </Space>
        </div>
        <Button
          style={
            Object.keys(campaign_filters).length === 0
              ? { padding: '0px 15px 0px 0px', marginLeft: '3px', fontWeight: 600, color: 'lightgray' }
              : { padding: '0px 15px 0px 0px', marginLeft: '3px', fontWeight: 600, color: '#7043ff', cursor: 'pointer' }
          }
          onClick={handleClearFilters}
          disabled={Object.keys(campaign_filters).length === 0}
          type={'text'}>
          Clear All
        </Button>
      </div>
      <div className={styles.rightSide}>
        <Button onClick={handleNewCampaignClick}>
          <div className={styles.newCampaignButton}>
            {/* <img src={plus_circle_icon} alt='' /> */}
            <PlusCircleOutlined style={{ marginRight: '5px' }} />
            <span>New Campaign</span>
          </div>
        </Button>
      </div>
    </div>
  )
}

export default CampaignFilters
