import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import './styles/global.css'
import './styles/global-legacy.css'
import './styles/variables.css'

import './index.css'

import { AuthProvider } from 'common/hooks/useAuth.hooks'

import { store } from './state'

import App from './App'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ShowStartScreenProvider } from 'common/providers/ShowStartScreenProvider'
import { Userpilot } from 'userpilot'
import { ReactFlowProvider } from 'reactflow'
import { HotkeysProvider } from 'react-hotkeys-hook'
import TagManager from 'react-gtm-module'
import CustomIntercomProvider from 'common/providers/CustomIntercomProvider'
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////// LEGACY //////////////////////////////////////
import { TelegramProvider } from 'LEGACY/common/providers/TelegramProvider' ////////
import { UserProvider } from 'Utils/UserContext'
////////////////////////////////////// LEGACY //////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const tagManagerArgs = {
  gtmId: 'GTM-PQNTTXL8',
}

TagManager.initialize(tagManagerArgs)
Userpilot.initialize('NX-56c5de42')

root.render(
  <GoogleOAuthProvider clientId='638589373885-4hb58pi09r7dhpdc14i1icvoj52n2mjj.apps.googleusercontent.com'>
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <ShowStartScreenProvider>
            <CustomIntercomProvider>
              <TelegramProvider>
                <ReactFlowProvider>
                  <UserProvider>
                    <HotkeysProvider initiallyActiveScopes={['heatbox']}>
                      <App />
                    </HotkeysProvider>
                  </UserProvider>
                </ReactFlowProvider>
              </TelegramProvider>
            </CustomIntercomProvider>
          </ShowStartScreenProvider>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  </GoogleOAuthProvider>,
)
