import styles from './AISummary.module.scss'
import { Skeleton } from 'antd'
import ai_summary_icon from 'common/assets/svg/ai_summary_icon.svg'
import white_rocket_icon from 'common/assets/svg/white_rocket_icon.svg'
import white_fire_icon from 'common/assets/svg/white_fire_icon.svg'
import cold_icon from 'common/assets/svg/cold_icon.svg'
import warming_up_icon from 'common/assets/svg/warming_up_icon.svg'
import { removeEmojis } from 'common/components/Heatbox/Utils/CardUtils'
import _ from 'lodash'
interface AISummaryProps {
  open: boolean
  onClick?: () => void
  onClose?: () => void
  isLoading: boolean
  showContent: boolean
  aiSummaryData: any
  card: any
}

const heatScoreStyles: Record<string, { icon: string; color: string; bgColor: string }> = {
  Viral: { icon: white_rocket_icon, color: 'white', bgColor: '#FF0000' },
  Hot: { icon: white_fire_icon, color: 'white', bgColor: '#FF4500' },
  'Warming Up': { icon: warming_up_icon, color: '#4A3800', bgColor: '#FFD700' },
  Cold: { icon: cold_icon, color: '#2D3748', bgColor: '#A0AEC0' },
}

const AISummary = ({ open, onClick, onClose, isLoading, showContent, aiSummaryData, card }: AISummaryProps) => {
  const ai_post_summary = aiSummaryData?.ai_post_summary
  const engagement_score = aiSummaryData?.engagement_score
  const topic = _.startCase(card?.topic)
  const recommended_action = aiSummaryData?.recommended_action
  const recommended_action_reasoning = aiSummaryData?.recommended_action_reasoning
  const top_audiences = aiSummaryData?.top_audiences
  const content = card?.content
  const upvote = content?.reactions || 0
  const comments = content?.comments || 0

  const heat_score = removeEmojis(card?.analysis?.heat_score || 'Warming Up')
  // const heat_score = removeEmojis(engagement_score || 'Warming Up')

  const { icon, color, bgColor } = heatScoreStyles[heat_score] || heatScoreStyles['Warming Up']

  return (
    <div className={`${styles.container} ${open ? styles.open : styles.closed}`} onClick={onClick}>
      {open && (
        <div
          className={styles.closeButton}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onClose && onClose()
          }}
        />
      )}
      <div className={styles.header}>
        <img src={ai_summary_icon} alt='' />
        <span className={styles.title}>Quick AI Summary</span>
      </div>

      {open && (
        <div className={`${styles.content} ${open ? styles.visible : ''} ${showContent ? styles.fadeIn : ''}`}>
          {!showContent && !isLoading ? (
            <></>
          ) : isLoading ? (
            <>
              <Skeleton active paragraph={{ rows: 4 }} />
              <Skeleton active paragraph={{ rows: 6 }} />
            </>
          ) : (
            <>
              {/* <h3>Why I see this post?</h3>
              <ul>
                {aiSummaryData?.postRelevance?.split('\n').map((point: any, index: any) => (
                  <li key={index}>{point.replace(/^- /, '')}</li>
                ))}
              </ul> */}

              <div className={styles.info} style={{ marginBottom: '20px' }}>
                <h3>AI Post Summary: </h3>
                {ai_post_summary?.split('\n').map((point: any, index: any) => (
                  <p style={{ fontWeight: 500 }} key={index}>
                    {point.replace(/^- /, '')}
                  </p>
                ))}
              </div>

              {topic && (
                <div className={styles.info}>
                  <span>Topic</span>
                  <p>{topic}</p>
                </div>
              )}

              {engagement_score && (
                <div className={styles.info}>
                  <span>Engagement Score</span>
                  <p className={styles.metricIcon} style={{ backgroundColor: bgColor, color }}>
                    <img style={{ marginRight: '4px' }} src={icon} alt='' />
                    {heat_score}
                  </p>{' '}
                  <p style={{ fontWeight: 500, marginTop: 0 }}>
                    {upvote} reactions, {comments} comments
                  </p>
                </div>
              )}

              {top_audiences && Array.isArray(top_audiences) && top_audiences.length > 0 && (
                <div className={styles.info}>
                  <span>Top Audiences</span>
                  <p style={{ fontWeight: 500 }}>
                    {top_audiences?.map((audience: any) => audience?.persona).join(', ')}
                  </p>
                </div>
              )}

              {recommended_action && (
                <div className={styles.info}>
                  <span>Recommended Action</span>
                  <p className={styles.action} style={{ marginBottom: '3px' }}>
                    {recommended_action}
                  </p>
                  <p style={{ fontWeight: 500, marginTop: 0, fontSize: '14px' }}>{recommended_action_reasoning}</p>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default AISummary
