import React from 'react'
import styles from './VerticalDots.module.scss'

interface VerticalDotsProps {
  currentIndex: number
  totalCount: number
  screenType?: string
}

const VerticalDots: React.FC<VerticalDotsProps> = ({ currentIndex, totalCount, screenType }) => {
  const visibleDotCount = 7
  const middleIndex = Math.floor(visibleDotCount / 2)
  const startIndex = Math.max(0, currentIndex - middleIndex)
  const adjustedVisibleDotCount = currentIndex < middleIndex ? currentIndex + middleIndex + 1 : visibleDotCount
  const middleDotPosition = Math.floor(adjustedVisibleDotCount / 2)

  return (
    <div className={styles.verticalDots}>
      {Array.from({ length: adjustedVisibleDotCount }, (_, index) => {
        const dotIndex = startIndex + index
        const isCurrentDot = dotIndex === currentIndex
        const oneDistanceApart = Math.abs(dotIndex - currentIndex) === 1
        const twoDistanceApart = Math.abs(dotIndex - currentIndex) === 2
        const threeDistanceApart = Math.abs(dotIndex - currentIndex) === 3
        const size = isCurrentDot
          ? '10px'
          : oneDistanceApart
          ? '8px'
          : twoDistanceApart
          ? '6px'
          : threeDistanceApart
          ? '4px'
          : '2px'

        const isMiddleDot = index === middleDotPosition
        const distance = totalCount - currentIndex + -index + 2
        const opacity = distance < 0 ? 0 : oneDistanceApart ? 1 : twoDistanceApart ? 0.7 : threeDistanceApart ? 0.4 : 1

        const backgroundColor = screenType === 'heatbox' ? '#fd6f6f' : '#7043ff'

        return (
          <div
            key={dotIndex}
            className={`${styles.dot} ${isCurrentDot ? styles.currentDot : ''}`}
            style={{
              backgroundColor: isMiddleDot ? backgroundColor : '#CCCCCC',
              width: size,
              height: size,
              opacity,
            }}
          />
        )
      })}
    </div>
  )
}

export default VerticalDots
