import React, { useEffect, useState } from 'react'
import { Typography, Row, Col, Card, message } from 'antd'
import styles from './GettingStarted.module.scss'
import { StepCard } from './StepCard'
// import convrt_video_thumbnail from 'common/assets/png/convrt_video_thumbnail.png'
import { useNavigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { useLazyUpdateUserProgressQuery } from 'common/APIs/UserApi'
import { useLocalStorage } from 'usehooks-ts'
import { useLazyGetUserInfoQuery } from 'common/APIs/UserApi'
import OutreachConnection from 'LEGACY/common/components/OutreachConnection/OutreachConnection'
import { trackEvent } from 'eventConfig'

const { Title, Paragraph } = Typography

export const GettingStarted: React.FC = () => {
  const [user, setUser] = useLocalStorage<any>('user', null)
  const isWeb3User = user?.user_type === 'web3'
  const navigate = useNavigate()
  const { startTour } = useIntercom()
  const [updateUser] = useLazyUpdateUserProgressQuery()
  const [progress, setProgress] = useState(user?.progress || {})
  const [getUserInfo] = useLazyGetUserInfoQuery()
  const [hasCampaigns, setHasCampaigns] = useState(false)
  const [latestCampaignId, setLatestCampaignId] = useState('')
  const [openOutreachConnectionModal, setOpenOutreachConnectionModal] = useState(false)
  // const [isPlaying, setIsPlaying] = useState(false)
  // const { boot, showNewMessage } = useIntercom()

  const isAllComplete = (updatedProgress: Record<string, boolean>) => {
    const tasks = isWeb3User
      ? ['claim_account', 'go_to_research', 'create_campaign']
      : ['create_campaign', 'collect_leads', 'setup_categorizer']
    return tasks.every((task) => updatedProgress[task])
  }

  // const bootIntercomWithMessage = () => {
  //   boot({
  //     name: user?.name,
  //     email: user?.email,
  //   })
  //   showNewMessage('I want to start the onboarding process!')
  // }

  // Helper function to update user progress
  const updateProgress = async (feature: string, isComplete: boolean) => {
    try {
      const updatedGettingStarted = {
        ...progress.getting_started,
        [feature]: isComplete,
      }

      const completed = isAllComplete(updatedGettingStarted)

      const updatedProgress = {
        ...progress,
        getting_started: {
          ...updatedGettingStarted,
          ...(completed ? { completed: true } : { completed: false }),
        },
      }

      setProgress(updatedProgress)

      // Save progress to the backend
      const response = await updateUser({ feature: 'getting_started', updates: updatedProgress.getting_started })
      if (!response?.data?.success) {
        throw new Error('Failed to update progress.')
      }

      // Update local user object
      setUser((prevUser: any) => ({
        ...prevUser,
        progress: updatedProgress,
      }))
    } catch (error) {
      console.error('Error updating progress:', error)
      message.error('Failed to update progress. Please try again.')
    }
  }

  // Handlers for each task
  const handleCreateCampaign = () => {
    trackEvent('GETTING_STARTED', { action: 'Create Campaign' })
    updateProgress('create_campaign', true)
    if (!hasCampaigns) {
      navigate('/outreach')
    } else {
      navigate('/outreach/create-new-campaign')
    }
    startTour(581488)
  }

  const handleCollectLeads = () => {
    trackEvent('GETTING_STARTED', { action: 'Collect Leads' })

    if (!hasCampaigns) {
      return message.error('Please create a campaign first.')
    }
    updateProgress('collect_leads', true)
    navigate(`/outreach/create-new-campaign?campaign_id=${latestCampaignId}`)
    // startTour(581449)
  }

  const handleSetupCategorizer = () => {
    trackEvent('GETTING_STARTED', { action: 'Setup Categorizer' })
    updateProgress('setup_categorizer', true)
    navigate('/settings/convrt-ai/categorizer')
    startTour(581483)
  }

  const handleClaimAccount = () => {
    trackEvent('GETTING_STARTED', { action: 'Claim Account' })
    updateProgress('claim_account', true)
    setOpenOutreachConnectionModal(true)
  }

  const handleGoToResearch = () => {
    trackEvent('GETTING_STARTED', { action: 'Go to Research' })
    updateProgress('go_to_research', true)
    navigate(`/companies`)
  }

  // const handlePlayVideo = () => {
  //   setIsPlaying(true)
  //   trackEvent('PLAY_DEMO_CLICKED', {
  //     where: 'Getting Started',
  //     user_attributes: {
  //       subscription_type: user?.subscription_type,
  //       force_pricing: user?.force_pricing,
  //       plan_type: user?.plan_type,
  //       plan: user?.plan,
  //       settings_id: user?.settings_id,
  //     },
  //   })
  // }

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const { data } = await getUserInfo()
        if (data?.hasCampaigns) {
          setHasCampaigns(true)
        }
        if (data?.latestCampaignId) {
          setLatestCampaignId(data.latestCampaignId)
        }
      } catch (error) {
        console.error('Error fetching user info:', error)
      }
    }

    fetchUserInfo()
  }, [])

  const getStepCardContent = (step: string) => {
    const content: any = {
      task_1: {
        title: isWeb3User ? 'Claim Your Warm Telegram Account' : 'Create a campaign',
        description: isWeb3User
          ? 'Get a Convrt-managed Telegram account for maximum send rates and account safety.'
          : 'Create a fully automated sequence to engage, reach, and nurture your leads.',
        imageSrc: isWeb3User
          ? 'https://storage.googleapis.com/convrt-fe-preprod/jpeg/web3_getting_started_card_1.svg'
          : 'https://storage.googleapis.com/convrt-fe-preprod/getting_started_card_1.svg',
        buttonText: isWeb3User ? 'Claim Account' : 'Create Campaign',
        onClick: isWeb3User ? handleClaimAccount : handleCreateCampaign,
        isComplete: isWeb3User ? progress.getting_started?.claim_account : progress.getting_started?.create_campaign,
      },
      task_2: {
        title: isWeb3User ? 'Research and build you ICP list.' : 'Collect Leads',
        description: isWeb3User
          ? 'Use our research page to filter and find your ideal customer profile. Manage, export to your CRM, and start reaching out effortlessly.'
          : 'Use Convrt to collect leads from social networks and discover the best communities, pages, and searches to target for optimal results.',
        imageSrc: isWeb3User
          ? 'https://storage.googleapis.com/convrt-fe-preprod/jpeg/web3_getting_started_card_2.svg'
          : 'https://storage.googleapis.com/convrt-fe-preprod/getting_started_card_2.svg',
        buttonText: isWeb3User ? 'Go To Research' : 'Collect Leads',
        onClick: isWeb3User ? handleGoToResearch : handleCollectLeads,
        isComplete: isWeb3User ? progress.getting_started?.go_to_research : progress.getting_started?.collect_leads,
      },
      task_3: {
        title: isWeb3User ? 'Create a Campaign' : 'Setup your AI Categorizer Agent',
        description: isWeb3User
          ? 'Create a fully automated sequence to engage, reach, and nurture your leads.'
          : 'Define your response categories to enable the AI responder to reply appropriately and with precision.',
        imageSrc: isWeb3User
          ? 'https://storage.googleapis.com/convrt-fe-preprod/jpeg/web3_getting_started_card_3.svg'
          : 'https://storage.googleapis.com/convrt-fe-preprod/getting_started_card_3.svg',
        buttonText: isWeb3User ? 'Create Campaign' : 'Setup Categorizer',
        onClick: isWeb3User ? handleCreateCampaign : handleSetupCategorizer,
        isComplete: isWeb3User
          ? progress.getting_started?.create_campaign
          : progress.getting_started?.setup_categorizer,
      },
    }
    return content[step]
  }

  return (
    <div className={styles.container}>
      <Card className={isWeb3User ? styles.web3Card : styles.card}>
        <div className={styles.cardContent}>
          <Title style={{ margin: 0, lineHeight: 1 }}>Start reaching your leads in minutes</Title>
          <Paragraph className={styles.subtitle}>Follow our quick guide to get started</Paragraph>
          <Row gutter={[24, 24]} style={{ height: '100%', maxWidth: 1200 }}>
            <Col xs={24} md={8}>
              <StepCard
                title={getStepCardContent('task_1').title}
                description={getStepCardContent('task_1').description}
                buttonText={getStepCardContent('task_1').buttonText}
                onClick={getStepCardContent('task_1').onClick}
                imageSrc={getStepCardContent('task_1').imageSrc}
                isComplete={getStepCardContent('task_1').isComplete || false}
              />
            </Col>

            <Col xs={24} md={8}>
              <StepCard
                title={getStepCardContent('task_2').title}
                description={getStepCardContent('task_2').description}
                buttonText={getStepCardContent('task_2').buttonText}
                onClick={getStepCardContent('task_2').onClick}
                imageSrc={getStepCardContent('task_2').imageSrc}
                isComplete={getStepCardContent('task_2').isComplete || false}
              />
            </Col>

            <Col xs={24} md={8}>
              <StepCard
                title={getStepCardContent('task_3').title}
                description={getStepCardContent('task_3').description}
                buttonText={getStepCardContent('task_3').buttonText}
                onClick={getStepCardContent('task_3').onClick}
                imageSrc={getStepCardContent('task_3').imageSrc}
                isComplete={getStepCardContent('task_3').isComplete || false}
              />
            </Col>
          </Row>
          {/* {!isWeb3User && (
            <>
              {' '}
              <div className={styles.videoContainer}>
                <Title level={3} style={{ textAlign: 'center', marginBottom: '24px' }}>
                  Watch Our Product Demo
                </Title>
                <div className={styles.videoWrapper}>
                  {!isPlaying ? (
                    <div
                      onClick={handlePlayVideo}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer',
                      }}>
                      <img
                        src={convrt_video_thumbnail}
                        alt='Video thumbnail'
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: '12px',
                        }}
                      />
                    </div>
                  ) : (
                    <iframe
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        borderRadius: '12px',
                      }}
                      src='https://www.youtube.com/embed/TUS1c7AuP0k?autoplay=1'
                      title='Product Demo'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen
                    />
                  )}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  type='primary'
                  size='large'
                  onClick={bootIntercomWithMessage}
                  style={{
                    fontWeight: 500,
                  }}>
                  Request Onboarding
                </Button>
              </div>
            </>
          )} */}
        </div>
      </Card>
      {openOutreachConnectionModal && (
        <OutreachConnection
          open={openOutreachConnectionModal}
          onClose={() => {
            setOpenOutreachConnectionModal(false)
          }}
        />
      )}
    </div>
  )
}
