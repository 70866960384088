import React, { useEffect, useState } from 'react'
import styles from './KeyboardShortcut.module.scss'
import { motion } from 'framer-motion'

interface ShortcutItem {
  macKeys: string[]
  windowsKeys: string[]
  action: string
}

const shortcuts: ShortcutItem[] = [
  { macKeys: ['⌘', 'K'], windowsKeys: ['Ctrl', 'K'], action: 'to comment' },
  { macKeys: ['⌘', '→'], windowsKeys: ['Ctrl', '→'], action: 'to skip' },
  //   { macKeys: ['⌘', '←'], windowsKeys: ['Ctrl', '←'], action: 'to skip back' },
  { macKeys: ['⌘', '↓'], windowsKeys: ['Ctrl', '↓'], action: 'to toggle menu' },
  //   { macKeys: ['⌘', '↑'], windowsKeys: ['Ctrl', '↑'], action: 'to skip up' },
  // { macKeys: ['⌘', '←'], windowsKeys: ['Ctrl', '←'], action: 'to skip back' },
  // { macKeys: ['⌘', ''], windowsKeys: ['Ctrl', 'B'], action: 'to bold' },
  //   { macKeys: ['⌘', 'I'], windowsKeys: ['Ctrl', 'I'], action: 'to italicize' },
  //   { macKeys: ['⌘', '/'], windowsKeys: ['Ctrl', '/'], action: 'to search' },
]

const variants = {
  open: { opacity: 1, y: -10, transition: { duration: 0.3, ease: 'easeIn' } },
  closed: { opacity: 0, y: 0, transition: { duration: 0.3, ease: 'easeOut' } },
}

interface KeyboardShortcutProps {
  open: boolean
  screenType?: string
}

const KeyboardShortcut: React.FC<KeyboardShortcutProps> = ({ open, screenType }) => {
  const [isMac, setIsMac] = useState(true)
  const backgroundColor = screenType === 'heatbox' ? '#FFDCB8' : '#d8cef8'

  useEffect(() => {
    // Detect OS
    setIsMac(navigator.platform.toUpperCase().indexOf('MAC') >= 0)
  }, [])

  return (
    <motion.div
      className={styles.container}
      initial='closed'
      animate={open ? 'open' : 'closed'}
      exit='closed'
      variants={variants}>
      {shortcuts.map((shortcut, index) => {
        const keys = isMac ? shortcut.macKeys : shortcut.windowsKeys

        return (
          <div key={index} className={styles.shortcutWrapper} style={{ backgroundColor }}>
            <span className={styles.text}>Hit</span>
            {keys.map((key, keyIndex) => (
              <React.Fragment key={keyIndex}>
                <span className={`${styles.keyBox} ${key.length > 1 ? styles.wideKey : ''}`}>{key}</span>
                {keyIndex < keys.length - 1 && <span className={styles.plus}>+</span>}
              </React.Fragment>
            ))}
            <span className={styles.text}>{shortcut.action}</span>
          </div>
        )
      })}
    </motion.div>
  )
}

export default KeyboardShortcut
