import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'antd'
import { AnimatePresence, motion } from 'framer-motion'
import no_logo from 'common/assets/svg/no_logo.svg'
import power from 'common/assets/svg/power.svg'
import { Image } from 'common/constants/alt.constants'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useIntercom } from 'react-use-intercom'
import styles from './user-logged.module.scss'
import { useLocalStorage } from 'usehooks-ts'
import _ from 'lodash'
import { useLazyGetTeamMembersQuery } from 'common/APIs/AdminApi'

interface IUserLogged {
  collapsed: boolean
}

const UserLogged: React.FC<IUserLogged> = ({ collapsed }) => {
  const navigate = useNavigate()
  const { shutdown } = useIntercom()
  const [user] = useLocalStorage<any>('user', null)
  const { logout: handleLogout, impersonateUser } = useAuth()
  const [loadingImpersonate, setLoadingImpersonate] = useState<string | null>(null)
  const [teamMembers, setTeamMembers] = useLocalStorage<any>('team_members', null)
  const [getTeamMembers] = useLazyGetTeamMembersQuery()

  // Handle switching to a selected team member account
  const handleSwitchUser = async (member: any) => {
    if (member.id === user?.id) return
    setLoadingImpersonate(member.id)
    await impersonateUser(member.id)
    setLoadingImpersonate(null)
  }

  const getTeamMembersApi = async () => {
    const { data } = await getTeamMembers({})
    if (data && data.teamMembers) {
      setTeamMembers(data.teamMembers)
    }
  }

  // Create dropdown content including team switcher and logout option
  const dropdownContent = (
    <div className={styles.dropdownContent}>
      {teamMembers && teamMembers.length > 0 && (
        <div className={styles.teamSwitcher}>
          <div className={styles.switcherTitle} style={{ display: 'flex', alignItems: 'center' }}>
            <AnimatePresence>
              {loadingImpersonate !== null && (
                <motion.div
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -10 }}
                  className={styles.spinner}
                />
              )}
            </AnimatePresence>
            <motion.span
              animate={{ x: loadingImpersonate !== null ? 10 : 0 }}
              transition={{ type: 'spring', stiffness: 300, damping: 20 }}>
              Admin Switch User
            </motion.span>
          </div>{' '}
          {teamMembers.map((member: any) => {
            const isActive = member.id === user?.id
            const isLoading = loadingImpersonate === member.id

            return (
              <motion.div
                key={member.id}
                onClick={() => {
                  if (!isActive && !loadingImpersonate) handleSwitchUser(member)
                }}
                className={styles.teamMemberItem}
                whileHover={{ scale: isActive ? 1 : 1.05 }}
                whileTap={{ scale: isActive ? 1 : 0.98 }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  cursor: isActive ? 'default' : 'pointer',
                  pointerEvents: isActive ? 'none' : 'auto',
                }}>
                {isLoading ? (
                  <motion.span
                    initial={{ opacity: 0, y: -5 }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      color: ['#000000', '#7043ff', '#000000'],
                    }}
                    transition={{
                      opacity: { duration: 0.3 },
                      y: { duration: 0.3 },
                      color: { repeat: Infinity, duration: 1, ease: 'linear' },
                    }}
                    style={{ fontWeight: 600 }}>
                    {member.email}
                  </motion.span>
                ) : (
                  <span style={{ fontWeight: isActive && !loadingImpersonate ? 600 : 400 }}>{member.email}</span>
                )}
              </motion.div>
            )
          })}
        </div>
      )}
      <motion.div
        onClick={() => {
          handleLogout()
          shutdown()
          navigate('/login')
        }}
        className={styles.teamMemberItem}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <img src={power} alt='Sign out' className='floatLeftImg' />
        <span className={styles.logout}>Sign out</span>
      </motion.div>
    </div>
  )

  let userDetails = null
  if (localStorage.getItem('user') !== null) {
    userDetails = JSON.parse(JSON.stringify(localStorage.getItem('user')))
  }

  const userTypeMap = {
    BETA: 'Trial',
    PREMIUM: 'Premium',
  }

  const planMapping: any = {
    go_to_market: 'Go To Market',
    connect_plus: 'Connect+',
    market_dominance: 'Market Dominance',
    trial: 'Trial',
    trail: 'Trial',
    PAID_TRIAL: 'Trial',
    custom: 'Custom',
  }

  return (
    <div className={styles.userLogged} style={{ cursor: 'pointer' }}>
      <Tooltip
        overlayStyle={{ zIndex: 2500 }}
        placement='right'
        title={dropdownContent}
        trigger='click'
        onOpenChange={(open) => {
          if (open) {
            getTeamMembersApi()
          }
        }}
        overlayInnerStyle={{
          justifyContent: 'center',
          color: '#000',
          fontFamily: 'Roboto',
          fontSize: '13px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '25px',
          letterSpacing: '0.065px',
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
        }}
        color='white'>
        <motion.div className={styles.container}>
          <img src={no_logo} alt={Image.USER_LOGGED} />
          <div className={!collapsed ? styles.visible : styles.hidden}>
            {userDetails !== 'null' && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className={styles.name}>
                {!collapsed && JSON.parse(userDetails).name}
              </motion.div>
            )}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className={styles.type}>
              {!collapsed &&
                (user?.plan
                  ? _.startCase(user?.plan)
                  : user?.plan_type
                  ? planMapping[user?.plan_type]
                  : `${userTypeMap[JSON.parse(userDetails)?.subscription_type as 'BETA' | 'PREMIUM']} User`)}
            </motion.div>
          </div>
        </motion.div>
      </Tooltip>
    </div>
  )
}

export default UserLogged
