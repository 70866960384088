import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'common/APIs/baseQueryWithValidation'

export const HeatboxApi: any = createApi({
  reducerPath: 'HeatboxApi',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    getHeatbox: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/getHeatbox?user_id=${params.user_id}${
          params.source_list ? `&source_list=${params.source_list}` : ''
        }${params.filter ? `&filter=${params.filter}` : ``}${params.sort ? `&sort=${params.sort}` : ``}`,
        method: 'GET',
      }),
    }),
    getCues: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/getCues?user_id=${params.user_id}${
          params.filter ? `&filter=${params.filter}` : ``
        }${params.sort ? `&sort=${params.sort}` : ``}`,
        method: 'GET',
      }),
    }),
    getLatestMarkedCue: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/getLatestMarkedCue?user_id=${params.user_id}`,
        method: 'GET',
      }),
    }),
    getNumberOfTrackedOnHeatbox: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/number_of_tracked_on_heatbox?user_id=${params.user_id}`,
        method: 'GET',
      }),
    }),
    getLatestMarkedHeatbox: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/getLatestMarkedHeatbox?user_id=${params.user_id}`,
        method: 'GET',
      }),
    }),
    getSeeds: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/seeds?user_id=${params.user_id}`,
        method: 'GET',
      }),
    }),
    explainPostRelevance: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ai/explain_post_relevance`,
        method: 'POST',
        body: params,
      }),
    }),
    explainCommentRelevance: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ai/explain_comment_relevance`,
        method: 'POST',
        body: params,
      }),
    }),
    summarizePost: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ai/summarize_post_v2`,
        // url: `${process.env.REACT_APP_HEATBOX_API_URL}/ai/summarize_post`,
        method: 'POST',
        body: params,
      }),
    }),
    markPost: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/mark_post`,
        method: 'POST',
        body: params,
      }),
    }),
    markComment: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/mark_comment`,
        method: 'POST',
        body: params,
      }),
    }),
    markContent: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/mark_content`,
        method: 'POST',
        body: params,
      }),
    }),
    markLead: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/mark_lead`,
        method: 'POST',
        body: params,
      }),
    }),
    markCue: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/mark_cue`,
        method: 'POST',
        body: params,
      }),
    }),
    removeFromHeatbox: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/remove_from_heatbox`,
        method: 'POST',
        body: params,
      }),
    }),
    removeFromCues: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/remove_from_cues`,
        method: 'POST',
        body: params,
      }),
    }),
    trackOnHeatbox: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/trackOnHeatbox`,
        method: 'POST',
        body: params,
      }),
    }),
    untrackOnHeatbox: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/untrackOnHeatbox`,
        method: 'POST',
        body: params,
      }),
    }),
    addToSeeds: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/seeds`,
        method: 'POST',
        body: params,
      }),
    }),
    generateComment: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ai/generate_comment_on_post`,
        method: 'POST',
        body: params,
      }),
    }),
    getLeadHeatscore: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/lead_heat_score?user_id=${params.user_id}&lead_id=${params.lead_id}`,
        method: 'GET',
      }),
    }),
    improveComment: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ai/improve_comment`,
        method: 'POST',
        body: params,
      }),
    }),
    sendComment: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/send_comment`,
        method: 'POST',
        body: params,
      }),
    }),
    sendLike: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/send_like`,
        method: 'POST',
        body: params,
      }),
    }),
    getHeatboxLists: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/getHeatboxLists?user_id=${params.user_id}`,
        method: 'GET',
      }),
    }),
    getComment: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_API_URL}/extension/getComment?content_id=${params.content_id}&is_reddit=${params.is_reddit}&is_cue=${params.is_cue}`,
        method: 'GET',
      }),
    }),
    getLike: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_API_URL}/extension/getLike?content_id=${params.content_id}&is_reddit=${params.is_reddit}&is_cue=${params.is_cue}`,
        method: 'GET',
      }),
    }),
    getLinkedinAccountStatus: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_API_URL}/extension/getAccountStatus`,
        method: 'GET',
      }),
    }),
    generatePostOutOfSeed: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ai/post_out_of_seed`,
        method: 'POST',
        body: params,
      }),
    }),
    getArticleData: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ai/getArticleData`,
        method: 'POST',
        body: params,
      }),
    }),
    getCurrentHeatbox: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/getCurrentHeatbox?user_id=${params.user_id}`,
        method: 'GET',
      }),
    }),
    createNewList: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/ti/createNewList`,
        method: 'POST',
        body: params,
      }),
    }),
    createSampleCampaign: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_HEATBOX_API_URL}/campaigns/createSampleCampaign`,
        method: 'POST',
        body: params,
      }),
    }),
  }),
})

export const {
  useLazyGetHeatboxQuery,
  useLazyGetCuesQuery,
  useLazyGetLatestMarkedCueQuery,
  useLazyGetNumberOfTrackedOnHeatboxQuery,
  useLazyGetLatestMarkedHeatboxQuery,
  useLazyGetSeedsQuery,
  useLazyExplainPostRelevanceQuery,
  useLazyExplainCommentRelevanceQuery,
  useLazySummarizePostQuery,
  useLazyMarkPostQuery,
  useLazyMarkCommentQuery,
  useLazyMarkContentQuery,
  useLazyMarkLeadQuery,
  useLazyMarkCueQuery,
  useLazyRemoveFromHeatboxQuery,
  useLazyRemoveFromCuesQuery,
  useLazyTrackOnHeatboxQuery,
  useLazyUntrackOnHeatboxQuery,
  useLazyAddToSeedsQuery,
  useLazyGenerateCommentQuery,
  useLazyGetLeadHeatscoreQuery,
  useLazyImproveCommentQuery,
  useLazySendCommentQuery,
  useLazyGetHeatboxListsQuery,
  useLazyGetCommentQuery,
  useLazyGetLinkedinAccountStatusQuery,
  useLazyGeneratePostOutOfSeedQuery,
  useLazyGetArticleDataQuery,
  useLazyGetCurrentHeatboxQuery,
  useLazyCreateNewListQuery,
  useLazyCreateSampleCampaignQuery,
  useLazySendLikeQuery,
  useLazyGetLikeQuery,
} = HeatboxApi
