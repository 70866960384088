import { createApi } from '@reduxjs/toolkit/query/react'
import { adminBaseQueryWithValidation } from 'common/APIs/baseQueryWithValidation'

export const AdminApi: any = createApi({
  reducerPath: 'AdminApi',
  baseQuery: adminBaseQueryWithValidation,
  endpoints: (builder) => ({
    ////////////////////////////////////////////////////////////////////////////
    // IMPORTANT: all of the following queries can use the admin_access_token //
    ////////////////////////////////////////////////////////////////////////////
    impersonate: builder.query({
      query: (values) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/impersonate`,
        method: 'POST',
        body: values,
      }),
    }),
    getTeamMembers: builder.query({
      query: (values) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/getTeamMembers`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useLazyImpersonateQuery, useLazyGetTeamMembersQuery } = AdminApi
