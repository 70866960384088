import React, { useEffect, useState } from 'react'
import { message, Switch, Tooltip } from 'antd'
import { LeadDataType } from 'common/components/Heatbox/Seeds'

interface TrackedSwitchProps {
  initialIsTracked: boolean
  lead: LeadDataType
  userId: string
  trackOnHeatbox: (params: any[]) => Promise<any>
  untrackOnHeatbox: (params: any[]) => Promise<any>
  refreshSeedsData: () => void
  platforms: string[]
}

const TrackedSwitch: React.FC<TrackedSwitchProps> = ({
  initialIsTracked,
  lead,
  userId,
  trackOnHeatbox,
  untrackOnHeatbox,
  refreshSeedsData,
  platforms,
}) => {
  const emptyString = lead?.status === 'success' || !initialIsTracked || !platforms?.includes('linkedin')
  const [isTracked, setIsTracked] = useState(initialIsTracked)
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState(emptyString ? '' : 'pending')
  const [tooltipText, setTooltipText] = useState(emptyString ? '' : 'Processing and analyzing...')

  const handleChange = async (checked: boolean) => {
    try {
      setIsLoading(true)
      if (checked) {
        const { data, error } = await trackOnHeatbox([{ lead_id: lead.id, user_id: userId, list_id: lead.lead_source }])
        if (data) {
          setIsTracked(true)
          if (platforms?.includes('linkedin')) {
            setStatus('pending')
            setTooltipText('Processing and analyzing...')
          }
        } else if (error) {
          message.error('Failed to add to heatbox')
        }
      } else {
        const { data, error } = await untrackOnHeatbox([{ lead_id: lead.id, user_id: userId }])
        if (data) {
          setIsTracked(false)
          setStatus('')
          setTooltipText('')
        } else if (error) {
          message.error('Failed to remove from heatbox')
        }
      }
      refreshSeedsData()
      setIsLoading(false)
    } catch (error) {
      console.error('Error updating tracked state:', error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsTracked(initialIsTracked)
    setStatus(emptyString ? '' : 'pending')
    setTooltipText(emptyString ? '' : 'Processing and analyzing...')
  }, [initialIsTracked, lead?.status])

  return (
    <Tooltip title={tooltipText} color='white' overlayInnerStyle={{ color: 'black' }}>
      <Switch loading={isLoading} checked={isTracked} onChange={handleChange} className={`custom-switch ${status}`} />
    </Tooltip>
  )
}

export default TrackedSwitch
