import React from 'react'
import { Modal, Card } from 'antd'
import { ReactComponent as CsvIcon } from 'common/assets/svg/add_leads_csv.svg'
import { ReactComponent as ExternalIcon } from 'common/assets/svg/external_tools.svg'
import { ReactComponent as LinkedInIcon } from 'common/assets/svg/linkedin_logo.svg'
import crown from 'common/assets/svg/premium_crown.svg'
import styles from './CreateImportListModal.module.scss'

interface CreateImportModalProps {
  open: boolean
  onClose: () => void
  onCancel: () => void
  onCSV: () => void
}

const CreateImportModal: React.FC<CreateImportModalProps> = ({ open, onClose, onCancel, onCSV }) => {
  const handleCSV = () => {
    onCSV()
    onClose()
  }

  return (
    <Modal
      centered
      title={<p style={{ fontSize: '20px' }}>Create & Import a List</p>}
      open={open}
      onCancel={onCancel}
      closable={false}
      footer={null}
      width={700}
      className={styles.addLeadsModal}>
      <span className={styles.closeIcon} onClick={onCancel}>
        Close
      </span>
      <div className={styles.smallCardsContainer}>
        <Card hoverable onClick={handleCSV} className={`addLeadsModal ${styles.card}`}>
          <CsvIcon className={styles.icon} />
          <h3>Import from CSV</h3>
          <p>Import your leads via CSV file upload.</p>
        </Card>
      </div>
      <div className={styles.smallCardsContainer}>
        <Card hoverable onClick={() => {}} className={`addLeadsModal ${styles.card} ${styles.disabled}`}>
          <ExternalIcon className={styles.icon} />
          <img
            src={crown}
            alt='premium'
            style={{ position: 'absolute', right: '10px', top: '10px', width: '25px', height: '25px' }}
          />
          <h3>Import from External Tools or CRM</h3>
          <p>Import your leads from your CRM or external systems.</p>
        </Card>
        <Card hoverable onClick={() => {}} className={`addLeadsModal ${styles.card} ${styles.disabled} `}>
          <LinkedInIcon className={styles.icon} />
          <img
            src={crown}
            alt='premium'
            style={{ position: 'absolute', right: '10px', top: '10px', width: '25px', height: '25px' }}
          />
          <h3>Search on LinkedIn</h3>
          <p>Search for leads on LinkedIn to add to your campaign.</p>
        </Card>
      </div>
    </Modal>
  )
}

export default CreateImportModal
