import React, { useState, useCallback } from 'react'
import { Image } from 'antd'
import styles from './PhotoGrid.module.scss'

interface Photo {
  url: string
  // No width/height needed; we measure at runtime
}

interface PhotoGridProps {
  photoUrls: Photo[]
  maxVisibleHeight?: number
}

const PhotoGrid: React.FC<PhotoGridProps> = ({ photoUrls, maxVisibleHeight }) => {
  const visibleCount = 2
  const remaining = photoUrls.length - visibleCount

  // Track the largest rendered height of any visible image
  const [maxHeight, setMaxHeight] = useState<number>(0)

  // Whenever an image finishes loading, measure its final rendered height
  const handleImgLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement>) => {
    const renderedHeight = (e.currentTarget as HTMLImageElement).clientHeight
    setMaxHeight((prev) => Math.max(prev, renderedHeight))
  }, [])

  return (
    <div className={styles.photoGridWrapper}>
      <Image.PreviewGroup>
        {photoUrls.map((photo, index) => {
          const isVisible = index < visibleCount
          return (
            <div
              key={index}
              className={isVisible ? styles.visiblePhoto : styles.hiddenPhoto}
              style={{ maxHeight: maxVisibleHeight }}>
              {isVisible && (
                <div className={styles.imageContainer}>
                  <Image
                    src={photo.url}
                    // Fire when the underlying <img> has loaded
                    onLoad={handleImgLoad}
                    className={styles.antImage}
                    style={{
                      width: '100%',
                      // Use 'auto' so the image height is determined by its aspect ratio
                      height: maxHeight ? `${maxHeight}px` : 'auto',
                      // maxHeight: '400px',
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                  />
                  {index === visibleCount - 1 && remaining > 0 && <div className={styles.overlay}>+{remaining}</div>}
                </div>
              )}

              {/* Render hidden images so that PreviewGroup still contains all of them */}
              {!isVisible && <Image src={photo.url} />}
            </div>
          )
        })}
      </Image.PreviewGroup>
    </div>
  )
}

export default PhotoGrid
