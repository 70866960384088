import React, { useState } from 'react'
import { Modal, Input, Button, Typography, message } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import styles from './APIKeyModal.module.scss'

interface APIKeyModalProps {
  isVisible: boolean
  onCancel: () => void
  onGenerate: (name: string, scopes: string[], expires_at: string) => Promise<string>
}

const { Text, Paragraph } = Typography

const APIKeyModal: React.FC<APIKeyModalProps> = ({ isVisible, onCancel, onGenerate }) => {
  const [keyName, setKeyName] = useState<string>('')
  const [isGenerating, setIsGenerating] = useState<boolean>(false)
  const [generatedKey, setGeneratedKey] = useState<string>('')
  const [isNameValid, setIsNameValid] = useState<boolean>(true)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setKeyName(value)
    setIsNameValid(value.trim().length > 0)
  }

  const handleGenerate = async () => {
    if (!keyName.trim()) {
      setIsNameValid(false)
      return
    }

    setIsGenerating(true)
    try {
      const scopes = ['read', 'write'] // Fixed scopes array
      const expires_at = new Date()
      expires_at.setFullYear(expires_at.getFullYear() + 1)
      const expires_atStr = expires_at.toISOString() // Fixed expires_at to ISO string
      const newKey = await onGenerate(keyName, scopes, expires_atStr)
      setGeneratedKey(newKey)
    } catch (error) {
      message.error('Failed to generate API key')
    } finally {
      setIsGenerating(false)
    }
  }

  const handleCopyToClipboard = () => {
    if (generatedKey) {
      navigator.clipboard
        .writeText(generatedKey)
        .then(() => message.success('API key copied to clipboard'))
        .catch(() => message.error('Failed to copy'))
    }
  }

  const handleClose = () => {
    setKeyName('')
    setGeneratedKey('')
    setIsNameValid(true)
    onCancel()
  }

  const renderGenerationForm = () => (
    <div className={styles.contentWrapper}>
      <div className={styles.inputGroup}>
        <Text strong className={styles.label}>
          Name
        </Text>
        <Input
          placeholder='Enter a key name'
          value={keyName}
          onChange={handleInputChange}
          className={!isNameValid ? styles.inputError : ''}
        />
        {!isNameValid && (
          <Text type='danger' className={styles.errorText}>
            Please enter a key name
          </Text>
        )}
      </div>

      <div className={styles.actionButtons}>
        <Button onClick={handleClose} className={styles.cancelButton}>
          Cancel
        </Button>
        <Button
          type='primary'
          onClick={handleGenerate}
          loading={isGenerating}
          disabled={!keyName.trim()}
          className={styles.generateButton}>
          Generate
        </Button>
      </div>
    </div>
  )

  const renderGeneratedKey = () => (
    <div className={styles.contentWrapper}>
      <div className={styles.successContent}>
        <p className={styles.saveKeyTitle}>Save your key</p>
        <Paragraph className={styles.saveKeyWarning}>
          Please store it somewhere safe and/or write it down, as we won't be able to show it again. If you lose this
          key, you'll need to generate a new one.
        </Paragraph>

        <div className={styles.keyContainer}>
          <Input
            value={generatedKey}
            readOnly
            className={styles.keyDisplay}
            suffix={
              <span className={styles.copyButton} onClick={handleCopyToClipboard}>
                <CopyOutlined />
              </span>
            }
          />
        </div>
      </div>
    </div>
  )

  return (
    <Modal
      centered
      open={isVisible}
      footer={null}
      onCancel={handleClose}
      width={550}
      className={styles.modal}
      maskClosable={false}>
      <div className={styles.content}>
        <div className={styles.title}>
          <p>Generate a new API key</p>
        </div>
        {generatedKey ? renderGeneratedKey() : renderGenerationForm()}
      </div>
    </Modal>
  )
}

export default APIKeyModal
