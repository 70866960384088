import React, { useState } from 'react'
import { Divider, Dropdown, Input, MenuProps, theme, Tooltip } from 'antd'
import improve_icon from 'common/assets/svg/improve_icon.svg'
import gray_ai_wand from 'common/assets/svg/gray_ai_wand.svg'
import right_gray_arrow from 'common/assets/svg/right_gray_arrow.svg'
import make_more_formal from 'common/assets/svg/make_more_formal.svg'
import make_more_casual from 'common/assets/svg/make_more_casual.svg'
import make_longer from 'common/assets/svg/make_longer.svg'
import make_shorter from 'common/assets/svg/make_shorter.svg'
import styles from './AICommentImprove.module.scss'

const { useToken } = theme

interface AICommentImproveProps {
  onImprove: (type: string) => void
  disabled: boolean
}

const AICommentImprove: React.FC<AICommentImproveProps> = ({ onImprove, disabled }) => {
  const [inputValue, setInputValue] = useState('')
  const [openDropdown, setOpenDropdown] = useState(false)
  const { token } = useToken()

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    minWidth: 260,
    fontSize: 13,
  }

  const menuStyle: React.CSSProperties = {
    boxShadow: 'none',
  }

  const items: MenuProps['items'] = [
    {
      key: 'longer',
      label: (
        <div className={styles.menuItem}>
          <img src={make_longer} alt='' />
          Make Longer
        </div>
      ),
      onClick: () => {
        onImprove('Make Longer')
        setOpenDropdown(false)
      },
    },
    {
      key: 'shorter',
      label: (
        <div className={styles.menuItem}>
          <img src={make_shorter} alt='' />
          Make Shorter
        </div>
      ),
      onClick: () => {
        onImprove('Make Shorter')
        setOpenDropdown(false)
      },
    },
    {
      key: 'formal',
      label: (
        <div className={styles.menuItem}>
          <img style={{ padding: '0px 2px 2px 2px' }} src={make_more_formal} alt='' />
          Make more Formal
        </div>
      ),
      onClick: () => {
        onImprove('Make more Formal')
        setOpenDropdown(false)
      },
    },
    {
      key: 'casual',
      label: (
        <div className={styles.menuItem}>
          <img style={{ padding: '0px 2px 2px 2px' }} src={make_more_casual} alt='' />
          Make more Casual
        </div>
      ),
      onClick: () => {
        onImprove('Make more Casual')
        setOpenDropdown(false)
      },
    },
  ]

  const handleOwnImprove = () => {
    onImprove(inputValue)
    setOpenDropdown(false)
    setTimeout(() => {
      setInputValue('')
    }, 200)
  }

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      open={openDropdown}
      onOpenChange={setOpenDropdown}
      disabled={disabled}
      dropdownRender={(menu) => (
        <div style={contentStyle}>
          {React.cloneElement(
            menu as React.ReactElement<{
              style: React.CSSProperties
            }>,
            { style: menuStyle },
          )}
          <Divider style={{ margin: 0 }} />
          <div style={{ padding: '10px' }}>
            <Input
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleOwnImprove()
                }
              }}
              prefix={<img src={gray_ai_wand} alt='ai_wand' />}
              suffix={
                <img
                  className={styles.right_arrow}
                  style={!inputValue ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
                  src={right_gray_arrow}
                  alt='right_arrow'
                  onClick={() => {
                    handleOwnImprove()
                  }}
                />
              }
              placeholder='Write your own instructions'
              style={{ backgroundColor: '#f5f5f5', border: 'none', padding: '8px' }}
              styles={{
                input: {
                  backgroundColor: '#f5f5f5',
                  fontSize: 12,
                },
              }}
            />
          </div>
        </div>
      )}>
      <Tooltip title={disabled ? 'Please type your comment first' : null}>
        <span className={styles.improve} style={disabled ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}>
          <img src={improve_icon} alt='improve' />
          <p style={{ margin: 0 }}>Improve</p>
        </span>
      </Tooltip>
    </Dropdown>
  )
}

export default AICommentImprove
