import React from 'react'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import styles from './AmountBox.module.scss'

interface AmountBoxProps {
  text: string
  amount: number
  maxAmount?: number
  hoveredText?: string
}

const AmountBox: React.FC<AmountBoxProps> = ({ text, amount, maxAmount, hoveredText }) => {
  const isOverMax = maxAmount !== undefined && amount > maxAmount

  const boxClasses = classNames(styles.container, {
    [styles.redBorder]: isOverMax,
    [styles.grayBorder]: !isOverMax,
  })

  //   const tooltipTitle = isOverMax && hoveredText ? hoveredText : ''

  return (
    <Tooltip title={hoveredText} placement='bottom' color='white' overlayInnerStyle={{ color: 'black' }}>
      <div className={boxClasses}>
        <span className={styles.label}>{text}</span>
        <span className={styles.amount}>{amount?.toLocaleString()}</span>
      </div>
    </Tooltip>
  )
}

export default AmountBox
