export function formatDate(date: string) {
  const options: any = {
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true, // 12-hour format
  }
  return new Date(date).toLocaleString('en-US', options).replace(',', ' at')
}
export function formatDateToISO(date: string) {
  return new Date(date).toLocaleTimeString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })
}
export function formatDateLinkedIn(date: string) {
  const now = new Date()
  const inputDate = new Date(date)
  const diffInSeconds = Math.floor((now.getTime() - inputDate.getTime()) / 1000)
  const diffInMinutes = Math.floor(diffInSeconds / 60)
  const diffInHours = Math.floor(diffInMinutes / 60)

  if (diffInMinutes < 1) {
    return 'Just now'
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes}m ago`
  } else if (diffInHours < 24) {
    return `${diffInHours}h ago`
  } else {
    return inputDate
      .toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
      })
      .replace(',', '') // returns the date in "26 Feb" format
  }
}
export const findCompany = (card: any) => {
  const positions = card?.lead?.profile?.positions
  if (!positions) return null
  const current = positions.find((position: any) => position?.end?.year === 0)
  if (current) return current
  return positions[0]
}

export function formatCompanyDuration(company: any) {
  function formatDate(date: any) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return `${months[date.month - 1] || ''} ${date.year}`
  }

  const startDate = formatDate(company?.start)
  let endDate = 'Present'

  if (!(company?.end?.day === 0 && company?.end?.month === 0 && company?.end?.year === 0)) {
    endDate = formatDate(company?.end)
  }
  return `${startDate} - ${endDate}`
}

export function extractSubreddit(url: string): string | null {
  const match = url?.match(/\/r\/([^/]+)/)
  return match ? `r/${match[1]}` : null
}

export const getFirstVideoUrl = (videoData: any) => {
  if (Array.isArray(videoData)) {
    return videoData.at(0)?.url
  }
  return undefined
}

interface ImageData {
  url: string
  width: number
  height: number
}

function extractUniqueId(url: string): string | null {
  const match = url.match(/\/dms\/image\/v2\/([^/]+)/)
  return match ? match[1] : null
}

/**
 * Returns only distinct images (based on unique identifier) selecting the highest quality version.
 * Quality is measured by the image area (width * height).
 */
// export function getDistinctHighQualityImages(images: ImageData[]): ImageData[] {
//   // Use a map to store the best image for each unique id
//   const bestImages: Record<string, { image: ImageData; quality: number }> = {}

//   images.forEach((img) => {
//     const id = extractUniqueId(img.url)
//     if (!id) return // Skip if unable to extract an id

//     const quality = img.width * img.height
//     if (!bestImages[id] || quality > bestImages[id].quality) {
//       bestImages[id] = { image: img, quality }
//     }
//   })

//   // Return only the image objects, not the extra quality property.
//   return Object.values(bestImages).map((entry) => entry.image)
// }

export function getDistinctHighQualityImages(images: ImageData[]): ImageData[] {
  // Group images by their unique identifier
  const imagesById: Record<string, ImageData[]> = {}

  images.forEach((img) => {
    const id = extractUniqueId(img.url)
    if (!id) return // Skip if unable to extract an id

    if (!imagesById[id]) {
      imagesById[id] = []
    }

    imagesById[id].push(img)
  })

  // Select the middle quality image for each unique id
  return Object.values(imagesById).map((imgList) => {
    // Sort by quality (width * height)
    imgList.sort((a, b) => a.width * a.height - b.width * b.height)

    // Pick the middle image
    return imgList[Math.floor(imgList.length / 2)]
  })
}

export const removeEmojis = (text: any) =>
  text
    .replace(/[\p{Emoji}]/gu, '')
    ?.replace(' ️', ' ')
    .trim()

export const CUE_CONSTANTS = {
  SEEDS_LIMIT_MESSAGE: 'You have reached your seeds monthly limit',
}
