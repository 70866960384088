import React, { useEffect, useState } from 'react'
import { Skeleton, Tooltip } from 'antd'
import { useLazyGetLeadHeatscoreQuery } from 'common/APIs/HeatboxApi'
import location_icon from 'common/assets/svg/location_icon.svg'
import fire_icon from 'common/assets/svg/fire_icon.svg'
import persona_matching_icon from 'common/assets/svg/persona_matching_icon.svg'
import stars from 'common/assets/svg/stars.svg'
import TruncatedTooltip from '../../GeneralComponents/UI/TruncatedTooltip'
import { formatCompanyDuration, findCompany, formatDateToISO } from 'common/components/Heatbox/Utils/CardUtils'
import styles from './CustomCard.module.scss'
import { Card } from '../CardStack'
import ProfilePicture from 'common/components/Heatbox/UI/ProfilePicture'
import { useUser } from 'Utils/UserContext'
import { useAppDispatch } from 'state'
import { SET_FIRE_LEVEL } from '../Utils/HeatboxSlice'
import { CloseOutlined } from '@ant-design/icons'

interface RightPanelHeatboxProps {
  loading?: boolean
  card?: Card
  useHeatboxPanel?: boolean
  toggleHeatboxPanel?: () => void
}

const RightPanelHeatbox: React.FC<RightPanelHeatboxProps> = ({
  loading,
  card,
  useHeatboxPanel,
  toggleHeatboxPanel,
}) => {
  // Local states for toggling the "show more/less" content
  const [showMore, setShowMore] = useState(false)
  const [showMoreDescription, setShowMoreDescription] = useState(false)
  const dispatch = useAppDispatch()
  // Fire level state & lazy query
  const [isFireLevelLoading, setIsFireLevelLoading] = useState(true)
  const [getFireLevel, { data: heatbox_score }] = useLazyGetLeadHeatscoreQuery()
  const fireLevel = Math.round(Math.min(Math.ceil(heatbox_score?.lead_heat_score || 0), 5))
  const { user } = useUser()
  const HEATBOX_USERID = user?.id
  // Fetch the fire level when the component mounts or when the card changes
  // Derive values from the card
  const location = card?.lead?.geo?.full
  const personaMatch = card?.lead_score
  const company = findCompany(card)
  const description = company?.description
  const summary = card?.lead?.profile?.summary
  const positions = card?.lead?.profile?.positions

  useEffect(() => {
    const fetchFireLevel = async () => {
      if (card?.lead?.username) {
        await getFireLevel({ user_id: HEATBOX_USERID, lead_id: card.lead.username })
        setIsFireLevelLoading(false)
      }
    }
    fetchFireLevel()
  }, [card, getFireLevel])
  useEffect(() => {
    dispatch(SET_FIRE_LEVEL(fireLevel))
  }, [fireLevel])

  return (
    <div className={styles.right}>
      {useHeatboxPanel && (
        <div className={styles.heatboxPanel}>
          <CloseOutlined onClick={toggleHeatboxPanel} style={{ fontSize: '14px', color: '#5b5e63' }} />
        </div>
      )}
      <div className={styles.profileHeader}>
        <ProfilePicture card={card} loading={loading} />
        <div className={styles.info}>
          <div className={styles.leadNameHeatbox}>{card?.lead?.name}</div>
          <p className={styles.title} style={{ textOverflow: 'ellipsis' }}>
            {card?.lead?.headline}
          </p>
          <div className={styles.generalData}>
            <div className={styles.location}>
              <img src={location_icon} alt='' />
              <TruncatedTooltip text={location} fontSize='14px' style={{ margin: 0 }} maxLength={15} />
            </div>

            <div
              className={`${styles.fireBadge} ${styles[`fire-${fireLevel > 5 ? 5 : fireLevel < 1 ? 1 : fireLevel}`]}`}>
              <img src={fire_icon} alt='' />
              {isFireLevelLoading ? (
                <Skeleton.Button
                  active
                  style={{
                    height: '10px',
                    display: 'flex',
                    minWidth: '0px',
                    maxWidth: '8px',
                  }}
                />
              ) : (
                <span>{fireLevel}</span>
              )}
            </div>

            <div
              className={`${styles.personaBadge} ${
                styles[`persona-${Math.min(Math.floor(personaMatch / 25) * 25, 75)}`]
              }`}>
              <img src={persona_matching_icon} alt='' />
              <span>{personaMatch}%</span>
            </div>
            {card?.last_engagement && (
              <Tooltip
                title={`${formatDateToISO(card?.last_engagement)}`}
                className={`${styles[`fire-2`]} ${styles['fireBadge']} ${styles['lastEngaged']}`}>
                Recently Interacted
              </Tooltip>
            )}
          </div>
        </div>
      </div>

      {company && (
        <div className={styles.summary}>
          <div className={styles.summaryTitle}>
            <img src={stars} alt='' />
            <span>Convrt AI Company Summary</span>
          </div>
          <div className={styles.summaryContent}>
            {company?.companyLogo && (
              <div className={styles.leftSummaryContent}>
                <img src={company?.companyLogo} alt='' />
              </div>
            )}
            <div className={styles.rightSummaryContent}>
              <p>{company?.companyName}</p>
              {company?.companyIndustry && (
                <p>
                  <b>Industry:</b> {company?.companyIndustry}
                </p>
              )}
              {description && (
                <p className={styles.descriptionText}>
                  <b>Description:</b>{' '}
                  <span className={showMoreDescription ? styles.expanded : styles.truncated}>{description}</span>
                  {description && description.split(' ').length > 10 && (
                    <span className={styles.showMore} onClick={() => setShowMoreDescription(!showMoreDescription)}>
                      {showMoreDescription ? ' Show Less' : ' Show More'}
                    </span>
                  )}
                </p>
              )}
              {company?.companyStaffCountRange && (
                <p>
                  <b>Employees:</b> {company?.companyStaffCountRange}
                </p>
              )}
            </div>
          </div>
        </div>
      )}

      <div className={styles.about}>
        <p>
          <b>About:</b>
          <span className={showMore ? styles.expanded : styles.truncated}>
            {summary
              ? showMore
                ? summary?.split('\n')?.map((line: any, index: number) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))
                : summary?.split(' ')?.slice(0, 30)?.join(' ') + (summary?.split(' ')?.length > 30 ? '...' : '')
              : ''}
          </span>
          {summary && summary.split(' ').length > 30 && (
            <span className={styles.showMore} onClick={() => setShowMore(!showMore)}>
              {showMore ? ' Show Less' : ' Show More'}
            </span>
          )}
        </p>
      </div>

      {positions?.length > 0 && (
        <div className={styles.experience}>
          <p>
            <b>Experience:</b>
          </p>
          {positions?.map((c: any, index: number) => (
            <div className={styles.experienceItem} key={index}>
              <p>
                <strong>{c.companyName}</strong>
              </p>
              <p className={styles.company}>{c?.title}</p>
              <p className={styles.duration}>{formatCompanyDuration(c)}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default RightPanelHeatbox
