import React, { useEffect } from 'react'
import { DeleteOutlined, InfoCircleOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { message, Select, Spin, Table, Typography, Upload, UploadProps } from 'antd'
import { forEach, isEmpty, isNull } from 'lodash'
import Papa, { ParseResult } from 'papaparse'
// import { useAppSelector } from 'state'

import styles from './ImportAndMapping.module.scss'
const { Dragger } = Upload

interface Props {
  onFinish: (campaign_id: any, campaign_name: any, csvData: any) => void
  setCsvData: any
  csvData: any
  isLoading: any
  initialState: any
  setFieldMapping: any
  fieldMapping: any
  // triggerImport: () => void
  setDisableButton: any
}

export const ImportAndMapping = ({
  onFinish,
  setCsvData,
  csvData,
  isLoading,
  initialState,
  setFieldMapping,
  fieldMapping,
  // triggerImport,
  setDisableButton,
}: Props) => {
  // const { campaign_id, campaign_name } = useAppSelector((state) => state.outreachCampaign)

  const fieldLabels: any = {
    handle: 'Profile URL (Mandatory)',
    full_name: 'Full name',
    company: 'Company',
    job_title: 'Job title',
    custom_1: 'Custom 1',
    custom_2: 'Custom 2',
    // For Heatbox:
    first_name: 'First Name',
    last_name: 'Last Name',
    heat_level: 'Heat Level',
  }

  const fieldExamples: any = {
    handle: 'david_alaba, john_wick_123',
    full_name: 'David Alaba, John Wick',
    company: 'Google, Microsoft',
    job_title: 'Product Manager',
    custom_1: 'Custom 1',
    custom_2: 'Custom 2',
    // For Heatbox:
    first_name: 'John, David',
    last_name: 'Smith, Alaba',
    heat_level: '1, 2',
  }
  const hasValidValues = (obj: any) => {
    if (obj.handle === '') {
      return false
    }
    return true
  }
  // const formatFieldKey = reduce(
  //   csvData.fieldArray,
  //   (result: any, value, index) => {
  //     result[value?.trim()] = index
  //     return result
  //   },
  //   {},
  // )

  const validateResultPapaParse = async (result: ParseResult<any>) => {
    let formatedData: any = []
    // let validRowData: any = []
    let unvalidRowData: any = []
    let isValidAmountOfRow = true

    if (result.data) {
      const { data } = result
      const filterData = data.filter((item: any) => !item.includes('Do not delete or modify the column names'))

      //REMOVE EMPTY COLUMN AND ROW
      forEach(filterData, (rowData) => {
        const isEmptyRow = rowData?.every((value: any) => isNull(value) || isEmpty(value) || !value)
        if (!isEmptyRow) {
          formatedData.push(rowData)
        }
      })

      if (formatedData.length > 10000) {
        isValidAmountOfRow = false

        return { data: {}, isValidAmountOfRow, csvRows: formatedData.length }
      }
    }

    return {
      data: {
        totalRowData: [...formatedData.slice(1)],
        rowData: [...formatedData.slice(1)],
        unvalidRowData,
        fieldArray: formatedData[0],
      },
      isValidAmountOfRow,
    }
  }

  // const formatedDataAfterMapping = () => {
  //   return csvData?.rowData?.map((row: any[]) => {
  //     return {
  //       handle: row[formatFieldKey[fieldMapping.handle]],
  //       full_name: row[formatFieldKey[fieldMapping.full_name]],
  //       company: row[formatFieldKey[fieldMapping.company]],
  //       job_title: row[formatFieldKey[fieldMapping.job_title]],
  //       custom_1: row[formatFieldKey[fieldMapping.custom_1]],
  //       custom_2: row[formatFieldKey[fieldMapping.custom_2]],
  //     }
  //   })
  // }

  // const handleUploadCSV = async () => {
  //   const dataMapping = formatedDataAfterMapping() || []
  //   const data = dataMapping.filter((item: any) => item.handle)
  //   try {
  //     if (data.length > 0) {
  //       onFinish(campaign_id, campaign_name, data)
  //     } else {
  //       message.error('No data valid')
  //     }
  //   } catch (error) {
  //     console.error(error)
  //     message.error('Error when upload CSV')
  //   }
  // }

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: '.csv',
    beforeUpload: (file) => {
      if (file) {
        handleProcessFile(file)
      }
      return false
    },

    onDrop(e) {
      handleProcessFile(e.dataTransfer.files[0])
    },
  }

  const renderRowMapping = () => {
    return fieldMapping
      ? Object.keys(fieldMapping)
          ?.filter((field: string) => field !== 'heat_level')
          .map((field: string) => {
            return {
              key: field,
              field: fieldLabels?.[field],
              atribute: (
                <Select
                  showSearch
                  key={field}
                  style={{ minWidth: '200px' }}
                  options={['none', ...csvData?.fieldArray].map((fieldName: string) => {
                    return {
                      value: fieldName,
                      label: fieldName,
                    }
                  })}
                  value={fieldMapping?.[field]}
                  onChange={(value: string) => {
                    const valueToChange = value === 'none' ? '' : value
                    setFieldMapping((oldFieldMapping: any) => {
                      return {
                        ...oldFieldMapping,
                        [field]: valueToChange,
                      }
                    })
                  }}
                />
              ),
              exampleData: fieldExamples[field],
            }
          })
      : []
  }

  const handleProcessFile = (file: File) => {
    setFieldMapping(initialState)

    Papa.parse(file, {
      complete: async (rs) => {
        const { data, isValidAmountOfRow, csvRows } = await validateResultPapaParse(rs)

        if (!isValidAmountOfRow) {
          return message.error(
            `The uploaded CSV exceeds the limit of 10,000 rows. Your file contains ${csvRows?.toLocaleString()} rows. Please upload a CSV with fewer rows.`,
          )
        }
        if (data) {
          setCsvData({
            ...data,
            file,
          })
        }
      },
      header: false,
      dynamicTyping: true,
      skipEmptyLines: true,
      error: (err) => {
        console.error(err.message)
        message.error('Error when read CSV file')
      },
    })
  }

  useEffect(() => {
    if (!hasValidValues(fieldMapping) || isLoading || !csvData?.file || csvData?.rowData?.length === 0) {
      setDisableButton(true)
    } else {
      setDisableButton(false)
    }
  }, [fieldMapping, isLoading, csvData])

  return (
    <div className={`${styles.importMapping}`}>
      {!csvData.file && (
        <div style={{ height: 'calc(100% - 85px)' }}>
          <Typography style={{ color: '#091E4299', padding: '0 0 15px' }}>
            {`Import Custom Leads`} <InfoCircleOutlined />
          </Typography>
          <Dragger {...uploadProps} disabled={isLoading}>
            {isLoading ? (
              <>
                <Spin indicator={<LoadingOutlined spin />} size='large' />
                <Typography className={styles.importMapping__loading__title}>
                  Uploading and Validating CSV Name.csv ...
                </Typography>
                <Typography className={styles.importMapping__loading__description}>
                  Please wait while we upload and validate your data; this may take a couple of minutes.
                </Typography>
              </>
            ) : (
              <div className={styles.flexDiv}>
                <Typography className={styles.importMapping__title}>
                  Upload your file by clicking here or dragging it into this area
                </Typography>
                <Typography className={styles.importMapping__description}>Supported file format: .csv</Typography>
                <Typography className={styles.importMapping__description}>Maximum file size: 10 MB</Typography>
                <Typography className={styles.importMapping__description}>Maximum rows per file: 10,000</Typography>
              </div>
            )}
          </Dragger>
        </div>
      )}

      {csvData.file && (
        <div className={styles.importMapping__result}>
          <div className={styles.importMapping__result__upload}>
            <Typography className={styles.importMapping__result__upload__title} style={{ fontSize: '14px' }}>
              Upload Leads CSV
            </Typography>
            <div className={styles.importMapping__result__upload__bar} style={{ padding: 10 }}>
              <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                <Typography>{csvData.file?.name}</Typography>
                <Typography style={{ color: 'rgba(9, 30, 66, 0.6)', fontSize: '11px' }}>
                  {csvData.file?.size} KB
                </Typography>
              </div>

              <div className={styles.importMapping__result__upload__bar__right}>
                <DeleteOutlined style={{ fontSize: '16px', cursor: 'pointer' }} onClick={() => setCsvData({})} />
                <label
                  className={styles.importMapping__result__upload__bar__right__button}
                  htmlFor='re-upload'
                  style={{ fontSize: '12px' }}>
                  <span>
                    <UploadOutlined /> Re-upload
                  </span>
                </label>
                <input
                  type={'file'}
                  accept='.csv'
                  hidden
                  id='re-upload'
                  onChange={(event) => {
                    if (event.target.files) {
                      handleProcessFile(event.target.files[0])
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <Typography className={styles.importMapping__result__upload__title}>Map your fields</Typography>
          <Typography className={styles.importMapping__result__upload__description}>
            To maximize your enrichment results, map as many fields from your CSV
          </Typography>
          {/* <div className={`${styles.importMapping__result__tableMapping} `}> */}
          <Table
            scroll={{ y: 'calc(100vh - 500px)', x: 'max-content' }}
            style={{ border: '1px solid #f0f0f0', borderRadius: '8px' }}
            size={'small'}
            pagination={false}
            dataSource={renderRowMapping()}
            columns={columns}
            rootClassName={'importAndMappingTable'}
            // className='hoverableScrollbarTable'
          />
        </div>
        // </div>
      )}
      {/* <div className={styles.importMapping__footer}>
        {isLoading && (
          <Spin style={{ margin: '0 10px' }} indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
        )}
        <Button
          className={`${styles.importMapping__result__footer__button} ${
            isLoading || styles['importMapping__result__footer__button--disabled']
          }`}
          type='primary'
          disabled={!hasValidValues(fieldMapping) || isLoading || !csvData?.file || csvData?.rowData?.length === 0}
          onClick={() => handleUploadCSV()}>
          Continue
        </Button>
      </div> */}
    </div>
  )
}

const columns = [
  {
    title: <div style={{ fontSize: '12px' }}>Field</div>,
    dataIndex: 'field',
    key: 'field',
    render: (field: any) => {
      return <span style={{ fontSize: '12px' }}>{field}</span>
    },
  },
  {
    title: <div style={{ fontSize: '12px' }}>Attribute</div>,
    dataIndex: 'atribute',
    key: 'atribute',
  },
  {
    title: <div style={{ fontSize: '12px' }}> Example Data</div>,
    dataIndex: 'exampleData',
    key: 'exampleData',
    render: (field: any) => {
      return <span style={{ fontSize: '12px' }}>{field}</span>
    },
  },
]
