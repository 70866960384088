import React, { useEffect, useState } from 'react'
import { Menu, MenuProps } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'

import vectorBlack from 'common/assets/svg/vector_black.svg'
import vectorWhite from 'common/assets/svg/vector_white.svg'
import chatsBlack from 'common/assets/svg/chatsBlack.svg'
import chatsWhite from 'common/assets/svg/chatsWhite.svg'
import tasksBlack from 'common/assets/svg/tasks_tab_icon_black.svg'
import tasksWhite from 'common/assets/svg/tasks_tab_icon_white.svg'
import homeBlack from 'common/assets/svg/home_tab_icon_black.svg'
import homeWhite from 'common/assets/svg/home_tab_icon_white.svg'
import rocket from 'common/assets/svg/convrt_rocket.svg'
import rocketWhite from 'common/assets/svg/white_rocket.svg'
import { MenuServices } from 'common/services/menu-services'
import lightBulbWhite from 'LEGACY/Assets/svg/Lightbulb.svg'
import lightBulbBlack from 'LEGACY/Assets/svg/LightbulbBlack.svg'
import contactsWhite from 'LEGACY/Assets/svg/contactsWhite.svg'
import contactsBlack from 'LEGACY/Assets/svg/contactsBlack.svg'
import cuesBlack from 'common/assets/svg/cues_icon_bw.svg'
import cuesWhite from 'common/assets/svg/cues_icon_white.svg'
import { useAppSelector } from 'state'

import styles from './top-menu.module.scss'
import ExitModal from '../../Campaigns/Campaign/UI/ExitModal'
import { useLocalStorage } from 'usehooks-ts'
import CustomHover from './CustomHover'
import { useUser } from 'Utils/UserContext'

interface ITopMenu {
  collapsed: boolean
  selectedKey: string
  callbackOnChange?: (value?: string) => void
}

const TopMenu: React.FC<ITopMenu> = ({ collapsed, callbackOnChange, selectedKey }) => {
  const [openExitModal, setOpenExitModal] = useState(false)
  const [localUser] = useLocalStorage<any>('user', null)
  const { isSubscriptionDaysPassed } = useAppSelector((state) => state.filters)
  const [blockNavigation, setBlockNavigation] = useState(false)
  const { user } = useUser()
  const organic_outbound = user?.addons?.organic_outbound === true
  // const campaigns = user?.addons?.campaigns === true
  const navigate = useNavigate()
  const disableNavigationsRoutes = ['/outreach/create-new-campaign']
  const [navigateTo, setNavigateTo] = useState('')

  type MenuItem = Required<MenuProps>['items'][number]

  const location = useLocation()

  const unifiedInbox = location.pathname.includes('/unifiedInbox') ? chatsBlack : chatsWhite
  const outreach = location.pathname.includes('/outreach') ? vectorBlack : vectorWhite
  const tasks = location.pathname.includes('/tasks') ? tasksBlack : tasksWhite
  const home = location.pathname.includes('/home') ? homeBlack : homeWhite
  const gettingStarted = location.pathname.includes('/getting-started') ? rocket : rocketWhite
  const cues = location.pathname.includes('/cues') ? cuesBlack : cuesWhite

  ////////////////////////////////
  //////////// LEGACY ////////////
  const lightBulb =
    location.pathname.includes('/companies') || location.pathname.includes('/contacts')
      ? lightBulbBlack
      : lightBulbWhite
  const exposedContacts =
    location.pathname.includes('/exposedContacts') || location.pathname.includes('/importTelegramContacts')
      ? contactsBlack
      : contactsWhite
  //////////// LEGACY ////////////
  ////////////////////////////////

  const getLabel = (key: string) => {
    switch (key) {
      case 'gettingStarted':
        return (
          <CustomHover
            navigate={() => {
              handleNavigation('getting-started')
            }}
            hkey={'gettingStarted'}
            title='Getting Started'
            body='Everything you need to get started.'
            image={'https://storage.googleapis.com/convrt-fe-preprod/GettingStarted.png'}>
            <div style={{ width: '100%' }}>Getting Started</div>
          </CustomHover>
        )
      case 'cues':
        return (
          <CustomHover
            navigate={() => {
              handleNavigation('cues')
            }}
            hkey={'cues'}
            title='Cues'
            body='Your cues.'
            image={'https://storage.googleapis.com/convrt-media-store/heatbox_preview.png'}>
            <div style={{ width: '100%' }}>
              Cues <sup className={styles.sup}>AI</sup>
            </div>
          </CustomHover>
        )
      ////////////////////////////////
      //////////// LEGACY ////////////
      case 'companies': // LEGACY
      case 'contacts': // LEGACY
      case 'research': // LEGACY
        return (
          <CustomHover
            navigate={() => {
              handleNavigation('companies')
            }}
            hkey={'research'}
            title='Research'
            body='Where you can find and research companies and contacts.'
            image={'https://storage.googleapis.com/convrt-fe-preprod/Research.png'}>
            <div style={{ width: '100%' }}>Research</div>
          </CustomHover>
        )
      case 'exposedContacts': // LEGACY
        return (
          <CustomHover
            navigate={() => {
              handleNavigation('exposedContacts')
            }}
            hkey={'exposedContacts'}
            title='My Contacts'
            body='Your contacts'
            image={'https://storage.googleapis.com/convrt-fe-preprod/MyContcats.png'}>
            <div style={{ width: '100%' }}>My Contacts</div>
          </CustomHover>
        )
      //////////// LEGACY ////////////
      ////////////////////////////////
      case 'outreach':
        return (
          <CustomHover
            navigate={() => {
              handleNavigation('outreach')
            }}
            hkey={'outreach'}
            title='Engage+'
            body='Where you can create, manage, and track your sequences.'
            image={'https://storage.googleapis.com/convrt-media-store/EngagePlus.png'}>
            <div style={{ width: '100%' }}>Engage+</div>
          </CustomHover>
        )
      case 'home':
        return (
          <CustomHover
            navigate={() => {
              handleNavigation('home')
            }}
            hkey={'home'}
            title='Home'
            body='Your homepage, where you can view an overview and manage everything in one place.'
            image={'https://storage.googleapis.com/convrt-media-store/Homepage.png'}>
            <div style={{ width: '100%' }}>Home</div>
          </CustomHover>
        )
      case 'tasks':
        return (
          <CustomHover
            navigate={() => {
              handleNavigation('tasks')
            }}
            hkey={'tasks'}
            title='Tasks Market'
            body='This is where you manage tasks, actively engage in social selling, and connect with your ideal customer profile (ICP).'
            image={'https://storage.googleapis.com/convrt-media-store/TasksPage.png'}>
            <div style={{ width: '100%' }}>Tasks</div>
          </CustomHover>
        )
      case 'unified':
        return (
          <CustomHover
            navigate={() => {
              handleNavigation('unified')
            }}
            hkey={'unified'}
            title='Inbox'
            body='Where you can message, tag, organize, and respond to your prospects.'
            image={'https://storage.googleapis.com/convrt-media-store/InboxPage.png'}>
            <div style={{ width: '100%' }}>Unified Inbox</div>
          </CustomHover>
        )
    }
  }

  const items: MenuItem[] = [
    MenuServices.getItem(
      !collapsed && getLabel('gettingStarted'),
      'getting-started',
      <img src={gettingStarted} alt={''} />,
      blockNavigation,
    ),
    ...(organic_outbound
      ? [
          MenuServices.getItem(
            !collapsed && getLabel('cues'),
            'cues',
            <img src={cues} alt={''} />,
            blockNavigation || !organic_outbound,
          ),
        ]
      : []),

    ////////////////////////////////
    //////////// LEGACY ////////////
    ...(user?.user_type === 'web3'
      ? [
          MenuServices.getItem(
            !collapsed && getLabel('research'),
            'companies',
            <img src={lightBulb} alt={''} />,
            isSubscriptionDaysPassed,
          ),
          MenuServices.getItem(
            !collapsed && getLabel('exposedContacts'),
            'exposedContacts',
            <img src={exposedContacts} alt={''} />,
            isSubscriptionDaysPassed,
          ),
        ]
      : []),
    //////////// LEGACY ////////////
    ////////////////////////////////
    MenuServices.getItem(
      !collapsed && getLabel('outreach'),
      'outreach',
      <img src={outreach} alt={''} />,
      blockNavigation,
    ),

    ...(user?.user_type === 'web3'
      ? []
      : [MenuServices.getItem(!collapsed && getLabel('home'), 'home', <img src={home} alt={''} />, blockNavigation)]),
    ...(user?.user_type === 'web3'
      ? []
      : [
          MenuServices.getItem(!collapsed && getLabel('tasks'), 'tasks', <img src={tasks} alt={''} />, blockNavigation),
        ]),
    MenuServices.getItem(
      !collapsed && getLabel('unified'),
      'unifiedInbox',
      <img src={unifiedInbox} alt={''} />,
      blockNavigation,
    ),
  ]

  const handleNavigation = (value: any) => {
    const routeToNavigate = `/${value.key}`
    const currentRoute = window.location.pathname

    if (disableNavigationsRoutes.includes(currentRoute)) {
      setOpenExitModal(true)
      setNavigateTo(routeToNavigate)
    } else {
      callbackOnChange && callbackOnChange(value.key)
      navigate(routeToNavigate)
    }

    const refreshAfterNavigation = ['/checkout/success']
    if (refreshAfterNavigation.includes(currentRoute)) {
      window.location.reload()
      navigate(routeToNavigate)
    }
  }

  useEffect(() => {
    if (localUser) {
      const { requires_onboarding, onboarding_status } = localUser?.addons
      if ((requires_onboarding && !onboarding_status) || isSubscriptionDaysPassed) {
        setBlockNavigation(true)
      } else {
        setBlockNavigation(false)
      }
    }
  }, [localUser])

  //In Outreach tab you can see all the contacts you exposed and easly reach out to them in bulk and see your stats.
  return (
    <>
      <Menu
        theme='dark'
        defaultSelectedKeys={[
          window.location.pathname.indexOf('companies') >= 0
            ? 'companies'
            : window.location.pathname.indexOf('contacts') >= 0
            ? 'companies'
            : window.location.pathname.indexOf('tasks') >= 0
            ? 'tasks'
            : window.location.pathname.indexOf('home') >= 0
            ? 'home'
            : window.location.pathname.indexOf('getting-started') >= 0
            ? 'getting-started'
            : window.location.pathname.indexOf('cues') >= 0
            ? 'cues'
            : window.location.pathname.indexOf('unifiedInbox') >= 0
            ? 'unifiedInbox'
            : window.location.pathname.indexOf('exposedContacts') >= 0 ||
              window.location.pathname.indexOf('importTelegramContacts') >= 0
            ? 'exposedContacts'
            : window.location.pathname.indexOf('outreach') >= 0
            ? 'outreach'
            : '',
        ]}
        selectedKeys={[
          window.location.pathname.indexOf('companies') >= 0
            ? 'companies'
            : window.location.pathname.indexOf('contacts') >= 0
            ? 'companies'
            : window.location.pathname.indexOf('tasks') >= 0
            ? 'tasks'
            : window.location.pathname.indexOf('home') >= 0
            ? 'home'
            : window.location.pathname.indexOf('getting-started') >= 0
            ? 'getting-started'
            : window.location.pathname.indexOf('cues') >= 0
            ? 'cues'
            : window.location.pathname.indexOf('unifiedInbox') >= 0
            ? 'unifiedInbox'
            : window.location.pathname.indexOf('exposedContacts') >= 0 ||
              window.location.pathname.indexOf('importTelegramContacts') >= 0
            ? 'exposedContacts'
            : window.location.pathname.indexOf('outreach') >= 0
            ? 'outreach'
            : '',
        ]}
        mode='inline'
        items={items}
        className={styles.topMenu}
        onSelect={handleNavigation}
      />
      {openExitModal && (
        <ExitModal
          open={openExitModal}
          onClose={() => {
            setOpenExitModal(false)
          }}
          navigateTo={navigateTo}
        />
      )}
    </>
  )
}

export default TopMenu
