import React, { useState } from 'react'
import { Popover, Select, Skeleton, Table, Input } from 'antd'
import type { TableColumnsType } from 'antd'
import _ from 'lodash'
import { CopyOutlined } from '@ant-design/icons'
import styles from './Seeds.module.scss'
import fallbackIcon1 from 'common/assets/svg/FallbackIcon1.png'
import lists_icon from 'common/assets/svg/lists_icon.svg'
import linkedin_icon from 'common/assets/svg/linkedin_icon.svg'
import reddit_icon from 'common/assets/svg/reddit_icon.svg'
// import start_sequence_icon from 'common/assets/svg/start_sequence_icon.svg'
import export_icon from 'common/assets/svg/export_icon.svg'
import fire_icon from 'common/assets/svg/fire_icon.svg'
import search_right from 'common/assets/svg/search_right.svg'
// import crown from 'common/assets/svg/premium_crown.svg'
// import { motion } from 'framer-motion'
import { trackEvent } from 'eventConfig'
import SeedsGeneratePost from './UI/SeedsGeneratePost'
import { useUser } from 'Utils/UserContext'
import {
  useLazyCreateSampleCampaignQuery,
  useLazyTrackOnHeatboxQuery,
  useLazyUntrackOnHeatboxQuery,
} from 'common/APIs/HeatboxApi'
import TrackedSwitch from 'common/components/Heatbox/UI/TrackedSwitch'
import StartSequenceCell from 'common/components/Heatbox/UI/StartSequenceCell'

// Helper functions for date formatting
const getOrdinalSuffix = (n: number): string => {
  if (n >= 11 && n <= 13) return 'th'
  switch (n % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

const formatDate = (dateStr: string) => {
  const date = new Date(dateStr)
  const day = date.getDate()
  const month = date.toLocaleString('en-US', { month: 'long' })
  const year = date.getFullYear()
  return `${month} ${day}${getOrdinalSuffix(day)}, ${year}`
}

/** Interface for a seed (post) */
export interface SeedDataType {
  content_id: string
  user_id: string
  content: string
  lead_id: string
  content_type: string
  seed_id: string
  my_content: string
  created_time: string
  updated_time: string
  platform: string
  url: string
  author: string
  action: string
  lead_ids: string[] // linked lead IDs
}

/** Interface for a lead (person) */
export interface LeadDataType {
  id: string
  name: string
  job_title: string
  company: string
  heat_score: number
  last_engagement: string
  lead_source: string
  is_on_heatbox: boolean
  seed_ids: string[] // linked seed IDs
  status?: string
}

/** Updated Columns for seeds (posts) */
const seedsColumns: TableColumnsType<any> = [
  {
    title: 'Seeds',
    dataIndex: 'index',
    key: 'index',
    ellipsis: true,
    sorter: (a, b) => String(a?.index)?.localeCompare(String(b?.index)),
    render: (index: any, record: SeedDataType) => (
      <div className={styles.people} style={{ display: 'flex', alignItems: 'center' }}>
        <img src={lists_icon} alt='' />
        <p style={{ margin: '0 4px' }}>Post {index + 1}</p>
        <img
          src={export_icon}
          alt='Go to'
          onClick={() => {
            window.open(record.url, '_blank')
          }}
          className={styles.exportIcon}
        />
      </div>
    ),
  },
  {
    title: 'Content Source',
    dataIndex: 'author',
    key: 'author',
    width: 130,
    ellipsis: true,
    sorter: (a, b) => a?.author?.localeCompare(b?.author),
    render: (author: string) => {
      return <span>{author || '-'}</span>
    },
  },
  {
    title: 'Content',
    dataIndex: 'content',
    key: 'content',
    ellipsis: true,
    sorter: (a, b) => a?.content?.localeCompare(b?.content),
    width: 150,
    render: (content: string) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center', maxWidth: '12px' }}>
          <Popover
            overlayStyle={{ maxWidth: '500px', zIndex: 1000000 }}
            content={
              <div
                style={{
                  whiteSpace: 'pre-wrap',
                  maxHeight: '300px',
                  overflowY: 'auto',
                }}>
                {content}
              </div>
            }
            placement='topLeft'>
            <span>{content}</span>
          </Popover>

          <CopyOutlined
            style={{
              marginLeft: 8,
              cursor: 'pointer',
              color: '#7043ff',
              position: 'absolute',
              right: 0,
              padding: '0px 6px',
              backgroundColor: 'white',
            }}
            onClick={(e) => {
              e.stopPropagation()
              navigator.clipboard.writeText(content)
            }}
          />
        </div>
      )
    },
  },
  {
    title: 'Generate Post',
    dataIndex: 'content',
    key: 'content',
    ellipsis: true,
    width: 130,
    render: (content: string, seed: any) => {
      return <SeedsGeneratePost content={content} seed={seed} />
    },
  },
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    ellipsis: true,
    sorter: (a, b) => a?.source?.localeCompare(b?.source),
  },

  {
    title: 'Channel',
    dataIndex: 'platform',
    key: 'platform',
    ellipsis: true,
    sorter: (a, b) => a?.platform?.localeCompare(b?.platform),
    render: (channel: string) => {
      return (
        <div className={styles.channels}>
          <img src={channel === 'linkedin' ? linkedin_icon : reddit_icon} alt='' />
          <p>{channel === 'linkedin' ? 'LinkedIn' : _.startCase(channel)}</p>
        </div>
      )
    },
  },
  {
    title: 'Seed Content',
    dataIndex: 'my_content',
    key: 'my_content',
    ellipsis: true,
    sorter: (a, b) => a?.my_content?.localeCompare(b?.my_content),
    width: 120,
  },
  {
    title: 'Type',
    dataIndex: 'action',
    key: 'action',
    width: 85,
    align: 'center',
    ellipsis: true,
    sorter: (a, b) => a?.action?.localeCompare(b?.action),
    render: (action: string) => {
      return <span>{_.startCase(action)}</span>
    },
  },
  {
    title: 'Reactions',
    dataIndex: 'reactions',
    key: 'reactions',
    align: 'center',
    ellipsis: true,
    sorter: (a, b) => {
      try {
        return String(a?.reactions || '0')?.localeCompare(String(b?.reactions || '0'))
      } catch (e) {
        console.log('a', a)
        console.log('b', b)
        return 0
      }
    },
    render: (text: any) => text || '0',
  },
  {
    title: 'Comments',
    dataIndex: 'comments',
    key: 'comments',
    align: 'center',
    ellipsis: true,
    sorter: (a, b) => String(a?.comments || '0')?.localeCompare(String(b?.comments || '0')),
    render: (text: any) => text || '0',
  },
  {
    title: 'Seed Time',
    dataIndex: 'created_time',
    key: 'created_time',
    ellipsis: true,
    align: 'center',
    sorter: (a, b) => a?.created_time?.localeCompare(b?.created_time),
    render: (_: any, record: SeedDataType) => formatDate(record.created_time),
  },
]

/** Columns for leads (people) */
// Instead of a constant array, export a function that returns the columns.

const getLeadsColumns = (
  seedsData: SeedDataType[],
  trackOnHeatbox: (params: any[]) => Promise<any>,
  untrackOnHeatbox: (params: any[]) => Promise<any>,
  createSampleCampaign: (params: any) => Promise<any>,
  refreshSeedsData: () => void,
  userId: string,
  addons: any,
): TableColumnsType<any> => [
  {
    title: 'People',
    dataIndex: 'name',
    key: 'name',
    width: 150,
    ellipsis: true,
    sorter: (a, b) => a?.name?.localeCompare(b?.name),
    render: (_: any, record: LeadDataType) => (
      <div className={styles.people}>
        <img src={fallbackIcon1} alt='' />
        <p>{record.name}</p>
      </div>
    ),
  },
  {
    title: 'Job Title',
    dataIndex: 'job_title',
    key: 'job_title',
    ellipsis: true,
    sorter: (a, b) => (a?.job_title || '')?.localeCompare(b?.job_title || ''),
  },
  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
    ellipsis: true,
    sorter: (a, b) => (a?.company || '')?.localeCompare(b?.company || ''),
  },
  {
    title: 'ICP Score',
    dataIndex: 'icp_score',
    key: 'icp_score',
    ellipsis: true,
    align: 'center',
    width: 110,
    sorter: (a, b) => (a?.icp_score || 0) - (b?.icp_score || 0),
    render: (icp_score: number) => {
      return (
        <div className={styles.fireBadgeWrapper}>
          {icp_score >= 0 ? (
            <p className={styles.score} style={{ fontSize: '13px' }}>
              {icp_score ? icp_score?.toLocaleString() : 0}%
            </p>
          ) : (
            <span>N/A</span>
          )}
        </div>
      )
    },
  },
  {
    title: 'Warm Level',
    dataIndex: 'heat_score',
    key: 'heat_score',
    ellipsis: true,
    width: 110,
    align: 'center',
    sorter: (a, b) => (a?.heat_score || 0) - (b?.heat_score || 0),
    defaultSortOrder: 'descend',
    render: (fireLevel: number) => (
      <div className={styles.fireBadgeWrapper}>
        <div className={`${styles.fireBadge} ${styles[`fire-${fireLevel > 5 ? 5 : fireLevel < 1 ? 1 : fireLevel}`]}`}>
          <img src={fire_icon} alt='' />
          <span>{fireLevel}</span>
        </div>
      </div>
    ),
  },
  {
    title: 'Source',
    dataIndex: 'lead_source',
    key: 'lead_source',
    ellipsis: true,
    align: 'center',
    sorter: (a, b) => a?.lead_source?.localeCompare(b?.lead_source),
  },
  {
    title: 'Last Engagement',
    dataIndex: 'last_engagement',
    key: 'last_engagement',
    ellipsis: true,
    align: 'center',
    sorter: (a, b) => (a?.last_engagement || '')?.localeCompare(b?.last_engagement || ''),
    render: (text: string) => formatDate(text) || '-',
  },
  {
    title: 'Tracked',
    dataIndex: 'is_on_heatbox',
    key: 'is_on_heatbox',
    align: 'center',
    sorter: (a, b) => a?.is_on_heatbox?.toString()?.localeCompare(b?.is_on_heatbox?.toString()),
    render: (is_on_heatbox: boolean, data: LeadDataType) => {
      const initialChecked = is_on_heatbox === true
      const platforms = Array.from(
        new Set(seedsData.filter((seed) => seed.lead_id === data.id).map((seed) => seed.platform.toLowerCase())),
      )

      return (
        <div className={styles.onHeatbox}>
          <TrackedSwitch
            initialIsTracked={initialChecked}
            lead={data}
            userId={userId}
            trackOnHeatbox={trackOnHeatbox}
            untrackOnHeatbox={untrackOnHeatbox}
            refreshSeedsData={refreshSeedsData}
            platforms={platforms}
          />
        </div>
      )
    },
  },
  {
    title: 'Start Sequence',
    dataIndex: 'lead_source',
    key: 'lead_source',
    ellipsis: true,
    align: 'center',
    sorter: (a, b) => a?.lead_source?.localeCompare(b?.lead_source),
    render: (lead_source: string, data: LeadDataType) => (
      <StartSequenceCell lead_source={lead_source} data={data} addons={addons} userId={userId} seedsData={seedsData} />
    ),
  },
  // {
  //   title: 'Start Sequence',
  //   dataIndex: 'lead_source',
  //   key: 'lead_source',
  //   ellipsis: true,
  //   align: 'center',
  //   sorter: (a, b) => a?.lead_source?.localeCompare(b?.lead_source),
  //   render: (lead_source: string, data: any) => {
  //     const generate_campaign_from_seeds = addons?.generate_campaign_from_seeds === true
  //     const platforms = Array.from(
  //       new Set(seedsData.filter((seed) => seed.lead_id === data.id).map((seed) => seed.platform.toLowerCase())),
  //     )
  //     const hasLinkedin = platforms.includes('linkedin')

  //     const handleStartSequenceClick = async () => {
  //       if (generate_campaign_from_seeds && hasLinkedin) {
  //         await createSampleCampaign({
  //           user_id: userId,
  //           lead_id: data.id,
  //           // messages: [
  //           //   ['test1', 'test2', 'test3'],
  //           //   ['test4', 'test5'],
  //           // ],
  //         })
  //         message.success('Campaign created successfully')
  //       }
  //     }

  //     return (
  //       <div className={styles.startSequence}>
  //         <motion.div
  //           className={styles.startSequenceWrapper}
  //           whileHover={{ scale: 1.1 }}
  //           transition={{ duration: 0.3 }}>
  //           <img src={start_sequence_icon} alt='' onClick={handleStartSequenceClick} />
  //           {(!generate_campaign_from_seeds || !hasLinkedin) && (
  //             <img
  //               src={crown}
  //               alt='premium'
  //               style={{ position: 'absolute', width: '18px', height: '18px', right: '-6px', top: '-6px' }}
  //             />
  //           )}
  //         </motion.div>
  //       </div>
  //     )
  //   },
  // },
]

/**
 * Seeds Component
 *
 * Data is passed in via props as:
 * {
 *   seeds: SeedDataType[],
 *   leads: LeadDataType[]
 * }
 *
 * When grouping by people:
 * - Outer table shows leads.
 * - Expanded rows show only the seeds whose seed_id is in the lead’s seed_ids.
 *
 * When grouping by posts:
 * - Outer table shows seeds.
 * - Expanded rows show only the leads whose id is in the seed’s lead_ids.
 */
const Seeds: React.FC<{ data: any; isLoading: boolean; refreshSeedsData: () => void }> = ({
  data,
  isLoading,
  refreshSeedsData,
}) => {
  const { user } = useUser()
  const addons = user?.addons || {}
  // Safely extract seeds and leads (default to empty arrays if not present)
  const seedsData: SeedDataType[] = data?.seeds || []
  const leadsData: LeadDataType[] = data?.leads || []

  const [groupBy, setGroupBy] = useState<'people' | 'posts'>('people')
  // New state for search term when filtering people
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [untrackOnHeatbox] = useLazyUntrackOnHeatboxQuery()
  const [trackOnHeatbox] = useLazyTrackOnHeatboxQuery()
  const [createSampleCampaign] = useLazyCreateSampleCampaignQuery()
  // await untrackOnHeatbox({ lead_id: , user_id:  })

  // Outer table uses leads if grouping by people, else uses seeds.
  // If grouping by people, filter by search term.
  const outerData: any[] =
    groupBy === 'people'
      ? leadsData.filter((lead) => lead.name.toLowerCase().includes(searchTerm.trim().toLowerCase()))
      : seedsData

  // Use the dynamic leads columns when grouping by people.
  const outerColumns: TableColumnsType<any> =
    groupBy === 'people'
      ? getLeadsColumns(
          seedsData,
          trackOnHeatbox,
          untrackOnHeatbox,
          createSampleCampaign,
          refreshSeedsData,
          user.id,
          addons,
        )
      : seedsColumns

  // Expanded row render: filter the linked records based on the current grouping
  const expandedRowRender = (record: any) => {
    if (groupBy === 'people') {
      const filteredSeeds = seedsData.filter((seed: SeedDataType) => record.seed_ids.includes(seed.seed_id))
      return (
        <Table
          rowKey='seed_id'
          size='small'
          columns={seedsColumns}
          dataSource={filteredSeeds}
          pagination={false}
          className={styles.table}
        />
      )
    } else {
      const filteredLeads = leadsData.filter((lead: LeadDataType) => record.lead_ids.includes(lead.id))
      return (
        <Table
          rowKey='id'
          size='small'
          columns={getLeadsColumns(
            seedsData,
            trackOnHeatbox,
            untrackOnHeatbox,
            createSampleCampaign,
            refreshSeedsData,
            user.id,
            addons,
          )}
          dataSource={filteredLeads}
          pagination={false}
          className={styles.table}
        />
      )
    }
  }

  // If loading, create dummy data for the outer table
  const dummyOuterData = isLoading ? Array.from({ length: 10 }, (_, index) => ({ key: `key${index}` })) : outerData

  // If loading, modify columns to render a Skeleton placeholder
  const modifiedOuterColumns = isLoading
    ? outerColumns.map((col: any) => ({
        ...col,
        render: () => <Skeleton className={styles.skeleton} active title paragraph={false} />,
      }))
    : outerColumns

  return (
    <div className={styles.container}>
      <div className={styles.header} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ marginRight: 8 }}>Group by:</p>
          <Select
            className={styles.headerSelect}
            style={{ width: '100px', border: 'none' }}
            placeholder='Please select'
            value={groupBy}
            onChange={(value) => {
              setGroupBy(value)
              trackEvent('SEEDS_ACTION', {
                type: 'GROUP_BY',
                value,
              })
              // Reset search term when switching grouping
              setSearchTerm('')
            }}>
            <Select.Option value='people'>People</Select.Option>
            <Select.Option value='posts'>Seeds</Select.Option>
          </Select>
        </div>
        {groupBy === 'people' && (
          <Input
            prefix={<img src={search_right} alt='search_right' style={{ marginLeft: '-4px' }} />}
            placeholder='Search People'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ width: 300 }}
            allowClear
          />
        )}
      </div>
      <Table
        rowKey={groupBy === 'people' ? 'id' : 'seed_id'}
        size='small'
        columns={modifiedOuterColumns}
        expandable={{
          expandedRowRender: expandedRowRender,
          rowExpandable: () => !isLoading,
        }}
        dataSource={dummyOuterData}
        style={{ height: '65%' }}
        scroll={{ y: '65vh' }}
        className={styles.table}
        pagination={{
          pageSize: 100,
          position: ['bottomCenter'],
        }}
      />
    </div>
  )
}

export default Seeds
