import { CopyOutlined, RedoOutlined } from '@ant-design/icons'
import { Button, Dropdown, message, Popover, Tooltip, theme, Divider, Input } from 'antd'
import { useLazyGeneratePostOutOfSeedQuery } from 'common/APIs/HeatboxApi'
import { trackEvent } from 'eventConfig'
import React, { useState } from 'react'
import { useAppSelector } from 'state'
import { useUser } from 'Utils/UserContext'
import gray_ai_wand from 'common/assets/svg/gray_ai_wand.svg'
import right_gray_arrow from 'common/assets/svg/right_gray_arrow.svg'
import styles from './SeedsGeneratePost.module.scss'
const { useToken } = theme
interface SeedsGeneratePostProps {
  content: string
  seed: any
}
export default function SeedsGeneratePost({ content, seed }: SeedsGeneratePostProps) {
  const [didGenerate, setDidGenerate] = useState(false)
  const [generatedContent, setGeneratedContent] = useState('')
  const [generatePostOutOfSeed, { isFetching: isLoading }] = useLazyGeneratePostOutOfSeedQuery()
  const [messageApi, contextHolder] = message.useMessage()
  const { user } = useUser()
  const user_id = user?.id
  const { menuOpen } = useAppSelector((state) => state.heatbox)
  const [openDropdown, setOpenDropdown] = useState(false)
  const { token } = useToken()
  const [inputValue, setInputValue] = useState('')
  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    minWidth: 300,
    maxWidth: 450,
    fontSize: 13,
  }
  const customMessage = (content: string, type: 'success' | 'error' | 'warning' | 'info') => {
    messageApi.open({
      type: type,
      content: content,
      className: 'custom-class',
      style: {
        marginLeft: '60px',
        marginTop: menuOpen ? '85px' : '0px',
      },
    })
  }
  const handleGenerate = async () => {
    try {
      const res = await generatePostOutOfSeed({
        seed_content: content,
        user_id: user_id,
        instruction: inputValue,
      })
      const { data } = res
      if (res?.isError || !data) {
        throw new Error(`'Failed to generate post out of seed - ${res}'`)
      }
      trackEvent('SEEDS_ACTION', {
        type: 'GENERATE_POST_FROM_SEED',
        command: 'None',
        seed_id: seed?.seed_id,
        lead_id: seed?.lead_ids?.[0],
      })
      setGeneratedContent(data)
      setDidGenerate(true)
      customMessage('Post generated successfully', 'success')
    } catch (e) {
      setGeneratedContent('')
      setDidGenerate(false)

      console.error(e)
      customMessage('Failed to generate post out of seed', 'error')
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', maxWidth: '12px' }}>
      {contextHolder}
      {!didGenerate && (
        <>
          {isLoading ? (
            <Button
              type='primary'
              size='small'
              loading
              disabled
              style={{
                fontSize: '12px',
              }}>
              Generating...
            </Button>
          ) : (
            <>
              <Dropdown
                menu={{}}
                trigger={['click']}
                open={openDropdown}
                placement='bottomCenter'
                onOpenChange={setOpenDropdown}
                dropdownRender={(menu) => (
                  <div style={contentStyle}>
                    {/*Place for text */}
                    <div
                      style={{
                        fontSize: 18,
                        height: '100%',
                        display: 'flex',
                        padding: '20px',
                        flexDirection: 'column',
                        textAlign: 'left',
                        gap: 2,
                      }}>
                      Get inspired and generate posts tailored to you.
                      <span style={{ fontSize: 12, fontWeight: 400, color: 'grey' }}>
                        Generate a post inspired by the selected content to maximize engagement.
                      </span>
                    </div>
                    <Divider style={{ margin: 0 }} />
                    <div style={{ padding: '10px' }}>
                      <Input
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleGenerate()
                          }
                        }}
                        prefix={<img src={gray_ai_wand} alt='ai_wand' />}
                        suffix={
                          <img
                            className={styles.right_arrow}
                            style={!inputValue ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
                            src={right_gray_arrow}
                            alt='right_arrow'
                            onClick={() => {
                              handleGenerate()
                            }}
                          />
                        }
                        placeholder={`Insert AI instructions (e.g - don't mention competitors)`}
                        style={{ backgroundColor: '#f5f5f5', border: 'none', padding: '8px' }}
                        styles={{
                          input: {
                            backgroundColor: '#f5f5f5',
                            fontSize: 12,
                          },
                        }}
                      />
                    </div>
                  </div>
                )}>
                <Tooltip title={'Generate Post Out Of Seed'}>
                  <Button
                    type='primary'
                    size='small'
                    style={{
                      fontSize: '12px',
                    }}>
                    Generate Post
                  </Button>
                </Tooltip>
              </Dropdown>
            </>
          )}
        </>
      )}
      {didGenerate && generatedContent && (
        <>
          <Popover
            overlayStyle={{ maxWidth: '500px', zIndex: 1000000 }}
            content={
              <div
                style={{
                  whiteSpace: 'pre-wrap',
                  maxHeight: '300px',
                  overflowY: 'auto',
                }}>
                {generatedContent}
              </div>
            }
            placement='topLeft'>
            <span>{generatedContent}</span>
          </Popover>
          <CopyOutlined
            style={{
              marginLeft: 8,
              cursor: 'pointer',
              color: '#7043ff',
              position: 'absolute',
              right: 20,
              padding: '0px 6px',
              backgroundColor: 'white',
            }}
            onClick={(e) => {
              e.stopPropagation()
              navigator.clipboard.writeText(generatedContent)
            }}
          />
          <RedoOutlined
            style={{
              marginLeft: 8,
              cursor: 'pointer',
              color: '#7043ff',
              position: 'absolute',
              right: 0,
              padding: '0px 6px',
              backgroundColor: 'white',
            }}
            onClick={(e) => {
              e.stopPropagation()
              setDidGenerate(false)
              setGeneratedContent('')
            }}
          />
        </>
      )}
    </div>
  )
}
