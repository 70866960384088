import React, { useEffect, useState } from 'react'
import { Modal, Button, Popover, message } from 'antd'
import { ImportAndMapping } from 'common/components/Campaigns/CreateCampaign/ImportCSV/ImportAndMapping'
import { CsvPreview } from 'common/components/Campaigns/CreateCampaign/ImportCSV/CsvPreview'
import { TemplateGuildLine } from 'common/components/Campaigns/CreateCampaign/ImportCSV/TemplateGuildline'
import { reduce } from 'lodash'
import import_and_mapping_active from 'common/assets/svg/import_and_mapping_active.svg'
import styles from './ImportListFromCSV.module.scss'
import HeatboxView from 'common/components/Heatbox/Modals/HeatboxView'
import AmountBox from 'common/components/Heatbox/Modals/AmountBox'
import { useUser } from 'Utils/UserContext'
import {
  useLazyCreateNewListQuery,
  useLazyGetCurrentHeatboxQuery,
  useLazyGetHeatboxListsQuery,
  useLazyTrackOnHeatboxQuery,
  useLazyUntrackOnHeatboxQuery,
} from 'common/APIs/HeatboxApi'
import { RedoOutlined } from '@ant-design/icons'
import { trackEvent } from 'eventConfig'

const initialStateFieldMapping = {
  handle: '',
  first_name: '',
  last_name: '',
  company: '',
  heat_level: '',
}

interface ImportListFromCSVProps {
  open: boolean
  onClose: () => void
  //   onFinish: (csvData: any) => void
  isLoading?: boolean
  fileName?: string
  listName?: string
}

// Based on ImportLeadsFromCsvModal.tsx
const ImportListFromCSV: React.FC<ImportListFromCSVProps> = ({
  open,
  onClose,
  //   onFinish,
  isLoading,
  fileName = 'csv_template.csv',
  listName = '',
}) => {
  const { user } = useUser()
  const user_id = user?.id
  const addons = user?.addons
  const max_tracked_in_heatbox = addons?.max_tracked_in_heatbox || 0
  const [csvData, setCsvData] = useState<any>({})
  const [fieldMapping, setFieldMapping] = useState<any>(initialStateFieldMapping)
  const [disableButton, setDisableButton] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [getCurrentHeatbox, { data: currentHeatbox }] = useLazyGetCurrentHeatboxQuery()
  const [getHeatboxLists] = useLazyGetHeatboxListsQuery()
  const [isOverLimit, setIsOverLimit] = useState(false)
  const [resetPopoverVisible, setResetPopoverVisible] = useState(false)
  const [reset, setReset] = useState(0)
  const [done, setDone] = useState(false)
  const [loading, setLoading] = useState(false)
  const [untrackOnHeatbox] = useLazyUntrackOnHeatboxQuery()
  const [trackOnHeatbox] = useLazyTrackOnHeatboxQuery()
  const [createNewList] = useLazyCreateNewListQuery()

  const getHeatbox = async () => {
    await getCurrentHeatbox({ user_id: user?.id })
  }

  const formatFieldKey = reduce(
    csvData.fieldArray,
    (result: any, value, index) => {
      result[value?.trim()] = index
      return result
    },
    {},
  )

  const formatedDataAfterMapping = () => {
    if (!csvData?.rowData) return []

    const seenProfiles = new Set(currentHeatbox?.map((item: any) => item.profile_url))

    return csvData?.rowData?.reduce((acc: any[], row: any[]) => {
      const profileUrl = row[formatFieldKey[fieldMapping.handle]]

      if (!profileUrl) return acc

      if (!seenProfiles.has(profileUrl)) {
        seenProfiles.add(profileUrl)
        acc.push({
          key: profileUrl,
          profile_url: profileUrl,
          handle: profileUrl,
          first_name: row[formatFieldKey[fieldMapping.first_name]],
          last_name: row[formatFieldKey[fieldMapping.last_name]],
          company: row[formatFieldKey[fieldMapping.company]],
          heat_level: 'N/A',
        })
      }
      return acc
    }, [])
  }

  const extractUsername = (profileUrl: string): string => {
    // Extract username from LinkedIn profile URLs
    const match = profileUrl.match(/(?:https?:\/\/)?(?:www\.)?linkedin\.com\/(?:in|pub)\/([^/?]+)/i)

    return match ? match[1] : profileUrl
  }

  const handleFinish = async (added: any[], removed: any[]) => {
    setLoading(true)

    try {
      // 1. Create the new List
      await createNewList({
        user_id: user_id,
        list_name: listName,
      })

      // 2. Prepare track/untrack payloads
      const trackPayload = added.map((item: any) => ({
        lead_id: extractUsername(item.profile_url),
        user_id: user_id,
        list_id: listName,
        meta_data: JSON.stringify(item),
      }))

      const untrackPayload = removed.map((item: any) => ({
        lead_id: extractUsername(item.profile_url),
        user_id: user_id,
      }))

      // 3. Track / Untrack leads in parallel
      await Promise.all([trackOnHeatbox(trackPayload), untrackOnHeatbox(untrackPayload)])

      // 4. Refresh Heatbox and Heatbox Lists in parallel
      await Promise.all([getHeatbox(), getHeatboxLists({ user_id })])

      // 5. Track event
      trackEvent('IMPORT_CSV_TO_LIST', {
        list_name: listName,
        num_of_added_leads: added.length,
        num_of_removed_leads: removed.length,
        added_leads: added.map((item: any) => item.profile_url),
        removed_leads: removed.map((item: any) => item.profile_url),
      })

      // Success
      message.success('CSV Imported Successfully!')
      onClose()
    } catch (error) {
      console.error(error)
      message.error('Something went wrong during the import.')
    } finally {
      setLoading(false)
      setDone(false)
    }
  }

  const handleResetClick = () => {
    setReset((prev) => prev + 1)
  }

  const handleConfirmReset = () => {
    setResetPopoverVisible(false)
    handleResetClick()
  }

  const handleCancelReset = () => {
    setResetPopoverVisible(false)
  }

  const handleNext = () => {
    setCurrentStep(2)
    const leads = formatedDataAfterMapping()
    trackEvent('CSV_UPLOAD_FIRST_STEP', {
      list_name: listName,
      number_of_uploaded_leads: leads.length,
    })
  }

  useEffect(() => {
    getHeatbox()
  }, [])

  return (
    <Modal
      className={styles.modal}
      title={
        <div className={styles.title} style={{ padding: '10px 20px' }}>
          <div className={styles.left}>
            <span>Import Leads to Heatbox ({listName})</span>
          </div>
          {currentStep === 2 && (
            <div className={styles.right}>
              <AmountBox
                text='Currently On Heatbox'
                amount={currentHeatbox?.length}
                hoveredText='The total number of leads currently being tracked in your Heatbox'
              />
              <AmountBox
                text='Heatbox Limit'
                amount={max_tracked_in_heatbox}
                hoveredText='The maximum number of leads you can track in your Heatbox based on your current plan'
              />
              <AmountBox
                text='Left to Add'
                amount={max_tracked_in_heatbox - currentHeatbox?.length}
                hoveredText='The remaining number of leads you can add to your Heatbox before reaching your plan limit'
              />
            </div>
          )}
        </div>
      }
      centered
      open={open}
      onCancel={onClose}
      footer={
        <div className={styles.footer}>
          <div className={styles.footerLeft}></div>
          <div className={styles.footerRight}>
            {currentStep === 2 && (
              <Popover
                content={
                  <div className={styles.popoverContent}>
                    <p>Are you sure you want to reset all changes made?</p>
                    <div className={styles.actions}>
                      <span className={styles.cancel} onClick={handleCancelReset}>
                        Cancel
                      </span>
                      <span className={styles.confirm} onClick={handleConfirmReset}>
                        Confirm
                      </span>
                    </div>
                  </div>
                }
                trigger='click'
                open={resetPopoverVisible && !loading}
                onOpenChange={setResetPopoverVisible}>
                <span
                  className={styles.reset}
                  onClick={() => {
                    if (!loading) {
                      setResetPopoverVisible(true)
                    }
                  }}>
                  Reset
                  <RedoOutlined style={{ marginLeft: '3px' }} />
                </span>
              </Popover>
            )}
            {currentStep === 1 ? (
              <Button type='primary' onClick={handleNext} disabled={disableButton}>
                Next
              </Button>
            ) : (
              <Button
                loading={loading}
                onClick={() => {
                  setDone(true)
                }}
                disabled={disableButton || isOverLimit}
                type='primary'>
                Done
              </Button>
            )}
          </div>
        </div>
      }
      width={'calc(95% - 100px)'}>
      <div className={styles.modalContentWrapper} style={{ padding: '0px 20px' }}>
        {currentStep === 1 ? (
          <div>
            <div className={styles['import-page__body']}>
              <div className={styles['import-page__body__left']}>
                <div className={styles['import-page__header']}>
                  <div className={styles['import-page__header__left']}>
                    <img src={import_and_mapping_active} alt='' />
                    <span style={{ fontSize: '15px' }}>Import And Mapping</span>
                  </div>
                </div>
                <ImportAndMapping
                  initialState={initialStateFieldMapping}
                  fieldMapping={fieldMapping}
                  setFieldMapping={setFieldMapping}
                  onFinish={() => {}}
                  csvData={csvData}
                  setCsvData={setCsvData}
                  isLoading={isLoading}
                  setDisableButton={setDisableButton}
                />
              </div>
              <div className={styles['import-page__body__right']}>
                {csvData?.file ? (
                  <CsvPreview fieldMapping={fieldMapping} csvData={csvData} />
                ) : (
                  <TemplateGuildLine fileName={fileName} />
                )}
              </div>
            </div>
          </div>
        ) : (
          <HeatboxView
            csvData={formatedDataAfterMapping()}
            existingData={currentHeatbox}
            maxAdd={max_tracked_in_heatbox}
            onLimitChange={setIsOverLimit}
            reset={reset}
            onFinish={handleFinish}
            done={done}
          />
        )}
      </div>
    </Modal>
  )
}

export default ImportListFromCSV
