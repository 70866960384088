import React, { useState } from 'react'
import { Spin, message } from 'antd'
import { motion } from 'framer-motion'
import start_sequence_icon from 'common/assets/svg/start_sequence_icon.svg'
import crown from 'common/assets/svg/premium_crown.svg'
import { useLazyCreateSampleCampaignQuery } from 'common/APIs/HeatboxApi'
import styles from '../Seeds.module.scss'
import { LeadDataType, SeedDataType } from 'common/components/Heatbox/Seeds'

interface StartSequenceCellProps {
  lead_source: string
  data: LeadDataType
  addons: any
  userId: string
  seedsData: SeedDataType[]
}

const StartSequenceCell: React.FC<StartSequenceCellProps> = ({ lead_source, data, addons, userId, seedsData }) => {
  const [loading, setLoading] = useState(false)
  const [createSampleCampaign] = useLazyCreateSampleCampaignQuery()

  const generate_campaign_from_seeds = addons?.generate_campaign_from_seeds === true
  const platforms = Array.from(
    new Set(seedsData.filter((seed) => seed.lead_id === data.id).map((seed) => seed.platform.toLowerCase())),
  )
  const hasLinkedin = platforms.includes('linkedin')

  const handleStartSequenceClick = async () => {
    if (generate_campaign_from_seeds && hasLinkedin) {
      setLoading(true)
      try {
        const campaignData = await createSampleCampaign({
          user_id: userId,
          lead_id: data.id,
        }).unwrap()

        if (campaignData.success) {
          message.success('Campaign created successfully')
        } else {
          message.error('Failed to create campaign')
        }
      } catch (error) {
        message.error('Failed to create campaign')
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <div className={styles.startSequence}>
      <motion.div className={styles.startSequenceWrapper} whileHover={{ scale: 1.1 }} transition={{ duration: 0.3 }}>
        {loading ? (
          <div style={{ margin: '7px 0px' }}>
            <Spin size='small' />
          </div>
        ) : (
          <img src={start_sequence_icon} alt='' onClick={handleStartSequenceClick} />
        )}
        {(!generate_campaign_from_seeds || !hasLinkedin) && (
          <img
            src={crown}
            alt='premium'
            style={{ position: 'absolute', width: '18px', height: '18px', right: '-6px', top: '-6px' }}
          />
        )}
      </motion.div>
    </div>
  )
}

export default StartSequenceCell
