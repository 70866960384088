import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Divider } from 'antd'
import styles from './SuggestionsList.module.scss'

export interface SuggestionOption {
  text: string
  numberOfBoldWords: number
  action?: string
}

interface SuggestionsListProps {
  options: SuggestionOption[]
  visible: boolean
  onSelectSuggestion?: (option: SuggestionOption, index: number) => void
  left?: string
  bottom?: number
  color?: string
  boldColor?: string
}

const SuggestionsList: React.FC<SuggestionsListProps> = ({
  options,
  visible,
  onSelectSuggestion,
  bottom = 53,
  left = '15%',
  color = '#9043ff',
  boldColor = 'black',
}) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  // Container variants – controls the overall list animation.
  const listVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        when: 'beforeChildren',
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.3,
        when: 'afterChildren',
      },
    },
  }

  // Item variants – controls each suggestion’s animation.
  const optionVariants = {
    hidden: {
      opacity: 0,
      x: -20,
      transition: {
        duration: 0.3,
      },
    },
    visible: (index: number) => ({
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        delay: index * 0.2, // Each item appears 0.2s after the previous one.
      },
    }),
    exit: {
      opacity: 0,
      x: -20,
      transition: {
        duration: 0.3,
      },
    },
    active: {
      scale: 1.1,
      filter: 'blur(0px)',
      transition: { duration: 0.2 },
    },
    inactive: {
      scale: 1,
      filter: 'blur(2px)',
      transition: { duration: 0.2 },
    },
    rest: {
      scale: 1,
      filter: 'blur(0px)',
      transition: { duration: 0.2 },
    },
  }

  // Calculate a bottom offset based on the number of options.
  const bottomOffset = `-${options.length * bottom}px`

  return (
    <AnimatePresence>
      {visible && (
        <motion.ul
          className={styles.suggestionsList}
          style={{ bottom: bottomOffset, left: left }}
          variants={listVariants}
          initial='hidden'
          animate='visible'
          exit='exit'>
          {options.map((option, index) => {
            const words = option.text.split(' ')
            const boldWords = words.slice(0, option.numberOfBoldWords).join(' ')
            const normalWords = words.slice(option.numberOfBoldWords).join(' ')

            const variant = hoveredIndex === null ? 'rest' : hoveredIndex === index ? 'active' : 'inactive'

            return (
              <div key={index} style={{ width: 'max-content' }}>
                <motion.li
                  custom={index}
                  variants={optionVariants}
                  initial='hidden'
                  animate={[variant, 'visible']}
                  exit='exit'
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  onMouseDown={() => onSelectSuggestion && onSelectSuggestion(option, index)}
                  style={{ cursor: 'pointer' }}>
                  <span className={styles.bold} style={{ color: boldColor }}>
                    {boldWords}{' '}
                  </span>
                  <span style={{ color }}>{normalWords}</span>
                </motion.li>
                {index < options.length - 1 && <Divider style={{ margin: 0 }} />}
              </div>
            )
          })}
        </motion.ul>
      )}
    </AnimatePresence>
  )
}

export default SuggestionsList
