import { useLazyGetExistingSocialMetadataQuery, useLazyGetSocialMetadataQuery } from 'common/APIs/SettingsApi'
import { SET_INTERCOM_OPEN } from 'common/components/GeneralComponents/LearningCenter/state/slice/learningSlice'
import { SET_FORCE_PRICING, SET_OUT_OF_OUTREACH } from 'GeneralSlice'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { setIsSubscriptionDaysPassed } from 'state/filtersSlice/filtersSlice'
import { Userpilot } from 'userpilot'

export const useIntercomSetup = (userDetails: any, boot: Function, shutdown: Function, logout: Function) => {
  const bootIntercom = () =>
    boot({
      user_id: userDetails?.id,
      name: userDetails?.name,
      email: userDetails?.email,
    })

  const handleError = () => {
    if (process.env.NODE_ENV === 'development') return
    shutdown()
    logout()
    window.location.reload()
  }

  return { bootIntercom, handleError }
}

export const useRouteChecks = () => {
  const location = useLocation()
  const path = location.pathname

  const isExcludedPath = (excludedPaths: string[]) =>
    excludedPaths.some((excludedPath) => path === excludedPath || path.startsWith(excludedPath))

  const showSidebar = !isExcludedPath([
    '/onboarding',
    '/login',
    '/cs-login',
    '/signup',
    '/register',
    '/reset-password',
    '/premium-signup/',
  ])

  const showTopBar = !isExcludedPath([
    '/login',
    '/tasks',
    '/home',
    '/cs-login',
    '/signup',
    '/register',
    '/reset-password',
    '/premium-signup/',
    '/settings',
    '/unifiedInbox',
    '/upgrade',
    '/checkout',
    '/checkout/success',
    '/outreach',
    '/exposedContacts',
    '/onboarding',
    '/companies',
    '/contacts',
    '/cues',
  ])

  const notForLearning = [
    'tasks',
    'home',
    'unifiedInbox',
    'login',
    'signup',
    'reset-password',
    'premium-signup',
    'signupAffiliate',
  ]

  const showWeb3TopBar = path.startsWith('/companies') || path.startsWith('/contacts')

  return { showSidebar, showTopBar, showWeb3TopBar, notForLearning }
}

export const useSafariCheck = () => {
  const [isSafari, setIsSafari] = useState(false)

  useEffect(() => {
    const userAgentString = navigator.userAgent
    const chromeAgent = userAgentString.indexOf('Chrome') > -1
    const safariAgent = userAgentString.indexOf('Safari') > -1 && !chromeAgent
    setIsSafari(safariAgent)
  }, [])

  return isSafari
}

export const useSubscriptionStatus = (userDetails: any) => {
  const currentDate = new Date()
  const createdSubscriptionDate = new Date(userDetails?.subscription_created_at)
  const subscriptionDays = userDetails?.subscription_days
  const timeDifference = currentDate?.getTime() - createdSubscriptionDate?.getTime()
  const daysPassed = timeDifference / (1000 * 60 * 60 * 24)

  const navToCheckout =
    !userDetails?.flag && userDetails?.subscription_type === 'BETA' && Math.trunc(subscriptionDays - daysPassed) <= 0

  return { navToCheckout, daysPassed }
}

export const useScreenSizeCheck = () => {
  const [isResolutionBad, setIsResolutionBad] = useState(false)

  useEffect(() => {
    const checkScreenSize = () => {
      setIsResolutionBad((window.innerWidth >= 1366 && window.innerWidth < 1600) || window.innerHeight < 768)
    }

    checkScreenSize() // Initial check

    window.addEventListener('resize', checkScreenSize)
    return () => window.removeEventListener('resize', checkScreenSize) // Cleanup
  }, [])

  return isResolutionBad
}

interface UseRedirectsProps {
  user: any
  token: string
  setStep: (step: number) => void
  localUser?: any
  pricing?: boolean
  requires_onboarding?: boolean
  onboarding_status?: boolean
  navToCheckout?: boolean
  userDetails?: any
  setLocalUserMetadata: (metadata: any) => void
  bootIntercom: () => void
}

export const useRedirects = ({
  user,
  token,
  setStep,
  localUser,
  pricing,
  requires_onboarding,
  onboarding_status,
  navToCheckout,
  userDetails,
  setLocalUserMetadata,
  bootIntercom,
}: UseRedirectsProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [populateSocialMetadata] = useLazyGetSocialMetadataQuery()
  const [getExistingSocialMetadata] = useLazyGetExistingSocialMetadataQuery()

  useEffect(() => {
    const href = window.location.href

    if (!user) {
      const path = window.location.pathname.split('/')[1]
      switch (path) {
        case 'signup': {
          const regex = /^\/signup\/[^/]+/
          if (regex.test(location.pathname)) {
            const updatedPath = location.pathname.replace('/signup', '/register')
            return navigate(`${updatedPath}${location.search}`, { replace: true })
          }
          navigate('/signup')
          return
        }
        case 'premium-signup':
        case 'reset-password':
          return
        case 'login':
          navigate('/login')
          return
        case 'cs-login':
          navigate('/cs-login')
          return
        case 'register?signedup-success=true':
          navigate('/register?signedup-success=true', { replace: true })
          return
        case 'register':
          if (!href.includes('/register/') || href.split('/').length <= 2) {
            navigate('/login')
          } else if (token) {
            setStep(1)
          }
          return
        default:
          navigate('/login')
          return
      }
    } else if (localUser?.outside_onboarding?.step < 6) {
      const linkId = localUser?.outside_onboarding?.linkId
      if (!href.includes('/register')) {
        navigate(`/register/${linkId}`)
      }
      return
    } else if (!pricing && window.location.pathname === '/upgrade') {
      navigate('/settings')
    } else if (localUser?.force_pricing) {
      if (!href.includes('/upgrade/success?session_id')) {
        navigate('/upgrade')
      }
    } else if (requires_onboarding && !onboarding_status) {
      if (!href.includes('/upgrade/success?session_id')) {
        if (localUser?.force_pricing && !href.includes('/upgrade')) {
          navigate('/upgrade')
        } else {
          navigate('/onboarding')
        }
      }
    } else if (window.location.pathname === '/onboarding' && (!requires_onboarding || onboarding_status)) {
      if (localUser?.progress?.getting_started?.completed) {
        navigate('/outreach')
      } else {
        navigate('/getting-started')
      }
    } else if (navToCheckout) {
      if (window.location.pathname !== '/checkout') {
        navigate('/checkout')
      }
    } else if (user) {
      Userpilot.identify(userDetails?.id, {
        name: userDetails?.name,
        email: userDetails?.email,
        created_at: userDetails?.subscription_created_at,
      })
      bootIntercom()
    }

    const fetchMetadata = async () => {
      try {
        populateSocialMetadata({ platform: 'all' })
        const { data: metadata } = await getExistingSocialMetadata()
        setLocalUserMetadata(metadata)
      } catch (error) {
        console.error(error)
      }
    }

    fetchMetadata()
  }, [user])
}

interface UseHandleLocalUserProps {
  localUser: any
  userData: any
  navToCheckout: boolean
  setStep: (step: number) => void
  setForcePricing: (value: boolean) => void
  setFacebookUsersWithMissingPin: (value: any[]) => void
  setOutOfOutreach: (value: boolean) => void
}

export const useHandleLocalUser = ({
  localUser,
  userData,
  navToCheckout,
  setStep,
  setForcePricing,
  setFacebookUsersWithMissingPin,
  setOutOfOutreach,
}: UseHandleLocalUserProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (localUser?.outside_onboarding?.step >= 0) {
      setStep(localUser?.outside_onboarding?.step)
    } else if (localUser?.force_pricing) {
      dispatch(SET_FORCE_PRICING(true))
      dispatch(SET_INTERCOM_OPEN(true))
      setForcePricing(true)
      setFacebookUsersWithMissingPin([])
      setOutOfOutreach(false)
      dispatch(SET_OUT_OF_OUTREACH(false))

      if (window.location.pathname !== '/upgrade') {
        const href = window.location.href
        if (!href.includes('/upgrade/success?session_id')) {
          navigate('/upgrade')
        }
      }
    } else {
      dispatch(SET_FORCE_PRICING(false))
      dispatch(SET_INTERCOM_OPEN(false))
      dispatch(setIsSubscriptionDaysPassed(navToCheckout))
      setForcePricing(false)

      const facebookAccounts = localUser?.facebook || []
      const accWithMissingPin = facebookAccounts.filter((acc: any) => !acc.is_pincode_success)

      if (accWithMissingPin.length > 0) {
        setFacebookUsersWithMissingPin(accWithMissingPin)
      } else {
        setFacebookUsersWithMissingPin([])
      }

      const outreachCredits = localUser?.credits?.contacts_outreached_left
      if (!outreachCredits || outreachCredits <= 0) {
        setOutOfOutreach(true)
        dispatch(SET_OUT_OF_OUTREACH(true))
      } else {
        setOutOfOutreach(false)
        dispatch(SET_OUT_OF_OUTREACH(false))
      }
    }
  }, [localUser, userData])
}

export const useReloadOnTokenChange = () => {
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'access_token') {
        window.location.reload()
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])
}
