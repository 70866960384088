import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'common/APIs/baseQueryWithValidation'

export const ConvrtApi: any = createApi({
  reducerPath: 'ConvrtApi',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    generateAPIKey: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_CP_API_URL}/api/v1/keys`,
        method: 'POST',
        body: params,
      }),
    }),
    getAPIKeys: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_CP_API_URL}/api/v1/keys`,
        method: 'GET',
      }),
    }),
    deleteAPIKey: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_CP_API_URL}/api/v1/keys/${params.key}`,
        method: 'DELETE',
      }),
    }),
    webhookSubscribe: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_CP_API_URL}/webhooks/subscribe`,
        method: 'POST',
        body: params,
      }),
    }),
    webhookUnsubscribe: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_CP_API_URL}/webhooks/unsubscribe`,
        method: 'POST',
        body: params,
      }),
    }),
    listWebhooks: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_CP_API_URL}/webhooks/list`,
        method: 'GET',
      }),
    }),
    testWebhook: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_CP_API_URL}/webhooks/test`,
        method: 'POST',
        body: params,
      }),
    }),
    getWebhookExample: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_CP_API_URL}/webhooks/example`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useLazyGenerateAPIKeyQuery,
  useLazyGetAPIKeysQuery,
  useLazyDeleteAPIKeyQuery,
  useLazyWebhookSubscribeQuery,
  useLazyWebhookUnsubscribeQuery,
  useLazyListWebhooksQuery,
  useLazyTestWebhookQuery,
  useLazyGetWebhookExampleQuery,
} = ConvrtApi
