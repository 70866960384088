import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'common/APIs/baseQueryWithValidation'

export const OutreachAIApi: any = createApi({
  reducerPath: 'OutreachAIApi',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    createCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/createCampaign`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
          messages_steps: params.messages_steps,
          prompt_params: params.prompt_params,
          platforms: params.platforms,
          steps: params.steps,
          summarytSettings: params.summarytSettings,
          generateMessages: params.generateMessages,
          custom_flow_id: params.custom_flow_id,
        },
      }),
    }),
    updateCampaignSenders: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/updateCampaignSenders`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          senders: params.senders,
          platform: params.platform,
        },
      }),
    }),
    updateCampaignAi: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/updateCampaignAI`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          messages_steps: params.messages_steps,
          campaign_name: params.campaign_name,
          steps: params.steps,
          custom_flow_id: params.custom_flow_id,
        },
      }),
    }),
    createCampaignActions: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/createCampaignActions`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          campaign_settings: params.campaign_settings,
          platforms: params.platforms,
          stepsToCheck: params.stepsToCheck,
        },
      }),
    }),
    runCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/runCampaign`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
        },
      }),
    }),
    updateCampaignData: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/updateCampaignData`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          currentSettings: params.currentSettings,
          custom_flow_id: params.custom_flow_id,
        },
      }),
    }),
    getCampaignStats: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getCampaignStats?campaign_id=${encodeURIComponent(
          params.campaign_id,
        )}`,
        method: 'GET',
      }),
    }),
    removeTempLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/removeTempLeads`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          indexes: params.indexes,
        },
      }),
    }),

    getTempLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getTempLeads?campaign_id=${encodeURIComponent(
          params.campaign_id,
        )}`,
        method: 'GET',
      }),
    }),

    /* ---------- AI ------- */
    assistWithAI: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/assistWithAI`,
        method: 'POST',
        body: {
          message: params.message,
          task: params.task,
        },
      }),
    }),

    /* ---------- AI ------- */
    getMessageSuggestionsAI: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getMessageSuggestionsAI`,
        method: 'POST',
        body: params,
      }),
    }),
    updateAiCampaignName: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/updateCampaignName`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
        },
      }),
    }),
    getGroups: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getGroups`,
        method: 'POST',
        body: {
          refinement: params.refinement,
        },
      }),
    }),
    getUserSpecificGroups: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getUserSpecificGroups`,
        method: 'POST',
        body: params,
      }),
    }),
    setSelectedGroups: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/setSelectedGroups`,
        method: 'POST',
        body: params,
      }),
    }),
    getGroupDetails: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getGroupDetails`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          group: params.group,
          showNewLeads: params.showNewLeads,
        },
      }),
    }),
    importGroup: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/importGroup`,
        method: 'POST',
        body: {
          platform: params.platform,
          sourceType: params.sourceType,
          url: params.url,
          numberOfLeads: params.numberOfLeads,
          searchName: params.searchName,
          account: params.account,
        },
      }),
    }),
    removeGroup: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/removeGroup`,
        method: 'POST',
        body: {
          platform: params.platform,
          group_id: params.group_id,
        },
      }),
    }),
    refetchGroup: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/refetchGroup`,
        method: 'POST',
        body: {
          platform: params.platform,
          group_id: params.group_id,
        },
      }),
    }),
    setGroupTrackMode: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/setGroupTrackMode`,
        method: 'POST',
        body: {
          platform: params.platform,
          group_id: params.group_id,
          trackMode: params.trackMode,
        },
      }),
    }),
    addLeadsFromGroups: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/addLeadsFromGroups`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
          leads: params.leads,
          sent_from: params.sent_from,
          ignoreLeadsFromOtherCampaigns: params.ignoreLeadsFromOtherCampaigns,
          ignoreOutreachedLeads: params.ignoreOutreachedLeads,
          ignoreOutreachedLeadsFromTeam: params.ignoreOutreachedLeadsFromTeam,
          ignoreLeadsFromOtherTeamMembers: params.ignoreLeadsFromOtherTeamMembers,
          useRemainingPoints: params.useRemainingPoints,
        },
      }),
    }),
    addCampaignAILeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/addCampaignAILeads`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
          companyLeads: params.companyLeads,
          AILeads: params.AILeads,
          CSVLeads: params.CSVLeads,
          sent_from: params.sent_from,
          ignoreLeadsFromOtherCampaigns: params.ignoreLeadsFromOtherCampaigns,
          ignoreOutreachedLeads: params.ignoreOutreachedLeads,
          useRemainingPoints: params.useRemainingPoints,
        },
      }),
    }),
    addLeadsFromLists: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/addLeadsFromLists`,
        method: 'POST',
        body: params,
      }),
    }),
    getMyTemplates: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getMyTemplates`,
        method: 'GET',
      }),
    }),
    saveCustomTemplate: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/saveCustomTemplate`,
        method: 'POST',
        body: {
          id: params.id,
          name: params.name,
          description: params.description,
          template: params.template,
          platforms: params.platforms,
          is_visible: params.is_visible,
        },
      }),
    }),
    saveTemplateToMarket: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/saveTemplateToMarket`,
        method: 'POST',
        body: {
          id: params.id,
          name: params.name,
          description: params.description,
          template: params.template,
          platforms: params.platforms,
          tags: params.tags,
        },
      }),
    }),
    collectLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/collectLeads`,
        method: 'POST',
        body: params,
      }),
    }),
    collectWeb3Leads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/collectWeb3Leads`,
        method: 'POST',
        body: params,
      }),
    }),
    getSavedFilters: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getSavedFilters`,
        method: 'GET',
      }),
    }),
    saveFilter: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/saveFilter`,
        method: 'POST',
        body: {
          name: params.name,
          filter: params.filter,
        },
      }),
    }),
    deleteFilter: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/deleteFilter`,
        method: 'POST',
        body: {
          id: params.id,
        },
      }),
    }),
    fillSuggestions: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/fillSuggestions`,
        method: 'POST',
      }),
    }),
    getExplore: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getExplore`,
        method: 'GET',
      }),
    }),
    getMyHub: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getMyHub`,
        method: 'GET',
      }),
    }),
    getExploreLoading: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getExploreLoading`,
        method: 'GET',
      }),
    }),
    fillSuggestionsOnboarding: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/fillSuggestionsOnboarding`,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useLazyFillSuggestionsOnboardingQuery,
  useLazyGetExploreLoadingQuery,
  useLazyGetMyHubQuery,
  useLazyGetExploreQuery,
  useLazyFillSuggestionsQuery,
  useLazyDeleteFilterQuery,
  useLazySaveFilterQuery,
  useLazyCreateCampaignQuery,
  useLazyUpdateCampaignSendersQuery,
  useLazyUpdateCampaignAiQuery,
  useLazyCreateCampaignActionsQuery,
  useLazyRunCampaignQuery,
  useLazyUpdateCampaignDataQuery,
  useLazyGetCampaignStatsQuery,
  useLazyRemoveTempLeadsQuery,
  useLazyGetTempLeadsQuery,
  useLazyAssistWithAIQuery,
  useLazyGetMessageSuggestionsAIQuery,
  useLazyUpdateAiCampaignNameQuery,
  // useLazyGetGroupsQuery,
  // useLazyGetUserSpecificGroupsQuery,
  // useLazySetSelectedGroupsQuery,
  useLazyGetGroupDetailsQuery,
  useLazyImportGroupQuery,
  // useLazyRemoveGroupQuery,
  // useLazyRefetchGroupQuery,
  useLazySetGroupTrackModeQuery,
  useLazyAddLeadsFromGroupsQuery,
  useLazyAddLeadsFromListsQuery,
  useLazyAddCampaignAILeadsQuery,
  useLazyGetMyTemplatesQuery,
  useLazySaveCustomTemplateQuery,
  useLazySaveTemplateToMarketQuery,
  useLazyCollectLeadsQuery,
  useLazyCollectWeb3LeadsQuery,
  useLazyGetSavedFiltersQuery,
} = OutreachAIApi
