import React, { useState, useEffect } from 'react'
import { Table, Button, Typography, Popconfirm, Empty, Tooltip, Modal, message, Select } from 'antd'
import { ExportOutlined } from '@ant-design/icons'
import trash_icon_gray from 'common/assets/svg/trash_icon_gray.svg'
import styles from './WebhooksManagement.module.scss'
import {
  useLazyWebhookSubscribeQuery,
  useLazyWebhookUnsubscribeQuery,
  useLazyListWebhooksQuery,
  useLazyTestWebhookQuery,
  useLazyGetWebhookExampleQuery,
} from 'common/APIs/ConvrtApi'
import WebhookModal from 'common/components/Settings/Pages/InternalManagements/WebhookModal'
import ReactJson from 'react-json-view'

const { Title } = Typography
const { Option } = Select

interface Webhook {
  id: string
  url: string
  event_types: string[]
  logs?: string
}

const WebhooksManagement: React.FC = () => {
  const [webhooks, setWebhooks] = useState<Webhook[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  // merged modal state used for both test and format actions
  const [mergedModal, setMergedModal] = useState<{
    visible: boolean
    action: 'test' | 'format' | null
    url: string
    eventTypes: string[]
    selectedEventType: string
    formatData?: any
  }>({
    visible: false,
    action: null,
    url: '',
    eventTypes: [],
    selectedEventType: '',
    formatData: undefined,
  })
  const [subscribeWebhook] = useLazyWebhookSubscribeQuery()
  const [listWebhooks] = useLazyListWebhooksQuery()
  const [unsubscribeWebhook] = useLazyWebhookUnsubscribeQuery()
  const [testWebhook] = useLazyTestWebhookQuery()
  const [getWebhookExample, { data: webhookExample }] = useLazyGetWebhookExampleQuery()
  const [loadingTest, setLoadingTest] = useState(false)

  const getWebhooks = async () => {
    setLoading(true)
    const { data } = await listWebhooks()
    setWebhooks(data || [])
    setLoading(false)
  }

  useEffect(() => {
    getWebhooks()
  }, [])

  const handleDeleteWebhook = async (record: Webhook) => {
    const { data } = await unsubscribeWebhook({ event_types: record.event_types, url: record.url })
    if (data) {
      getWebhooks()
      message.success('Webhook deleted successfully')
    } else {
      message.error('An error has occurred deleting this webhook. Please try again or contact support.')
    }
  }

  const handleAddWebhook = () => setIsModalVisible(true)
  const handleModalCancel = () => setIsModalVisible(false)

  const subscribeNewWebhook = async (url: string, events: string[]): Promise<void> => {
    try {
      const { data, error } = await subscribeWebhook({ url, event_types: events })
      if (error || !data) {
        message.error('Failed to subscribe to webhook')
        throw new Error('Webhook subscription failed')
      }
      getWebhooks()
      message.success('Webhook subscribed successfully')
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const sendTestPayload = async (url: string, event_type: string, payload: any) => {
    try {
      setLoadingTest(true)
      const { data } = await testWebhook({ url, event_type, payload })
      setLoadingTest(false)
      if (!data) {
        return message.error('Failed to send test webhook')
      }

      message.success('Test webhook sent successfully!')
    } catch (error) {
      message.error('Failed to send test webhook')
      console.error(error)
    }
  }

  // Open the unified modal and set the default selected event type to the first option.
  const openMergedModal = (action: 'test' | 'format', url: string, eventTypesArr: string[]) => {
    const defaultEventType = eventTypesArr[0]
    setMergedModal({
      visible: true,
      action,
      url,
      eventTypes: eventTypesArr,
      selectedEventType: defaultEventType,
      formatData:
        action === 'format'
          ? webhookExample?.find((webhook: any) => webhook?.event_type === defaultEventType)?.format
          : undefined,
    })
  }

  const handleFormatClick = (eventTypesArray: string[]) => {
    openMergedModal('format', '', eventTypesArray)
  }

  const handleTestWebhook = async (url: string, eventTypesArray: string[]) => {
    if (eventTypesArray.length === 1) {
      const testPayload = webhookExample?.find(
        (webhook: any) => webhook?.event_type === eventTypesArray[0],
      )?.example_payload
      await sendTestPayload(url, eventTypesArray[0], testPayload)
    } else {
      openMergedModal('test', url, eventTypesArray)
    }
  }

  const columns = [
    {
      title: 'Webhook URL',
      dataIndex: 'url',
      key: 'url',
      sorter: (a: Webhook, b: Webhook) => a.url.localeCompare(b.url),
    },
    {
      title: 'Event Types',
      dataIndex: 'event_types',
      key: 'event_types',
      sorter: (a: Webhook, b: Webhook) => {
        const aEvents = Array.isArray(a.event_types) ? a.event_types.join(', ') : a.event_types
        const bEvents = Array.isArray(b.event_types) ? b.event_types.join(', ') : b.event_types
        return aEvents.localeCompare(bEvents)
      },
      render: (event_types: any) => (Array.isArray(event_types) ? event_types.join(', ') : event_types),
    },
    {
      title: 'Format',
      dataIndex: 'format',
      key: 'format',
      render: (_: any, record: Webhook) => (
        <Button
          type='default'
          onClick={() =>
            handleFormatClick(Array.isArray(record.event_types) ? record.event_types : [record.event_types])
          }>
          Format
        </Button>
      ),
    },
    {
      title: 'Logs',
      dataIndex: 'logs',
      key: 'logs',
      render: (logs: string) => logs || 'No logs available',
    },
    {
      title: 'Test',
      dataIndex: 'test',
      key: 'test',
      render: (_: any, record: Webhook) => (
        <Button
          type='default'
          onClick={() =>
            handleTestWebhook(record.url, Array.isArray(record.event_types) ? record.event_types : [record.event_types])
          }>
          Test
        </Button>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_: any, record: Webhook) => (
        <Tooltip title='Delete'>
          <Popconfirm
            title='Are you sure you want to delete this webhook?'
            onConfirm={() => handleDeleteWebhook(record)}
            okText='Yes'
            cancelText='No'>
            <Button type='text' icon={<img src={trash_icon_gray} alt='' />} className={styles.deleteButton} />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ]

  const getExample = async () => {
    await getWebhookExample()
  }

  useEffect(() => {
    getExample()
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <Title level={4} className={styles.title}>
            Webhooks
          </Title>
        </div>
        <div className={styles.actions}>
          <a
            href='https://docs.example.com/webhooks'
            target='_blank'
            rel='noopener noreferrer'
            className={styles.docsLink}>
            Webhooks Docs <ExportOutlined />
          </a>
          <Button type='primary' onClick={handleAddWebhook} className={styles.generateButton}>
            Add Webhook
          </Button>
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={webhooks}
        loading={loading}
        rowKey='id'
        pagination={false}
        className={styles.table}
        scroll={{ y: '60vh' }}
        locale={{
          emptyText: <Empty description='No webhooks found' image={Empty.PRESENTED_IMAGE_SIMPLE} />,
        }}
      />

      <WebhookModal isVisible={isModalVisible} onCancel={handleModalCancel} onSubscribe={subscribeNewWebhook} />

      {/* Merged Modal */}
      <Modal
        title={mergedModal.action === 'format' ? 'Event Format Example' : 'Test Webhook'}
        open={mergedModal.visible}
        onCancel={() => setMergedModal({ ...mergedModal, visible: false })}
        footer={
          mergedModal.action === 'test' && [
            <Button key='cancel' onClick={() => setMergedModal({ ...mergedModal, visible: false })}>
              Cancel
            </Button>,
            <Button
              key='send'
              type='primary'
              loading={loadingTest}
              onClick={async () => {
                const payload = webhookExample?.find(
                  (webhook: any) => webhook?.event_type === mergedModal.selectedEventType,
                )?.example_payload
                await sendTestPayload(mergedModal.url, mergedModal.selectedEventType, payload)
                setMergedModal({ ...mergedModal, visible: false })
              }}>
              Send Test
            </Button>,
          ]
        }
        centered>
        <Select
          style={{ width: '100%', marginBottom: '16px' }}
          value={mergedModal.selectedEventType}
          onChange={(value: string) =>
            setMergedModal((prev) => ({
              ...prev,
              selectedEventType: value,
              formatData:
                mergedModal.action === 'format'
                  ? webhookExample?.find((webhook: any) => webhook?.event_type === value)?.format
                  : undefined,
            }))
          }>
          {mergedModal.eventTypes.map((et: string) => (
            <Option key={et} value={et}>
              {et}
            </Option>
          ))}
        </Select>
        {mergedModal.action === 'format' && mergedModal.formatData && (
          <ReactJson name={false} src={mergedModal.formatData} collapsed={false} displayDataTypes={false} />
        )}
      </Modal>
    </div>
  )
}

export default WebhooksManagement
