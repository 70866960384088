import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Button, Divider, Input } from 'antd'
import styles from './RightPanelCues.module.scss'
import { Card } from '../CardStack'
import GaugeMeter from 'common/components/Heatbox/CustomCard/GaugeMeter'
import send_arrow_icon from 'common/assets/svg/send_arrow_icon.svg'
import ai_message_icon from 'common/assets/svg/ai_message_icon.svg'
import eng_metric_1 from 'common/assets/svg/eng_metric_1.svg'
import eng_metric_2 from 'common/assets/svg/eng_metric_2.svg'
import eng_metric_3 from 'common/assets/svg/eng_metric_3.svg'
import { SET_AI_COMMENT, SET_OPEN_AI_COMMENT } from 'common/components/Heatbox/Utils/HeatboxSlice'
import { useAppDispatch } from 'state'
import { formatNumber } from 'common/components/Campaigns/Helpers/GroupHelpers'
import white_rocket_icon from 'common/assets/svg/white_rocket_icon.svg'
import white_fire_icon from 'common/assets/svg/white_fire_icon.svg'
import cold_icon from 'common/assets/svg/cold_icon.svg'
import warming_up_icon from 'common/assets/svg/warming_up_icon.svg'
import { removeEmojis } from 'common/components/Heatbox/Utils/CardUtils'
import { useLazyGenerateCommentQuery } from 'common/APIs/HeatboxApi'
import { useUser } from 'Utils/UserContext'

function round50(x: number) {
  return Math.ceil(x / 50) * 50
}

interface RightPanelCuesProps {
  loading?: boolean
  card?: Card
}

const RightPanelCues: React.FC<RightPanelCuesProps> = ({ loading, card }) => {
  const dispatch = useAppDispatch()
  const { user } = useUser()
  const [generateComment] = useLazyGenerateCommentQuery()
  const [isExpanded, setIsExpanded] = useState(false)
  const [aiGenerated, setAiGenerated] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [generatedComment, setGeneratedComment] = useState('')
  const [dotCount, setDotCount] = useState(1)

  //   const isReddit = card?.channel === 'reddit'
  const content = card?.content
  const upvote = content?.reactions || 0
  const comments = content?.comments || 0

  // Parse analysis data
  const analysis = typeof card?.analysis === 'string' ? JSON.parse(card?.analysis || '{}') : card?.analysis
  const competitors_mentioned = analysis?.competitors_mentioned || []
  const discussion_tone =
    typeof analysis?.discussion_tone === 'string' ? [analysis?.discussion_tone] : analysis?.discussion_tone || []
  const icp_fit_percentage = analysis?.icp_fit_percentage || 0
  const problem_fit = analysis?.problem_fit_score?.problem_fit || {}
  const solution_fit = analysis?.problem_fit_score?.solution_fit || {}
  const market_fit = analysis?.problem_fit_score?.market_fit || {}
  const reach_total = analysis?.reach_total || 1000
  const top_personas = analysis?.top_personas || []
  const heat_score = removeEmojis(analysis?.heat_score || 'Warming Up')

  const heatScoreStyles: Record<string, { icon: string; color: string; bgColor: string }> = {
    Viral: { icon: white_rocket_icon, color: 'white', bgColor: '#FF0000' },
    Hot: { icon: white_fire_icon, color: 'white', bgColor: '#FF4500' },
    'Warming Up': { icon: warming_up_icon, color: '#4A3800', bgColor: '#FFD700' },
    Cold: { icon: cold_icon, color: '#2D3748', bgColor: '#A0AEC0' },
  }

  // Fallback to 'Warming Up' if the value is not in the defined options
  const { icon, color, bgColor } = heatScoreStyles[heat_score] || heatScoreStyles['Warming Up']

  const handleGenerate = async () => {
    setIsGenerating(true)
    // Simulate AI "thinking" delay

    const { data } = await generateComment({
      user_id: user?.id,
      post_content: card?.content?.text || card?.content?.content?.text || card?.content?.title,
      tone: 'natural',
      comment_type: 'Generate a comment recommendation based on the post content.',
    })

    setTimeout(() => {
      setGeneratedComment(data)
      setAiGenerated(true)
      setIsGenerating(false)
    }, 500)
  }

  useEffect(() => {
    if (isGenerating) {
      let current = 1
      let direction = 1
      setDotCount(current)
      const interval = setInterval(() => {
        current += direction
        if (current >= 3) {
          current = 3
          direction = -1
        } else if (current <= 1) {
          current = 1
          direction = 1
        }
        setDotCount(current)
      }, 500)
      return () => clearInterval(interval)
    }
  }, [isGenerating])

  return (
    <div className={styles.container}>
      <div className={styles.borderedBox}>
        <div className={styles.rowDivider}>
          <div className={styles.colDiv} style={{ minWidth: 'fit-content' }}>
            <p>Reach Potential</p>
            <span className={styles.spanTitle}>Your ICP</span>
            <span className={styles.spanValue} style={{ backgroundColor: '#b69dfb', fontSize: '20px', color: 'white' }}>
              {reach_total
                ? `${formatNumber(
                    round50(Math.floor(reach_total * (icp_fit_percentage / 100) * 0.5)),
                  )} - ${formatNumber(round50(Math.floor(reach_total * (icp_fit_percentage / 100) * 1.5)))}`
                : 'N/A'}
            </span>
            <span className={styles.spanTitle}>General</span>
            <span className={styles.spanValue} style={{ backgroundColor: '#D9D9D9' }}>
              {reach_total
                ? `${formatNumber(round50(Math.floor(reach_total * 0.5)))} - ${formatNumber(
                    round50(Math.floor(reach_total * 1.5)),
                  )}`
                : 'N/A'}
            </span>
          </div>
          <div className={styles.divider}>
            <Divider type='vertical' style={{ margin: 0, height: '90px' }} />
          </div>
          <div className={styles.colDiv}>
            <p>Top Personas</p>
            <div className={styles.topPersonas}>
              {top_personas.slice(0, 3).map((persona: any, index: number) => (
                <div key={index} className={styles.row}>
                  <span className={styles.spanTitle}>{Object.keys(persona)[0]}</span>
                  <span
                    className={styles.spanValue}
                    style={{
                      backgroundColor: ['#F3F4F6', '#F3F4F6', '#F3F4F6'][index],
                    }}>
                    {Math.round(persona?.[Object.keys(persona)[0]])}%
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.boxesRow}>
        <div className={styles.metricsBox}>
          <div className={styles.leftSide}>
            <div className={styles.title}>
              <p>Engagement Analysis</p>
            </div>
            <div className={styles.metrics}>
              <div className={styles.metric} style={{ alignItems: 'flex-start' }}>
                <p className={styles.metricIcon} style={{ backgroundColor: bgColor, color }}>
                  <img style={{ marginRight: '4px' }} src={icon} alt='' />
                  {heat_score}
                </p>
                <img src={eng_metric_1} alt='' />
              </div>

              <div className={styles.rowMetrics}>
                <div className={styles.metric}>
                  <p>{Math.round(comments)}</p>
                  <img src={eng_metric_2} alt='' />
                </div>
                <Divider type='vertical' style={{ margin: 0, height: '24px' }} />
                <div className={styles.metric}>
                  <p>{Math.round(upvote)}</p>
                  <img src={eng_metric_3} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.metricsBox}>
          <div className={styles.rightSide}>
            <div className={styles.title}>
              <p>Discussion tone</p>
            </div>
            <div className={styles.tones}>
              {discussion_tone.map((tone: string, index: number) => (
                <span key={index} style={{ backgroundColor: ['#f9b998', '#bee4f9'][index] }}>
                  {tone}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.problemBox}>
        <div className={styles.title}>
          <p>Problem-Solution Fit Score</p>
          <span className={styles.toggleIcon} onClick={() => setIsExpanded((prev) => !prev)}>
            {isExpanded ? <span style={{ fontSize: '36px' }}>-</span> : <span>+</span>}
          </span>
        </div>
        <div className={styles.scores}>
          <GaugeMeter percentage={problem_fit?.score || 0} label='Problem Fit' open={true} />
          <Divider
            type='vertical'
            style={{ margin: 0, height: '45%', backgroundColor: 'black', borderRadius: '50%' }}
          />
          <GaugeMeter percentage={solution_fit?.score || 0} label='Solution Fit' open={true} />
          <Divider
            type='vertical'
            style={{ margin: 0, height: '45%', backgroundColor: 'black', borderRadius: '50%' }}
          />
          <GaugeMeter percentage={market_fit?.score || 0} label='Market Fit' open={true} />
        </div>
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: isExpanded ? 1 : 0, height: isExpanded ? 'auto' : 0 }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}>
          {isExpanded && (
            <div className={styles.expanded}>
              <div className={styles.titleDescription}>
                <p>Problem</p>
                <span>{problem_fit?.explaination || 'No problem description available'}</span>
              </div>
              <div className={styles.titleDescription}>
                <p>Solution</p>
                <span>{solution_fit?.explaination || 'No solution description available'}</span>
              </div>
              <div className={styles.titleDescription}>
                <p>Market</p>
                <span>{market_fit?.explaination || 'No market description available'}</span>
              </div>
            </div>
          )}
        </motion.div>
      </div>
      {competitors_mentioned?.length > 0 && (
        <div className={styles.competitorsBox}>
          <div className={styles.title}>
            <p>Competitors Mentions</p>
          </div>
          <div className={styles.competitors}>
            {competitors_mentioned.map((competitor: string, index: number) => (
              <span key={index} style={{ backgroundColor: ['#FFC9C8', '#b19afa', '#8fbbe8'][index % 3] }}>
                {competitor}
              </span>
            ))}
          </div>
        </div>
      )}
      {/* <div className={styles.commentBox}>
        <div className={styles.title}>
          <img src={ai_message_icon} alt='' />
          <p>Comment AI Recommendation</p>
        </div>
        <Input.TextArea
          placeholder='Type your message here'
          autoSize={{ minRows: 2, maxRows: 4 }}
          className={styles.commentInput}
          value={analysis?.recommendation_comment || ''}
        />
        <div className={styles.send}>
          <img
            src={send_arrow_icon}
            alt=''
            onClick={() => {
              dispatch(SET_AI_COMMENT(analysis?.recommendation_comment || ''))
              dispatch(SET_OPEN_AI_COMMENT(true))
            }}
          />
        </div>
      </div> */}
      <div className={styles.commentBox}>
        <div className={styles.title} style={{ justifyContent: 'space-between', height: '24px' }}>
          <div className={styles.subTitle}>
            <img src={ai_message_icon} alt='' />
            <p>Comment AI Recommendation</p>
          </div>

          {!aiGenerated && (
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className={styles.generateContainer}>
              <Button onClick={handleGenerate} disabled={isGenerating} style={isGenerating ? { minWidth: '88px' } : {}}>
                {isGenerating ? `Generating${'.'.repeat(dotCount)}` : 'Generate'}
              </Button>
            </motion.div>
          )}
        </div>

        {aiGenerated && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
            <Input.TextArea
              placeholder='AI thought about it...'
              autoSize={{ minRows: 2, maxRows: 4 }}
              className={styles.commentInput}
              value={generatedComment}
              onChange={(e) => setGeneratedComment(e.target.value)}
            />
          </motion.div>
        )}
        {aiGenerated && (
          <div className={styles.send}>
            <img
              src={send_arrow_icon}
              alt=''
              onClick={() => {
                dispatch(SET_AI_COMMENT(generatedComment))
                dispatch(SET_OPEN_AI_COMMENT(true))
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default RightPanelCues
