import React, { useLayoutEffect, useRef, useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import CustomComment from 'common/components/Heatbox/CustomComment'
import styles from './ContentSection.module.scss'
import topic_custom_card from 'common/assets/svg/topic_custom_card.svg'
import persona_matching_icon from 'common/assets/svg/persona_matching_icon.svg'
import comment_line from 'common/assets/svg/comment_line.svg'
import export_icon from 'common/assets/svg/export_icon.svg'
import comment_icon from 'common/assets/svg/comment_icon.svg'
import repost_icon from 'common/assets/svg/repost_icon.svg'
import {
  formatDateLinkedIn,
  formatDateToISO,
  getDistinctHighQualityImages,
  getFirstVideoUrl,
} from 'common/components/Heatbox/Utils/CardUtils'
import TruncatedTooltip from '../../GeneralComponents/UI/TruncatedTooltip'
import { useParams } from 'react-router-dom'
import AvatarGroup from 'common/components/Heatbox/UI/AvatarGroup'
import ProfilePicture from 'common/components/Heatbox/UI/ProfilePicture'
import { ExpandableText } from 'common/components/Heatbox/UI/ExpandableText'
import { Card, Image, Skeleton, Tooltip } from 'antd'
import PhotoGrid from 'common/components/Heatbox/UI/PhotoGrid'
import { useLazyGetArticleDataQuery } from 'common/APIs/HeatboxApi'
import { InfoCircleOutlined, LinkOutlined } from '@ant-design/icons'
function getDomain(url: any) {
  if (!url) return ''
  try {
    return new URL(url).hostname.replace('www.', '')
  } catch (e) {
    return ''
  }
}
interface ArticleContentProps {
  hasArticle: {
    hasArticle: boolean
    articleData: any
    articleError: boolean
  }
}
const ArticleContent = ({ hasArticle }: ArticleContentProps) => {
  const isArticle = hasArticle?.hasArticle
  const articleData = hasArticle?.articleData
  const articleError = hasArticle?.articleError
  return (
    <>
      {isArticle && !articleError && (
        <>
          <div className='articleWrapper'>
            <Card className='articleCard' bordered={false} style={{ cursor: 'pointer' }}>
              {articleData ? (
                <>
                  <Image
                    height='120px'
                    width='120px'
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%',
                      objectPosition: 'center',
                    }}
                    loading='lazy'
                    src={articleData?.ogImage?.[0]?.url}
                  />
                  <div className='articleContent'>
                    <span className='articleContentHeading'>{articleData?.ogTitle}</span>
                    <span className='articleContentSub'>{articleData?.ogDescription}</span>
                    <div className='articleContentEnd'>
                      <span>{getDomain(articleData?.ogUrl)}</span>
                      <span>
                        {
                          <LinkOutlined
                            className='articleContentLink'
                            onClick={() => {
                              window.open(articleData?.ogUrl, '_blank')
                            }}
                          />
                        }
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {articleError ? (
                    <></>
                  ) : (
                    <>
                      <Skeleton.Image style={{ width: '120px', height: '120px' }} />
                      <div className='articleContent'>
                        <span className='articleContentHeading'>
                          <Skeleton.Input size='default' block active />
                        </span>
                        <span className='articleContentSub'>
                          <Skeleton.Input size='default' block active style={{ marginTop: '5px', height: '20px' }} />
                        </span>
                        <div className='articleContentEnd'>
                          <Skeleton.Input size='default' block active style={{ height: '15px', marginTop: '5px' }} />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </Card>
          </div>
        </>
      )}
    </>
  )
}
interface ContentSectionProps {
  type: 'post' | 'comment' | 'repost'
  content: any
  card: any
  comment?: any
  commentStatus?: string
  name: string
  aboveCommentVariants: any
  belowCommentVariants: any
  itemVariants: any
  toggleHeatboxPanel: () => void
}

interface PostContentProps {
  content: any
  card: any
  showMore: boolean
  toggleShowMore: () => void
  toggleHeatboxPanel: () => void
  aboveCommentVariants: any
  lineClamp: any
  height: any
  hasArticle: {
    hasArticle: boolean
    articleData: any
    articleError: boolean
  }
}
const PostContent: React.FC<PostContentProps> = ({
  content,
  card,
  showMore,
  toggleShowMore,
  toggleHeatboxPanel,
  aboveCommentVariants,
  lineClamp,
  height,
  hasArticle,
}) => {
  const view = useParams<any>()
  const subUrl = view?.['*']
  const isCues = subUrl === 'cues'
  const isReddit = card?.channel === 'reddit'

  const postTitle = card?.content?.title
  const postText = card?.content?.text || card?.content?.content?.text // || 'Post text is missing.'
  const date = content?.posted_date || card?.posted_at
  const leadName = card?.content?.author?.name
    ? card?.content?.author?.name
    : card?.content?.author?.firstName && card?.content?.author?.firstName !== 'undefined'
    ? `${card?.content?.author?.firstName} ${card?.content?.author?.lastName}`
    : card?.content?.company?.name || 'Unknown Name'

  const headline = card?.content?.author?.headline ? card?.content?.author?.headline : card?.content?.company?.name
  const showTopicAndScore = card?.topic || +card?.content_score > 0
  const videoUrl =
    card?.content?.content?.video ||
    getFirstVideoUrl(card?.content?.video?.video) ||
    getFirstVideoUrl(card?.content?.video)
  const photoUrls = Array.isArray(card?.content?.image) ? getDistinctHighQualityImages(card?.content?.image) : undefined
  const icp_fit_percentage = card?.lead_score

  const didTeamAction = card?.team_action
  // IMPORTANT
  // DO NOT CHANGE classNames or add new ones without checking the Show More
  return (
    <>
      <motion.div className={styles.prospectInfo} variants={aboveCommentVariants} initial='initial' animate='animate'>
        <div className={styles.leadNameHeatbox}>
          {leadName}
          {isCues && !isReddit && (
            <div className={styles.infoCircle}>
              <InfoCircleOutlined onClick={toggleHeatboxPanel} />
            </div>
          )}
          {typeof icp_fit_percentage === 'number' && icp_fit_percentage >= 0 && !isReddit && isCues && (
            <div
              className={`${styles.personaBadge} ${
                styles[`persona-${Math.min(Math.floor(icp_fit_percentage / 25) * 25, 75)}`]
              }`}
              style={{ fontSize: '13px', fontWeight: 'bold' }}>
              <img src={persona_matching_icon} alt='' />
              <span style={{ fontWeight: 'bolder' }}>{icp_fit_percentage}%</span>
            </div>
          )}
          {isCues && card?.last_engagement && (
            <Tooltip
              title={`${formatDateToISO(card?.last_engagement)}`}
              className={`${styles[`fire-2`]} ${styles['fireBadge']}`}
              style={{ height: '18px' }}>
              Recently Interacted
            </Tooltip>
          )}
        </div>
        <p className={styles.timestamp}>{formatDateLinkedIn(date)}</p>
        <p className={styles.title}>{headline}</p>
        <div className={styles.topicWrapper}>
          {showTopicAndScore && (
            <>
              <span className={styles.topic}>
                <img src={topic_custom_card} alt='' />
                <span>{card?.topic}</span>
              </span>
              <span className={styles.score}>{card?.content_score ? card?.content_score?.toLocaleString() : 0}%</span>
              {didTeamAction && (
                <Tooltip
                  title={`${card?.team_action_by} at ${formatDateToISO(card?.team_action_at)}`}
                  className={styles.teamAction}>
                  <span className={`${styles[`fire-2`]} ${styles['fireBadge']}`}>Team Member Interacted</span>
                </Tooltip>
              )}
            </>
          )}
        </div>
        {postTitle && (
          <p className={styles.postTitle} style={{ marginBottom: '5px' }}>
            {postTitle}
          </p>
        )}
        <motion.div
          className={styles.post}
          variants={aboveCommentVariants}
          initial='initial'
          animate='animate'
          style={{
            height: showMore ? 'auto' : 'fit-content',
            overflowY: showMore ? 'visible' : 'scroll',
          }}>
          <ExpandableText
            text={postText}
            lineClamp={photoUrls ? lineClamp - 1 : lineClamp}
            expanded={showMore}
            toggleExpanded={toggleShowMore}
          />
        </motion.div>

        {photoUrls && <PhotoGrid photoUrls={photoUrls} />}

        {videoUrl && (
          <div className={styles.videoWrapper}>
            <video controls playsInline width='100%'>
              <source src={videoUrl} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
        <ArticleContent hasArticle={hasArticle} />
      </motion.div>
    </>
  )
}

interface CommentContentProps {
  content: any
  card: any
  showMore: boolean
  toggleShowMore: () => void
  toggleHeatboxPanel: () => void
  aboveCommentVariants: any
  lineClamp: any
  height: any
  hasArticle: {
    hasArticle: boolean
    articleData: any
    articleError: boolean
  }
}

const CommentContent: React.FC<CommentContentProps> = ({
  content,
  card,
  showMore,
  toggleShowMore,
  toggleHeatboxPanel,
  aboveCommentVariants,
  lineClamp,
  height,
  hasArticle,
}) => {
  const view = useParams<any>()
  const subUrl = view?.['*']
  const isCues = subUrl === 'cues'
  const isReddit = card?.channel === 'reddit'
  const postText = card?.content?.text
  const leadName = card?.content?.author?.name
    ? card?.content?.author?.name
    : card?.content?.author?.firstName
    ? `${card?.content?.author?.firstName} ${card?.content?.author?.lastName}`
    : `${card?.content?.company?.name}`

  const headline = card?.content?.author?.headline ? card?.content?.author?.headline : card?.content?.company?.name
  const personaMatch = card?.lead_score
  const commentText = card?.content?.highlights?.[0]
  const borderColor = subUrl === 'seeds' ? '#20BA6D' : subUrl === 'cues' ? '#7043ff' : '#FF7171'
  const showTopicAndScore = card?.topic || +card?.content_score > 0

  const videoUrl =
    card?.content?.content?.video ||
    getFirstVideoUrl(card?.content?.video?.video) ||
    getFirstVideoUrl(card?.content?.video)
  const photoUrls = Array.isArray(card?.content?.image) ? getDistinctHighQualityImages(card?.content?.image) : undefined
  const icp_fit_percentage = card?.analysis?.icp_fit_percentage
  const didTeamAction = card?.team_action
  // IMPORTANT
  // DO NOT CHANGE classNames or add new ones without checking the Show More
  return (
    <>
      <motion.div
        className={showMore ? styles.commentInfoExpanded : styles.commentInfo}
        variants={aboveCommentVariants}
        initial='initial'
        animate='animate'>
        <h2 className={styles.name}>
          {leadName}
          {isCues && !isReddit && (
            <div className={styles.infoCircle}>
              <InfoCircleOutlined onClick={toggleHeatboxPanel} />
            </div>
          )}
          {typeof icp_fit_percentage === 'number' && icp_fit_percentage >= 0 && !isReddit && isCues && (
            <p className={styles.score} style={{ fontSize: '13px' }}>
              {icp_fit_percentage ? icp_fit_percentage?.toLocaleString() : 0}%
            </p>
          )}
        </h2>
        <p className={styles.timestamp}>{formatDateLinkedIn(content?.comment_date)}</p>
        <p className={styles.title}>{headline}</p>
        <div className={styles.topicWrapper}>
          {showTopicAndScore && (
            <>
              <span className={styles.topic}>
                <img src={topic_custom_card} alt='' />
                <span>{card?.topic}</span>
              </span>
              <span className={styles.score}>{card?.content_score ? card?.content_score?.toLocaleString() : 0}%</span>
              {didTeamAction && (
                <Tooltip
                  title={`${card?.team_action_by} at ${formatDateToISO(card?.team_action_at)}`}
                  className={styles.teamAction}>
                  <span className={`${styles[`fire-2`]} ${styles['fireBadge']}`}>Team Member Interacted</span>
                </Tooltip>
              )}
            </>
          )}
        </div>
        <motion.div
          className={styles.post}
          variants={aboveCommentVariants}
          initial='initial'
          animate='animate'
          style={{
            height: showMore ? 'auto' : 'fit-content',
            overflowY: showMore ? 'visible' : 'scroll',
          }}>
          <ExpandableText
            text={postText}
            lineClamp={photoUrls ? lineClamp - 3 : lineClamp}
            expanded={showMore}
            toggleExpanded={toggleShowMore}
          />
          <ArticleContent hasArticle={hasArticle} />
        </motion.div>

        {photoUrls && <PhotoGrid photoUrls={photoUrls} maxVisibleHeight={showMore ? undefined : 100} />}
        {videoUrl && (
          <div className={styles.videoWrapper}>
            <video controls playsInline width='100%'>
              <source src={videoUrl} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </motion.div>
      <motion.div className={styles.commentWrapper} variants={aboveCommentVariants} initial='initial' animate='animate'>
        <img src={comment_line} alt='' />
        <div className={styles.commentText} style={{ borderColor }}>
          <div className={styles.topSection}>
            <div className={styles.topSectionLeft}>
              <div className={styles.title}>
                <p>{card?.lead?.name}</p>
                <div
                  className={`${styles.personaBadge} ${
                    styles[`persona-${Math.min(Math.floor(personaMatch / 25) * 25, 75)}`]
                  }`}>
                  <img src={persona_matching_icon} alt='' />
                  <span>{personaMatch}%</span>
                </div>
                <div className={styles.commentBadge}>
                  <img src={comment_icon} alt='' />
                </div>
              </div>
              <div className={styles.subTitle}>
                <p>
                  <TruncatedTooltip text={card?.lead?.headline} maxLength={75} />
                </p>
              </div>
            </div>
            <div className={styles.topSectionRight}>{formatDateLinkedIn(card?.content?.comment_date)}</div>
          </div>
          <div className={styles.bottomSection}>{commentText}</div>
        </div>
      </motion.div>
    </>
  )
}

interface RepostContentProps {
  content: any
  card: any
  showMore: boolean
  toggleShowMore: () => void
  toggleHeatboxPanel: () => void
  aboveCommentVariants: any
  lineClamp: any
  height: any
  hasArticle: {
    hasArticle: boolean
    articleData: any
    articleError: boolean
  }
}

const RepostContent: React.FC<RepostContentProps> = ({
  content,
  card,
  showMore,
  toggleShowMore,
  toggleHeatboxPanel,
  aboveCommentVariants,
  lineClamp,
  height,
  hasArticle,
}) => {
  const view = useParams<any>()
  const subUrl = view?.['*']
  const isCues = subUrl === 'cues'

  const isReddit = card?.channel === 'reddit'
  const postText = card?.content?.text
  const repostText = card?.content?.repost_text

  const postLeadName = card?.content?.author?.name
    ? card?.content?.author?.name
    : card?.content?.author?.firstName
    ? `${card?.content?.author?.firstName} ${card?.content?.author?.lastName}`
    : `${card?.content?.company?.name}`

  const postHeadline = card?.content?.author?.headline ? card?.content?.author?.headline : card?.content?.company?.name
  const date = formatDateLinkedIn(content?.posted_date)
  const name = card?.lead?.name
  const headline = card?.lead?.headline
  const leadScore = card?.lead_score
  const showTopicAndScore = card?.topic || +card?.content_score > 0

  const videoUrl =
    card?.content?.content?.video ||
    getFirstVideoUrl(card?.content?.video?.video) ||
    getFirstVideoUrl(card?.content?.video)

  const photoUrls = Array.isArray(card?.content?.image) ? getDistinctHighQualityImages(card?.content?.image) : undefined
  const icp_fit_percentage = card?.analysis?.icp_fit_percentage

  // IMPORTANT
  // DO NOT CHANGE classNames or add new ones without checking the Show More
  return (
    <>
      <motion.div
        className={styles.prospectRepostWrapper}
        variants={aboveCommentVariants}
        initial='initial'
        animate='animate'>
        <div className={styles.prospectRepost}>
          <div className={styles.profilePictureWrapper}>
            <ProfilePicture card={card} />
            <div className={styles.commentLine}>
              <img src={comment_line} alt='' />
            </div>
          </div>
          <div className={styles.profileText}>
            <div className={styles.flexRowDiv}>
              <h2 className={styles.name}>{name} </h2>
              {isCues && !isReddit && (
                <div className={styles.infoCircle}>
                  <InfoCircleOutlined onClick={toggleHeatboxPanel} />
                </div>
              )}
            </div>
            <Tooltip title={headline}>
              <p className={styles.title}>{headline}</p>
            </Tooltip>
            <div className={styles.topicWrapper}>
              <span className={styles.score}>{leadScore ? leadScore?.toLocaleString() : 0}%</span>
              <img className={styles.repostIcon} src={repost_icon} alt='' />
              <p className={styles.timestamp}>{date}</p>
            </div>
          </div>
        </div>
        {repostText && (
          <motion.div className={styles.repostTextWrapper} variants={aboveCommentVariants}>
            <ExpandableText text={repostText} lineClamp={20} />
          </motion.div>
        )}
        <motion.div
          className={showMore ? styles.commentInfoExpanded : styles.commentInfo}
          style={{ marginLeft: '8px', marginTop: '5px' }}
          variants={aboveCommentVariants}
          initial='initial'
          animate='animate'>
          <h2
            className={styles.name}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
            {postLeadName}

            {typeof icp_fit_percentage === 'number' && icp_fit_percentage >= 0 && !isReddit && isCues && (
              <p className={styles.score} style={{ fontSize: '13px' }}>
                {icp_fit_percentage ? icp_fit_percentage?.toLocaleString() : 0}%
              </p>
            )}
          </h2>
          <p className={styles.timestamp}>{date}</p>
          <p className={styles.title}>{postHeadline}</p>
          <div className={styles.topicWrapper}>
            {showTopicAndScore && (
              <>
                <span className={styles.topic}>
                  <img src={topic_custom_card} alt='' />
                  <span>{card?.topic}</span>
                </span>
                <span className={styles.score}>{card?.content_score ? card?.content_score?.toLocaleString() : 0}%</span>
              </>
            )}
          </div>
          <motion.div
            className={styles.post}
            variants={aboveCommentVariants}
            initial='initial'
            animate='animate'
            style={{
              height: showMore ? 'auto' : 'fit-content',
              overflowY: showMore ? 'visible' : 'scroll',
            }}>
            <ExpandableText
              text={postText}
              lineClamp={photoUrls ? lineClamp - 1 : lineClamp}
              expanded={showMore}
              toggleExpanded={toggleShowMore}
            />
            <ArticleContent hasArticle={hasArticle} />
          </motion.div>

          {photoUrls && (
            <div className={styles.imageWrapper}>
              <PhotoGrid photoUrls={photoUrls} />
            </div>
          )}

          {videoUrl && (
            <div className={styles.videoWrapper}>
              <video controls playsInline width='100%' style={{ maxHeight: '100px' }}>
                <source src={videoUrl} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </motion.div>
      </motion.div>
    </>
  )
}

const ContentSection: React.FC<ContentSectionProps> = ({
  type,
  content,
  card,
  comment,
  commentStatus,
  name,
  aboveCommentVariants,
  belowCommentVariants,
  itemVariants,
  toggleHeatboxPanel,
}) => {
  // Local state for toggling the "show more" text
  const [showMore, setShowMore] = useState(false)
  const toggleShowMore = () => setShowMore((prev) => !prev)
  const [articleData, setArticleData] = useState<any>(null)
  const [hasArticle, setHasArticle] = useState(false)
  const [getArticleData] = useLazyGetArticleDataQuery()
  const [articleError, setArticleError] = useState(false)
  const isReddit = card?.channel === 'reddit'
  const url = isReddit
    ? card?.url
    : ['post', 'repost'].includes(type)
    ? card?.content?.post_url
    : card?.content?.comment_url

  const [lineClamp, setLineClamp] = useState(1)
  const [height, setHeight] = useState('30vh')

  // Attach a ref to the container (.content).
  const contentRef = useRef<HTMLDivElement>(null)
  // const contentEl = contentRef.current
  // const commentContainerEl = contentEl?.querySelector(`.${styles.customCommentWrapper}`)
  // const commentContainerHeight = commentContainerEl?.clientHeight || 0

  useEffect(() => {
    async function fetchArticle() {
      try {
        const { data } = await getArticleData({ article_url: content?.article?.link || content?.article?.url })
        console.log('data', data)
        const datadata = data?.data
        if (Object.keys(datadata || {}).length === 0) {
          setArticleError(true)
        }
        if (data?.data) {
          setArticleData(data?.data)
        }
      } catch (e) {
        console.log(e)
      }
    }
    if (content?.article?.title && content?.article?.subtitle) {
      setHasArticle(true)
      // fetch article data
      fetchArticle()
    }
  }, [])

  useLayoutEffect(() => {
    const calculateLineClamp = () => {
      if (contentRef.current) {
        // The parent container.
        const contentEl = contentRef.current
        // Query the elements above and below the ExpandableText.
        // POST
        const nameEl = contentEl.querySelector(`.${styles.name}`)
        const leadNameHeatboxEl = contentEl.querySelector(`.${styles.leadNameHeatbox}`)
        const timestampEl = contentEl.querySelector(`.${styles.timestamp}`)
        const titleEl = contentEl.querySelector(`.${styles.title}`)
        const postTitleEl = contentEl.querySelector(`.${styles.postTitle}`)
        const topicWrapperEl = contentEl.querySelector(`.${styles.topicWrapper}`)
        const videoWrapperEl = contentEl.querySelector(`.${styles.videoWrapper}`)
        const imageWrapperEl = contentEl.querySelector(`.${styles.imageWrapper}`)
        const articleWrapperEl = contentEl.querySelector(`.articleWrapper`)
        // COMMENT
        const commentWrapperEl = contentEl.querySelector(`.${styles.commentWrapper}`)
        // REPOST
        const prospectRepostEl = contentEl.querySelector(`.${styles.prospectRepost}`)
        const repostTextWrapperEl = contentEl.querySelector(`.${styles.repostTextWrapper}`)

        // ALL
        const commentContainerEl = contentEl.querySelector(`.${styles.customCommentWrapper}`)
        const actionsEl = contentEl.querySelector(`.${styles.actionsWrapper}`)

        // has article and is comment
        const addition = hasArticle && type === 'comment' ? 30 : 0
        if (
          contentEl &&
          actionsEl &&
          (nameEl || leadNameHeatboxEl) &&
          timestampEl &&
          (titleEl || postTitleEl) &&
          topicWrapperEl
        ) {
          const totalHeight = contentEl.clientHeight
          // const actionsHeight = actionsEl.clientHeight
          const commentContainerHeight = commentContainerEl?.clientHeight || 0
          // POST
          const nameHeight = nameEl?.clientHeight || 0
          const leadNameHeatboxHeight = leadNameHeatboxEl?.clientHeight || 0
          const timestampHeight = timestampEl?.clientHeight || 0
          const titleHeight = titleEl?.clientHeight || 0
          const postTitleHeight = postTitleEl?.clientHeight || 0
          const topicWrapperHeight = topicWrapperEl?.clientHeight || 0
          const videoWrapperHeight = videoWrapperEl?.clientHeight || 0
          const imageWrapperHeight = imageWrapperEl?.clientHeight || 0
          const articleWrapperHeight = articleWrapperEl?.clientHeight || 0
          // COMMENT
          const commentWrapperHeight = type === 'comment' ? commentWrapperEl?.clientHeight || 0 : 0

          // REPOST
          const prospectRepostHeight = type === 'repost' ? prospectRepostEl?.clientHeight || 0 : 0
          const repostTextHeight = repostTextWrapperEl?.clientHeight || 0

          const extra = type === 'comment' ? 45 : type === 'repost' ? 20 : 100
          // CALCULATE
          const expandableHeight =
            totalHeight -
            // actionsHeight -
            nameHeight -
            leadNameHeatboxHeight -
            timestampHeight -
            titleHeight -
            topicWrapperHeight -
            commentContainerHeight -
            commentWrapperHeight -
            prospectRepostHeight -
            videoWrapperHeight -
            postTitleHeight -
            imageWrapperHeight -
            repostTextHeight -
            (hasArticle ? articleWrapperHeight : 0) -
            extra -
            65 -
            addition

          const calculatedLines = Math.max(1, Math.floor(expandableHeight / 20) - (type === 'repost' ? 4 : 2))
          setLineClamp(calculatedLines)

          const calculatedHeight = Math.max(
            3,
            +(expandableHeight / window.innerHeight).toFixed(2) * 100 + 2 + (type === 'repost' ? -8 : 0),
          )

          setHeight(`${calculatedHeight}vh`)
        }
      }
    }

    // Initial calculation.
    calculateLineClamp()

    // Re-calculate on window resize.
    window.addEventListener('resize', calculateLineClamp)
    // return () => window.removeEventListener('resize', calculateLineClamp)
    const observer = new ResizeObserver(() => calculateLineClamp())
    if (contentRef.current) observer.observe(contentRef.current)

    return () => {
      window.removeEventListener('resize', calculateLineClamp)
      observer.disconnect()
    }
  }, [content, card, comment, name, hasArticle])

  // IMPORTANT
  // DO NOT CHANGE classNames or add new ones without checking the Show More
  return (
    <div
      ref={contentRef}
      className={`${styles.content} ${type === 'comment' ? styles.commentContent : ''}`}
      style={{ height: `calc(100% - 40px)` }}>
      {type === 'post' && (
        <PostContent
          content={content}
          card={card}
          showMore={showMore}
          toggleShowMore={toggleShowMore}
          toggleHeatboxPanel={toggleHeatboxPanel}
          aboveCommentVariants={aboveCommentVariants}
          lineClamp={lineClamp}
          height={height}
          hasArticle={{
            hasArticle,
            articleData,
            articleError,
          }}
        />
      )}
      {type === 'comment' && (
        <CommentContent
          content={content}
          card={card}
          showMore={showMore}
          toggleShowMore={toggleShowMore}
          toggleHeatboxPanel={toggleHeatboxPanel}
          aboveCommentVariants={aboveCommentVariants}
          lineClamp={lineClamp}
          height={height}
          hasArticle={{ hasArticle, articleData, articleError }}
        />
      )}
      {type === 'repost' && (
        <RepostContent
          content={content}
          card={card}
          showMore={showMore}
          toggleShowMore={toggleShowMore}
          toggleHeatboxPanel={toggleHeatboxPanel}
          aboveCommentVariants={aboveCommentVariants}
          lineClamp={lineClamp}
          height={height}
          hasArticle={{ hasArticle, articleData, articleError }}
        />
      )}

      {/* Comment section – shared between types */}
      <motion.div
        className={styles.customCommentWrapper}
        style={comment ? { marginTop: '45px' } : {}}
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          type: 'spring',
          stiffness: 500,
          damping: 30,
          delay: 0.2,
        }}>
        {comment && <CustomComment author={name} content={comment} commentStatus={commentStatus} />}
      </motion.div>

      {/* Content below comment – also shared */}
      <motion.div className={styles.actionsWrapper} variants={belowCommentVariants} initial='initial' animate='animate'>
        <div className={styles.fixedDiv}></div>
        <motion.div className={styles.actions} variants={itemVariants}>
          <p className={styles.reactions}>
            <AvatarGroup count={2} />
            {+content?.reactions} Reactions, {+content?.comments} Comments
          </p>
          <img
            src={export_icon}
            alt='Go to'
            onClick={() => {
              window.open(url, '_blank')
            }}
            className={styles.exportIcon}
          />
        </motion.div>
      </motion.div>
    </div>
  )
}

export default ContentSection
