export const BLACK_LIST_OUTREACH = [
  { country: 'China', code: '+86' },
  { country: 'Iran', code: '+98' },
  // { country: 'Russia', code: '+7' },
  { country: 'Pakistan', code: '+92' },
  { country: 'India', code: '+91' },
  { country: 'Turkey', code: '+90' },
  { country: 'Saudi Arabia', code: '+966' },
  { country: 'United Arab Emirates', code: '+971' },
  { country: 'Bahrain', code: '+973' },
  { country: 'Egypt', code: '+20' },
  { country: 'Ethiopia', code: '+251' },
  { country: 'Eritrea', code: '+291' },
  { country: 'Azerbaijan', code: '+994' },
  { country: 'Turkmenistan', code: '+993' },
  { country: 'Uzbekistan', code: '+998' },
  // { country: 'Kazakhstan', code: '+7' },
  { country: 'Tajikistan', code: '+992' },
  { country: 'Belarus', code: '+375' },
  { country: 'Cuba', code: '+53' },
  { country: 'Vietnam', code: '+84' },
  { country: 'Myanmar', code: '+95' },
  { country: 'Algeria', code: '+213' },
  { country: 'Tunisia', code: '+216' },
  { country: 'Indonesia', code: '+62' },
  { country: 'Thailand', code: '+66' },
  { country: 'Philippines', code: '+63' },
  { country: 'Nigeria', code: '+234' },
  { country: 'Kenya', code: '+254' },
  { country: 'Morocco', code: '+212' },
  { country: 'Ghana', code: '+233' },
  { country: 'Ivory Coast', code: '+225' },
  { country: 'Brazil', code: '+55' },
  { country: 'Colombia', code: '+57' },
  { country: 'Venezuela', code: '+58' },
  { country: 'Peru', code: '+51' },
]
