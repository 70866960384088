import React, { useState, useEffect } from 'react'
import { Table, Tooltip } from 'antd'
import type { ColumnsType, TableProps } from 'antd/es/table'
import styles from './HeatboxView.module.scss'
// import { LinkOutlined } from '@ant-design/icons'
import left_arrow_add from 'common/assets/svg/left_arrow_add.svg'
import right_arrow_add from 'common/assets/svg/right_arrow_add.svg'
import TableRowSelectionDropdown from 'common/components/GeneralComponents/UI/TableRowSelectionDropdown'

interface RowData {
  id: string | number
  profile_url: string
  first_name: string
  last_name: string
  company: string
  heat_level: string
}

interface HeatboxViewProps {
  csvData: RowData[] // Rows from CSV (left side)
  existingData: RowData[] // Existing user data (right side)
  maxAdd?: number // Maximum rows allowed to "Add" from the left
  onLimitChange?: (isOverLimit: boolean) => void // Callback to notify parent when limit conditions change
  reset?: number
  onFinish: (added: RowData[], removed: RowData[]) => void
  done: boolean
}

/**
 * Displays two tables side by side:
 * - Left: "Pending Insertion to Heatbox" (CSV data)
 * - Right: "My Heatbox" (existing data)
 * Only one side can be selected at a time.
 * If the user selects from the left, we show "Add" in the middle.
 * If from the right, we show "Remove" in the middle.
 */
const HeatboxView: React.FC<HeatboxViewProps> = ({
  csvData = [],
  existingData = [],
  maxAdd = 1000,
  onLimitChange,
  reset,
  onFinish,
  done,
}) => {
  const pageSize = 25

  // We copy the props into local state so we can modify them
  const [leftTableData, setLeftTableData] = useState<RowData[]>(csvData)
  const [rightTableData, setRightTableData] = useState<RowData[]>(existingData)

  const [originalSide] = useState(() => {
    const map: Record<string, 'left' | 'right'> = {}
    csvData.forEach((row) => {
      map[row.profile_url] = 'left'
    })
    existingData.forEach((row) => {
      map[row.profile_url] = 'right'
    })
    return map
  })

  // Keep track of how many leads were "Added" or "Removed"
  const [addedCount, setAddedCount] = useState(0)
  const [removedCount, setRemovedCount] = useState(0)

  // Pagination state for each table
  const [currentPageLeft, setCurrentPageLeft] = useState(1)
  const [currentPageRight, setCurrentPageRight] = useState(1)

  // Track selected row IDs for each table
  const [selectedLeft, setSelectedLeft] = useState<any[]>([])
  const [selectedRight, setSelectedRight] = useState<any[]>([])

  // Whether we are currently in "add" mode (left table has selections)
  const inAddMode = selectedLeft.length > 0
  // Whether we are currently in "remove" mode (right table has selections)
  const inRemoveMode = selectedRight.length > 0

  const isOverAddLimit = addedCount > maxAdd - existingData.length
  const isOverTotalLimit = rightTableData.length > maxAdd

  // Columns for both tables (you can replace with tableColumn if you prefer)
  const columns: ColumnsType<RowData> = [
    {
      title: 'Profile URL',
      dataIndex: 'profile_url',
      align: 'center',
      key: 'profile_url',
      ellipsis: true,
      render: (profile_url: string) => {
        const match = profile_url?.match(/(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/([^/?]+)/)
        const username = match ? match[1] : profile_url

        return (
          <div className={styles.profileUrl}>
            {/* <LinkOutlined
              style={{ color: '#7043ff', cursor: 'pointer' }}
              onClick={() => window.open(profile_url, '_blank')}
            /> */}
            <Tooltip title={username} placement='topLeft' color='white' overlayInnerStyle={{ color: 'black' }}>
              <span onClick={() => window.open(profile_url, '_blank')}>{username}</span>
            </Tooltip>
          </div>
        )
      },
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      align: 'center',
      key: 'first_name',
      ellipsis: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      align: 'center',
      key: 'last_name',
      ellipsis: true,
    },
    {
      title: 'Company',
      dataIndex: 'company',
      align: 'center',
      key: 'company',
      ellipsis: true,
    },
    {
      title: 'Heat Level',
      dataIndex: 'heat_level',
      align: 'center',
      key: 'heat_level',
      ellipsis: true,
      width: 98,
    },
    {
      title: 'List',
      dataIndex: 'source',
      align: 'center',
      key: 'source',
      ellipsis: true,
      render: (source: string) => (
        <Tooltip title={source} placement='topLeft' color='white' overlayInnerStyle={{ color: 'black' }}>
          <span>{source || 'N/A'}</span>
        </Tooltip>
      ),
    },
  ]

  // Handle left-side (CSV) selection changes
  const onLeftSelectionChange: any = (selectedRowKeys: any, _selectedRows: any) => {
    // If the user is selecting on the left but already had selections on the right, clear the right side
    if (inRemoveMode) {
      setSelectedRight([])
    }
    // If the user tries to select more than maxAdd, revert or limit
    if (selectedRowKeys.length > maxAdd) {
      return // or show a message, or limit the selection
    }
    setSelectedLeft(selectedRowKeys)
  }

  // Handle right-side (existing data) selection changes
  const onRightSelectionChange: any = (selectedRowKeys: any, _selectedRows: any) => {
    // If the user is selecting on the right but already had selections on the left, clear the left side
    if (inAddMode) {
      setSelectedLeft([])
    }
    setSelectedRight(selectedRowKeys)
  }

  // Configure row selection for each table
  const leftRowSelection: TableProps<RowData>['rowSelection'] = {
    selectedRowKeys: selectedLeft,
    onChange: onLeftSelectionChange,
    getCheckboxProps: () => ({
      disabled: inRemoveMode,
    }),
    columnTitle: (
      <TableRowSelectionDropdown
        data={leftTableData}
        pageSize={pageSize}
        currentPage={currentPageLeft}
        selectedRowKeys={selectedLeft}
        onSelectChange={onLeftSelectionChange}
        originalRows={leftTableData?.filter((row) => originalSide[row.profile_url] === 'left')}
        modifiedRows={leftTableData?.filter((row) => originalSide[row.profile_url] === 'right')}
      />
    ),
  }

  const rightRowSelection: TableProps<RowData>['rowSelection'] = {
    selectedRowKeys: selectedRight,
    onChange: onRightSelectionChange,
    getCheckboxProps: () => ({
      disabled: inAddMode,
    }),
    columnTitle: (
      <TableRowSelectionDropdown
        data={rightTableData}
        pageSize={pageSize}
        currentPage={currentPageRight}
        selectedRowKeys={selectedRight}
        onSelectChange={onRightSelectionChange}
        originalRows={rightTableData?.filter((row) => originalSide[row.profile_url] === 'right')}
        modifiedRows={rightTableData?.filter((row) => originalSide[row.profile_url] === 'left')}
      />
    ),
  }

  const handleReset = () => {
    // Reset tables to their original data
    setLeftTableData(csvData)
    setRightTableData(existingData)

    // Clear all selections
    setSelectedLeft([])
    setSelectedRight([])

    // Reset counters
    setAddedCount(0)
    setRemovedCount(0)

    // Reset pagination to first page
    setCurrentPageLeft(1)
    setCurrentPageRight(1)

    // Reset limit status
    if (onLimitChange) {
      onLimitChange(false)
    }
  }

  /**
   * Move selected rows from left -> right
   * Remove them from leftTableData, append to rightTableData,
   * and increment 'Added' count.
   */

  const handleAddLeads = () => {
    // Find which rows are selected on the left
    const rowsToMove = leftTableData.filter((row) => selectedLeft.includes(row.profile_url))
    if (rowsToMove.length === 0) return

    // Update left side (remove those rows)
    setLeftTableData((prev) => prev.filter((row) => !selectedLeft.includes(row.profile_url)))

    // Update right side (append those rows)
    setRightTableData((prev) => [...rowsToMove, ...prev])

    // Only increase addedCount for rows that were originally on the left side
    const newlyAdded = rowsToMove.filter((row) => originalSide[row.profile_url] === 'left').length
    setAddedCount((prev) => prev + newlyAdded)

    // Decrease removedCount for rows that were originally on the right side (moving back)
    const restoredRows = rowsToMove.filter((row) => originalSide[row.profile_url] === 'right').length
    setRemovedCount((prev) => Math.max(0, prev - restoredRows))

    // Clear the selection
    setSelectedLeft([])
  }

  /**
   * Move selected rows from right -> left
   * Remove them from rightTableData, append to leftTableData,
   * and increment 'Removed' count.
   */
  const handleRemoveLeads = () => {
    // Find which rows are selected on the right
    const rowsToMove = rightTableData.filter((row) => selectedRight.includes(row.profile_url))
    if (rowsToMove.length === 0) return

    // Update right side (remove those rows)
    setRightTableData((prev) => prev.filter((row) => !selectedRight.includes(row.profile_url)))

    // Update left side (append those rows)
    setLeftTableData((prev) => [...rowsToMove, ...prev])

    // Only increase removedCount for rows that were originally on the right side
    const newlyRemoved = rowsToMove.filter((row) => originalSide[row.profile_url] === 'right').length
    setRemovedCount((prev) => prev + newlyRemoved)

    // Decrease addedCount for rows that were originally on the left side (moving back)
    const restoredRows = rowsToMove.filter((row) => originalSide[row.profile_url] === 'left').length
    setAddedCount((prev) => Math.max(0, prev - restoredRows))

    // Clear the selection
    setSelectedRight([])
  }

  const getLeftRowClassName = (record: RowData) => {
    return originalSide[record.profile_url] === 'left' ? styles.originalColor : styles.highlightedColor
  }

  const getRightRowClassName = (record: RowData) => {
    return originalSide[record.profile_url] === 'right' ? styles.originalColor : styles.highlightedColor
  }

  useEffect(() => {
    handleReset()
  }, [reset])

  // Notify parent component of limit status changes
  useEffect(() => {
    if (onLimitChange) {
      onLimitChange(isOverAddLimit || isOverTotalLimit)
    }
  }, [isOverAddLimit, isOverTotalLimit, onLimitChange])

  useEffect(() => {
    if (done) {
      const addedRows = rightTableData.filter((row) => originalSide[row.profile_url] === 'left')
      const removedRows = leftTableData.filter((row) => originalSide[row.profile_url] === 'right')
      onFinish(addedRows, removedRows)
    }
  }, [done])

  // Middle part: add or remove
  const centerText = inAddMode ? (
    <div className={styles.centeredText}>
      <img src={right_arrow_add} alt='' onClick={handleAddLeads} />
      <span>Add</span> <span>({selectedLeft.length})</span>
    </div>
  ) : inRemoveMode ? (
    <div className={styles.centeredText}>
      <img src={left_arrow_add} alt='' onClick={handleRemoveLeads} />
      <span>Remove</span> <span>({selectedRight.length})</span>
    </div>
  ) : (
    ''
  )

  return (
    <div className={styles.container}>
      {/* Left side: CSV data */}
      <div className={styles.tableContainer}>
        <div className={styles.tableHeader}>
          <div className={styles.title}>
            <p>Pending Insertion to Heatbox</p>
            <span>
              Prospects who selected <b>not to be tracked on your heatbox.</b>
            </span>
          </div>
          <div className={styles.stats}>
            <p>Total</p>
            <span>{leftTableData.length || 0}</span>
          </div>
        </div>
        <div className={styles.tableWrapper}>
          <Table
            rowClassName={getLeftRowClassName}
            className={styles.table}
            rowKey={(record) => record.profile_url}
            columns={columns}
            dataSource={leftTableData}
            style={{ height: '100%' }}
            scroll={{ y: '45vh' }}
            rowSelection={leftRowSelection}
            pagination={{
              onChange: (num) => setCurrentPageLeft(num),
              pageSize: pageSize,
              position: ['bottomCenter'],
            }}
          />
        </div>
      </div>

      {/* Middle area: shows Add / Remove text */}
      <div className={styles.centerArea}>{centerText}</div>

      {/* Right side: existing user data */}
      <div className={styles.tableContainer}>
        <div className={styles.tableHeader}>
          <div className={styles.title}>
            <p>My Heatbox</p>
            <span>
              Prospects who selected <b>to be tracked on your heatbox.</b>
            </span>
          </div>
          <div className={styles.statsContainer}>
            <Tooltip title={isOverTotalLimit && 'You have reached your total heatbox limit.'} placement='bottom'>
              <div className={isOverTotalLimit ? styles.statsOverLimit : styles.stats}>
                <p>Total</p>
                <span>{rightTableData.length || 0}</span>
              </div>
            </Tooltip>
            <Tooltip title={isOverAddLimit && 'You have reached your total heatbox limit.'} placement='bottom'>
              <div className={isOverAddLimit ? styles.statsOverLimit : styles.stats}>
                <p>Added</p>
                <span>{addedCount}</span>
              </div>
            </Tooltip>
            <div className={styles.stats}>
              <p>Removed</p>
              <span>{removedCount}</span>
            </div>
          </div>
        </div>
        <div className={styles.tableWrapper}>
          <Table
            rowClassName={getRightRowClassName}
            rowKey={(record) => record.profile_url}
            className={styles.table}
            columns={columns}
            dataSource={rightTableData}
            rowSelection={rightRowSelection}
            style={{ height: '100%' }}
            scroll={{ y: '45vh' }}
            pagination={{
              onChange: (num) => setCurrentPageRight(num),
              pageSize: pageSize,
              position: ['bottomCenter'],
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default HeatboxView
