import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'common/APIs/baseQueryWithValidation'

export const SettingsApi: any = createApi({
  reducerPath: 'SettingsApi',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    getCategorizer: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/categorizer/getLists`,
        method: 'GET',
      }),
    }),
    getUserPersonas: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/categorizer/getUserPersonas`,
        method: 'GET',
      }),
    }),
    removeCategory: builder.mutation({
      query: (param: any) => ({
        url: `${process.env.REACT_APP_API_URL}/categorizer/deleteCategory/${param.index}`,
        method: 'DELETE',
      }),
    }),
    addCategory: builder.mutation({
      query: (params?: any) => ({
        url: `${process.env.REACT_APP_API_URL}/categorizer/addCategory`,
        method: 'POST',
        body: {
          category_name: params.category_name,
          category_description: params.category_description,
          personas: params.personas,
        },
      }),
    }),
    addCategoryRunAnyway: builder.mutation({
      query: (params?: any) => ({
        url: `${process.env.REACT_APP_API_URL}/categorizer/addCategoryRunAnyway`,
        method: 'POST',
        body: {
          category_name: params.category_name,
          category_description: params.category_description,
          personas: params.personas,
        },
      }),
    }),
    updateCategory: builder.mutation({
      query: (params?: any) => ({
        url: `${process.env.REACT_APP_API_URL}/categorizer/updateCategory/${params.index}`,
        method: 'PUT',
        body: {
          category_name: params.category_name,
          category_description: params.category_description,
          personas: params.personas,
        },
      }),
    }),
    updateCategoryStatus: builder.mutation({
      query: (params?: any) => ({
        url: `${process.env.REACT_APP_API_URL}/categorizer/updateCategoryStatus/${params.index}`,
        method: 'PUT',
        body: {
          is_active: params.is_active,
        },
      }),
    }),
    getCredits: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings`,
      }),
    }),
    getSubscriptionSettings: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getSubscriptionSettings`,
      }),
    }),
    getHandleSpothub: builder.query({
      query: (key: string) => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/hubspot`,
        method: 'POST',
        body: { key: key },
      }),
    }),
    getHubspotProperties: builder.query({
      query: (key?: string) => ({
        url: `${process.env.REACT_APP_API_URL}/integrations/hubspot/getProperties`,
        method: 'POST',
        body: { key: key },
      }),
    }),
    addHubspotFieldMapping: builder.query({
      query: (params?: any) => ({
        url: `${process.env.REACT_APP_API_URL}/integrations/hubspot/fieldMapping`,
        method: 'POST',
        body: { hubspotFieldMapping: params.hubspotFieldMapping, isSaveFieldMapping: params.isSaveFieldMapping },
      }),
    }),
    getLoginHubSpot: builder.query({
      query: (key: string) => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/hubspotOAuth`,
        method: 'POST',
        body: { key: key },
      }),
    }),
    getPointsStore: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/pointsStore`,
        method: 'GET',
      }),
    }),
    getFormattedPointsStore: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/getFormattedPointsStore`,
        method: 'GET',
      }),
    }),
    updateOutreachDetails: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/updateOutreachDetails`,
        method: 'POST',
        body: {
          value: params.value,
          type: params.type,
          key: params.key,
        },
      }),
    }),
    getTelegramAccountInfo: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/user/getTelegramAccountInfo?telegram_number=${params.telegram_number}`,
        method: 'GET',
      }),
    }),
    removeAccount: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/removeAccount`,
        method: 'POST',
        body: params,
      }),
    }),
    getCampaignsForAccountsService: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getCampaignsForAccounts`,
        method: 'GET',
      }),
    }),
    getSlackConnection: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/slack/install`,
        method: 'GET',
      }),
    }),
    redirectSlackOAuth: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/slack/oauth_redirect?code=${encodeURIComponent(
          params.code,
        )}`,
        method: 'GET',
      }),
    }),
    getSlackKeys: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/slack/getKeys`,
        method: 'GET',
      }),
    }),
    disconnectSlack: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/slack/disconnect`,
        method: 'GET',
      }),
    }),
    getSalesforceAuthorizationUrl: builder.query({
      query: (params: any) => ({
        url: `${
          process.env.REACT_APP_API_URL
        }/admin/settings/salesforce/getAuthorizationUrl?codeChallenge=${encodeURIComponent(params.codeChallenge)}`,
        method: 'GET',
      }),
    }),
    getSalesforceAccessToken: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/salesforce/getAccessToken?codeVerifier=${params.codeVerifier}&authCode=${params.authCode}`,
        method: 'GET',
      }),
    }),
    redirectSalesforceOAuth: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/salesforce/oauth_redirect?code=${encodeURIComponent(
          params.code,
        )}`,
        method: 'GET',
      }),
    }),
    getSalesforceKeys: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/salesforce/getKeys`,
        method: 'GET',
      }),
    }),
    disconnectSalesforce: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/salesforce/disconnect`,
        method: 'GET',
      }),
    }),
    updateUserData: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/user/updateUser`,
        method: 'POST',
        body: {
          addonName: params.addonName,
          addonValue: params.addonValue,
        },
      }),
    }),
    getIndividualPersonas: builder.query({
      query: (body) => ({
        url: `${process.env.REACT_APP_API_URL}/user/getIndividualPersonas`,
        method: 'GET',
      }),
    }),
    addIndividualPersonas: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_API_URL}/user/addIndividualPersonas`,
        method: 'POST',
        body: body,
      }),
    }),
    updateIndividualPersonas: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_API_URL}/user/updateIndividualPersonas`,
        method: 'POST',
        body: body,
      }),
    }),
    submitOnboardingStep: builder.query({
      query: ({ step, data }) => ({
        url: `${process.env.REACT_APP_API_URL}/user/onboarding/${step}`,
        method: 'POST',
        body: data,
      }),
    }),
    updateICP: builder.query({
      query: ({ step, data }) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/updateICP`,
        method: 'POST',
        body: data,
      }),
    }),
    getUserMetadata: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getUserMetadata`,
        method: 'GET',
      }),
    }),
    getUserSettings: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getUserSettings`,
        method: 'GET',
      }),
    }),
    getSocialMetadata: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getSocialMetadata?platform=${params.platform}`,
        method: 'GET',
      }),
    }),
    getExistingSocialMetadata: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getExistingSocialMetadata`,
        method: 'GET',
      }),
    }),
    getResponders: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getResponders`,
        method: 'GET',
      }),
    }),
    saveResponder: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/saveResponder`,
        method: 'POST',
        body: params,
      }),
    }),
    deleteResponder: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/deleteResponder`,
        method: 'POST',
        body: params,
      }),
    }),
    isRegistrationAdmin: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/settings/isRegistrationAdmin`,
        method: 'GET',
      }),
    }),
    getRegistrationURL: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getRegistrationURL`,
        method: 'POST',
        body: params,
      }),
    }),
    getAffiliatesTable: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/auth/getAffiliatesTable`,
        method: 'GET',
      }),
    }),
    generateOneTimeToken: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/generateOneTimeToken`,
        method: 'POST',
        body: params,
      }),
    }),
    getAllRegistrationLinks: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getAllRegistrationLinks`,
        method: 'GET',
      }),
    }),
    getAllABLinks: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getAllABLinks`,
        method: 'GET',
      }),
    }),
    generateABLink: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/generateABLink`,
        method: 'POST',
        body: params,
      }),
    }),
    setActiveABLink: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/setActiveABLink`,
        method: 'POST',
        body: params,
      }),
    }),
    getAllIcp: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getAllIcp`,
        method: 'GET',
      }),
    }),
    getTags: builder.mutation({
      query: (params: string) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/tags?search=${params}`,
      }),
    }),
  }),
})

export const {
  useLazyGetAffiliatesTableQuery,
  useLazyGetRegistrationURLQuery,
  useLazyIsRegistrationAdminQuery,
  useLazyGetCreditsQuery,
  useLazyGetSubscriptionSettingsQuery,
  useLazyGetHandleSpothubQuery,
  useLazyGetHubspotPropertiesQuery,
  useLazyAddHubspotFieldMappingQuery,
  useLazyGetLoginHubSpotQuery,
  useLazyGetPointsStoreQuery,
  useLazyGetFormattedPointsStoreQuery,
  useLazyUpdateOutreachDetailsQuery,
  useLazyGetTelegramAccountInfoQuery,
  useLazyGetCampaignsForAccountsServiceQuery,
  useLazyGetSlackConnectionQuery,
  useLazyRedirectSlackOAuthQuery,
  useLazyGetSlackKeysQuery,
  useLazyDisconnectSlackQuery,
  useLazyGetSalesforceAccessTokenQuery,
  useLazyGetSalesforceAuthorizationUrlQuery,
  useLazyRedirectSalesforceOAuthQuery,
  useLazyGetSalesforceKeysQuery,
  useLazyDisconnectSalesforceQuery,
  useLazyUpdateUserDataQuery,
  useLazyUpdateICPQuery,
  useLazyGetIndividualPersonasQuery,
  useAddIndividualPersonasMutation,
  useUpdateIndividualPersonasMutation,
  useLazySubmitOnboardingStepQuery,
  useLazyGetUserMetadataQuery,
  useLazyGetCategorizerQuery,
  useLazyGetUserPersonasQuery,
  useRemoveCategoryMutation,
  useAddCategoryMutation,
  useAddCategoryRunAnywayMutation,
  useUpdateCategoryMutation,
  useUpdateCategoryStatusMutation,
  useLazyGetUserSettingsQuery,
  useLazyGetSocialMetadataQuery,
  useLazyGetExistingSocialMetadataQuery,
  useLazyGetRespondersQuery,
  useLazySaveResponderQuery,
  useLazyDeleteResponderQuery,
  useLazyRemoveAccountQuery,
  useLazyGenerateOneTimeTokenQuery,
  useLazyGetAllRegistrationLinksQuery,
  useLazyGetAllABLinksQuery,
  useLazyGenerateABLinkQuery,
  useLazySetActiveABLinkQuery,
  useLazyGetAllIcpQuery,
  useGetTagsMutation,
} = SettingsApi
