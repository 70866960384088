import React, { useState, useEffect } from 'react'
import { Table, Button, Typography, Popconfirm, Empty, Tooltip, message } from 'antd'
import { ExportOutlined, KeyOutlined } from '@ant-design/icons'
import trash_icon_gray from 'common/assets/svg/trash_icon_gray.svg'
import styles from './APIKeysManagement.module.scss'
import APIKeyModal from './APIKeyModal'
import { useLazyDeleteAPIKeyQuery, useLazyGenerateAPIKeyQuery, useLazyGetAPIKeysQuery } from 'common/APIs/ConvrtApi'
import { useUser } from 'Utils/UserContext'
import moment from 'moment'

interface APIKey {
  name: string
  secret_key: string
  createdBy: string
  created_at: string
  expires_at: string
  scopes: string[] // Fixed to string[]
  key: string
  id: string
}

const { Title } = Typography

const APIKeysManagement: React.FC = () => {
  const { user } = useUser()
  const email = user.email
  const [apiKeys, setApiKeys] = useState<APIKey[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [generateAPIKey] = useLazyGenerateAPIKeyQuery()
  const [getAPIKeys] = useLazyGetAPIKeysQuery()
  const [deleteAPIKey] = useLazyDeleteAPIKeyQuery()

  const getKeys = async () => {
    setLoading(true)
    const { data } = await getAPIKeys()
    setApiKeys(data || [])
    setLoading(false)
  }

  useEffect(() => {
    getKeys()
  }, [])

  const handleDeleteKey = async (key: string) => {
    console.log(key)
    const { data } = await deleteAPIKey({ key })
    if (data) {
      setApiKeys(apiKeys.filter((item) => item.id !== key))
      message.success('API key deleted successfully')
    } else {
      message.error('An error has occured deleting this API key. Please try again or contact support.')
    }
  }

  const handleGenerateKey = () => {
    setIsModalVisible(true)
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
  }

  const generateNewKey = async (name: string, scopes: string[], expires_at: string): Promise<string> => {
    try {
      const { data, error } = await generateAPIKey({ name, scopes, expires_at })
      if (error) {
        message.error('Failed to generate API key')
        throw new Error('API key generation failed')
      }
      const newKey = data.key
      const partial_key = data.partial_key

      const newApiKey: APIKey = {
        id: data.id,
        key: data.id,
        name: name,
        secret_key: partial_key,
        createdBy: email,
        created_at: new Date().toLocaleString(),
        expires_at: expires_at,
        scopes,
      }
      setApiKeys([...apiKeys, newApiKey])
      return newKey
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: APIKey, b: APIKey) => a.name.localeCompare(b.name),
    },
    {
      title: 'Secret Key',
      dataIndex: 'secret_key',
      key: 'secret_key',
      sorter: (a: APIKey, b: APIKey) => a.secret_key.localeCompare(b.secret_key),
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: (a: APIKey, b: APIKey) => a.createdBy.localeCompare(b.createdBy),
      render: () => email,
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a: APIKey, b: APIKey) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (created_at: any) => {
        return <Typography>{moment(created_at).format('MM/DD/YYYY')}</Typography>
      },
    },
    {
      title: 'Expires At',
      dataIndex: 'expires_at',
      key: 'expires_at',
      sorter: (a: APIKey, b: APIKey) => new Date(a.expires_at).getTime() - new Date(b.expires_at).getTime(),
      render: (expires_at: any) => {
        return <Typography>{moment(expires_at).format('MM/DD/YYYY')}</Typography>
      },
    },
    {
      title: 'Scopes',
      dataIndex: 'scopes',
      key: 'scopes',
      render: (scopes: string[]) => {
        if (Array.isArray(scopes)) {
          return scopes.join(', ')
        }
        return scopes
      },
    },
    {
      title: '',
      key: 'action',
      render: (_: any, record: APIKey) => {
        console.log(record)
        return (
          <Tooltip title='Delete'>
            <Popconfirm
              title='Are you sure you want to delete this API key?'
              onConfirm={() => handleDeleteKey(record.id)}
              okText='Yes'
              cancelText='No'>
              <Button type='text' icon={<img src={trash_icon_gray} alt='' />} className={styles.deleteButton} />
            </Popconfirm>
          </Tooltip>
        )
      },
    },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <KeyOutlined className={styles.keyIcon} />
          <Title level={4} className={styles.title}>
            API Keys
          </Title>
        </div>
        <div className={styles.actions}>
          <a
            href='https://connectplus-api.convrt.io/docs'
            target='_blank'
            rel='noopener noreferrer'
            className={styles.docsLink}>
            API Docs <ExportOutlined />
          </a>
          <Button type='primary' onClick={handleGenerateKey} className={styles.generateButton}>
            Generate
          </Button>
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={apiKeys}
        loading={loading}
        rowKey='key'
        pagination={false}
        className={styles.table}
        scroll={{ y: '60vh' }}
        locale={{
          emptyText: <Empty description='No API keys found' image={Empty.PRESENTED_IMAGE_SIMPLE} />,
        }}
      />

      <APIKeyModal isVisible={isModalVisible} onCancel={handleModalCancel} onGenerate={generateNewKey} />
    </div>
  )
}

export default APIKeysManagement
