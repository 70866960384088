import React from 'react'
import { Collapse, theme } from 'antd'
import styles from './CollapseStep.module.scss'

// import select_upload_type from 'LEGACY/Assets/svg/select_upload_type.svg'
// import select_upload_type_active from 'LEGACY/Assets/svg/select_upload_type_active.svg'

import import_and_mapping from 'LEGACY/Assets/svg/import_and_mapping.svg'
import import_and_mapping_active from 'LEGACY/Assets/svg/import_and_mapping_active.svg'

import enrich_data from 'LEGACY/Assets/svg/enrich_data.svg'
import enrich_data_active from 'LEGACY/Assets/svg/enrich_data_active.svg'

import completed_icon from 'LEGACY/Assets/svg/completeIcon.svg'
import uncompleted_icon from 'LEGACY/Assets/svg/uncompleted_icon.svg'

// import { SelectUploadType } from './SelectUploadType'
import { ImportAndMapping } from './ImportAndMapping'
import { EnrichYourData } from './EnrichYourData'
import { useAppSelector } from 'state'
import { platformsAreEmpty } from 'common/utils/userHelpers'

const { Panel } = Collapse

interface Props {
  activeKey: string
  importTypeNumber: number
  isCompanyImport: boolean
  onChangeActiveKey: (key: string) => void
  onBackToExposedContact: () => void
}

const CollapseStep = ({
  activeKey,
  importTypeNumber,
  isCompanyImport,
  onBackToExposedContact,
  onChangeActiveKey,
}: Props) => {
  const { token } = theme.useToken()
  const { csvData } = useAppSelector((state) => state.importContact)
  const { selected_platforms_accounts } = useAppSelector((state) => state.outreachAICampaign)
  const panelStyle: React.CSSProperties = {
    backgroundColor: 'white',
    borderRadius: token.borderRadiusLG,
    border: '1px solid #DFE1E6',
  }

  const generateHeader = (key: string, text: string) => {
    let image
    let editBtn
    let completeIcon
    switch (key) {
      // case '1':
      //   image = activeKey === '1' ? select_upload_type_active : select_upload_type
      //   editBtn = activeKey === '1' ? '' : 'Edit'
      //   completeIcon = activeKey === '1' ? '' : importTypeNumber ? completed_icon : uncompleted_icon
      //   break
      case '1':
        image = activeKey === '1' ? import_and_mapping_active : import_and_mapping
        editBtn = activeKey === '1' ? '' : !importTypeNumber ? '' : 'Edit'
        completeIcon = activeKey === '1' ? '' : csvData?.rowData?.length > 0 ? completed_icon : uncompleted_icon
        break
      case '2':
        image = activeKey === '2' ? enrich_data_active : enrich_data
        editBtn =
          activeKey === '2'
            ? ''
            : csvData?.rowData && csvData?.rowData?.length > 0 && !platformsAreEmpty(selected_platforms_accounts)
            ? 'Edit'
            : ''
        completeIcon = activeKey === '2' ? '' : uncompleted_icon
        break
    }

    return (
      <div className={styles.container} onClick={(e: any) => e.stopPropagation()}>
        <div className={styles.leftSide}>
          <img src={image} alt='' />
          <span className={`${styles.headerText} ${key === activeKey && styles.headerText__active}`}>{text}</span>
        </div>
        <div className={styles.rightSide}>
          <span style={{ cursor: 'pointer' }} onClick={() => handleClick(key, activeKey)}>
            {editBtn}
          </span>
          <img src={completeIcon} alt='' className={styles.completeIcon} />
        </div>
      </div>
    )
  }

  const handleClick = (key: string, curKey?: any) => {
    onChangeActiveKey(key)
  }

  return (
    <Collapse
      className={`${styles['custom-collapse']} customCollapseImportContact`}
      activeKey={activeKey}
      onChange={(key) => handleClick(key.toString())}
      accordion>
      {/* <Panel header={generateHeader('1', 'Select Upload Type')} key='1' style={panelStyle} showArrow={false}>
        <SelectUploadType
          importTypeNumber={importTypeNumber}
          onContinue={() => {
            onChangeActiveKey('2')
          }}
        />
      </Panel> */}
      <Panel
        header={generateHeader('1', 'Import And Mapping')}
        key='1'
        style={{
          ...panelStyle,
        }}
        showArrow={false}>
        <ImportAndMapping
          importTypeNumber={importTypeNumber}
          isCompanyImport={isCompanyImport}
          onBackToExposedContact={onBackToExposedContact}
          onContinue={() => {
            onChangeActiveKey('2')
          }}
        />
      </Panel>

      {!isCompanyImport && (
        <Panel
          header={generateHeader('2', 'Enrich your data')}
          key='2'
          style={{
            ...panelStyle,
            marginTop: '24px',
          }}
          showArrow={false}>
          <EnrichYourData onBackToExposedContact={onBackToExposedContact} />
        </Panel>
      )}
    </Collapse>
  )
}

export default CollapseStep
