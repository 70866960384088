import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import styles from './CustomComment.module.scss'
import { ClockCircleOutlined, LoadingOutlined } from '@ant-design/icons'

interface CustomCommentProps {
  author: string
  content: string
  verificationDelay?: number
  commentStatus?: string
}

const CustomComment: React.FC<CustomCommentProps> = ({ author, content, verificationDelay = 1000, commentStatus }) => {
  // Only use the delay for tick animation (completed/success)
  const [isVerified, setIsVerified] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVerified(true)
    }, verificationDelay)

    return () => clearTimeout(timer)
  }, [verificationDelay, commentStatus])

  // Variants for the tick and its container.
  const tickVariants = {
    hidden: {
      opacity: 0,
      scale: 0.5,
      pathLength: 0,
    },
    visible: {
      opacity: 1,
      scale: 1,
      pathLength: 1,
      transition: {
        duration: 0.3,
        ease: 'easeInOut',
      },
    },
  }

  const circleVariants = {
    hidden: {
      opacity: 0,
      scale: 0.5,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.4,
        ease: 'easeInOut',
      },
    },
  }

  let icon = null

  if (commentStatus === 'completed' || commentStatus === 'success') {
    // Render a tick animation.
    icon = (
      <motion.div initial='hidden' animate={isVerified ? 'visible' : 'hidden'} className={styles.verificationIcon}>
        <motion.div variants={circleVariants} className={styles.circle}>
          <motion.svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
            <motion.path
              d='M6 12L10 16L18 8'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              variants={tickVariants}
            />
          </motion.svg>
        </motion.div>
      </motion.div>
    )
  } else if (commentStatus === 'failed') {
    // Render a red X animation.
    icon = (
      <motion.div initial='hidden' animate='visible' className={styles.verificationIcon}>
        <motion.div variants={circleVariants} className={styles.circle} style={{ backgroundColor: 'red' }}>
          <motion.svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
            <motion.path
              d='M6 6L18 18'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              variants={tickVariants}
            />
            <motion.path
              d='M18 6L6 18'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              variants={tickVariants}
            />
          </motion.svg>
        </motion.div>
      </motion.div>
    )
  } else if (commentStatus === 'pending') {
    icon = <ClockCircleOutlined style={{ fontSize: 24, color: '#7043ff' }} />
  } else {
    // Render a waiting spinner animation.
    icon = <LoadingOutlined style={{ fontSize: 24, color: '#7043ff' }} spin />
  }

  return (
    <div className={styles.commentContainer}>
      <div className={styles.commentContent}>
        <h3 className={styles.author}>{author}</h3>
        <div className={styles.textWrapper}>
          <p className={styles.text}>{content}</p>
        </div>
      </div>
      <div className={styles.verificationContainer}>{icon}</div>
    </div>
  )
}

export default CustomComment
