// ExpandableText.tsx
import React, { useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react'

interface ExpandableTextProps {
  text: string
  lineClamp?: number
  className?: string
  style?: React.CSSProperties
  linkColor?: string
  /** Controlled expanded state */
  expanded?: boolean
  /** Callback to toggle the expanded state */
  toggleExpanded?: () => void
}

export const ExpandableText: React.FC<ExpandableTextProps> = ({
  text,
  lineClamp = 3,
  className,
  style,
  linkColor = '#7043ff',
  expanded,
  toggleExpanded,
}) => {
  // Ref for the visible container.
  const containerRef = useRef<HTMLDivElement>(null)
  // Ref for a hidden element used to measure text height.
  const measureRef = useRef<HTMLDivElement>(null)
  // Track the container's width (affects text wrapping).
  const [containerWidth, setContainerWidth] = useState<number>(0)

  // Internal state fallback for expanded if not controlled.
  const [internalExpanded, setInternalExpanded] = useState<boolean>(false)
  const isExpanded = expanded !== undefined ? expanded : internalExpanded
  const toggleExpandedInternal = useCallback(() => {
    setInternalExpanded((prev) => !prev)
  }, [])
  const toggle: any = toggleExpanded ? toggleExpanded : toggleExpandedInternal

  // States to decide if truncation is needed and store the truncated substring.
  const [isTruncated, setIsTruncated] = useState<boolean>(false)
  const [truncatedText, setTruncatedText] = useState<string>('')

  // On mount (and when the container resizes), update the container width.
  useLayoutEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.clientWidth)
      }
    }

    updateWidth()

    window.addEventListener('resize', updateWidth)
    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  // Re-run measurement when the text, number of lines, or container width changes.
  useEffect(() => {
    if (!containerRef.current || !measureRef.current) {
      return
    }
    // Make sure the measurement node is the same width as the visible container.
    measureRef.current.style.width = `${containerWidth}px`

    // Retrieve the computed line height from the container.
    const computedStyle = window.getComputedStyle(containerRef.current)
    let lineHeight = parseFloat(computedStyle.lineHeight)
    if (!lineHeight || isNaN(lineHeight)) {
      const fontSize = parseFloat(computedStyle.fontSize)
      lineHeight = fontSize * 1.2
    }
    // Calculate the maximum allowed height in pixels.
    const maxHeight = lineClamp * lineHeight

    // Measure the full text height.
    measureRef.current.innerText = text
    const fullHeight = measureRef.current.clientHeight

    if (fullHeight <= maxHeight) {
      // The text fits in the allowed number of lines; no need to truncate.
      setIsTruncated(false)
      return
    }

    // Otherwise, truncation is needed.
    setIsTruncated(true)

    // Use binary search to find the largest substring that, when appended with the suffix,
    // still fits within the allowed height.
    const suffix = '... Show More'
    let low = 0
    let high = text?.length
    let suitable = ''

    while (low <= high) {
      const mid = Math.floor((low + high) / 2)
      const candidate = text?.slice(0, mid)?.trim()
      // Append the suffix and measure.
      measureRef.current.innerText = candidate + suffix
      const candidateHeight = measureRef.current.clientHeight
      if (candidateHeight <= maxHeight) {
        suitable = candidate
        low = mid + 1
      } else {
        high = mid - 1
      }
    }
    setTruncatedText(suitable)
  }, [text, lineClamp, containerWidth])

  // Helper function to format text while preserving newlines.
  const formatText = (input: string) =>
    input?.split('\n')?.map((line, index, arr) => (
      <React.Fragment key={index}>
        {line}
        {index < arr.length - 1 && <br />}
      </React.Fragment>
    ))

  return (
    <>
      {/* Visible container */}
      <div ref={containerRef} className={className} style={{ display: 'inline', ...style }}>
        {isExpanded || !isTruncated ? (
          <span>
            {formatText(text)}
            {isTruncated && (
              <span onClick={toggle} style={{ color: linkColor, cursor: 'pointer', fontSize: 14, fontWeight: 600 }}>
                {' '}
                Show Less
              </span>
            )}
          </span>
        ) : (
          <span>
            {formatText(truncatedText)}
            {'... '}
            <span onClick={toggle} style={{ color: linkColor, cursor: 'pointer', fontSize: 14, fontWeight: 600 }}>
              Show More
            </span>
          </span>
        )}
      </div>

      <div
        ref={measureRef}
        style={{
          position: 'absolute',
          visibility: 'hidden',
          whiteSpace: 'pre-wrap',
          top: 0,
          left: 0,
          overflow: 'visible',
        }}
      />
    </>
  )
}
