import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'LEGACY/API/baseQueryWithValidation'

export const OutreachApiWeb3: any = createApi({
  reducerPath: 'OutreachApiWeb3',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    /* ---------- Exposed Contacts ------- */
    getSenderList: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/outreach/senderList`,
        method: 'GET',
      }),
    }),
    getAllCampaigns: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getAllCampaigns`,
        method: 'GET',
      }),
    }),
    downloadCSV: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/downloadCSV`,
        method: 'GET',
      }),
    }),
    getSaveFilters: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/filters/get`,
        body: params,
      }),
    }),
    addSaveFilters: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/filters/add`,
        body: params,
      }),
    }),
    deleteSaveFilters: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/filters/delete`,
        body: params,
      }),
    }),
    getLinkedinSenders: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getLinkedinSenders`,
        method: 'GET',
      }),
    }),
    getCheckReplies: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/outreach/checkReplies`,
        method: 'POST',
        body: {
          outreach: params.outreach,
          max_messages_to_check: params.max_messages_to_check,
          usernames: params.usernames,
        },
      }),
    }),
    getAutoCheckReplies: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/outreach/autoCheckReplies`,
        method: 'POST',
        body: {},
      }),
    }),
    /* ---------- Exposed Contacts ------- */

    getCampaigns: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getCampaigns`,
        method: 'POST',
        body: {
          archive: params?.is_archived === 'archive',
          campaign_name: params?.campaign_name,
          senders: params?.senders,
          platform: params?.platform,
          fromDate: params?.fromDate,
          toDate: params?.toDate,
          dateType: params?.dateType,
        },
      }),
    }),

    getAllCampaignNames: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getAllCampaignNames`,
        method: 'GET',
      }),
    }),
    getCampaignById: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getCampaignById`,
        method: 'GET',
        params: { campaign_id: params.campaign_id },
      }),
    }),
    getMessageSuggestions: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getMessageSuggestions`,
        method: 'GET',
      }),
    }),
    getCustomMessages: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/customMessage`,
        method: 'GET',
      }),
    }),
    insertCustomMessage: builder.mutation({
      query: (body: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/customMessage`,
        method: 'POST',
        body: body,
      }),
    }),
    updateCustomMessage: builder.mutation({
      query: (param: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/customMessage/${param.message_id}`,
        method: 'PUT',
        body: param,
      }),
    }),
    deleteCustomMessage: builder.query({
      query: (param: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/customMessage/${param.message_id}`,
        method: 'DELETE',
      }),
    }),
    getSender: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getSender`,
        method: 'GET',
      }),
    }),
    getCampaignHeader: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getCampaignHeader`,
        method: 'POST',
        body: {
          archive: params?.is_archived === 'archive',
          campaign_name: params?.campaign_name,
          senders: params?.senders,
          platform: params?.platform,
          fromDate: params?.fromDate,
          toDate: params?.toDate,
          dateType: params?.dateType,
        },
      }),
    }),
    getCampaignsRecipients: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getRecipients`,
        method: 'GET',
        params: { is_archived: false },
      }),
    }),
    getAllCampaignsRecipientsIds: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getAllRecipientsIds`,
        method: 'GET',
        params: { campaign_id: params.campaign_id },
      }),
    }),
    getCampaignsRecipientsById: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getRecipientsByCampaign`,
        method: 'GET',
        params: { campaign_id: params.campaign_id, page: params.page, limit: params.limit },
      }),
    }),
    getCampaignsMessages: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getMessages`,
        method: 'GET',
        params: { is_archived: false },
      }),
    }),
    setCampaignsActive: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/setActive`,
        method: 'POST',
        body: { campaign_ids: params.campaign_ids, is_active: params.is_active, status: params.status },
      }),
    }),
    createCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/setCampaign`,
        method: 'POST',
        body: {
          campaign_name: params.campaign_name,
          campaign_description: params.campaign_description,
          status: params.status,
          steps: params.steps,
          messages_steps: params.messages_steps,
          settings: params.settings,
          platform: params.platform,
        },
      }),
    }),
    updateCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/updateCampaign`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
          campaign_description: params.campaign_description,
          status: params.status,
          steps: params.steps,
          settings: params.settings,
          edit_mode: params.edit_mode,
          platform: params.platform,
          messages_steps: params.messages_steps,
        },
      }),
    }),
    editCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/editCampaign`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
          campaign_description: params.campaign_description,
          status: params.status,
          steps: params.steps,
          settings: params.settings,
          edit_mode: params.edit_mode,
          messages_steps: params.messages_steps,
        },
      }),
    }),
    getLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getContactLists?platform_type=${encodeURIComponent(
          params.platform_type,
        )}`,
        method: 'GET',
      }),
    }),
    setCampaignRecipients: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/setRecipients`,
        method: 'POST',
        body: {
          contacts_list_ids: params.contactsListIds,
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
          credits: params.credits,
          allCntacts: params.allCntactsToggle,
          failedCntacts: params.failedCntactsToggle,
          excludeTeam: params.excludeTeam,
          excludeTeamSent: params.excludeTeamSent,
          useRemainingPoints: params.useRemainingPoints || false,
          selected_platform: params.selected_platform,
        },
      }),
    }),
    deleteCampaignRecipients: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/deleteRecipients`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          recipient_ids: params.recipient_ids,
        },
      }),
    }),
    updateRecipientOutcome: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/updateRecipientOutcome`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          outcome: params.outcome,
          recipient_id: params.recipient_id,
        },
      }),
    }),
    setCampaignsSettings: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/setSettings`,
        method: 'POST',
        body: {
          start_date: params.start_date,
          schedule_id: params.schedule_id,
          campaign_name: params.campaign_name,
          campaign_id: params.campaign_id,
          timezone: params.timezone,
          send_after_cooldown: params.cooldownSwitch,
        },
      }),
    }),
    getCampaignsSettings: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getSettings`,
        method: 'GET',
        params: {
          campaign_id: params.campaign_id,
        },
      }),
    }),
    getCampaignSchedule: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getSchedule`,
        method: 'GET',
      }),
    }),
    setCampaignSchedule: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/setSchedule`,
        method: 'POST',
        body: {
          schedule: params.schedule,
          schedule_type: params.schedule_type,
          // schedule_id: params.schedule_id,
          schedule_label: params.schedule_label,
        },
      }),
    }),
    updateCampaignSchedule: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/updateSchedule`,
        method: 'POST',
        body: {
          schedule: params.schedule,
          schedule_type: params.schedule_type,
          schedule_id: params.schedule_id,
          schedule_label: params.schedule_label,
        },
      }),
    }),
    sendTemplateRequest: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/sendTemplateRequest`,
        method: 'POST',
        body: {
          request: params.request,
        },
      }),
    }),
    archiveCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/addToArchive`,
        method: 'POST',
        body: {
          campaign_ids: params.campaign_ids,
          archive: params.archive,
        },
      }),
    }),
    unarchiveCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/unarchiveCampaign`,
        method: 'POST',
        body: {
          campaign_ids: params.campaign_ids,
          archive: params.archive,
        },
      }),
    }),
    getCooldowns: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getCooldowns`,
        method: 'GET',
      }),
    }),
    getUserDetails: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/user/userDetails`,
        method: 'GET',
      }),
    }),
    setRecipientsFromCsv: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/setRecipientsFromCSV`,
        method: 'POST',
        body: {
          recipients: params.csvData,
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
          excludeLeadsInOtherCampaigns: params.excludeLeadsInOtherCampaigns,
          exclusePreviouslySent: params.exclusePreviouslySent,
          excludeTeam: params.excludeTeam,
          excludeTeamSent: params.excludeTeamSent,
          selected_platform: params.selected_platform,
        },
      }),
    }),
    setRecipientsFromGroups: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/setRecipientsByGroup`,
        method: 'POST',
        body: {
          groups: params.groups,
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
          excludeLeadsInOtherCampaigns: params.excludeLeadsInOtherCampaigns,
          exclusePreviouslySent: params.exclusePreviouslySent,
          selected_platform: params.selected_platform,
          useRemainingPoints: params.useRemainingPoints,
        },
      }),
    }),
    getGroups: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getGroups`,
        method: 'POST',
        body: {
          group_name: params.group_name,
          title: params.group_name,
          getAll: params.getAll,
          current: params.current,
          pageSize: params.pageSize,
          isFeaturedGroups: params.is_featured_groups,
        },
      }),
    }),
    getEvents: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getEvents`,
        method: 'POST',
        body: {
          title: params.title,
          current: params.current,
          pageSize: params.pageSize,
        },
      }),
    }),
    getGroupMembers: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getGroupMembers`,
        method: 'GET',
        params,
      }),
    }),
    getEventAttendees: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getEventAttendees`,
        method: 'GET',
        params,
      }),
    }),
    addLeads: builder.mutation({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/addLeads`,
        method: 'POST',
        body: {
          campaignId: params.campaignId,
          ignoreLeadsFromOtherCampaigns: params.ignoreLeadsFromOtherCampaigns,
          ignoreLeadsFromOtherTeamMembers: params.ignoreLeadsFromOtherTeamMembers,
          ignoreOutreachedLeads: params.ignoreOutreachedLeads,
          ignoreOutreachedLeadsFromTeam: params.ignoreOutreachedLeadsFromTeam,
          leads: {
            source: params.source,
            ids: params.ids,
            group_name: params.group_name,
          },
        },
      }),
    }),
    /* ---------- AI ------- */
    // generateWithAI: builder.query({
    //   query: (params: any) => ({
    //     url: `https://generate`,
    //     method: 'POST',
    //     body: {
    //       audience: params.audience,
    //       pain_points: params.pain_points,
    //       solution: params.solution,
    //       hierarchy: params.hierarchy,
    //     },
    //   }),
    // }),
    // linkedinGenerateWithAI: builder.query({
    //   query: (params: any) => ({
    //     url: `https://linkedinGenAiDemo`,
    //     method: 'POST',
    //     body: {
    //       audience: params.audience,
    //       pain_points: params.pain_points,
    //       solution: params.solution,
    //       hierarchy: params.hierarchy,
    //     },
    //   }),
    // }),
    assistWithAI: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/assistWithAI`,
        method: 'POST',
        body: {
          message: params.message,
          task: params.task,
        },
      }),
    }),
    // sentimentWithAI: builder.query({
    //   query: (params: any) => ({
    //     url: `sentiment`,
    //     method: 'POST',
    //     body: {
    //       text: params.text,
    //       initial_message: params.initial_message,
    //     },
    //   }),
    // }),
    /* ---------- AI ------- */
    updateRecipientDetails: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/putRecipientsByCampaign`,
        method: 'PUT',
        body: { userID: params.userId, name: params.name, campaign_id: params.campaignId, field: params.field },
      }),
    }),

    uploadCSVCompanies: builder.mutation({
      query: (body: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/companies/importCompaniesCSV`,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    }),
    /* ---------- Import Telegram Contacts ------- */
    getContributorLogs: builder.query({
      query: (params: any) => ({
        // No longer in use. Removed from backend
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/getContributorLogs?area_code=${params.area_code}&number=${params.number}&email=${params.email}`,
        method: 'GET',
      }),
    }),
    importFromTelegram: builder.mutation({
      query: (body: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/import_from_telegram`,
        method: 'POST',
        body,
      }),
    }),
    getAllRawLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/getAllRawLeads`,
        method: 'GET',
        params,
      }),
    }),
    getRawLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/getRawLeads`,
        method: 'GET',
        params,
      }),
    }),
    getMatchedLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/getMatchedLeads`,
        method: 'GET',
        params,
      }),
    }),
    getAllMatchedLeadsIds: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/contacts/getAllMatchedLeadsIds`,
        method: 'GET',
        params,
      }),
    }),
    getTelegramGroups: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getTelegramGroups`,
        method: 'GET',
        params,
      }),
    }),
    getLeadsByGroupId: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/campaign/getLeads/${params.groupId}`,
        method: 'GET',
        params,
      }),
    }),
    updateImportTelegramStatus: builder.mutation({
      query: (body: any) => ({
        url: `${process.env.REACT_APP_LEGACY_API_URL}/user/updateImportTelegramStatus`,
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const {
  useLazyGetSenderListQuery,
  useLazyGetAllCampaignsQuery,
  useLazyGetCheckRepliesQuery,
  useLazyGetAutoCheckRepliesQuery,
  useLazyGetCampaignsQuery,
  useLazyGetAllCampaignNamesQuery,
  useLazyGetCampaignByIdQuery,
  useLazyGetMessageSuggestionsQuery,
  useLazyGetCustomMessagesQuery,
  useInsertCustomMessageMutation,
  useUpdateCustomMessageMutation,
  useLazyDeleteCustomMessageQuery,
  useLazyGetSenderQuery,
  useLazyGetCampaignHeaderQuery,
  useLazyGetCampaignsRecipientsQuery,
  useLazyUpdateRecipientOutcomeQuery,
  useLazyGetCampaignsRecipientsByIdQuery,
  useLazyGetAllCampaignsRecipientsIdsQuery,
  useLazyGetCampaignsMessagesQuery,
  useLazySetCampaignsActiveQuery,
  useLazyCreateCampaignQuery,
  useLazyUpdateCampaignQuery,
  useLazyEditCampaignQuery,
  useLazyGetLeadsQuery,
  useLazySetCampaignRecipientsQuery,
  useLazyDeleteCampaignRecipientsQuery,
  useLazySetCampaignsSettingsQuery,
  useLazyGetCampaignsSettingsQuery,
  useLazyGetCampaignScheduleQuery,
  useLazySetCampaignScheduleQuery,
  useLazyUpdateCampaignScheduleQuery,
  useLazySendTemplateRequestQuery,
  useLazyArchiveCampaignQuery,
  useLazyUnarchiveCampaignQuery,
  useLazyGetCooldownsQuery,
  useLazyGetUserDetailsQuery,
  useLazySetRecipientsFromCsvQuery,
  useLazySetRecipientsFromGroupsQuery,
  useLazyGetGroupsQuery,
  // useLazyGenerateWithAIQuery,
  // useLazyLinkedinGenerateWithAIQuery,
  useLazyAssistWithAIQuery,
  // useLazySentimentWithAIQuery,
  useLazyUpdateRecipientDetailsQuery,
  useLazyDownloadCSVQuery,
  useLazyGetLinkedinSendersQuery,
  useLazyAddSaveFiltersQuery,
  useLazyGetSaveFiltersQuery,
  useLazyDeleteSaveFiltersQuery,
  useUploadCSVCompaniesMutation,
  useGetGroupMembersQuery,
  useGetEventAttendeesQuery,
  useGetAllGroupMembersQuery,
  useGetAllEventAttendeesQuery,
  useAddLeadsMutation,
  useGetGroupsQuery,
  useGetEventsQuery,
  useImportFromTelegramMutation,
  useGetContributorLogsQuery,
  useGetAllRawLeadsQuery,
  useGetRawLeadsQuery,
  useGetMatchedLeadsQuery,
  useGetAllMatchedLeadsIdsQuery,
  useGetTelegramGroupsQuery,
  useGetLeadsByGroupIdQuery,
  useUpdateImportTelegramStatusMutation,
} = OutreachApiWeb3
