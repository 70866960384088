import React, { useEffect, useState } from 'react'
// import { Button } from 'antd'
// import styles from './CampaignSummarySidebar.module.scss'
// import facebook_icon from 'common/assets/svg/facebook_icon.svg'
// import instagram_logo from 'common/assets/svg/instagram_logo.svg'
// import twitter_logo from 'common/assets/svg/twitter_logo.svg'
// import discord_logo from 'common/assets/svg/discord_logo.svg'
// import linkedin_logo from 'common/assets/svg/linkedin_logo.svg'
// import telegram_logo from 'common/assets/svg/telegram_logo.svg'
// import no_logo from 'common/assets/svg/no_logo.svg'
import CampaignSummary from './CampaignSummary'
import { useAppDispatch, useAppSelector } from 'state'
import {
  SET_PROMPT,
  SET_SAVED_PROMPT_PARAMS,
  SET_SELECTED_LEADS,
  SET_SELECTED_PLATFORMS_ACCOUNTS,
  SET_NODES_DATA,
  SET_ACTIVE_PLATFORMS,
  RESET_SELECTED_LEADS,
  SET_AI_CAMPAIGN_NAME,
  SET_CAMPAIGN_ID_AI,
  SET_BLOCK_EDITING,
  SET_CURRENT_COLLAPSE_KEY,
  SET_STATS_DATA,
  SET_CAMPAIGN_STATUS,
  SET_GENERATE_MESSAGES,
  SET_IS_CAMPAIGN_RUNNING_ALREADY,
  SET_SKIP_PROMPT,
  SET_CAMPAIGN_SETTINGS_AI,
  SET_IS_EDIT_CAMPAIGN,
  SET_SELECT_CUSTOM_TEMPLATE,
  SET_ARE_LEADS_FROM_GROUPS,
  SET_DEFAULT_TEMPLATES,
  SET_AI_LEADS_TYPE,
  SET_SETUP_METHOD,
  SET_STORED_LEADS_FROM_DB,
  SET_CREATED_AT,
  SET_STORED_TEMP_LEADS,
  SET_USER_UPDATED_SEQUENCE,
  SET_DEFAULT_COLUMNS,
  SET_USER_POINTS,
  SET_NON_EDITABLE_STEPS,
  SET_ACTION_SENT,
} from '../state/outreachAICampaignSlice'
import { useLocation } from 'react-router-dom'

import {
  useLazyGetCampaignSummaryQuery,
  useLazyGetCampaignsRecipientsByIdQuery,
  useLazyUpdateDefaultColumnsQuery,
} from 'common/APIs/OutreachApi'
import {
  useLazyGetCampaignStatsQuery,
  useLazyGetMyTemplatesQuery,
  useLazyGetTempLeadsQuery,
} from '../../../APIs/OutreachAI-API'
import {
  SET_BUILDER_DATA,
  SET_BUILDER_EDGES,
  SET_BUILDER_NODES,
  SET_USE_CUSTOM_FLOW,
} from '../Builder/Slice/CampaignBuilderSlice'
import { filterPlaceholderNodesAndEdges, getLayoutedElements } from '../Builder/Utils/BuilderUtils'
import { buildRecipientArray, getDefaultColumnsByRecipient } from 'common/utils/campaignHelpers'
import { useLazyGetPointsStoreQuery } from 'common/APIs/SettingsApi'

const CampaignSummaryView: React.FC = () => {
  const dispatch = useAppDispatch()
  const [getCampaignStats] = useLazyGetCampaignStatsQuery()
  const [fetchingData, setFetchingData] = useState(false)
  const [getTempLeads] = useLazyGetTempLeadsQuery()
  const [refetcGetCampaignSummary] = useLazyGetCampaignSummaryQuery()
  const [refetcGetCampaignRecipients] = useLazyGetCampaignsRecipientsByIdQuery()
  const [getMyTemplates] = useLazyGetMyTemplatesQuery()
  const [refetchGetPointsStore] = useLazyGetPointsStoreQuery()
  const [updateDefaultColumns] = useLazyUpdateDefaultColumnsQuery()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const urlCampaignId = params.get('campaign_id')

  const { saved_prompt_params, default_templates } = useAppSelector((state) => state.outreachAICampaign)

  const getCampaignSummaryView = async () => {
    setFetchingData(true)

    const [
      { data: campaignSummaryData },
      { data: campaignStatsData },
      { data: templates },
      { data: tempLeads },
      points_store,
      { data },
    ] = await Promise.all([
      refetcGetCampaignSummary({ campaign_id: urlCampaignId }),
      getCampaignStats({ campaign_id: urlCampaignId }),
      getMyTemplates(),
      getTempLeads({ campaign_id: urlCampaignId }),
      refetchGetPointsStore(),
      refetcGetCampaignRecipients({ campaign_id: urlCampaignId }),
    ])

    const storedData = data?.response ? data.response : []
    const storedTempLeads = tempLeads ? buildRecipientArray(tempLeads, true) : []
    dispatch(SET_STORED_TEMP_LEADS(storedTempLeads))
    const allLeads: any = [...storedData, ...storedTempLeads]

    const prompt = JSON.parse(campaignSummaryData.response.prompt || '[]')
    const custom_flow = campaignSummaryData?.custom_flow
    const steps = JSON.parse(campaignSummaryData.response.steps)
    const created_at = campaignSummaryData?.response?.created_at
    const campaignStatus = campaignSummaryData?.response?.status
    const nonEditableSteps = campaignSummaryData?.nonEditableSteps
    const actionSent = campaignSummaryData?.actionSent

    const showPrompt =
      prompt?.setup_method === 'ai' ||
      (!(saved_prompt_params?.industry === '') &&
        !(saved_prompt_params?.location === '') &&
        !(saved_prompt_params?.target_persone === ''))

    const recipientsAi = []
    if (allLeads !== undefined)
      for (const [, item] of allLeads.entries()) {
        const recipient = {
          accuracy: item.accuracy || '',
          annual_revenue: item.annual_revenue || '', // ADDED
          biography: item.biography || '',
          businessType: item.businessType || '',
          category: item.category || '',
          categories: item.categories || '',
          channels: item.channels || '',
          company: item.company || '',
          company_linkedin: item.company_linkedin || '', // ADDED
          company_photo: item?.company_photo || '',
          company_website: item.company_website || '', // ADDED
          description: item.description || '', // ADDED
          discord: item.discord || '',
          enrich: item.enrich || '',
          facebook: item.facebook || '',
          follower_count: item.follower_count || '',
          followed_username: item.followed_username || '',
          founded_year: item.founded_year || '', // ADDED
          full_name: item.full_name || '', // ADDED
          index: item.index || '', // ADDED
          industry: item.industry || '',
          industries: item.industries || '', // ADDED
          is_private: item.is_private || '', // ADDED
          is_verified: item.is_verified || '', // ADDED
          instagram: item.instagram || '',
          jobTitle: item.job_title || '',
          job_title: item.job_title || '',
          key: item.key,
          last_step: item.status,
          lead_photo: item?.lead_photo || '',
          linkedin: item.linkedin || '',
          location: item.location || '',
          mode: item.mode || '',
          name: item.full_name || item.name || '',
          number_of_employees: item.number_of_employees || '',
          physical_location: item.physical_location || '', // ADDED
          platform: item.platform || '', // ADDED
          recipient: item.recipient || '', // ADDED
          recipient_id: item.recipient_id || '', // ADDED
          source: item.source_name || '',
          source_type: item.source_type || '',
          step_type: item.step_type,
          tags: item.tags || item.categories || '', // ADDED
          technologies: item.technologies || '',
          telegram: item.telegram || '',
          total_funding: item.total_funding || '', // ADDED
          twitter: item.twitter || '',
          type: item.type || '', // ADDED
          username: item.username || '', // ADDED
          isTemp: item.isTemp || false,
          display: item.display || false,
          custom_1: item?.custom_1 || '',
          custom_2: item?.custom_2 || '',
          // linkedin
          time_in_role_and_company: item?.time_in_role_and_company,
          skills: item?.skills,
          search_name: item?.search_name,
          position: item?.position,
          number_of_followers: item?.number_of_followers,
          nav_url: item?.nav_url,
          headline: item?.headline,
          experience: item?.experience,
          connection_level: item?.connection_level,
          about: item?.about,
          company_pic_url: item?.company_pic_url,
          profile_pic_url: item?.profile_pic_url,
          convrt_source: item?.convrt_source,
          is_doxxed: item?.is_doxxed,
          ...item,
        }
        if (prompt.are_leads_from_groups) {
          switch (item.source_type) {
            case 'facebook_group':
              recipient['facebook'] = item.handle
              break
            case 'instagram_group':
              recipient['instagram'] = item.handle
              break
            case 'linkedin_group':
              recipient['linkedin'] = item.handle
              break
            case 'telegram_group':
              recipient['telegram'] = item.handle
              break
            case 'discord_group':
              recipient['discord'] = item.handle
              break
            case 'twitter_group':
              recipient['twitter'] = item.handle
              break
          }
        }
        recipientsAi.push(recipient)
      }
    dispatch(SET_DEFAULT_COLUMNS(prompt?.default_columns))
    dispatch(SET_NON_EDITABLE_STEPS(nonEditableSteps))
    dispatch(SET_ACTION_SENT(actionSent))
    if (!prompt.default_columns || prompt.default_columns?.length === 0) {
      // check one of the leads source
      const columns = getDefaultColumnsByRecipient(recipientsAi[0] || {})
      dispatch(SET_DEFAULT_COLUMNS(columns))
      await updateDefaultColumns({ campaign_id: urlCampaignId, default_columns: columns })
    }
    dispatch(RESET_SELECTED_LEADS([]))
    dispatch(SET_SELECTED_LEADS(recipientsAi))
    dispatch(SET_STORED_LEADS_FROM_DB(recipientsAi?.filter((rec: any) => !rec?.isTemp)?.map((rec: any) => rec.index)))
    dispatch(SET_SAVED_PROMPT_PARAMS(prompt?.saved_prompt_params || {}))
    dispatch(SET_PROMPT(prompt?.prompt || {}))
    dispatch(SET_AI_CAMPAIGN_NAME(campaignSummaryData.response.campaign_name))

    dispatch(
      SET_USER_POINTS({
        points: points_store?.data?.response?.points,
        pointsStore: points_store?.data?.response?.pointsStore,
      }),
    )
    dispatch(SET_SELECTED_PLATFORMS_ACCOUNTS(prompt?.selected_platforms_accounts || {}))
    dispatch(SET_ACTIVE_PLATFORMS(prompt?.active_platforms || {}))
    dispatch(SET_NODES_DATA(JSON.parse(campaignSummaryData.response.steps || '[]')))
    dispatch(SET_CAMPAIGN_ID_AI(campaignSummaryData?.response?.campaign_id))
    dispatch(SET_BLOCK_EDITING(true))
    dispatch(SET_CURRENT_COLLAPSE_KEY('2'))
    dispatch(SET_STATS_DATA(campaignStatsData))
    dispatch(SET_CAMPAIGN_STATUS(campaignSummaryData.response?.status))
    dispatch(SET_GENERATE_MESSAGES(false))
    dispatch(SET_IS_CAMPAIGN_RUNNING_ALREADY(campaignSummaryData.response?.is_running_already))
    dispatch(SET_SKIP_PROMPT(prompt?.skip_prompt))
    dispatch(SET_ARE_LEADS_FROM_GROUPS(prompt?.are_leads_from_groups))
    dispatch(SET_AI_LEADS_TYPE(prompt?.ai_leads_type))
    dispatch(SET_USER_UPDATED_SEQUENCE(prompt?.user_updated_sequence))
    dispatch(SET_SETUP_METHOD(prompt?.setup_method ? prompt.setup_method : showPrompt ? 'ai' : 'manually'))
    dispatch(SET_CAMPAIGN_SETTINGS_AI(prompt?.campaign_settings_ai))
    dispatch(SET_IS_EDIT_CAMPAIGN(true))
    if (created_at) {
      dispatch(SET_CREATED_AT(created_at))
    }
    if (custom_flow) {
      const nodesFromSteps = custom_flow?.nodes?.map((node: any) => {
        const step_node = steps.filter((step: any) => step.step_id === node.id)
        if (step_node?.length > 0) {
          const nd = step_node[0]
          const message_id = nd.message_id
          const data = nd.data
          if (!data) {
            return node
          }
          return {
            ...node,
            data: { ...node.data, ...data, message_id: message_id, messages: node?.data?.messages || data.messages },
          }
        }
        return node
      })
      const newData = { nodes: nodesFromSteps, edges: custom_flow.edges }
      dispatch(SET_USE_CUSTOM_FLOW(true))
      dispatch(SET_BUILDER_DATA(newData))
      const { filteredNodes, filteredEdges } = filterPlaceholderNodesAndEdges(
        newData.nodes,
        newData.edges,
        nonEditableSteps,
      )
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(filteredNodes, filteredEdges, true)
      const allowEdit = campaignStatus === 'draft' || campaignStatus === 'awaiting'
      dispatch(SET_BUILDER_NODES(allowEdit ? newData.nodes : layoutedNodes))
      dispatch(SET_BUILDER_EDGES(allowEdit ? newData.edges : layoutedEdges))
    } else if (prompt?.select_custom_template) {
      dispatch(SET_SELECT_CUSTOM_TEMPLATE(prompt?.select_custom_template))
      const template = prompt?.select_custom_template?.template

      const nodesFromSteps = template?.nodes?.map((node: any) => {
        const step_node = steps.filter((step: any) => step.step_id === node.id)
        if (step_node?.length > 0) {
          const nd = step_node[0]
          const message_id = nd.message_id
          const data = nd.data
          if (!data) {
            return node
          }
          return {
            ...node,
            data: { ...node.data, ...data, messages: node?.data?.messages || data.messages, message_id: message_id },
          }
        }
        return node
      })
      const newData = { nodes: nodesFromSteps, edges: template.edges }
      dispatch(SET_BUILDER_DATA(newData))
      const { filteredNodes, filteredEdges } = filterPlaceholderNodesAndEdges(
        newData.nodes,
        newData.edges,
        nonEditableSteps,
      )
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(filteredNodes, filteredEdges, true)
      dispatch(SET_BUILDER_NODES(layoutedNodes))
      dispatch(SET_BUILDER_EDGES(layoutedEdges))
      dispatch(SET_USE_CUSTOM_FLOW(true))
    }
    if (templates && default_templates?.length === 0) {
      const temps = templates?.filter((temp: any) => temp?.type === 'default')
      dispatch(SET_DEFAULT_TEMPLATES(temps))
    }

    setFetchingData(false)
  }

  useEffect(() => {
    getCampaignSummaryView()
  }, [])

  return <CampaignSummary isLoading={fetchingData} />
}

export default CampaignSummaryView
