import React, { useEffect, useState } from 'react'
import { Form, Card, Radio, Modal, Button, InputNumber, Popover } from 'antd'
import type { RadioChangeEvent } from 'antd'
import styles from './PricingForm.module.scss'
import { CheckOutlined } from '@ant-design/icons'
import CreditsComponent from './CreditsComponent'
import { useAuth } from 'common/hooks/useAuth.hooks'
import PricingModal from './PricingModal'
import { useLazyGetAssignedSeatsQuery, useLazyGetUnassignedSeatsQuery } from 'LEGACY/API/SettingsApi'
import info_icon from 'LEGACY/Assets/svg/info_icon.svg'
import { checkIsBetaOrTrialUser, checkIsPaidTrialUser } from 'LEGACY/helper'

// type PlanType = 'monthly' | 'three_months' | 'yearly'
type PlanType = 'three_months' | 'yearly'

interface PricingFormProps {
  setPlan: any
  setPlanDuration: any
  planType: PlanType
  setPlanType: (planType: PlanType) => void
  setQuantity: any
  setTGQuantity?: any
  setNextButton?: any
  TGQuantity?: any
}

const pricingOptions = [
  // {
  //   id: 'free_trial',
  //   label: 'Free Trial',
  //   description: `Experience Convrt's magic at no cost. Begin transforming your sales now.`,
  //   price: { monthly: 0, six_months: 0, yearly: 0 },
  //   convrtPoints: 300,
  //   features: [
  //     // { label: 'Seats', value: 1 },
  //     { label: 'Convrt Points' },
  //     { label: 'Companies Research', value: 'Basic' },
  //     { label: 'Prospects Finder', value: 'Basic' },
  //     { label: 'Accuracy Score', value: 'Included' },
  //   ],
  // },
  // {
  //   id: 'go_to_market',
  //   label: 'Go To Market',
  //   description: 'Empower your market launch with essential tools for smarter research and prospecting.',
  //   // price: { monthly: 99, three_months: 79, yearly: 68 },
  //   price: { three_months: 79, yearly: 68 },
  //   convrtPoints: 1250,
  //   features: [
  //     {
  //       label: 'Convrt Points',
  //       value: 1250,
  //     },
  //     { label: 'Companies Research', value: 'Basic' },
  //     { label: 'Prospects Finder', value: 'Basic' },
  //     { label: 'On Chain Signals', value: 'Included' },
  //     { label: 'On Chain Data Filtering', value: 'Included' },
  //     { label: 'Company Social Media', value: 'Included' },
  //   ],
  // },
  // {
  //   id: 'connect_plus',
  //   label: 'Connect+',
  //   description: 'Enhance outreach with AI-driven features and automations. Boost your pipeline with consistent leads.',
  //   price: { three_months: 259, yearly: 207 },
  //   convrtPoints: 5000,
  //   all_in_prev: 'Go To Market +',
  //   features: [
  //     {
  //       label: 'Convrt Points',
  //       value: 5000,
  //     },
  //     { label: 'Companies Research', value: 'Advanced' },
  //     { label: 'Prospects Finder', value: 'Advanced' },
  //     { label: 'Outreach Sequences', value: 'Included' },
  //     { label: 'Warmed TG Account', value: '1 Included' },
  //   ],
  // },
  {
    id: 'market_dominance',
    label: 'Market Dominance',
    description: 'Seize total sales control with comprehensive tools. Turn leads into meetings, outshine competitors.',
    // price: { monthly: 649, three_months: 538, yearly: 422 },
    // price: { three_months: 538, yearly: 422 },
    price: 'Talk to us',
    convrtPoints: 15000,
    all_in_prev: 'Connect+ +',
    features: [
      {
        label: 'Convrt Points',
        value: 15000,
      },
      { label: 'AI Generated Sequences', value: 'Included' },
      { label: 'AI Personalization', value: 'Included' },
      { label: 'Contacts Importing', value: 'Included' },
      // { label: 'Data Enrichment', value: 'Included' },
      { label: 'Warmed TG Account', value: '3 Included' },
      { label: 'Unified Inbox', value: 'Included' },
      { label: 'CRM Integration', value: 'Full Sync' },
      { label: 'LinkedIn Outreach Sequence' },
    ],
  },

  {
    id: 'ai_meeting_setter',
    label: 'AI Meeting Setter',
    description: 'Your dedicated AI SDR that works for you, creating campaigns, respond and setting up meetings.',
    price: 'Talk to us',
    convrtPoints: 40000,
    all_in_prev: 'Market Dominance +',
    features: [
      {
        label: 'Convrt Points',
        value: 40000,
      },
      { label: 'Performance Dashboard', value: 'Included' },
      { label: 'Weekly Summary Report', value: 'Included' },
      { label: 'Warmed TG Account', value: '5 Included' },
      { label: '1 LinkedIn Connection' },
      { label: 'Dedicated Support', value: 'Included' },
      { label: 'Sales Team Member Monitoring' },
    ],
  },
]

const planMapping: any = {
  free_trial: 0,
  go_to_market: 1,
  connect_plus: 2,
  market_dominance: 3,
  ai_meeting_setter: 4,
}

const PricingForm: React.FC<PricingFormProps> = ({
  setPlan,
  setPlanDuration,
  planType,
  setPlanType,
  setQuantity,
  setTGQuantity,
  setNextButton,
  TGQuantity,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [outreachSuite, setOutreachSuite] = useState<any>(0)
  const [daysLeft, setDaysLeft] = useState<any>()
  const { userDetails } = useAuth()
  const isBetaOrTrialUser = checkIsBetaOrTrialUser(userDetails)
  const isPaidTrialUser = checkIsPaidTrialUser(userDetails)
  const [selectedPlan, setSelectedPlan] = useState(
    pricingOptions[userDetails?.plan_type ? planMapping[userDetails.plan_type] : 0],
  )
  // eslint-disable-next-line
  const [seatsCountByPlan, setSeatsCountByPlan] = useState<any>({})
  const [refetchGetUnassignedSeats, { data: seatsToAssign }] = useLazyGetUnassignedSeatsQuery()
  const [refetchGetAssignedSeats, { data: assignedSeats }] = useLazyGetAssignedSeatsQuery()

  // eslint-disable-next-line
  const [subscriptionCreatedAtLocalStorage, setSubscriptionCreatedAtLocalStorage] = useState(null)
  // eslint-disable-next-line
  const [subscriptionDaysLocalStorage, setSubscriptionDaysLocalStorage] = useState(null)
  // const fields = new Set<string>()
  // const selectOptions = [
  //   { value: 'monthly', label: '1 Month' },
  //   { value: 'six_months', label: '6 Months' },
  //   { value: 'yearly', label: '1 Year' },
  // ]

  useEffect(() => {
    const getSeats = async () => {
      await refetchGetUnassignedSeats()
      await refetchGetAssignedSeats()
    }
    getSeats()
  }, [])

  useEffect(() => {
    // Process and group seats by plan type
    const processSeatsData = (combinedSeats: any[]) => {
      const counts: any = {}
      combinedSeats.forEach((seat) => {
        if (counts[seat.product]) {
          counts[seat.product] += 1
        } else {
          counts[seat.product] = 1
        }
      })
      return counts
    }
    const countsByPlan = processSeatsData([...(seatsToAssign || []), ...(assignedSeats || [])])
    setSeatsCountByPlan(countsByPlan)
  }, [seatsToAssign, assignedSeats])

  useEffect(() => {
    const currentDate = new Date()
    const createdSubscriptionDate = userDetails?.subscription_created_at
      ? new Date(userDetails.subscription_created_at)
      : subscriptionCreatedAtLocalStorage
      ? new Date(subscriptionCreatedAtLocalStorage)
      : null
    const subscriptionDays = userDetails?.subscription_days || subscriptionDaysLocalStorage

    if (createdSubscriptionDate !== null && typeof subscriptionDays === 'number') {
      const timeDifference = currentDate.getTime() - createdSubscriptionDate.getTime()
      const daysPassed = timeDifference / (1000 * 60 * 60 * 24)
      setDaysLeft(Math.trunc(subscriptionDays - daysPassed))
    } else {
      setDaysLeft('N/A')
    }
  }, [userDetails, subscriptionCreatedAtLocalStorage, subscriptionDaysLocalStorage])

  const handleCardClick = (planId: any) => {
    if (selectedPlan?.id !== planId?.id) {
      setOutreachSuite(0)
      setTGQuantity(0)
    }

    setSelectedPlan(planId)
    setPlan(planId)
    setQuantity(1)
  }

  useEffect(() => {
    setPlan(pricingOptions[0])
    setPlanDuration('three_months')
  }, [])

  const handleSelectChange = (e: RadioChangeEvent) => {
    setPlanType(e.target.value)
    setPlanDuration(e.target.value)
  }
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const onQuantityChange = (value: number | null) => {
    setQuantity(value)
  }

  const onTGQuantityChange = (value: number | null) => {
    setOutreachSuite(value)

    setTGQuantity(value)
  }

  useEffect(() => {
    setPlan(pricingOptions[userDetails?.plan_type ? planMapping[userDetails.plan_type] : 0])
  }, [])

  const renderText = (optionId: any) => {
    if (isBetaOrTrialUser || isPaidTrialUser) {
      return 'Upgrade Now'
    } else {
      const currentPlanIndex = planMapping[userDetails?.plan_type]
      const optionIndex = planMapping[optionId]

      if (optionIndex === currentPlanIndex) {
        return 'Current Plan'
      } else if (optionIndex > currentPlanIndex) {
        return 'Upgrade'
      } else {
        return 'Update'
      }
    }
  }

  return (
    <Form style={{ minWidth: '700px' }}>
      <div className={styles.selectPlanType}>
        {/* <Select style={{ width: 200 }} options={selectOptions} defaultValue={'monthly'} onChange={handleSelectChange} /> */}
        <Radio.Group value={planType} onChange={handleSelectChange}>
          <Radio.Button value='three_months' style={{ minWidth: '100px', textAlign: 'center' }}>
            3 Months
          </Radio.Button>
          <Radio.Button value='yearly' style={{ minWidth: '100px', textAlign: 'center' }}>
            1 Year
          </Radio.Button>
          {/* <Radio.Button value='monthly' style={{ minWidth: '100px', textAlign: 'center' }}>
            1 Month
          </Radio.Button> */}
        </Radio.Group>
      </div>
      <div style={{ margin: '10px 20px 20px 20px', display: 'flex', justifyContent: 'space-evenly' }}>
        {pricingOptions.map((option) => (
          <div key={option?.id}>
            {/* {seatsCountByPlan[option.id] && (
                <div className={styles.seatsIndicator}>
                  <span>
                    {seatsCountByPlan[option.id] === 1
                      ? `${seatsCountByPlan[option.id]} Seat`
                      : `${seatsCountByPlan[option.id]} Seats`}
                  </span>
                  <span>Current Plan</span>
                </div>
              )} */}
            <Card
              hoverable
              style={{
                // marginBottom: 16,
                padding: '0px !important',
                cursor: 'default',
                border: selectedPlan?.id === option?.id ? '2px solid #7043ff' : '1px solid #d9d9d9',
                minWidth: '300px',
                maxWidth: '300px',
                minHeight: '540px',
                // minHeight: '675px',
                // maxHeight: '675px',
              }}
              className={styles.buyConvrtCard}>
              <div className={styles.plan}>
                {option?.id === 'market_dominance' && (
                  <div className={styles.displayPopular}>{<span>Most Popular</span>}</div>
                )}
                <p>{option?.label}</p>
                <span style={{ minHeight: '60px' }}>{option?.description}</span>
                {/* {typeof option?.price === 'object' ? (
                  <p>
                    ${option.price[planType]?.toFixed(2)}
                    <span>/month</span>
                  </p>
                ) : ( */}
                <p>{option.price}</p>
                {/* )} */}
                <div className={styles.upgradeButton}>
                  <Button
                    type='primary'
                    onClick={() => handleCardClick(option)}
                    style={{
                      borderRadius: '6px',
                      fontSize: '16px',
                      height: '35px',
                      width: '100%',
                      marginTop: '5px',
                      background:
                        option.label === 'Free Trial' ? 'grey' : 'linear-gradient(88deg, #6A45F6 0%, #B84BF8 100.16%)',
                      border: '1px solid white',
                    }}>
                    {renderText(option.id)}
                  </Button>
                </div>
                {option.id === 'free_trial' && (isBetaOrTrialUser || isPaidTrialUser) ? (
                  <div className={styles.trialDays}>
                    <div>{daysLeft < 0 ? 'Expired ' + Math.abs(daysLeft) + ' days ago' : daysLeft + ' days left'}</div>
                  </div>
                ) : (
                  ''
                )}
                <div className={styles.features}>
                  {option.all_in_prev && <span style={{ color: '#7043ff' }}>All in {option.all_in_prev}</span>}
                  {option.features.slice(0, 8).map(({ label, value }: any) => (
                    <div key={label}>
                      {label === 'Convrt Points' ? (
                        <CreditsComponent points={option.convrtPoints} credits={value} />
                      ) : (
                        <>
                          <CheckOutlined
                            style={{
                              padding: '1.5px',
                              color: 'black',
                              fontSize: '12px',
                              marginRight: '5px',
                            }}
                          />
                          <span>
                            {label}
                            {value ? ':' : ''}
                          </span>{' '}
                          {value}
                        </>
                      )}
                    </div>
                  ))}
                  {selectedPlan?.id === option?.id &&
                    // option.id !== 'ai_meeting_setter' &&
                    (userDetails?.plan_type === 'trial' || userDetails?.plan_type === 'PAID_TRIAL') && (
                      <div className={styles.quantity}>
                        <div style={{ paddingRight: '138px' }}>{'Seats: '}</div>
                        <InputNumber
                          style={{ width: '55px' }}
                          size='small'
                          min={1}
                          max={100000}
                          defaultValue={1}
                          onChange={onQuantityChange}
                        />
                      </div>
                    )}
                  {selectedPlan?.id === option?.id &&
                    // option.id !== 'ai_meeting_setter' &&
                    (userDetails?.plan_type === 'trial' || userDetails?.plan_type === 'PAID_TRIAL') && (
                      <div>
                        <div className={styles.quantityWarmed}>
                          <div style={{ paddingRight: '15px' }}>{'Add Warmed TG Account: '}</div>
                          <InputNumber
                            style={{ width: '55px' }}
                            size='small'
                            min={0}
                            max={20}
                            defaultValue={0}
                            onChange={onTGQuantityChange}
                          />
                        </div>
                        {Math.floor(outreachSuite / 5) > 0 && (
                          <Popover
                            className={styles.outreachSuitePopover}
                            placement='top'
                            trigger='hover'
                            content='Every 5 Warmed TG accounts require an Outreach Suite'>
                            <div className={styles.outreachSuite}>{`Outreach Suite X ${Math.floor(
                              outreachSuite / 5,
                            )}`}</div>
                            <img src={info_icon} alt={''} className={styles.outreachSuiteInfoIcon} />
                          </Popover>
                        )}
                      </div>
                    )}
                  <div className={styles.seeMore}>
                    <Button type='text' onClick={showModal} style={{ color: '#7043ff' }}>
                      + More Features
                    </Button>
                  </div>
                  <div>
                    <Modal
                      className='PricingModal'
                      width={1200}
                      open={isModalOpen}
                      onCancel={handleCancel}
                      footer={null}
                      style={{
                        maxHeight: '800px',
                        borderRadius: '10px',
                      }}>
                      <PricingModal />
                    </Modal>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
      {/* </Row> */}
    </Form>
  )
}

export default PricingForm
