import React from 'react'
import { Modal, Button, List } from 'antd'
import { CheckCircleOutlined, CreditCardOutlined } from '@ant-design/icons'
import styles from './PaymentSummaryModal.module.scss'
import stripeLogo from 'LEGACY/Assets/svg/stripe_logo.svg'
import link_logo from 'LEGACY/Assets/svg/link_logo.svg'
import LoadingBox from '../../../General/LoadingBox/LoadingBox'
import Success from '../../../General/SuccessAnimation/Success'

interface PaymentSummaryModalProps {
  open: boolean
  onClose: () => void
  onPay: () => void
  selectedPlan?: 'market_dominance' | 'connect_plus' | 'go_to_market'
  // billingCycle: 'monthly' | 'three_months' | 'yearly'
  billingCycle: 'three_months' | 'yearly'
  quantity?: number
  convrtPoints: number
  defaultPaymentMethod: any
  totalPrice: any
  isLoading: boolean
  isCompleted: boolean
}

const PaymentSummaryModal: React.FC<PaymentSummaryModalProps> = ({
  open,
  onClose,
  onPay,
  selectedPlan,
  quantity,
  convrtPoints,
  defaultPaymentMethod,
  billingCycle,
  totalPrice,
  isLoading,
  isCompleted,
}) => {
  const planDetails = {
    // go_to_market: { name: 'Go to Market', pricePerUnit: { monthly: 99, three_months: 79, yearly: 68 } },
    // connect_plus: { name: 'Connect Plus', pricePerUnit: { monthly: 319, three_months: 259, yearly: 207 } },
    // market_dominance: { name: 'Market Dominance', pricePerUnit: { monthly: 649, three_months: 538, yearly: 422 } },
    go_to_market: { name: 'Go to Market', pricePerUnit: { three_months: 79, yearly: 68 } },
    connect_plus: { name: 'Connect Plus', pricePerUnit: { three_months: 259, yearly: 207 } },
    // market_dominance: { name: 'Market Dominance', pricePerUnit: { three_months: 538, yearly: 422 } },
    market_dominance: {
      name: 'Market Dominance',
      pricePerUnit: 'Talk to us',
    },
    ai_meeting_setter: {
      name: 'AI Meeting Setter',
      pricePerUnit: 'Talk to us',
    },
  }

  const cycleMapping = {
    monthly: 'Every Month',
    three_months: 'Every Three Months',
    six_months: 'Every Six Months',
    yearly: 'Every Year',
  }

  //   const cycleToInt = {
  //     monthly: 1,
  //     six_months: 6,
  //     yearly: 12,
  //   }

  // const { name } = selectedPlan && planDetails[selectedPlan]
  //   const totalPrice = pricePerUnit[billingCycle] * quantity * cycleToInt[billingCycle] + (convrtPoints / 50) * 2.95

  return (
    <Modal
      title={<div className={styles.modalTitle}>Confirm Your Order</div>}
      open={open}
      onCancel={onClose}
      footer={null}
      centered
      className={styles.paymentSummaryModal}>
      {isLoading ? (
        <div className={styles.loading}>
          <LoadingBox />
        </div>
      ) : isCompleted ? (
        <div className={styles.completed}>
          <Success />
          <h2>Thank You For Your Payment!</h2>
        </div>
      ) : (
        <>
          <List className={styles.summaryList}>
            {selectedPlan && (
              <List.Item className={styles.listItem}>
                <div className={styles.summaryItem}>
                  <span>Selected Plan: </span>
                  <span className={styles.planName}>
                    {quantity} x {planDetails[selectedPlan]?.name}
                    <span style={{ fontWeight: 400, color: '#666' }}>, {cycleMapping[billingCycle]}</span>
                  </span>
                </div>
              </List.Item>
            )}

            <List.Item className={styles.listItem}>
              <div className={styles.summaryItem}>
                <span>Convrt Points: </span>
                <span>{convrtPoints.toLocaleString()}</span>
              </div>
            </List.Item>
            <List.Item className={styles.listItem}>
              <div className={styles.summaryTotal}>
                <span>Total Price (Due Today): </span>
                <span>${totalPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
              </div>
            </List.Item>
          </List>
          <div className={styles.cardInfoSection}>
            {defaultPaymentMethod?.link ? (
              <img style={{ height: '20px', marginRight: '5px' }} src={link_logo} alt='' />
            ) : (
              <CreditCardOutlined className={styles.cardIcon} />
            )}
            {/* <span className={styles.cardText}>Paying with •••• •••• •••• {defaultPaymentMethod?.slice(-4)}</span> */}
            <span className={styles.cardText}>
              Paying with {`${defaultPaymentMethod?.link ? 'LINK' : ` •••• •••• •••• ${defaultPaymentMethod?.last4}`}`}
            </span>
          </div>
          <div className={styles.confirmationSection}>
            <CheckCircleOutlined className={styles.confirmationIcon} />
            <p className={styles.confirmationText}>Review your order details before proceeding to payment.</p>
          </div>
          <div className={styles.actionButtons}>
            <Button className={styles.reviewButton} onClick={onClose}>
              Review Order
            </Button>
            <Button type='primary' className={styles.payButton} onClick={onPay}>
              Pay Now
            </Button>
          </div>
          <div className={styles.poweredByStripe}>
            <span className={styles.poweredBy}>Powered by</span>
            <img src={stripeLogo} alt='Stripe' className={styles.stripeLogo} />
          </div>
        </>
      )}
    </Modal>
  )
}

export default PaymentSummaryModal
