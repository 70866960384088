import React from 'react'
import styles from './GaugeMeter.module.scss'

interface GaugeMeterProps {
  percentage?: number
  label?: string
  open?: boolean
}

const GaugeMeter: React.FC<GaugeMeterProps> = ({ percentage = 0, label = 'Solution Fit', open = false }) => {
  // Ensure percentage is between 0 and 100
  const normalizedPercentage = Math.min(Math.max(percentage, 0), 100)

  // Convert percentage to a rotation angle for the needle (-90° for 0%, 90° for 100%)
  const needleAngle = -90 + (180 * normalizedPercentage) / 100

  // Function to determine color based on percentage
  const getColor = (percent: number) => {
    if (percent < 30) return '#FDAE61'
    if (percent < 70) return '#A6D96A'
    return '#66BD63'
  }

  // Arc properties
  const radius = 80
  const centerX = 100
  const centerY = 100

  // Compute the arc endpoint.
  // The needle’s tip in polar coordinates is at:
  // tipAngle = -90 (base angle) + needleAngle = -180 + (180 * percentage / 100)
  const arcAngleDeg = -180 + (180 * normalizedPercentage) / 100
  const angleRad = (arcAngleDeg * Math.PI) / 180
  const endX = centerX + radius * Math.cos(angleRad)
  const endY = centerY + radius * Math.sin(angleRad)

  return (
    <div className={styles.gaugeContainer}>
      {open ? (
        <>
          <svg viewBox='0 0 200 120' className={styles.gauge}>
            {/* Wrap all gauge elements in a group that scales vertically by 0.9 and translates down by 6 */}
            <g transform='translate(0,6) scale(1,0.9)'>
              {/* Background Arc */}
              <path
                d='M20 100 A80 80 0 0 1 180 100'
                fill='none'
                stroke='#e0e0e0'
                strokeWidth='40'
                strokeLinecap='round'
              />

              {/* Colored Arc */}
              <path
                d={`M20 100 A80 80 0 0 1 ${endX} ${endY}`}
                fill='none'
                stroke={getColor(normalizedPercentage)}
                strokeWidth='40'
                strokeLinecap='round'
              />

              {/* Needle using the custom SVG */}
              <g transform={`translate(${centerX},${centerY}) rotate(${needleAngle + 15}) scale(2.75)`}>
                <g transform='translate(-4.93442, -21.7572)'>
                  <circle cx='4.93442' cy='21.7572' r='3.5' transform='rotate(-3.83353 4.93442 21.7572)' fill='black' />
                  <path d='M3.49217 0.233771L7.92409 21.0378L1.87547 21.4431L3.49217 0.233771Z' fill='black' />
                </g>
              </g>
            </g>
          </svg>
        </>
      ) : (
        <span className={`${styles.percentage}`} style={{ backgroundColor: getColor(normalizedPercentage) }}>
          {percentage}%
        </span>
      )}

      {/* Text Label */}
      <span className={styles.label}>{label}</span>
    </div>
  )
}

export default GaugeMeter
