import { createSlice } from '@reduxjs/toolkit'

interface HeatboxState {
  currentScreen: string
  menuOpen: boolean
  openAIComment: boolean
  AIComment: string
  checkComment: boolean
  checkLike: boolean
  sourceList: any
  fireLevel: any
  status?: 'idle' | 'loading' | 'succeeded' | 'failed'
  killedCard: string
  filterObject: any
  sort: string
  hasFilters?: boolean
  asc: boolean
  linkedinConnected?: boolean
  liked: string
}

const initialState = {
  currentScreen: 'cues',
  menuOpen: true,
  openAIComment: false,
  AIComment: '',
  checkComment: true,
  liked: '',
  checkLike: true,
  sourceList: undefined,
  fireLevel: undefined,
  status: 'idle',
  killedCard: '',
  filterObject: [
    {
      type: 'channel',
      is: 'is',
      value: 'linkedin',
    },
  ],
  sort: 'relevance',
  hasFilters: false,
  asc: false,
  linkedinConnected: true,
} as HeatboxState

export const heatboxSlice = createSlice({
  name: 'heatbox',
  initialState,
  reducers: {
    SET_CURRENT_SCREEN(state, action) {
      state.status = 'succeeded'
      state.currentScreen = action.payload
    },
    SET_MENU_OPEN(state, action) {
      state.status = 'succeeded'
      state.menuOpen = action.payload
    },
    SET_OPEN_AI_COMMENT(state, action) {
      state.status = 'succeeded'
      state.openAIComment = action.payload
    },
    SET_AI_COMMENT(state, action) {
      state.status = 'succeeded'
      state.AIComment = action.payload
    },
    SET_CHECK_COMMENT(state, action) {
      state.status = 'succeeded'
      state.checkComment = action.payload
    },
    SET_SOURCE_LIST(state, action) {
      state.status = 'succeeded'
      state.sourceList = action.payload
    },
    SET_FIRE_LEVEL(state, action) {
      state.status = 'succeeded'
      state.fireLevel = action.payload
    },
    SET_KILLED_CARD(state, action) {
      state.status = 'succeeded'
      state.killedCard = action.payload
    },
    SET_FILTER_OBJECT(state, action) {
      state.status = 'succeeded'
      state.filterObject = action.payload
    },
    SET_SORT(state, action) {
      state.status = 'succeeded'
      state.sort = action.payload
    },
    SET_HAS_FILTERS(state, action) {
      state.status = 'succeeded'
      state.hasFilters = action.payload
    },
    SET_ASC(state, action) {
      state.status = 'succeeded'
      state.asc = action.payload
    },
    SET_CHECK_LIKE(state, action) {
      state.status = 'succeeded'
      state.checkLike = action.payload
    },

    SET_LIKED(state, action) {
      state.status = 'succeeded'
      state.liked = action.payload
    },
    SET_LINKEDIN_CONNECTED(state, action) {
      state.status = 'succeeded'
      state.linkedinConnected = action.payload
    },
  },
})

export const {
  SET_CURRENT_SCREEN,
  SET_MENU_OPEN,
  SET_OPEN_AI_COMMENT,
  SET_AI_COMMENT,
  SET_CHECK_COMMENT,
  SET_SOURCE_LIST,
  SET_FIRE_LEVEL,
  SET_KILLED_CARD,
  SET_FILTER_OBJECT,
  SET_SORT,
  SET_HAS_FILTERS,
  SET_ASC,
  SET_CHECK_LIKE,
  SET_LIKED,
  SET_LINKEDIN_CONNECTED,
} = heatboxSlice.actions

export const heatboxReducer = heatboxSlice.reducer
