import React, { useMemo } from 'react'
import { Avatar } from 'antd'
import fallbackIcon1 from 'common/assets/svg/FallbackIcon1.png'
import fallbackIcon2 from 'common/assets/svg/FallbackIcon2.png'
import fallbackIcon3 from 'common/assets/svg/FallbackIcon3.png'
import fallbackIcon4 from 'common/assets/svg/FallbackIcon4.png'
import fallbackIcon5 from 'common/assets/svg/FallbackIcon5.png'
import fallbackIcon6 from 'common/assets/svg/FallbackIcon6.png'
import fallbackIcon7 from 'common/assets/svg/FallbackIcon7.png'
import fallbackIcon8 from 'common/assets/svg/FallbackIcon8.png'
import fallbackIcon9 from 'common/assets/svg/FallbackIcon9.png'

const fallbackIcons = [
  fallbackIcon1,
  fallbackIcon2,
  fallbackIcon3,
  fallbackIcon4,
  fallbackIcon5,
  fallbackIcon6,
  fallbackIcon7,
  fallbackIcon8,
  fallbackIcon9,
]

interface AvatarGroupProps {
  count?: number // Number of avatars to show (default: 4)
}

const AvatarGroup: React.FC<AvatarGroupProps> = ({ count = 4 }) => {
  const avatars = useMemo(
    () => Array.from({ length: count }, () => fallbackIcons[Math.floor(Math.random() * fallbackIcons.length)]),
    [count],
  )

  return (
    <Avatar.Group size='small'>
      {avatars.map((src, index) => (
        <Avatar key={index} src={src} />
      ))}
    </Avatar.Group>
  )
}

export default React.memo(AvatarGroup)
