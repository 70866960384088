import React from 'react'
import { Card, Skeleton } from 'antd'
import styles from './CampaignStatusOverview.module.scss'
import { Cell, Label, Pie, PieChart } from 'recharts'

interface CampaignStatusOverviewProps {
  total: any
  others: any
  paused: any
  completed: any
  running: any
  warming: any
  loading: boolean
  screenSize?: { width: number; height: number }
}
const CampaignStatusOverview: React.FC<CampaignStatusOverviewProps> = ({
  total,
  others,
  paused,
  completed,
  running,
  warming,
  loading,
  screenSize,
}) => {
  const data = [
    { name: 'Others', value: Number(others), color: '#EBECF0' },
    { name: 'Paused', value: Number(paused), color: '#FF5C5C' },
    { name: 'Warming', value: Number(warming), color: '#F9C628' },
    { name: 'Completed', value: Number(completed), color: '#02C3AB' },
    { name: 'Running', value: Number(running), color: '#19BCFE' },
  ]

  const maxValue = Math.max(+others, +paused, +warming, +completed, +running)
  const minWidth = maxValue > 99 ? 28 : 22

  const CustomLabel = ({ viewBox, labelText, value }: any) => {
    const { cx, cy } = viewBox
    return (
      <g>
        <text
          x={cx}
          y={cy - 9}
          className='recharts-text recharts-label'
          textAnchor='middle'
          dominantBaseline='central'
          alignmentBaseline='middle'
          fill='black'
          fontSize='20'
          fontWeight='600'>
          {!loading && value}
        </text>
        <text
          x={cx}
          y={cy + 9}
          className='recharts-text recharts-label'
          textAnchor='middle'
          dominantBaseline='central'
          alignmentBaseline='middle'
          fontSize='11'>
          {!loading && labelText}
        </text>
      </g>
    )
  }

  return (
    <Card className={`campaignStatsCard ${styles.card}`}>
      <h3>Campaigns Status {screenSize && screenSize.width > 1215 && 'Overview'}</h3>
      <div className={styles.body}>
        <PieChart width={100} height={100}>
          <Pie
            data={data}
            cx={45}
            cy={45}
            innerRadius={40}
            outerRadius={50}
            stroke='none'
            fill='#8884d8'
            dataKey='value'>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={loading ? '#EBECF0' : entry.color} />
            ))}
            <Label content={<CustomLabel labelText='Campaigns' value={total} />} position='center' />
          </Pie>
        </PieChart>
        {screenSize && (screenSize.width <= 1105 || screenSize.width > 1215) && (
          <div className={styles.progressDetails}>
            <div className={styles.legendRow}>
              <span className={styles.running}></span>
              <span className={styles.number} style={{ minWidth }}>
                {loading ? <Skeleton.Avatar shape='square' active={true} size={15} style={{}} /> : running}
              </span>
              <span className={styles.status}>Running</span>
            </div>
            <div className={styles.legendRow}>
              <span className={styles.warming}></span>
              <span className={styles.number} style={{ minWidth }}>
                {loading ? <Skeleton.Avatar shape='square' active={true} size={15} style={{}} /> : warming}
              </span>
              <span className={styles.status}>Warming</span>
            </div>
            <div className={styles.legendRow}>
              <span className={styles.completed}></span>
              <span className={styles.number} style={{ minWidth }}>
                {loading ? <Skeleton.Avatar shape='square' active={true} size={15} style={{}} /> : completed}
              </span>
              <span className={styles.status}>Completed</span>
            </div>
            <div className={styles.legendRow}>
              <span className={styles.paused}></span>
              <span className={styles.number} style={{ minWidth }}>
                {loading ? <Skeleton.Avatar shape='square' active={true} size={15} style={{}} /> : paused}
              </span>
              <span className={styles.status}>Paused</span>
            </div>
            <div className={styles.legendRow}>
              <span className={styles.others}></span>
              <span className={styles.number} style={{ minWidth }}>
                {loading ? <Skeleton.Avatar shape='square' active={true} size={15} style={{}} /> : others}
              </span>
              <span className={styles.status}>Others</span>
            </div>
          </div>
        )}
      </div>
    </Card>
  )
}

export default CampaignStatusOverview
