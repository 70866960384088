import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import styles from './SelectRow.module.scss'
import arrow_icon from 'LEGACY/Assets/svg/arrow_icon.svg'
import warning_icon from 'LEGACY/Assets/svg/warning_icon.svg'
import delete_outlined from 'LEGACY/Assets/svg/delete_outlined.svg'

interface SelectType {
  items: any
  isDisabled?: boolean
  className?: string
  row: any
  onOptionSelect: (object: any, row_id: number, type: string, group: string) => void
  hubspot: any
  showDeleteRow?: boolean
  deleteRow?: (row_id: string) => void
  updateWarning?: (rowId: string, hasWarning: boolean) => void
  enableMap?: boolean
  selectedHubspot?: string[]
}

interface ItemType {
  dataSource?: string
  label: string
  value: string
  type: string
  parent_label?: string
}

export const SelectRow: React.FC<SelectType> = ({
  items,
  onOptionSelect,
  row,
  hubspot,
  deleteRow,
  showDeleteRow = true,
  updateWarning,
  enableMap = false,
  selectedHubspot = [],
}) => {
  const [selectedType, setSelectedType] = useState<string | null>(null)
  const [filteredData, setFilteredData] = useState(hubspot)
  const [selectTypeToValue, setSelectTypeToValue] = useState<any>(row.to)
  const [selectTypeFromValue, setSelectTypeFromValue] = useState<any>(row.from)
  const [fieldMapping, setFieldMapping] = useState<any>({})
  useEffect(() => {
    // hanle filder
    if (selectedHubspot.length > 0) {
      setFilteredData((filteredData: any) => {
        const newFilteredData = filteredData.map((optionGroup: any) => {
          return {
            ...optionGroup,
            options: optionGroup?.options
              ?.map((o: any) => ({ ...o, disabled: selectedHubspot.includes(o.value) }))
              .sort((a: any, b: any) => a.label.localeCompare(b.label)),
          }
        })
        return newFilteredData
      })
    }
  }, [selectedHubspot])

  useEffect(() => {
    setFieldMapping({})
  }, [])

  useEffect(() => {
    setSelectTypeFromValue(row.from)
    setSelectTypeToValue(row.to)

    const listOptions: ItemType[] = []
    items.forEach((item: any) => {
      listOptions.push(...item.options)
    })

    const selectedFromOption = listOptions.find((item) => item.value === row.from.value)
    setSelectedType(selectedFromOption?.type || null)
  }, [row])

  useEffect(() => {
    if (!updateWarning) return
    const hasWarning = !selectedType || selectTypeToValue.label === 'Select'
    updateWarning(row.id, hasWarning)
  }, [selectTypeToValue, selectedType])

  const handleSiteChange = (value: string, option: any) => {
    setSelectTypeFromValue(value)
    setSelectedType(option)
    const filtered = hubspot.map((item: any) => ({
      ...item,
      options: item.options.filter((optionItem: any) => optionItem.type === option.type),
    }))
    // filtered by parent
    const filterData = filtered.filter((item: any) => {
      return item.label === option.parent_label
    })

    if (filterData.length > 0) {
      setFilteredData(filterData)
    }

    if (!fieldMapping[row.id]) {
      fieldMapping[row.id] = {
        caption: `row-${row.id}`,
        id: row.id,
        from: {},
        to: {},
        value: value,
        group: option.parent_label,
      }
    }

    onOptionSelect({ value: option.value, label: option.label }, row.id, 'from', option.parent_label)
  }

  const handleHubspotChange = (value: string, option: any) => {
    setSelectTypeToValue(value)
    if (!fieldMapping[row.id]) {
      fieldMapping[row.id] = {
        caption: `row-${row.id}`,
        id: row.id,
        from: {},
        to: {},
        value: value,
        group: option.parent_label,
      }
    }

    onOptionSelect({ value: option.value, label: option.label }, row.id, 'to', option.parent_label)
  }

  return (
    <div className={styles.mappingListBody}>
      <Select
        showSearch
        placeholder='Select Field'
        value={selectTypeFromValue}
        options={items}
        disabled={false}
        className={styles.left_input}
        onChange={handleSiteChange}
        optionFilterProp='children'
        filterOption={(input, option) => (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '')?.toLowerCase()?.localeCompare((optionB?.label ?? '')?.toLowerCase())
        }
      />
      <img src={arrow_icon} alt='arrow' />
      <Select
        showSearch
        defaultValue='Select Field'
        value={selectTypeToValue}
        options={filteredData}
        disabled={!selectedType}
        className={styles.right_input}
        onChange={handleHubspotChange}
        optionFilterProp='children'
        filterOption={(input, option) => (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())}
        filterSort={(optionA, optionB) => {
          return (optionA?.label ?? '')?.toLowerCase()?.localeCompare((optionB?.label ?? '')?.toLowerCase())
        }}
      />
      {showDeleteRow ? (
        <span
          onClick={() => {
            deleteRow && deleteRow(row.id)
          }}
          className={styles.delete}>
          <img src={delete_outlined} alt='delete-icon' />
        </span>
      ) : (
        <>
          {(!selectedType || selectTypeToValue === 'Select') && (
            <img src={warning_icon} className={styles.delete} alt='warning-icon' />
          )}
        </>
      )}
    </div>
  )
}
